import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const contactUsApi = createApi({
  reducerPath: "contactUsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    contactUs: builder.mutation<
      void,
      {
        full_name?: String;
        phone_number?: String;
        email?: String;
        address?: String;
        message?: String;
      }
    >({
      query: ({ full_name, phone_number, email, address, message }) => {
        return {
          url: `/contact-us/`,
          method: "POST",
          body: { full_name, phone_number, email, address, message },
        };
      },
    }),
  }),
});

export const { useContactUsMutation } = contactUsApi;
