import "../../styles/HealthPackages.css";

// Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/free-mode";
// import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper";
import ShimmerCards from "../UI/uiComponents/LoadingShimmer/ShimmerCards";
import { Link } from "react-router-dom";
import { gotoViewMore } from "../../utils/routeNames";
// import { Test } from "../../models/tests";

interface Props {
  header: string | undefined;
  type: "health-packages" | "test-packages";
  view?: string;
  isLoading?: boolean;
  data?: any[] | undefined;
  children: React.ReactNode;
}

export default function HealthPackages({
  header,
  type,
  view,
  isLoading = false,
  data,
  children,
}: Props) {
  return (
    <section className={type}>
      <div className="capsule">
        {isLoading || data?.length ? (
          <div className="header health-packages-header">
            <h2>{header}</h2>
            {view && <Link to={gotoViewMore("package")}>{view}</Link>}
          </div>
        ) : null}
        {isLoading ? (
          <ShimmerCards />
        ) : data?.length ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            slidesPerGroup={1}
            // loop={true}
            // loopFillGroupWithBlank={true}
            navigation={true}
            cssMode={true}
            breakpoints={{
              200: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 4,
              },
              1600: {
                slidesPerView: 4,
              },
            }}
            modules={[Navigation]}
            className="wrapper"
          >
            {children}
          </Swiper>
        ) : null}
      </div>
    </section>
  );
}
