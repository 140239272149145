import HealthPackages from "../../components/utils/HealthPackages";
import TitlePicture from "../../components/utils/TitlePicture";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
import { SwiperSlide } from "swiper/react";
import Cards from "../../components/UI/cards/HealthPackagesCards";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import Hero from "../../components/utils/Hero";
import { useGetPhysiotherapyQuery } from "../../services/consult-doctor";
import { useGetMarketingBannersQuery } from "../../services/home";

export default function PhysiotherapyPage() {
  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();
  const { data: phyData, isLoading } = useGetPhysiotherapyQuery();

  // const crumbs = [
  //   // Must be in order after home
  //   {
  //     name: "Physiotherapy",
  //     link: "",
  //   },
  // ];

  return (
    <>
      {/* <Breadcrumb crumbs={crumbs} /> */}
      <TitlePicture
        header={bannerData?.physiotherapy_service[0]?.title ?? "Physiotherapy"}
      />
      <Hero
        hasContainer={true}
        data={bannerData?.physiotherapy_banner}
        isLoading={isBannerLoading}
      />
      <HorizontalTab
        label1="Description"
        label2="Included"
        label3="Excluded"
        label1data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.physiotherapy_service[0]?.description ?? "",
            }}
          />
        }
        label2data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.physiotherapy_service[0]?.included ?? "",
            }}
          />
        }
        label3data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.physiotherapy_service[0]?.excluded ?? "",
            }}
          />
        }
      />

      <HealthPackages
        header="Book Physiotherapy Services"
        type="health-packages"
        view="View More"
        isLoading={isLoading}
        // data={doctorsData?.results}
        data={phyData?.results}
      >
        {phyData?.results?.map((detail) => {
          return (
            <SwiperSlide>
              <Cards
                id={detail?.id}
                slug={detail?.slug}
                image={detail?.icon}
                header={detail?.title}
                actualPrice={detail?.discounted_price}
                crossedPrice={detail?.market_price}
                tests={detail?.test}
                type="physiotherapy-pkg"
                showFeatures={false}
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages>
    </>
  );
}
