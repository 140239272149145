import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AddressType, ConfirmedOrder } from "../CheckoutAccordion";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import { getPriceStatement } from "../../../../utils/priceCommas";
import { ButtonBlue } from "../Button";
import { serializeArrOfString } from "../../../../utils/serializeQueryString";
import {
  useKhaltiV2Mutation,
  useLazyCheckoutQuery,
} from "../../../../services/checkout";
import { toast } from "react-toastify";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import "../../../../styles/CheckoutModal/index.css";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmedOrder?: ConfirmedOrder[];
  confirmedDelAddr?: AddressType;
  confirmedBillAddr?: AddressType;
  confirmedPayOption?: "khalti" | "cod";
  totalAmount?: number | undefined;
  // For API
  selectedShipAddr: number | undefined;
  selectedBillAddr: number | undefined;
  selectedCartItems: Array<string | undefined>;
  selectedPkgCartItems: Array<string | undefined>;
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  border: "none",
  boxShadow: 0,
  p: 4,
  outline: "none",
  borderRadius: "7px",
  padding: "0px",
  width: 710,
  height: "600px",
  maxWidth: "90%",
};

const CheckoutModal = ({
  open,
  setOpen,
  confirmedOrder,
  confirmedDelAddr,
  confirmedBillAddr,
  confirmedPayOption,
  totalAmount,
  // For API
  selectedShipAddr,
  selectedBillAddr,
  selectedCartItems,
  selectedPkgCartItems,
}: Props) => {
  const handleClose = () => setOpen(false);

  // API: Cash on Delivery
  const [trigger, { isSuccess, isLoading, isError }] = useLazyCheckoutQuery();

  // API: Khalti V2
  const [
    checkoutKhaltiV2,
    {
      data: khaltiResponse,
      isLoading: loadingKhaltiV2,
      isSuccess: successKhaltiV2,
      isError: errorKhaltiV2,
    },
  ] = useKhaltiV2Mutation();

  // Cash on Delivery
  const handleCheckout = (e: any) => {
    e?.preventDefault();
    if (
      selectedShipAddr &&
      selectedBillAddr &&
      (selectedCartItems?.length || selectedPkgCartItems?.length)
    ) {
      const queryString = `${serializeArrOfString(
        selectedCartItems,
        "cart_id"
      )}&${serializeArrOfString(selectedPkgCartItems, "scart_id")}`;
      const amtQs = `&total_amount=${totalAmount}&`;
      trigger({
        shipping_address_id: selectedShipAddr,
        billing_address_id: selectedBillAddr,
        cartIdsQueryString: queryString,
        totalAmountQueryString: amtQs,
        paymentMethod: "cod",
      });
    }
  };

  // Khalti
  const handleKhaltiV2 = () => {
    if (
      selectedShipAddr &&
      selectedBillAddr &&
      (selectedCartItems?.length || selectedPkgCartItems?.length)
    ) {
      checkoutKhaltiV2({
        // For when we have to explicitly convert string[] to number[].
        // cart_id: selectedCartItems?.length
        //   ? selectedCartItems?.map((item) =>
        //       item ? parseInt(item) : undefined
        //     )
        //   : undefined,
        // scart_id: selectedPkgCartItems?.length
        //   ? selectedPkgCartItems?.map((item) =>
        //       item ? parseInt(item) : undefined
        //     )
        //   : undefined,
        cart_id: selectedCartItems?.length ? selectedCartItems : undefined,
        scart_id: selectedPkgCartItems?.length
          ? selectedPkgCartItems
          : undefined,
        total_amount: totalAmount,
        payment_method: "khalti",
        shipping_address_id: selectedShipAddr,
        billing_address_id: selectedBillAddr,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessages?.checkout);
      setTimeout(() => window.location.replace("/orders"), 1000);
    } else if (isError) {
      toast.error(errorMessages?.networkError);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (errorKhaltiV2) {
      toast.error(errorMessages?.txnFailed);
    } else if (successKhaltiV2) {
      if (khaltiResponse?.payment_url) {
        window?.location?.replace(khaltiResponse?.payment_url);
      } else {
        toast.error(errorMessages?.txnFailed);
      }
    }
  }, [errorKhaltiV2, successKhaltiV2, khaltiResponse]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <div className="modal checkout-modal">
          <div className="modalHeader">
            <h4>Checkout</h4>
          </div>
          <div className="modalContent">
            <div className="orders">
              <div className="heading">
                <p className="title">Orders</p>
                <p className="total">Total</p>
              </div>

              {confirmedOrder?.length ? (
                <>
                  {confirmedOrder?.map((or, idx) => (
                    <div className="list" key={idx}>
                      <div className="image-title">
                        <div className="image">
                          <img src={resolveImgPath(or?.img)} />
                        </div>
                        <div className="title-qty">
                          <p className="title">{or?.name}</p>
                          <p className="qty">Qty: {or?.qty}</p>
                        </div>
                      </div>
                      <div className="price">
                        <p className="cost">{getPriceStatement(or?.price)}</p>
                      </div>
                      <div className="total">
                        <p className="cost">{getPriceStatement(or?.total)}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            {/* Shipping Address */}
            <div className="address">
              <div className="heading">
                <p className="title">Shipping Address</p>
              </div>
              <div className="content">
                <p className="title">{`${confirmedDelAddr?.name}, ${confirmedDelAddr?.phone}`}</p>
                <p className="address-desc">{`${confirmedDelAddr?.address}, ${confirmedDelAddr?.district}, ${confirmedDelAddr?.province}`}</p>
              </div>
            </div>
            {/* Billing Address */}
            <div className="address">
              <div className="heading">
                <p className="title">Billing Address</p>
              </div>
              <div className="content">
                <p className="title">{`${confirmedBillAddr?.name}, ${confirmedBillAddr?.phone}`}</p>
                <p className="address-desc">{`${confirmedBillAddr?.address}, ${confirmedBillAddr?.district}, ${confirmedBillAddr?.province}`}</p>
              </div>
            </div>
            {/* Finish */}
            <div className="finish">
              <div className="heading">
                <p className="title">Confirmation</p>
              </div>
              <div className="content">
                <h4 className="total">
                  <small>Total:</small>
                  {getPriceStatement(totalAmount)} /-
                </h4>
                {confirmedPayOption === "cod" ? (
                  <ButtonBlue
                    name={isLoading ? "Processing" : "Checkout"}
                    onClick={handleCheckout}
                    disabled={isLoading}
                  />
                ) : (
                  <ButtonBlue
                    name={loadingKhaltiV2 ? "Processing" : "Proceed To Payment"}
                    onClick={handleKhaltiV2}
                    disabled={loadingKhaltiV2}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footData">
            <h4>
              <small>Total:</small>
              {getPriceStatement(totalAmount)} /-
            </h4>
          </div> */}
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
