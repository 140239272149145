import { useState, useEffect, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  // boolValidation,
  nameValidation,
  phoneNumberValidation,
  stringValidation,
} from "../../../../../validations";
import {
  useEditDeliveryAddressMutation,
  useGetSelectedDeliveryAddressQuery,
} from "../../../../../services/user";
import style from "../../../../../styles/input.module.css";
import alertStyle from "../../../../../styles/Alert/alert.module.css";
import Input from "../../Formik/Input";
import Select from "../../Formik/Select";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { errorMessages } from "../../../../../utils/alertMessages";
import { ButtonBlue, ButtonWhite } from "../../Button";
import { useGetConfigQuery } from "../../../../../services/config";
// import CheckBox from "../../Formik/Checkbox";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  type?: "shipping" | "billing";
}

interface InitialValuesType {
  receiver_name: string;
  receiver_contact_number: string;
  address: string;
  state_id: string;
  city_id: string;
  // is_default: boolean;
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vmin",
  height: 500,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "7px",
  overflow: "auto",
};

const validationSchema = Yup.object({
  receiver_name: nameValidation(),
  receiver_contact_number: phoneNumberValidation(),
  address: stringValidation(),
  state_id: stringValidation("Select One"),
  city_id: stringValidation("Select One"),
  // is_default: boolValidation("Required", false),
});

const DeliveryAddressEditModal = ({
  open,
  setOpen,
  id = "",
  type = "shipping",
}: Props) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const handleClose = () => setOpen(false);

  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    receiver_name: "",
    receiver_contact_number: "",
    address: "",
    state_id: "",
    city_id: "",
    // is_default: false,
  });

  // API: Get Delivery Address
  const {
    data: delAddr,
    isFetching: isLoadingDelAddr,
    isError: isErrorDelAddr,
  } = useGetSelectedDeliveryAddressQuery(
    { id },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  // API: Get locations
  const {
    data: locationData,
    isLoading: isLoadingLocations,
    isError: isLocationsError,
  } = useGetConfigQuery();

  // API: Edit Delivery Address
  const [
    editDeliveryAddress,
    { isLoading: editing, isSuccess: isEditSuccess, isError: isEditError },
  ] = useEditDeliveryAddressMutation();

  const handleAddressEdit = (values: InitialValuesType) => {
    if (type === "shipping") {
      editDeliveryAddress({
        ...values,
        is_only_shipping_address: true,
        is_only_billing_address: false,
        id,
      });
    } else {
      editDeliveryAddress({
        ...values,
        is_only_shipping_address: false,
        is_only_billing_address: true,
        id,
      });
    }
  };

  useEffect(() => {
    if (isEditSuccess) {
      setOpen(false);
    }
  }, [isEditSuccess]);

  // Fill Edit Form
  useEffect(() => {
    if (delAddr) {
      const cityId = delAddr?.state_obj?.district
        ?.find((dis) => dis?.name === delAddr?.city)
        ?.id?.toString();

      setInitialValues({
        receiver_name: delAddr?.receiver_name ?? "",
        receiver_contact_number: delAddr?.receiver_contact_number ?? "",
        address: delAddr?.address ?? "",
        state_id: delAddr?.state_obj?.id
          ? delAddr?.state_obj?.id?.toString()
          : "",
        city_id: cityId ?? "",
        // is_default: delAddr?.is_default,
      });
    }
  }, [delAddr]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <div className="modalBox">
          <div>
            <h4>Edit {type === "shipping" ? "Shipping" : "Billing"} Address</h4>
          </div>
          <div className="accountOutlet no-shadow no-padding">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => handleAddressEdit(values)}
              validationSchema={validationSchema}
              innerRef={formikRef}
              enableReinitialize={true}
            >
              {() => (
                <Form>
                  <div className="inputs">
                    <div className={style.inputContainer}>
                      <Input
                        type="text"
                        name="receiver_name"
                        label="Full Name"
                        placeholder="Enter Full Name"
                        labelLogo={<PersonOutlineOutlined />}
                        disabled={editing || isLoadingDelAddr}
                      />
                    </div>
                    <div className={style.inputContainer}>
                      <Input
                        type="text"
                        name="receiver_contact_number"
                        label="Phone Number"
                        placeholder="Enter 10 digit mobile number"
                        labelLogo={<LocalPhoneOutlined />}
                        disabled={editing || isLoadingDelAddr}
                      />
                    </div>
                    <div className={style.inputContainer}>
                      <Select
                        name="state_id"
                        label="Province"
                        labelLogo={<LocationOnOutlinedIcon />}
                        disabled={isLoadingLocations || isLoadingDelAddr}
                      >
                        <option value="" disabled>
                          Select Province
                        </option>
                        {locationData?.state &&
                          locationData?.state?.map((st, idx) => (
                            <option value={st?.id} key={idx}>
                              {st?.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                    <div className={style.inputContainer}>
                      <Select
                        name="city_id"
                        label="District"
                        labelLogo={<LocationOnOutlinedIcon />}
                        disabled={isLoadingLocations || isLoadingDelAddr}
                      >
                        <option value="" disabled>
                          Select District
                        </option>
                        {/* If Province is touched then load only those districts that belong to that province. */}
                        {formikRef?.current?.touched?.state_id ? (
                          <>
                            {locationData?.district &&
                              locationData?.district
                                ?.filter((ds) => {
                                  return (
                                    ds?.state?.toString() ===
                                    formikRef?.current?.values?.state_id
                                  );
                                })
                                ?.map((district, idx) => (
                                  <option value={district?.id} key={idx}>
                                    {district?.name}
                                  </option>
                                ))}
                          </>
                        ) : (
                          <>
                            {locationData?.district?.map((district, idx) => (
                              <option value={district?.id} key={idx}>
                                {district?.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Select>
                    </div>
                    <div className={style.inputContainer}>
                      <Input
                        type="text"
                        name="address"
                        label="Address"
                        placeholder="Enter exact delivery address"
                        labelLogo={<LocationOnOutlinedIcon />}
                        disabled={editing || isLoadingDelAddr}
                      />
                    </div>

                    <div className={alertStyle.alertContainer}>
                      {/* Error Loading Delivery Address */}
                      {isErrorDelAddr && (
                        <Alert severity="error">
                          {errorMessages?.networkError}
                        </Alert>
                      )}
                      {/* Autofill Error */}
                      {isLocationsError && (
                        <Alert severity="error">
                          {errorMessages?.locations}
                        </Alert>
                      )}
                      {/* Edit Delivery Address Error */}
                      {isEditError && (
                        <Alert severity="error">
                          {errorMessages?.networkError}
                        </Alert>
                      )}
                    </div>
                  </div>
                  {/* <CheckBox
                    id="is_default"
                    name="is_default"
                    label={`Set as default ${type} address`}
                    labelFor="is_default"
                    disabled={editing}
                    defaultChecked={delAddr?.is_default}
                  /> */}
                </Form>
              )}
            </Formik>
          </div>

          <div className="buttonGroup">
            <ButtonBlue
              name={editing ? "Editing" : "Edit"}
              onClick={() => formikRef?.current?.submitForm()}
              disabled={editing}
            />
            <ButtonWhite name="Cancel" onClick={handleClose} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeliveryAddressEditModal;
