import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { useGetDeliveryAddressQuery } from "../../../../../services/user";
import { errorMessages } from "../../../../../utils/alertMessages";
import "../../../VerticalAppBarComponents/ShippingAddress/ShippingAddress.css";
import DeliveryAddressModal from "../../DeliveryAddressModal";
import { ButtonBlue } from "../../Button";
import DeliveryAddressEditModal from "../../DeliveryAddressModal/Edit";
import NoData from "../../../../utils/NoData";
import { AddressType } from "../../CheckoutAccordion";

interface Props {
  setExpanded: React.Dispatch<
    React.SetStateAction<"step1" | "step2" | "step3" | "step4">
  >;
  // setStep2: React.Dispatch<React.SetStateAction<boolean>>;
  setNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedShipAddr: React.Dispatch<React.SetStateAction<number | undefined>>;
  setConfirmedDelAddr?: React.Dispatch<React.SetStateAction<AddressType>>;
}

const ShippingAddress = ({
  setExpanded,
  // setStep2,
  setNextStep,
  setSelectedShipAddr,
  setConfirmedDelAddr,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // For Edit
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const [editAddressId, setEditAddressId] = useState<string>("");
  // Address Radio
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  // Get Added Delivery Addresses
  const {
    data: delAddrData,
    isError: isDelAddrError,
  } = useGetDeliveryAddressQuery(null, { refetchOnMountOrArgChange: true });

  const handleSelectAddress = (e: any, id: number) => {
    setSelectedAddress(e?.target?.value);
    setSelectedShipAddr(id);
    // For Checkout Confirmation Modal
    const selected = delAddrData?.results?.find((d) => d?.id === id);
    if (selected && setConfirmedDelAddr) {
      setConfirmedDelAddr({
        name: selected?.receiver_name,
        phone: selected?.receiver_contact_number,
        province: selected?.state,
        district: selected?.city,
        address: selected?.address,
      });
    }
  };

  useEffect(() => {
    if (delAddrData) {
      if (delAddrData?.results) {
        // Find the default shipping address.
        const defaultAddress = delAddrData?.results?.find(
          (d) => d?.is_default && d?.is_only_shipping_address
        );
        setSelectedAddress(
          defaultAddress?.id ? `address-${defaultAddress?.id}` : ""
        );
        setSelectedShipAddr(defaultAddress?.id);
        // For Checkout Confirmation Modal
        if (setConfirmedDelAddr) {
          setConfirmedDelAddr({
            name: defaultAddress?.receiver_name,
            phone: defaultAddress?.receiver_contact_number,
            province: defaultAddress?.state,
            district: defaultAddress?.city,
            address: defaultAddress?.address,
          });
        }
      }
    }
  }, [delAddrData]);

  return (
    <Typography>
      {delAddrData?.results?.filter((d) => d?.is_only_shipping_address)
        ?.length ? (
        delAddrData?.results
          ?.filter((d) => d?.is_only_shipping_address)
          ?.map((d, idx) => (
            <div className="accordionInputLabel" key={idx}>
              <input
                type="radio"
                id={`address-${d?.id}`}
                name="shipping-addresses"
                value={`address-${d?.id}`}
                // checked={d?.is_default}
                checked={selectedAddress === `address-${d?.id}`}
                onChange={(e) => handleSelectAddress(e, d?.id)}
              />
              {/* <label htmlFor="address1"> */}
              <label htmlFor={`address-${d?.id}`} className="address-wrapper">
                <div className="addressDiv">
                  <div className="personalInfo">
                    <p className="name">
                      {/* Ramesh Kumar,<span className="number">9800000000</span> */}
                      {d?.receiver_name},
                      <span className="number">
                        {d?.receiver_contact_number}
                      </span>
                    </p>
                    <p
                      className="edit"
                      onClick={(e) => {
                        e?.preventDefault();
                        setEditAddressId(d?.id?.toString());
                        handleOpenEdit();
                      }}
                    >
                      Edit
                    </p>
                  </div>

                  <p className="location">
                    {d?.address}, {d?.city}, {d?.state}, {d?.country}
                  </p>
                  {/* <ButtonBlue name="Deliver Here" /> */}
                  {selectedAddress === `address-${d?.id}` && (
                    <ButtonBlue
                      name="Deliver Here"
                      // onClick={() => setStep2(true)}
                      onClick={() => {
                        // setExpanded("step2");
                        setExpanded("step3");
                        // setStep2(true);
                        setNextStep(true);
                      }}
                      disabled={!selectedAddress}
                    />
                  )}
                </div>
              </label>
            </div>
          ))
      ) : (
        <NoData
          containerHeight="50%"
          imgWidth="25%"
          msg={errorMessages?.noDeliveryAddress}
        />
        // <Alert severity="error">{errorMessages?.noDeliveryAddress}</Alert>
      )}

      <div className="add-new">
        <p onClick={handleOpen}>
          <AddCircleOutlineSharpIcon /> Add a new address/guest
        </p>
      </div>

      {/* Add New Address */}
      <DeliveryAddressModal open={open} setOpen={setOpen} />

      {/* Edit Address */}
      <DeliveryAddressEditModal
        open={openEdit}
        setOpen={setOpenEdit}
        id={editAddressId}
      />

      <div style={{ width: "100%" }}>
        {/* Get Delivery Address Error */}
        {isDelAddrError && (
          <Alert severity="error">{errorMessages?.deliveryAddress}</Alert>
        )}
      </div>
    </Typography>
  );
};

export default ShippingAddress;
