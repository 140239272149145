import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useAddToPackageCartMutation,
  useAddToTestPackageCartMutation,
} from "../../../../../services/cart";
import Alert from "@mui/material/Alert";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  errorMessages,
  successMessages,
} from "../../../../../utils/alertMessages";
import { resolveImgPath } from "../../../../../utils/imagePathResolver";
import ConfirmationModal from "../../../uiComponents/ConfirmationModal";
import {
  useDeleteFromPackageWishlistMutation,
  useGetPackageWishlistQuery,
} from "../../../../../services/wishlist";
import { ButtonBlue } from "../../../uiComponents/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getPriceStatement } from "../../../../../utils/priceCommas";
import useServiceDetail from "../../../../../hooks/useServiceDetail";
import PackageDetailModal from "../../../uiComponents/PackageDetailModal";
import { getPackageModalType } from "../../../../../utils/getServiceType";

interface Props {
  setIsNoPackages: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingPkg: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackageWishlist = ({ setIsNoPackages, setLoadingPkg }: Props) => {
  // Package Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // Get User Id
  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );

  // Move to cart product
  const [movedProductId, setMovedProductId] = useState<string | number>("");
  const [movedTestId, setMovedTestId] = useState<string | number>("");

  // For single delete.
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  // Get API
  const {
    data: packagesData,
    isLoading: loadingPackages,
  } = useGetPackageWishlistQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // Delete API
  const [
    deleteFromWishlist,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteFromPackageWishlistMutation();

  const handleDeleteFromWishlist = (package_id: number | undefined) => {
    deleteFromWishlist({ package_id });
  };

  // API: Move to Cart
  const [
    moveToCart,
    { error: moveError, isLoading: moving, isSuccess: isMoveSuccess },
  ] = useAddToPackageCartMutation();

  // API: Move to Cart
  const [
    moveTestToCart,
    {
      error: moveTestError,
      isLoading: movingTest,
      isSuccess: isMoveTestSuccess,
    },
  ] = useAddToTestPackageCartMutation();

  const handleMoveToCart = (
    package_id: number | undefined,
    type: "package" | "test"
  ) => {
    if (type === "package") {
      moveToCart({
        package_id,
        user_id: userProfileId,
      });
    } else {
      moveTestToCart({
        test_id: package_id,
        user_id: userProfileId,
      });
    }
  };

  // Delete Side Effects.
  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteFromWishlist);
      handleCloseDelete();
    }
  }, [isDeleteError, isDeleteSuccess]);

  // Move Package Side Effects
  useEffect(() => {
    if (isMoveSuccess) {
      toast.success(successMessages?.addToCart);
      setMovedProductId("");
    } else if (moveError) {
      if ("status" in moveError) {
        const errMsg: any =
          moveError?.status === 400 || moveError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
      setMovedProductId("");
    }
  }, [isMoveSuccess, moveError]);

  // Move Test Side Effects
  useEffect(() => {
    if (isMoveTestSuccess) {
      toast.success(successMessages?.addToCart);
      setMovedTestId("");
    } else if (moveTestError) {
      if ("status" in moveTestError) {
        const errMsg: any =
          moveTestError?.status === 400 || moveTestError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
      setMovedTestId("");
    }
  }, [isMoveTestSuccess, moveTestError]);

  useEffect(() => {
    setLoadingPkg(loadingPackages);
    if (packagesData?.length) setIsNoPackages(false);
    else setIsNoPackages(true);
  }, [packagesData, loadingPackages]);

  return (
    <>
      {packagesData?.length ? (
        <div className="services">
          <p className="greyText">
            Services in my wishlist ({packagesData?.length ?? 0})
          </p>
          {packagesData?.length ? (
            <>
              {packagesData?.map((pkg, idx) => (
                <div className="service" key={idx}>
                  {/* <input
                type="checkbox"
                id="service1"
                name="service1"
                value="diabetes"
              /> */}
                  {pkg?.package ? (
                    <label htmlFor="service1">
                      <div className="imgPrice">
                        <img
                          //   src="assets/images/Vector (1).svg"
                          src={
                            pkg?.package?.icon
                              ? resolveImgPath(pkg?.package?.icon)
                              : "assets/images/Vector (1).svg"
                          }
                          alt={pkg?.package?.title}
                          onClick={() => {
                            handleOpen(
                              getPackageModalType(pkg?.package),
                              pkg?.package?.slug
                            );
                          }}
                        />
                        <div className="namePrice">
                          <p
                            className="name"
                            onClick={() => {
                              handleOpen(
                                getPackageModalType(pkg?.package),
                                pkg?.package?.slug
                              );
                            }}
                          >
                            {pkg?.package?.title}
                          </p>
                          <ul className="data">
                            {pkg?.package?.test?.slice(0, 2)?.map((d, idx) => (
                              <li key={idx}>{d?.title}</li>
                            ))}
                          </ul>
                          <p className="price">
                            {getPriceStatement(
                              pkg?.package?.discounted_price ??
                                pkg?.package?.market_price
                            )}
                          </p>
                        </div>
                      </div>
                      <ButtonBlue
                        name={
                          moving && movedProductId === pkg?.package?.id
                            ? "Adding"
                            : "Add to Cart"
                        }
                        onClick={() => {
                          setMovedProductId(pkg?.package?.id ?? "");
                          handleMoveToCart(pkg?.package?.id, "package");
                        }}
                        disabled={moving && movedProductId === pkg?.package?.id}
                      />
                      <div className="logos">
                        {/* <FavoriteBorderOutlinedIcon className="heart" /> */}
                        <CloseOutlinedIcon
                          onClick={() => {
                            setDeleteId(pkg?.id);
                            handleOpenDelete();
                          }}
                          className="delete-icon"
                        />
                      </div>
                    </label>
                  ) : pkg?.tests ? (
                    <label htmlFor="service1">
                      <div className="imgPrice">
                        <img
                          src={
                            pkg?.tests?.icon
                              ? resolveImgPath(pkg?.tests?.icon)
                              : "assets/images/Vector (1).svg"
                          }
                          alt={pkg?.tests?.title}
                          onClick={() => {
                            handleOpen("test-pkg", pkg?.tests?.slug);
                          }}
                        />
                        <div className="namePrice">
                          <p
                            className="name"
                            onClick={() => {
                              handleOpen("test-pkg", pkg?.tests?.slug);
                            }}
                          >
                            {pkg?.tests?.title}
                          </p>
                          {/* <p
                      className="data"
                      dangerouslySetInnerHTML={{
                        __html: pkg?.tests?.description ?? "",
                      }}
                    /> */}
                          <ul className="data">
                            {pkg?.tests?.included_tests
                              ?.slice(0, 2)
                              ?.map((d, idx) => (
                                <li key={idx}>{d}</li>
                              ))}
                          </ul>
                          <p className="price">
                            {getPriceStatement(
                              pkg?.tests?.discounted_price ??
                                pkg?.tests?.market_price
                            )}
                          </p>
                        </div>
                      </div>
                      <ButtonBlue
                        name={
                          movingTest && movedTestId === pkg?.tests?.id
                            ? "Adding"
                            : "Add to Cart"
                        }
                        onClick={() => {
                          setMovedTestId(pkg?.tests?.id ?? "");
                          handleMoveToCart(pkg?.tests?.id, "test");
                        }}
                        disabled={movingTest && movedTestId === pkg?.tests?.id}
                      />
                      <div className="logos">
                        {/* <FavoriteBorderOutlinedIcon className="heart" /> */}
                        <CloseOutlinedIcon
                          onClick={() => {
                            setDeleteId(pkg?.id);
                            handleOpenDelete();
                          }}
                          className="delete-icon"
                        />
                      </div>
                    </label>
                  ) : null}
                  {/* Modal for singular delete */}
                  <ConfirmationModal
                    open={openDelete}
                    setOpen={setOpenDelete}
                    loading={deleting}
                    yesAction={() => handleDeleteFromWishlist(deleteId)}
                  />
                </div>
              ))}
            </>
          ) : (
            <Alert severity="error">
              {errorMessages?.noPackagesInWishlist}
            </Alert>
          )}
        </div>
      ) : null}
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        slug={slug}
        type={type}
        hasAddToCart={false}
        hasBookNow={false}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
      />
    </>
  );
};

export default PackageWishlist;
