import { Navigate, Outlet } from "react-router-dom";
import { getLocalAuthToken } from "../../storage/localStorage";

interface Props {
  redirectPath?: string;
  token: string | null;
}

const ProtectedRoutes = ({ redirectPath = "/", token }: Props) => {
  // console.log(token);
  // We didn't use global state's "authToken" because when ProtectedRoutes render, "authToken" would not have been set yet.
  if (!getLocalAuthToken()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
