import TuneIcon from "@mui/icons-material/Tune";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Link, useSearchParams } from "react-router-dom";
import { appendNewParam } from "../../../utils/serializeQueryString";
import { useGetCategoryListQuery } from "../../../services/category";
import { useGetConfigQuery } from "../../../services/config";
import { addPriceCommas } from "../../../utils/priceCommas";
import { useDebounce } from "../../../hooks/useDebounce";

function valuetext(value: number, index: number) {
  return `${value}°C`;
}

interface Props {
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  minPrice?: number | null;
  maxPrice?: number | null;
}

export default function Filter({ setReset, maxPrice, minPrice }: Props) {
  const [value, setValue] = useState([0, 10]);
  const debouncedValue = useDebounce(value, 1000);
  const [marks, setMarks] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [resultType, setResultType] = useState(
    searchParams.get("type") ?? "product"
  );

  const { data } = useGetCategoryListQuery();
  const { data: configData } = useGetConfigQuery();

  const filterCategoriesList: JSX.Element[] | undefined = data?.results.map(
    (e, idx) => {
      return (
        <FormControlLabel
          key={idx}
          value={e.id}
          control={<Radio />}
          label={e.title}
        />
      );
    }
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleClearAll = () => {
    const newParam = searchParams.get("search") ?? "";
    if (newParam) {
      setSearchParams({ search: newParam });
    } else {
      setSearchParams({});
    }
    setValue([0, 10]);
    setReset(true);
  };

  const handleFilterByCategory = (e: any, filter: "category" | "type") => {
    setResultType(e.target.value);
    setSearchParams(
      appendNewParam(searchParams, {
        [filter]: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (!searchParams.get("type")) setResultType("product");
  }, [searchParams]);

  useEffect(() => {
    if (minPrice && maxPrice) {
      setMarks(
        new Array(
          {
            value: minPrice / 1000,
            label: `Rs. ${addPriceCommas(minPrice)}`,
          },
          {
            value: maxPrice / 1000,
            label: `Rs. ${addPriceCommas(maxPrice)}`,
          }
        )
      );
    } else if (configData) {
      setMarks(
        new Array(
          {
            value: configData?.min_price / 1000,
            label: `Rs. ${addPriceCommas(configData?.min_price)}`,
          },
          {
            value: configData?.max_price / 1000,
            label: `Rs. ${addPriceCommas(configData?.max_price)}`,
          }
        )
      );
    }
  }, [configData, minPrice, maxPrice]);

  useEffect(() => {
    if (debouncedValue) {
      setSearchParams(
        appendNewParam(searchParams, {
          min_price: value[0] * 1000,
          max_price: value[1] * 1000,
        })
      );
    }
  }, [debouncedValue]);

  return (
    <>
      <div className="left">
        {/* Filter Section for mobile  */}
        <div className="leftMobile">
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <img
                    className="filter-icon"
                    src="https://cdn.asparksys.com/medias/1661429275277.svg"
                    alt=""
                  />
                  Filters
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <small onClick={handleClearAll}>Clear all</small>
              </AccordionItemPanel>
              <AccordionItemPanel>
                <div className="rangeSlider">
                  <h3>Set Price Range</h3>
                  <Box sx={{ width: "70vw", padding: "0px 5px" }}>
                    <Slider
                      // getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      getAriaValueText={valuetext}
                      marks={marks}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        `${addPriceCommas(value * 1000)}`
                      }
                      min={
                        minPrice
                          ? minPrice / 1000
                          : configData?.min_price
                          ? configData?.min_price / 1000
                          : 0
                      }
                      max={
                        maxPrice
                          ? maxPrice / 1000
                          : configData?.max_price
                          ? configData?.max_price / 1000
                          : 100
                      }
                      sx={{
                        '& .MuiSlider-markLabel[data-index="0"]': {
                          left: "6% !important",
                        },
                      }}
                    />
                  </Box>
                </div>
                <hr />
                <div className="category">
                  <h3>Type</h3>
                  <FormControl>
                    <RadioGroup
                      onChange={(e) => handleFilterByCategory(e, "type")}
                      name="radio-buttons-group"
                      className="radioCategory"
                      value={resultType}
                    >
                      <FormControlLabel
                        value="product"
                        control={<Radio />}
                        label="Products"
                      />
                      <FormControlLabel
                        value="package"
                        control={<Radio />}
                        label="Packages"
                      />
                      <FormControlLabel
                        value="test"
                        control={<Radio />}
                        label="Tests"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <hr />
                <div className="category">
                  <h3>Categories</h3>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      className="radioCategory"
                      onChange={(e) => handleFilterByCategory(e, "category")}
                    >
                      {filterCategoriesList}
                    </RadioGroup>
                  </FormControl>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          {/* Filter Section for Mobile */}
        </div>
        <div className="leftDesktop">
          <div className="top">
            <div className="head" id="close-filter">
              <TuneIcon />
              <p>Filters</p>
            </div>
            <Link onClick={handleClearAll} to="#">
              Clear all
            </Link>
          </div>
          <hr />
          <div className="rangeSlider">
            <h3>Set Price Range</h3>
            <Box sx={{ width: "18vw", padding: "0px 5px" }}>
              <Slider
                // getAriaLabel={() => "Temperature range"}
                value={value}
                onChange={handleChange}
                getAriaValueText={valuetext}
                marks={marks}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${addPriceCommas(value * 1000)}`}
                min={
                  minPrice
                    ? minPrice / 1000
                    : configData?.min_price
                    ? configData?.min_price / 1000
                    : 0
                }
                max={
                  maxPrice
                    ? maxPrice / 1000
                    : configData?.max_price
                    ? configData?.max_price / 1000
                    : 100
                }
                sx={{
                  '& .MuiSlider-markLabel[data-index="0"]': {
                    left: "6% !important",
                  },
                }}
              />
            </Box>
          </div>
          <hr />
          <div className="category">
            <h3>Type</h3>
            <FormControl>
              <RadioGroup
                onChange={(e) => handleFilterByCategory(e, "type")}
                name="radio-buttons-group"
                className="radioCategory"
                value={resultType}
              >
                <FormControlLabel
                  value="product"
                  control={<Radio />}
                  label="Products"
                />
                <FormControlLabel
                  value="package"
                  control={<Radio />}
                  label="Packages"
                />
                <FormControlLabel
                  value="test"
                  control={<Radio />}
                  label="Lab Tests"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <hr />
          <div className="category">
            <h3>Categories</h3>
            <FormControl>
              <RadioGroup
                onChange={(e) => handleFilterByCategory(e, "category")}
                name="radio-buttons-group"
                className="radioCategory"
              >
                {filterCategoriesList}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}
