import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useGetFAQListsQuery } from "../../../services/faq";
import faq from "../../../styles/faq.module.css";

export default function SimpleAccordion() {
  // const { data } = useGetFAQListsQuery();

  return (
    <div className="capsule">
      <div className={faq.faqAccordion} id="accordion">
        <div className={faq.header}>
          <h2>Frequently Asked Questions</h2>
          <p>
            People very often search answers for these questions in Doctor's On
            Call Nepal.
          </p>
        </div>
        {/* Dynamic Data */}
        {/* {data?.results &&
          data.results.map((e, idx) => (
            <Accordion
              key={idx}
              className={faq.rows}
              defaultExpanded={idx === 0}
              sx={style}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight={"600"} className={faq.topic}>
                  {e?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={faq.answers}>{e?.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))} */}

        {/* Static Data */}
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              Who needs us?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                <strong>You need us if you are:</strong>
              </p>
              <ul>
                <li>A bedridden patient and need medical attention at home</li>
                <li>A patient who needs physiotherapy services at home</li>
                <li>
                  A patient suffering from hypertension, diabetes, fever or
                  other conditions.
                </li>
                <li>
                  A diabetic with a foot infection and you require wound-care at
                  home.
                </li>
                <li>
                  A geriatric patient who doesn’t want to wait outside the
                  hospital to get the treatment at home.
                </li>
                <li>
                  A senior with little or no family support close to you and you
                  recently had a surgery or are otherwise unwell.
                </li>
                <li>
                  A patient who needs ECG, Digital X-Rays, Laboratory services
                  and pharmacy services at home.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              How much do we charge?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                We charge according to the doctor visiting, the condition of the
                patient, location of the patient etc. To know more please
                contact us on 01-4011666.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              How we work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                We act as a synergy between the patient and the doctors so as to
                make home visits by doctors as easy and cost effective as
                possible.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              Is it 24x7 available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                Yes, we provide 24×7 service at your doorstep anywhere anytime
                with proper medication equipment.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              Do I get a complementary doctor visit along with other services
              from DOC?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                Yes. Depending on the patient’s need, you will get complementary
                doctor visits with our care + packages and nursing services.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={faq.rows}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"600"} className={faq.topic}>
              How often a DOC doctor will visit me?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={faq.answers}>
              <p>
                The frequency of doctor visits varies depending on the patient’s
                needs and treatment plan.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div className={faq.footer}>
          <h2>Still have questions?</h2>
          <p>
            If you cannot find answer to your questions in our FAQ, you can
            always{" "}
            <Link to="/contact-us" className="link-secondary">
              contact us
            </Link>
            . We will answer to you shortly!
          </p>
        </div>
      </div>
    </div>
  );
}
