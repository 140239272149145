import * as Yup from "yup";

export const nameValidation = (
  min: number = 2,
  max: number = 25,
  reqdMessage: string = "Required"
) => {
  return Yup.string()
    .min(2, `At least ${min} characters required`)
    .max(25, `At most ${max} characters allowed`)
    .required(reqdMessage);
};

export const emailValidation = () => {
  return Yup.string()
    .email("Invalid email")
    .required("Required");
};

export const otpValidation = () => {
  return Yup.number().required("Required");
};

export const phoneNumberValidation = () => {
  // return Yup.number()
  //   .integer()
  //   .typeError("Invalid phone number")
  //   .required("Required")
  //   .min(10, "At least 10 characters required")
  //   .max(10, "At most 10 characters required");
  return Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Invalid phone number")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits");
};

export const stringValidation = (
  reqdMessage: string = "Required",
  reqd: boolean = true
) => {
  if (reqd) return Yup.string().required(reqdMessage);
  else return Yup.string();
};

export const boolValidation = (
  reqdMessage: string = "Required",
  reqd: boolean = true
) => {
  if (reqd) return Yup.boolean().required(reqdMessage);
  else return Yup.boolean();
};

export const passwordValidation = (reqdMessage: string = "Required") => {
  return Yup.string()
    .min(8, "At least 8 characters required")
    .max(16, "At most 16 characters allowed")
    .required(reqdMessage);
};

export const confirmPasswordValidation = (
  confirmWith: string,
  confirmMessage: string = "Passwords do not match",
  reqdMessage: string = "Required"
) => {
  return Yup.string()
    .min(8, "At least 8 characters required")
    .max(16, "At most 16 characters allowed")
    .required(reqdMessage)
    .oneOf([Yup.ref(confirmWith)], confirmMessage);
};

export const dateValidation = (reqdMessage: string = "Required") => {
  return Yup.date().required(reqdMessage);
};
