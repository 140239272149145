import { useField, FieldHookConfig } from "formik";
import style from "../../../../../styles/select.module.css";

interface OtherProps {
  required?: Boolean;
  disabled?: Boolean;
  placeholder?: String;
  errMessage?: String;
  label: String;
  labelLogo?: any;
}

function Select({
  required = true,
  disabled = false,
  placeholder = "",
  errMessage = "",
  label = "",
  labelLogo,
  children,
  ...props
}: OtherProps & FieldHookConfig<string>) {
  const [field, meta] = useField(props);
  return (
    <div className={style.selectDiv}>
      <label htmlFor={props.id} className={style.label}>
        {" "}
        {labelLogo && labelLogo} {label}
      </label>
      <select
        {...field}
        required={required}
        placeholder={placeholder}
        className={
          disabled ? `${style.select} ${style.disabled}` : `${style.select}`
        }
        disabled={disabled}
      >
        {children}
      </select>
      {meta.touched && meta.error ? (
        <p className={style.selectError}>
          {errMessage?.length ? errMessage : meta.error}
        </p>
      ) : null}
    </div>
  );
}

export default Select;
