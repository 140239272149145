import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { getLocalAuthToken } from "../../storage/localStorage";

export const checkoutApi = createApi({
  reducerPath: "checkoutApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    checkout: builder.query<
      void,
      {
        shipping_address_id: number;
        billing_address_id: number;
        cartIdsQueryString: string;
        totalAmountQueryString: string;
        paymentMethod: "khalti" | "cod";
        token?: string | undefined;
      }
    >({
      query: ({
        shipping_address_id,
        billing_address_id,
        cartIdsQueryString,
        totalAmountQueryString,
        paymentMethod,
        token = undefined,
      }) => {
        return {
          url: `/product/checkout/?shipping_address_id=${shipping_address_id}&billing_address_id=${billing_address_id}&${cartIdsQueryString}${totalAmountQueryString}payment_method=${paymentMethod}${
            token ? `&token=${token}` : ""
          }${paymentMethod === "khalti" ? `&platform=web` : ""}`,
          method: "GET",
        };
      },
    }),

    // Khalti Checkout V2
    khaltiV2: builder.mutation<
      {
        pidx: string;
        payment_url: string;
      },
      {
        cart_id?: Array<string | undefined>;
        scart_id?: Array<string | undefined>;
        total_amount?: number;
        payment_method: "khalti" | "cod";
        platform?: "web";
        shipping_address_id: number;
        billing_address_id: number;
      }
    >({
      query: ({
        cart_id,
        scart_id,
        total_amount,
        payment_method,
        platform = "web",
        shipping_address_id,
        billing_address_id,
      }) => {
        return {
          url: "/v2/checkout/",
          method: "POST",
          body: {
            cart_id,
            scart_id,
            total_amount,
            payment_method,
            platform,
            shipping_address_id,
            billing_address_id,
          },
        };
      },
    }),

    // Payment Success: Khalti V2
    khaltiV2Success: builder.query<
      void,
      {
        payment_id?: string;
      }
    >({
      query: ({ payment_id }) => {
        return {
          url: `/v2/payment/success/`,
          method: "GET",
          params: {
            payment_id,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyCheckoutQuery,
  useKhaltiV2Mutation,
  useLazyKhaltiV2SuccessQuery,
} = checkoutApi;
