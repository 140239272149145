import { useState } from "react";

interface Props {
  value?: number;
}

const useUiAmt = ({ value }: Props) => {
  const [uiAmt, setUiAmt] = useState<number | undefined>(value);
  return { uiAmt, setUiAmt };
};

export default useUiAmt;
