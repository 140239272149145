import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
// import { BsPersonFill, BsTelephone, BsGenderAmbiguous } from "react-icons/bs";
// import { MdMailOutline, MdOutlineDateRange } from "react-icons/md";
import "./MyAccount.css";
import style from "../../../../styles/input.module.css";
// import Input from "../../uiComponents/Input";
import Input from "../../uiComponents/Formik/Input";
import Select from "../../uiComponents/Formik/Select";
import { FormButtonBlue } from "../../uiComponents/Button";
import {
  dateValidation,
  emailValidation,
  nameValidation,
  phoneNumberValidation,
  stringValidation,
} from "../../../../validations";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
// API Services
import {
  useGetUserProfileQuery,
  useEditUserProfileMutation,
} from "../../../../services/user";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";

interface InitialValuesType {
  full_name: string;
  email: string;
  phone_number: string;
  dob: string;
  gender: string;
}

const validationSchema = Yup.object({
  full_name: nameValidation(),
  email: emailValidation(),
  phone_number: phoneNumberValidation(),
  dob: dateValidation(),
  gender: stringValidation("Select One"),
});

export default function MyAccount() {
  // API
  const {
    data: userProfileData,
    isError,
    isLoading,
    isSuccess,
  } = useGetUserProfileQuery();

  const [
    editProfile,
    { isLoading: editing, error: editError, isSuccess: isEditSuccess },
  ] = useEditUserProfileMutation();

  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    full_name: "",
    email: "",
    phone_number: "",
    dob: "",
    gender: "",
  });

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        full_name: userProfileData?.full_name,
        email: userProfileData?.email,
        phone_number: userProfileData?.phone_number,
        dob: userProfileData?.profile?.dob ? userProfileData?.profile?.dob : "",
        gender: userProfileData?.profile?.gender,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isEditSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isEditSuccess]);

  const handleEdit = (values: InitialValuesType) => {
    editProfile(values);
  };

  return (
    <>
      <div className="accountOutlet">
        <h1 className="accHeader">My Account</h1>
        <p className="welcomeMsg">
          Welcome to your account <strong>{userProfileData?.full_name}</strong>
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleEdit(values)}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <div className="inputs">
                <div className={style.inputContainer}>
                  <Input
                    type="text"
                    name="full_name"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    labelLogo={<PersonOutlineOutlined />}
                    disabled={isError || isLoading}
                  />
                </div>
                <div className={style.inputContainer}>
                  <Input
                    type="text"
                    name="phone_number"
                    label="Phone Number"
                    placeholder="Enter 10 digit mobile number"
                    labelLogo={<LocalPhoneOutlined />}
                    disabled={isError || isLoading}
                  />
                </div>
                <div className={style.inputContainer}>
                  <Input
                    type="email"
                    name="email"
                    label="Email Address"
                    placeholder="Enter Email Address"
                    labelLogo={<EmailOutlinedIcon />}
                    disabled={true}
                  />
                </div>
                <div className={style.inputContainer}>
                  <Input
                    type="date"
                    name="dob"
                    label="Date of Birth"
                    labelLogo={<DateRangeOutlinedIcon />}
                    disabled={isError || isLoading}
                  />
                </div>
                <div className={style.inputContainer}>
                  <Select
                    name="gender"
                    label="Gender"
                    labelLogo={<MaleOutlinedIcon />}
                    disabled={isError || isLoading}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Select>
                </div>
                <div style={{ width: "100%" }}>
                  {isError && (
                    <Alert severity="error">
                      {errorMessages?.networkError}
                    </Alert>
                  )}
                </div>

                <div style={{ width: "100%" }}>
                  {editError && (
                    <Alert severity="error">
                      {errorMessages?.networkError}
                    </Alert>
                  )}
                  {isEditSuccess && (
                    <Alert severity="success">
                      {successMessages?.updateProfile}
                    </Alert>
                  )}
                  <FormButtonBlue
                    name={editing ? "Editing" : "Edit Profile"}
                    type="submit"
                    disabled={editing}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
