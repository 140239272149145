// Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper";
import "swiper/css/navigation";
import style from "../../styles/TestCardSmall.module.css";
import ShimmerCards from "../UI/uiComponents/LoadingShimmer/ShimmerCards";
import { Test } from "../../models/tests";
import { Link } from "react-router-dom";
import { gotoViewMore } from "../../utils/routeNames";

interface Props {
  type?: "is_featured" | "is_discount";
  header: string | undefined;
  isLoading: boolean;
  data: Test[] | undefined;
  children: React.ReactNode;
}

export default function TestCardSlider({
  type = "is_featured",
  header,
  isLoading,
  data,
  children,
}: Props) {
  return (
    <div className="capsule">
      <div className={style.healthTest}>
        {isLoading || data?.length ? (
          <div className={style.header}>
            <h1>{header}</h1>
            <Link to={gotoViewMore("test", type)} className="view-all">
              View More
            </Link>
          </div>
        ) : null}
        {isLoading ? (
          <ShimmerCards />
        ) : data?.length ? (
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            modules={[Navigation]}
            navigation={true}
            cssMode={true}
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              700: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              992: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
              1400: {
                slidesPerView: 7,
                spaceBetween: 20,
              },
            }}
            className={style.TestCardSwiper}
          >
            {children}
          </Swiper>
        ) : null}
      </div>
    </div>
  );
}
