export const ROUTES = {
  career: "careers",
  contactUs: "contact-us",
  labTest: "lab-test",
  doctorConsultation: "consult-doctors",
  nursePackages: "nurse-service",
  healthDevices: "health-devices",
  healthPackages: "health-packages",
  bookAppointments: "book-appointments",
  category: "category",
  productList: "product-list",
  productDetail: "product-detail",
  packageDetail: "package-detail",
  physiotherapy: "physiotherapy",
  shortProcedure: "short-procedure",
  viewAll: "all",
  paymentSuccess: "payment/success/",
};

export const gotoViewMore = (
  type?: "product" | "package" | "test",
  category?:
    | "is_featured"
    | "is_top_selling"
    | "is_most_viewed"
    | "is_just_for_you"
    | "is_discount"
    | "is_popular"
) => {
  if (type) {
    if (category) return `/all?type=${type}&category=${category}`;
    else return `/all?type=${type}`;
  } else return "/product-list?sort=popularity";
};
