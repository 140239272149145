import TopSellingCards from "../../components/UI/cards/ProductCard";
import "../../styles/CategoryPage.css";
import Filter from "../../components/utils/Filters/Filters";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLazySearchAllQuery } from "../../services/product";
import { resolveImgPath } from "../../utils/imagePathResolver";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import { appendNewParam } from "../../utils/serializeQueryString";
import CardSmall from "../../components/UI/uiComponents/TestCardSmall";
import Cards from "../../components/UI/cards/HealthPackagesCards";
import { Package } from "../../models/package";
import ShimmerCards from "../../components/UI/uiComponents/LoadingShimmer/ShimmerCards";
import SortBy from "../../components/utils/Filters/SortBy";
import { getPackageModalType } from "../../utils/getServiceType";

export default function CategoryPage() {
  const [trigger, { data, isFetching }] = useLazySearchAllQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  // Reset
  const [reset, setReset] = useState<boolean>(false);

  const crumbs = [
    // Must be in order after home
    {
      name: "Products List",
      link: "",
    },
  ];

  useEffect(() => {
    trigger({ q: location.search });
  }, [location.search]);

  const searchPhrase = searchParams.get("search");
  const resultType = searchParams.get("type") ?? "all";
  const isResultAll = resultType === "all";

  useEffect(() => window.scrollTo(0, 0), [resultType]);

  const handleViewMoreClick = (value: String) =>
    setSearchParams(
      appendNewParam(searchParams, {
        type: value,
      })
    );

  let noData = false;

  if (data?.package && data?.package.length === 0) {
    if (data?.test && data?.test.length === 0) {
      if (data?.product && data?.product.length === 0) {
        noData = true;
      }
    }
  }

  const renderNoData = (
    <div>
      <div
        style={{
          textAlign: "center",
          fontSize: 18,
          fontWeight: "normal",
          margin: "4rem 0",
          color: "gray",
        }}
      >
        <img
          width="150px"
          src="/assets/images/illustrations/not-found.svg"
          alt="not found"
        />
        <p>Sorry, No results found! Please try with another query.</p>
      </div>
    </div>
  );

  const getMinPrice = () => {
    const min = data?.min ?? undefined;
    return min;
  };

  const getMaxPrice = () => {
    const max = data?.max ?? undefined;
    return max;
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <section className="filters-section capsule">
        <Filter
          setReset={setReset}
          minPrice={getMinPrice()}
          maxPrice={getMaxPrice()}
        />
        <div className="right">
          <div className="information">
            {searchPhrase && <h4>{`Showing results for "${searchPhrase}"`}</h4>}
            <SortBy reset={reset} setReset={setReset} type="search" />
          </div>
          {noData && renderNoData}
          {noData ? (
            data?.for_you?.length ? (
              <>
                <div className="headertitle">
                  <span>Products You May Like</span>
                </div>
                <div className="products">
                  {
                    <>
                      {data?.for_you.map((product) => (
                        <TopSellingCards
                          slug={product?.slug}
                          image={
                            product.images && product.images.length > 0
                              ? resolveImgPath(product.images[0].image)
                              : undefined
                          }
                          genre={product?.category?.title}
                          discount={getDiscountPercent(
                            product?.price,
                            product?.discounted_price
                          )}
                          name={product?.title}
                          actualPrice={product?.discounted_price}
                          crossedPrice={product?.price}
                        />
                      ))}
                    </>
                  }
                </div>
              </>
            ) : null
          ) : null}

          {/* Loading */}
          {isFetching ? (
            <ShimmerCards maxHeight="auto" itemsToShow={8} showAllOnMobile />
          ) : (
            <>
              {/* Products */}
              {(isResultAll || resultType === "product") &&
                Boolean(data?.product.length) && (
                  <div className="headertitle">
                    <span>Products</span>
                    {resultType !== "product" && (
                      <button onClick={() => handleViewMoreClick("product")}>
                        View More
                      </button>
                    )}
                  </div>
                )}
              <div className="products">
                {(isResultAll || resultType === "product") && (
                  <>
                    {data?.product.map((product) => (
                      <TopSellingCards
                        slug={product.slug}
                        image={
                          product.images && product.images.length > 0
                            ? resolveImgPath(product.images[0].image)
                            : undefined
                        }
                        genre={product?.category?.title}
                        discount={getDiscountPercent(
                          product?.price,
                          product?.discounted_price
                        )}
                        name={product?.title}
                        actualPrice={product?.discounted_price}
                        crossedPrice={product?.price}
                      />
                    ))}
                  </>
                )}
              </div>
              {/* Package */}
              {(isResultAll || resultType === "package") &&
                Boolean(data?.package.length) && (
                  <div className="headertitle">
                    <span>Packages</span>
                    {resultType !== "package" && (
                      <button onClick={() => handleViewMoreClick("package")}>
                        View More
                      </button>
                    )}
                  </div>
                )}
              <div className="products">
                {(isResultAll || resultType === "package") &&
                  data?.package.map((product, idx) => {
                    const detail = (product as unknown) as Package;
                    return (
                      <div className="search-products">
                        <Cards
                          id={detail?.id}
                          slug={detail?.slug}
                          image={detail?.icon}
                          header={detail?.title}
                          actualPrice={detail?.discounted_price}
                          crossedPrice={detail?.market_price}
                          tests={detail?.test}
                          type={getPackageModalType(detail)}
                          showFeatures={false}
                          isSearchResult={true}
                        />
                      </div>
                    );
                  })}
              </div>
              {/* Tests */}
              {(isResultAll || resultType === "test") &&
                Boolean(data?.test.length) && (
                  <div className="headertitle">
                    <span>Tests</span>
                    {resultType !== "test" && (
                      <button onClick={() => handleViewMoreClick("test")}>
                        View More
                      </button>
                    )}
                  </div>
                )}
              <div className="products">
                {(isResultAll || resultType === "test") &&
                  data?.test.map((test, idx) => {
                    return (
                      <div className="search-test-products">
                        <CardSmall
                          id={test?.id}
                          slug={test?.slug}
                          title={test?.title}
                          price={test?.market_price}
                          discounted_price={test?.discounted_price}
                          description={test?.description}
                          image={test?.icon}
                          key={idx}
                          isSearchResult={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
