import { useState, useEffect } from "react";
import { Formik, Form /* useFormik */ } from "formik";
import * as Yup from "yup";
import style from "../../../../../styles/Login.module.css";
import Alert from "@mui/material/Alert";
// import Input from "./Input";
import Input from "../../Formik/Input";
import PasswordInput from "../../Formik/PasswordInput";
// Icons
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { /* ButtonBlue, */ FormButtonBlue } from "../../Button";
// Models
import {
  emailValidation,
  passwordValidation,
} from "../../../../../validations";
// API services
import { useLoginMutation } from "../../../../../services/auth";
import { loginHelper } from "../../../../../helpers/login";
import { errorMessages } from "../../../../../utils/alertMessages";
import { Feature } from "../../../../../models/feature";

interface Props {
  onClose: Function;
  switchModalType: Function;
  feature?: Feature;
}

interface Values {
  email: string;
  password: string;
}

const initialValues = {
  password: "",
  email: "",
};

const validationSchema = Yup.object({
  password: passwordValidation(),
  email: emailValidation(),
});

export default function Login({ onClose, switchModalType, feature }: Props) {
  const [customErr, setCustomErr] = useState<String>("");
  const [
    login,
    {
      data: loginResponse,
      isLoading: loggingIn,
      error: loginError,
      isSuccess: isLoginSuccess,
    },
  ] = useLoginMutation();

  const handleLogin = async (values: Values) => {
    login({ email: values?.email, password: values?.password });
  };

  useEffect(() => {
    if (isLoginSuccess) {
      /* On Success, we set localStorage not global state.
      The Layout wrapper will set global state automatically if local storage exists.
      BUT on logging out, we dispatch the removeToken state action which will remove the token from both localStorage and global state. */
      loginHelper(loginResponse?.token?.access);
      location.reload();
    } else if (loginError) {
      if ("data" in loginError) {
        const errMsg: any =
          "error" in loginError ? loginError.error : loginError.data;
        setCustomErr(
          errMsg?.detail || errMsg?.Error || errorMessages?.networkError
        );
      } else {
        setCustomErr(errorMessages?.networkError);
      }
    }
  }, [isLoginSuccess, loginError]);

  return (
    <>
      <div className={style.box}>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
            zIndex: "99",
          }}
        ></i>
        <div className={style.img}>
          <button
            style={{ cursor: "pointer" }}
            onClick={() => {
              switchModalType("SignUp");
            }}
          >
            Signup
          </button>
        </div>
        <div className={style.loginPanel}>
          <h3>Login</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={validationSchema}
          >
            <Form>
              <Input
                type="text"
                name="email"
                label="Email Address/Phone Number"
                placeholder="Enter Email"
                labelLogo={<EmailOutlinedIcon />}
              />
              <PasswordInput
                type="password"
                name="password"
                label="Password"
                placeholder="Enter Password"
                labelLogo={<LockIcon />}
              />
              <div className={style.flex}>
                {/* <div className={style.checkbox}>
                  <input
                    type="checkbox"
                    id="remember"
                    name="remember"
                    value="remember"
                  />
                  <label htmlFor="vehicle1"> Remember me</label>
                </div> */}

                <p
                  className={style.blue}
                  style={{ cursor: "pointer", textAlign: "right" }}
                  onClick={() => {
                    switchModalType("ForgotPassword");
                  }}
                >
                  Forgot your password?
                </p>
              </div>
              {/* <ButtonBlue name="Log In" /> */}
              {loginError && <Alert severity="error">{customErr}</Alert>}
              <FormButtonBlue
                name={loggingIn ? "Logging In..." : "Log In"}
                type="submit"
                disabled={loggingIn}
              />
            </Form>
          </Formik>

          <p className={style.signUp}>
            Do not have an acount? <span className={style.stick}>|</span>{" "}
            <span
              className={style.red}
              style={{ cursor: "pointer" }}
              onClick={() => {
                switchModalType("SignUp");
              }}
            >
              Sign Up
            </span>
          </p>
          {feature && (
            <Alert severity="error">
              {feature === "cart"
                ? errorMessages?.pleaseLoginForCart
                : feature === "wishlist"
                ? errorMessages?.pleaseLoginForWishlist
                : feature === "buyNow"
                ? errorMessages?.pleaseLoginForBuyNow
                : errorMessages?.pleaseLogin}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
}
