import style from "../../styles/AboutUsHero.module.css";

export default function AboutUsAim() {
  return (
    <div className="capsule">
      <div className={style.heroContainer}>
        <h2 className={style.heroHeader}>Our Aim</h2>
        <div className={style.hero}>
          <div className={style.full}>
            <p>
              Our aim is to meet the health and wellbeing needs of every
              individual specially our CHRONICALY ILL PATIENTS,
              HOUSEBOUND/BEDRIDDEN PATIENTS AND AGED/ELDERLY population by
              providing the best service we can, and promoting individual
              choice, recovery and independence. We believe everyone has the
              right to:
            </p>
            <ul>
              <li>
                <strong>Have optimum health</strong>
              </li>
              <li>
                <strong>Fulfil their potential</strong>
              </li>
              <li>
                <strong>Be valued and respected</strong>
              </li>
              <li>
                <strong>Make informed choices</strong>
              </li>
              <li>
                <strong>Be heard and understood</strong>
              </li>
              <li>
                <strong>Feel safe and supported</strong>
              </li>
              <li>
                <strong>
                  Have all their needs and preferences considered including:
                </strong>
              </li>
            </ul>
            <p>
              Race, Economic circumstances, Gender, Age, Religion or belief,
              Disability, Sexuality, mental health and physical wellbeing,
              emotional and psychological needs, family, parental and social
              needs.
            </p>
            <p>
              DOC service provision is based on operational values and
              principles of care. DOC believes that for services to be
              effective, we should be based on sound values, principles,
              standards and an understanding of the fundamental and needs of the
              people.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
