import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
// import apiClient from "../../../helpers/api";
import {
  ForgotPwResponseModal,
  LoginResponseModel,
  RegisterResponseModel,
} from "../../models/auth";

// export async function login(email: string, password: string) {
//   return (
//     await apiClient.post("/user/login/", {
//       email,
//       password,
//     })
//   ).data;
// }

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    // Login API service
    // First type is the type of Response that we get from api. i.e. "LoginResponseModel".
    // Second type is the type of parameters in the "query" property.
    login: builder.mutation<
      LoginResponseModel,
      { email: String | undefined; password: String | undefined }
    >({
      query: ({ email, password }) => {
        return {
          url: `/user/login/`,
          method: "POST",
          body: { email, password },
        };
      },
    }),

    // Register API service
    register: builder.mutation<
      RegisterResponseModel,
      {
        email: String | undefined;
        password: String | undefined;
        full_name: String | undefined;
        phone_number: String | undefined;
      }
    >({
      query: ({ email, password, full_name, phone_number }) => {
        return {
          url: `/user/register/`,
          method: "POST",
          body: { email, password, full_name, phone_number },
        };
      },
    }),

    // Forgot Password API service
    forgotPassword: builder.mutation<
      ForgotPwResponseModal,
      {
        email: String | undefined;
      }
    >({
      query: ({ email }) => {
        return {
          url: `/user/password/forget/`,
          method: "POST",
          body: { email },
        };
      },
    }),

    // Reset Password API service
    resetPassword: builder.mutation<
      any,
      {
        email: String | undefined;
        password: String | undefined;
        key: String | undefined;
        token: String | undefined;
      }
    >({
      query: ({ email, password, key, token }) => {
        return {
          url: `/user/password/reset/`,
          method: "POST",
          body: { email, password, key, token },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
