// import AppointmentForm from "../../components/utils/AppointmentForm";
import ContactUsForm from "../../components/utils/ContactUsForm";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
export default function ContactUs() {
  const crumbs = [
    // Must be in order after home
    {
      name: "Contact Us",
      link: "",
    },
  ];
  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <ContactUsForm />
    </>
  );
}
