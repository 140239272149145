import SimpleAccordion from "../../components/UI/uiComponents/Accordion";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";

export default function Faq() {
  const crumbs = [
    // Must be in order after home
    {
      name: "FAQs",
      link: "",
    },
  ];
  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <SimpleAccordion />
    </>
  );
}
