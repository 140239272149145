import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useGetSelectedPackageQuery } from "../../../../services/packages";
import { useGetSelectedTestQuery } from "../../../../services/tests";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import AddToCartBtn from "../Buttons/AddToCart";
import WishProduct from "../WishProduct";
// import BuyNowBtn from "../Buttons/BuyNow";
import {
  useGetConsultDoctorPkgQuery,
  useGetPhysiotherapyPkgQuery,
  useGetShortProcedurePkgQuery,
} from "../../../../services/consult-doctor";
import { useGetNurseServicePkgQuery } from "../../../../services/nurse-service";
// import BookNowBtn from "../Buttons/BookNow";
import BuyNowBtn from "../Buttons/BuyNow";
import { Feature } from "../../../../models/feature";
import { getPriceStatement } from "../../../../utils/priceCommas";
import Spinner from "../../../utils/Spinner";

export type PackageModalType =
  | "health-pkg"
  | "test-pkg"
  | "consult-doctor-pkg"
  | "nursing-pkg"
  | "physiotherapy-pkg"
  | "shortprocedure-pkg";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAuthModal?: React.Dispatch<React.SetStateAction<boolean>>;
  slug: string | undefined;
  type: PackageModalType;
  hasWishlist?: boolean;
  hasAddToCart?: boolean;
  hasBookNow?: boolean;
  hasIncludedTests?: boolean;
  hasSpeciality?: boolean;
  setFeature?: React.Dispatch<React.SetStateAction<Feature | undefined>>;
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffffff",
  border: "none",
  boxShadow: 0,
  p: 4,
  outline: "none",
  borderRadius: "7px",
  padding: "0px",
  width: 710,
  height: "600px",
  maxWidth: "90%",
};

const loadingBoxStyle = {
  ...boxStyle,
  height: "300px",
  display: "flex",
};

const PackageDetailModal = ({
  open,
  setOpen,
  setOpenAuthModal,
  slug,
  type,
  hasWishlist = true,
  hasAddToCart = true,
  hasBookNow = true,
  hasIncludedTests = true,
  hasSpeciality = true,
  setFeature,
}: Props) => {
  const handleClose = () => setOpen(false);

  // API: Get selected Test
  const { data: testData, isFetching: loadingTests } = useGetSelectedTestQuery(
    { slug },
    {
      skip: !slug || !open || type !== "test-pkg",
    }
  );

  // API: Get selected Package
  const { data: pkgData, isFetching: loadingPkg } = useGetSelectedPackageQuery(
    { slug },
    {
      skip: !slug || !open || type !== "health-pkg",
    }
  );

  // API: Get selected Consult Doctor Package
  const {
    data: doctorPkgData,
    isFetching: loadingDoctorPkg,
  } = useGetConsultDoctorPkgQuery(
    { slug },
    {
      // skip: !slug || !open || type === "test-pkg" || type === "health-pkg",
      skip: !slug || !open || type !== "consult-doctor-pkg",
    }
  );

  // API: Get selected Nurse Services Package
  const {
    data: nursePkgData,
    isFetching: loadingNursingPkg,
  } = useGetNurseServicePkgQuery(
    { slug },
    {
      skip: !slug || !open || type !== "nursing-pkg",
    }
  );

  // API: Get selected Physiotherapy Package
  const {
    data: phyPkgData,
    isFetching: loadingPhyPkg,
  } = useGetPhysiotherapyPkgQuery(
    { slug },
    {
      skip: !slug || !open || type !== "physiotherapy-pkg",
    }
  );

  // API: Get selected Short Procedure Package
  const {
    data: shortProPkgData,
    isFetching: loadingShortProPkg,
  } = useGetShortProcedurePkgQuery(
    { slug },
    {
      skip: !slug || !open || type !== "shortprocedure-pkg",
    }
  );

  const getImgUrl = () => {
    return type === "test-pkg"
      ? resolveImgPath(testData?.icon)
      : type === "health-pkg"
      ? resolveImgPath(pkgData?.icon)
      : type === "consult-doctor-pkg"
      ? resolveImgPath(doctorPkgData?.icon)
      : type === "nursing-pkg"
      ? resolveImgPath(nursePkgData?.icon)
      : type === "physiotherapy-pkg"
      ? resolveImgPath(phyPkgData?.icon)
      : resolveImgPath(shortProPkgData?.icon);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {(type === "health-pkg" && loadingPkg) ||
        (type === "test-pkg" && loadingTests) ||
        (type === "consult-doctor-pkg" && loadingDoctorPkg) ||
        (type === "nursing-pkg" && loadingNursingPkg) ||
        (type === "physiotherapy-pkg" && loadingPhyPkg) ||
        (type === "shortprocedure-pkg" && loadingShortProPkg) ? (
          <Box sx={loadingBoxStyle}>
            <Spinner />
          </Box>
        ) : (
          <Box sx={boxStyle}>
            <div className="modal">
              <div className="modalHeader">
                <img
                  src={getImgUrl() ?? "assets/images/Vector (1).svg"}
                  alt={
                    type === "test-pkg"
                      ? testData?.title
                      : type === "health-pkg"
                      ? pkgData?.title
                      : type === "consult-doctor-pkg"
                      ? doctorPkgData?.title
                      : type === "nursing-pkg"
                      ? nursePkgData?.title
                      : type === "physiotherapy-pkg"
                      ? phyPkgData?.title
                      : shortProPkgData?.title
                  }
                  width={60}
                  height={60}
                  style={{ marginRight: 8, objectFit: "contain" }}
                />
                <h4>
                  {type === "test-pkg"
                    ? testData?.title
                    : type === "health-pkg"
                    ? pkgData?.title
                    : type === "consult-doctor-pkg"
                    ? doctorPkgData?.title
                    : type === "nursing-pkg"
                    ? nursePkgData?.title
                    : type === "physiotherapy-pkg"
                    ? phyPkgData?.title
                    : shortProPkgData?.title}
                </h4>
              </div>
              {hasIncludedTests && (
                <>
                  {type === "test-pkg" && testData?.included_tests?.length ? (
                    <div className="testCount">
                      <p>Includes {testData?.included_tests?.length} Tests</p>
                    </div>
                  ) : type === "health-pkg" && pkgData?.test?.length ? (
                    <div className="testCount">
                      <p>Includes {pkgData?.test?.length} Tests</p>
                    </div>
                  ) : type === "consult-doctor-pkg" &&
                    doctorPkgData?.test?.length ? (
                    <div className="testCount">
                      <p>Includes {doctorPkgData?.test?.length} Tests</p>
                    </div>
                  ) : type === "nursing-pkg" && nursePkgData?.test?.length ? (
                    <div className="testCount">
                      <p>Includes {nursePkgData?.test?.length} Tests</p>
                    </div>
                  ) : null}
                </>
              )}
              {/* <p className="eReports description">
          <i className="fa-solid fa-clock-rotate-left"></i> E-Reports
          available on same day
        </p> */}
              <p
                className="eReports description"
                dangerouslySetInnerHTML={{
                  __html:
                    type === "test-pkg"
                      ? testData?.description ?? ""
                      : type === "health-pkg"
                      ? pkgData?.description ?? ""
                      : type === "consult-doctor-pkg"
                      ? doctorPkgData?.description ?? ""
                      : type === "nursing-pkg"
                      ? nursePkgData?.description ?? ""
                      : type === "physiotherapy-pkg"
                      ? phyPkgData?.description ?? ""
                      : type === "shortprocedure-pkg"
                      ? shortProPkgData?.description ?? ""
                      : "",
                }}
              />
              {/* <p
          className="description cartWishlist"
          onClick={() => setOpenAuthModal(true)}
        >
          <i className="fa-regular fa-heart"></i> Add to Wishlist{" "}
          <span>|</span> <i className="fa-brands fa-opencart"></i> Add to cart
        </p> */}
              {hasWishlist && (
                <div className="description cartWishlist">
                  <WishProduct
                    setOpen={setOpenAuthModal}
                    product_id={
                      type === "health-pkg"
                        ? pkgData?.id
                        : type === "test-pkg"
                        ? testData?.id
                        : type === "consult-doctor-pkg"
                        ? doctorPkgData?.id
                        : type === "nursing-pkg"
                        ? nursePkgData?.id
                        : type === "physiotherapy-pkg"
                        ? phyPkgData?.id
                        : type === "shortprocedure-pkg"
                        ? shortProPkgData?.id
                        : undefined
                    }
                    type={
                      type === "health-pkg" ||
                      type === "consult-doctor-pkg" ||
                      type === "nursing-pkg" ||
                      type === "physiotherapy-pkg" ||
                      type === "shortprocedure-pkg"
                        ? "package"
                        : "test"
                    }
                    setFeature={setFeature}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {hasIncludedTests && (
                  <>
                    {type === "test-pkg" && testData?.included_tests?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Tests Included:</h4>
                        <ul>
                          {testData?.included_tests?.map(
                            (test: string, idx: number) => (
                              <li key={idx}>{test}</li>
                            )
                          )}
                        </ul>
                      </div>
                    ) : type === "health-pkg" && pkgData?.test?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Tests Included:</h4>
                        <ul>
                          {pkgData?.test?.map((test: any, idx: number) => (
                            <li key={idx}>{test?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : type === "consult-doctor-pkg" &&
                      doctorPkgData?.test?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Tests Included:</h4>
                        <ul>
                          {doctorPkgData?.test?.map(
                            (test: any, idx: number) => (
                              <li key={idx}>{test?.title}</li>
                            )
                          )}
                        </ul>
                      </div>
                    ) : type === "nursing-pkg" && nursePkgData?.test?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Tests Included:</h4>
                        <ul>
                          {nursePkgData?.test?.map((test: any, idx: number) => (
                            <li key={idx}>{test?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </>
                )}
                {hasSpeciality && (
                  <>
                    {type === "test-pkg" && testData?.speciality?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Speciality:</h4>
                        <ul>
                          {testData?.speciality?.map((sp, idx) => (
                            <li key={idx}>{sp?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : type === "health-pkg" && pkgData?.speciality?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Speciality:</h4>
                        <ul>
                          {pkgData?.speciality?.map((sp, idx) => (
                            <li key={idx}>{sp?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : type === "consult-doctor-pkg" &&
                      doctorPkgData?.speciality?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Speciality:</h4>
                        <ul>
                          {doctorPkgData?.speciality?.map((sp, idx) => (
                            <li key={idx}>{sp?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : type === "nursing-pkg" &&
                      nursePkgData?.speciality?.length ? (
                      <div
                        className="dataDiv"
                        style={{ flexGrow: 1, flexBasis: "50%" }}
                      >
                        <h4>Speciality:</h4>
                        <ul>
                          {nursePkgData?.speciality?.map((sp, idx) => (
                            <li key={idx}>{sp?.title}</li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="footData">
                {type === "test-pkg" && testData ? (
                  <>
                    {testData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(testData?.discounted_price)}{" "}
                        <span>{getPriceStatement(testData?.market_price)}</span>
                      </h4>
                    ) : (
                      <h4>{getPriceStatement(testData?.market_price)}</h4>
                    )}
                  </>
                ) : type === "health-pkg" && pkgData ? (
                  <>
                    {pkgData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(pkgData?.discounted_price)}{" "}
                        <span>{getPriceStatement(pkgData?.market_price)}</span>
                      </h4>
                    ) : (
                      <h4>{getPriceStatement(pkgData?.market_price)}</h4>
                    )}
                  </>
                ) : type === "consult-doctor-pkg" && doctorPkgData ? (
                  <>
                    {doctorPkgData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(doctorPkgData?.discounted_price)}{" "}
                        <span>
                          {getPriceStatement(doctorPkgData?.market_price)}
                        </span>
                      </h4>
                    ) : (
                      <h4>{getPriceStatement(doctorPkgData?.market_price)}</h4>
                    )}
                  </>
                ) : type === "nursing-pkg" && nursePkgData ? (
                  <>
                    {nursePkgData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(nursePkgData?.discounted_price)}{" "}
                        <span>
                          {getPriceStatement(nursePkgData?.market_price)}
                        </span>
                      </h4>
                    ) : (
                      <h4>{getPriceStatement(nursePkgData?.market_price)}</h4>
                    )}
                  </>
                ) : type === "physiotherapy-pkg" && phyPkgData ? (
                  <>
                    {phyPkgData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(phyPkgData?.discounted_price)}{" "}
                        <span>
                          {getPriceStatement(phyPkgData?.market_price)}
                        </span>
                      </h4>
                    ) : (
                      <h4>{getPriceStatement(phyPkgData?.market_price)}</h4>
                    )}
                  </>
                ) : type === "shortprocedure-pkg" && shortProPkgData ? (
                  <>
                    {shortProPkgData?.discounted_price ? (
                      <h4>
                        {getPriceStatement(shortProPkgData?.discounted_price)}{" "}
                        <span>
                          {getPriceStatement(shortProPkgData?.market_price)}
                        </span>
                      </h4>
                    ) : (
                      <h4>
                        {getPriceStatement(shortProPkgData?.market_price)}
                      </h4>
                    )}
                  </>
                ) : null}
                {/* <h4>
            NPR 399 <span>NPR 599</span>
          </h4> */}
                <div /* className="buttonGroup" */>
                  {hasAddToCart && (
                    <AddToCartBtn
                      setOpen={setOpenAuthModal}
                      id={
                        type === "health-pkg"
                          ? pkgData?.id
                          : type === "test-pkg"
                          ? testData?.id
                          : type === "consult-doctor-pkg"
                          ? doctorPkgData?.id
                          : type === "nursing-pkg"
                          ? nursePkgData?.id
                          : type === "physiotherapy-pkg"
                          ? phyPkgData?.id
                          : type === "shortprocedure-pkg"
                          ? shortProPkgData?.id
                          : undefined
                      }
                      type={type === "test-pkg" ? "test" : "package"}
                      size="small"
                      setFeature={setFeature}
                    />
                  )}
                  {/* {hasBookNow && <ButtonBlue name="Book Now" />} */}
                  {hasBookNow && (
                    <BuyNowBtn
                      setOpen={setOpenAuthModal}
                      id={
                        type === "health-pkg"
                          ? pkgData?.id
                          : type === "test-pkg"
                          ? testData?.id
                          : type === "consult-doctor-pkg"
                          ? doctorPkgData?.id
                          : type === "nursing-pkg"
                          ? nursePkgData?.id
                          : undefined
                      }
                      type={type === "test-pkg" ? "test" : "package"}
                      name="Book Now"
                      size="small"
                      setFeature={setFeature}
                    />
                  )}
                </div>
              </div>
            </div>
          </Box>
        )}
      </>
    </Modal>
  );
};

export default PackageDetailModal;
