import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalAuthToken,
  removeLocalAuthToken,
} from "../../storage/localStorage";

interface StateType {
  authToken: string | null;
}

const initialState: StateType = {
  authToken: null,
};

export const authTokenSlice = createSlice({
  name: "authTokenState",
  initialState,
  reducers: {
    setAuthToken: (state) => {
      const token = getLocalAuthToken() || null;
      state.authToken = token;
    },
    removeAuthToken: (state) => {
      removeLocalAuthToken();
      state.authToken = null;
      // state.user = null;
    },
  },
});

export const { setAuthToken, removeAuthToken } = authTokenSlice.actions;

export default authTokenSlice.reducer;
