import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { ConfigModel } from "../../models/config";

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getConfig: builder.query<ConfigModel, void>({
      query: () => {
        return {
          url: `/config/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
