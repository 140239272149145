import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import style from "../../../styles/HorizontalTab.module.css";
import "../../../styles/HorizontalTab.css";

interface Props {
  label1: string;
  label2: string;
  label3: string;
  label1data: JSX.Element;
  label2data: JSX.Element;
  label3data: JSX.Element;
  selectedTab?: number;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={style.tabPanel}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography className="myorder-wrapper">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HorizontalTab({
  label1,
  label2,
  label3,
  label1data,
  label2data,
  label3data,
  selectedTab = 0,
}: Props) {
  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div className={`capsule ${style.widthFull}`}>
      <Box
        className={style.mainBox}
        sx={{ width: "100%", alignItems: "left", paddingBottom: "0px" }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className={style.mainTab}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={style.mainTab}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                backgroundColor: "#27548A",
                padding: "1.4px",
                borderRadius: "10px",
              },
            }}
          >
            <Tab className={style.title} label={label1} {...a11yProps(0)} />

            <Tab className={style.title} label={label2} {...a11yProps(1)} />
            <Tab className={style.title} label={label3} {...a11yProps(2)} />
          </Tabs>
        </Box>
        {/* {type === "order-tabs" && (
          <>
            {label1RawData || label2RawData || label3RawData ? (
              label1RawData?.length ||
              label2RawData?.length ||
              label3RawData?.length ? null : (
                <TabPanel value={value} index={0}>
                  <NoData
                    containerHeight="50%"
                    imgWidth="25%"
                    msg={errorMessages?.noItemsInMyOrders}
                  />
                </TabPanel>
              )
            ) : null}
          </>
        )} */}
        <TabPanel /* className={style.tabPanel} */ value={value} index={0}>
          {label1data}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {label2data}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {label3data}
        </TabPanel>
      </Box>
    </div>
  );
}
