import "../../styles/NotFound.css";
import { Alert } from "@mui/material";

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <div className="img">
        <img src="/assets/images/illustrations/page-not-found.svg" />
      </div>
      <Alert severity="error">Page Not Found</Alert>
    </div>
  );
};

export default NotFoundPage;
