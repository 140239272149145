import { LazyLoadImage } from "react-lazy-load-image-component";
import style from "../../styles/AboutUsHero.module.css";

export default function AboutUsMissions() {
  return (
    <div className="capsule">
      <div className={style.heroContainer}>
        <h2 className={style.heroHeader}>Our Mission, Vision and Objectives</h2>
        <div className={style.hero}>
          <div className={style.left}>
            <h4>Vision</h4>
            <p>
              Our vision is to make home healthcare accessible ,affordable and
              equitably available.
            </p>
            <h4>Mission</h4>
            <p>
              Our mission is to provide better accessible, affordable, and
              equitably available home healthcare services for all through the
              intervention of technology to help in recovery, promote wellness
              and regaining in-dependence wherever and whenever required.
            </p>
            <h4>Our Objectives</h4>
            <ul>
              <li>Provide quality and compassionate Care</li>
              <li>Enhance clients quality of life</li>
              <li>Expand service offerings and geographic reach</li>
              <li>
                Promote community engagement and awareness of home health care.
              </li>
            </ul>
            {/* <h4>Vision</h4>
            <p>
              Making Healthcare Accessible, Affordable and Equitably Available.
            </p>
            <h4>Mission</h4>
            <p>
              Together, for providing better accessible, affordable and
              equitably available healthcare services for all through
              intervention of ICTs that set health and social care standards, to
              help in recovery, promote wellness and help in regaining
              independence and are provided in a caring, convenient,
              cost-effective manner wherever and whenever required.
            </p>
            <h4>Values</h4>
            <ul>
              <li>
                <strong>Caring and Compassion:</strong> By our words and
                actions, we strive to create a caring environment for everyone
                whom we serve and work with. We recognize our co-workers’
                diverse and unique contributions. We provide our patients and
                customers with a compassionate health care experience.
              </li>
              <li>
                <strong>Service Above All:</strong> We just don’t want our
                clients to be pleased with the products and services of DOC, we
                want them to be delighted.
              </li>
              <li>
                <strong>Integrity:</strong> We are honest, dependable, and
                consistent in all our endeavors.
              </li>
              <li>
                <strong>Teamwork:</strong> We work collaboratively for our
                common goals, building relationships based on mutual respect and
                open communication.
              </li>
              <li>
                <strong>Innovation:</strong> We continuously introduce new
                healthcare products and services. Always challenge the status
                quo to provide better care and improve overall patient
                experience.
              </li>
            </ul> */}
          </div>
          <div className={style.right}>
            {/* <img src="assets/images/value-proposition.jpg" /> */}
            <LazyLoadImage src={"assets/images/value-proposition.jpg"} />
          </div>
        </div>
      </div>
    </div>
  );
}
