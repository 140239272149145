import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductType {
  id: number | null | undefined;
  title: string | null | undefined;
  price: number | null | undefined;
  quantity: number | null | undefined;
  image: string | null | undefined;
}

interface StateType {
  product: ProductType[];
}

const initialState: StateType = {
  product: [],
};

export const cartSlice = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    setProductCart: (state, action: PayloadAction<ProductType>) => {
      state.product = [...state.product, action.payload];
    },
  },
});

export const { setProductCart } = cartSlice.actions;

export default cartSlice.reducer;
