import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function OffersCards1(props: any) {
  return (
    <div className="offers-div">
      <a href={props?.launchUrl ?? "#"}>
        {/* <img src={props.image} alt="" /> */}
        <LazyLoadImage src={props?.image} alt={props?.title} />
        <div className="info-div">
          <h2>{props.heading}</h2>
          <p>
            <a href="">Know more</a>
          </p>
          <button>
            <Link to="/book-appointment-page">Book Now</Link>
          </button>
        </div>
      </a>
    </div>
  );
}

function OffersCards2(props: any) {
  return (
    <div className="offers-div">
      <a href={props?.launchUrl ?? "#"}>
        {/* <img
          style={{ width: "100%", objectFit: "contain" }}
          src={props?.image}
          alt={props?.title}
        /> */}
        <LazyLoadImage
          src={props?.image}
          alt={props?.title}
          style={{ width: "100%", objectFit: "contain" }}
        />
      </a>
    </div>
  );
}

export { OffersCards1, OffersCards2 };
