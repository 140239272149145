import style from "../../../../styles/select.module.css";

interface Props {
  id?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  labelLogo?: any;
  handleChange?: any;
  children: JSX.Element[];
}

const Select = ({
  id = "",
  name = "",
  required = true,
  disabled = false,
  label = "",
  labelLogo,
  handleChange,
  children,
}: Props) => {
  return (
    <div className={style.selectDivInline}>
      <label htmlFor={id} className={style.label}>
        {" "}
        {labelLogo && labelLogo} {label}
      </label>
      <select
        id={id}
        name={name}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        className={
          disabled ? `${style.select} ${style.disabled}` : `${style.select}`
        }
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
