import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../../../styles/VerticalAppBar.css";
import Breadcrumb from "./Breadcrumb";
// Icons
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
// Tabs
import MyAccount from "../VerticalAppBarComponents/MyAcount/MyAccount";
import MyOrders from "../VerticalAppBarComponents/MyOrders/MyOrders";
import ShippingAddress from "../VerticalAppBarComponents/ShippingAddress/ShippingAddress";
import BillingAddress from "../VerticalAppBarComponents/BillingAddress/BillingAddress";
import MyWishlist from "../VerticalAppBarComponents/MyWishlist/MyWishlist";
import ChangePassword from "../VerticalAppBarComponents/ChangePassword/ChangePassword";
import MyCart from "../VerticalAppBarComponents/MyCart/MyCart";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeAuthToken } from "../../../features/auth/authTokenSlice";
import MyBookings from "../VerticalAppBarComponents/MyBookings/MyBookings";

function TabPanel(props: any) {
  const { children, value, index, hasFullWidth = false, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={hasFullWidth ? { width: "100%" } : undefined}
      {...other}
    >
      {value === index && (
        <Box className="tab-panel-content">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  hasFullWidth: PropTypes.bool,
};

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalAppBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Tab Management
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const crumbs = [
    // Must be in order after home
    {
      name: "My Account",
      link: ".",
    },
  ];

  function showTab() {
    return showTabDisplay(true);
  }

  // function hideTab() {
  //   return showTabDisplay(false);
  // }
  const [tabDisplay, showTabDisplay] = useState(true);
  // const[hideArrow, setHideArrow] = React.useState("block")

  // if (tabDisplay === "block"){
  //   setHideArrow('none')
  // }else{
  //   setHideArrow("block")
  // }

  const routeChange = (pathname: string) => {
    navigate(pathname);
  };

  // Logout
  const handleLogout = () => {
    // This action will remove "authToken" from both global state and localstorage.
    dispatch(removeAuthToken());
    location.reload();
  };

  useEffect(() => {
    switch (pathname) {
      case "/orders":
        setValue(1);
        break;
      case "/my-bookings":
        setValue(2);
        break;
      case "/wishlist":
        setValue(3);
        break;
      case "/cart":
        setValue(4);
        break;
      case "/change-password":
        setValue(5);
        break;
      case "/shipping-address":
        setValue(6);
        break;
      case "/billing-address":
        setValue(7);
        break;
      default:
        setValue(0);
        break;
    }
  }, [pathname]);

  return (
    <>
      <Box
        className="verticalApp capsule"
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "fitContent",
          marginBottom: 10,
        }}
      >
        <i
          className="fa-solid fa-chevron-right tabArrows tabArrowRight"
          onClick={showTab}
          style={{ display: tabDisplay ? "none" : "flex" }}
        ></i>

        <div
          className="leftTab responsive-tab"
          // style={{ display: tabDisplay ? "block" : "none" }}
        >
          <Breadcrumb crumbs={crumbs} pl="0rem" />

          {/* <i
            className="fa-solid fa-chevron-left tabArrows tabArrowLeft"
            onClick={hideTab}
          ></i> */}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              icon={<ManageAccountsIcon />}
              iconPosition="start"
              label="My Account"
              onClick={() => routeChange("/account")}
              {...a11yProps(0)}
            />
            <Tab
              icon={<WorkOutlineIcon />}
              iconPosition="start"
              label="My Orders"
              onClick={() => routeChange("/orders")}
              {...a11yProps(1)}
            />
            <Tab
              icon={<CalendarMonthOutlined />}
              iconPosition="start"
              label="My Bookings"
              onClick={() => routeChange("/my-bookings")}
              {...a11yProps(1)}
            />
            <Tab
              icon={<FavoriteBorderIcon />}
              iconPosition="start"
              label="My Wishlist"
              onClick={() => routeChange("/wishlist")}
              {...a11yProps(2)}
            />
            <Tab
              icon={<ShoppingCartOutlinedIcon />}
              iconPosition="start"
              label="My Cart"
              onClick={() => routeChange("/cart")}
              {...a11yProps(3)}
            />
            <Tab
              icon={<LockResetOutlinedIcon />}
              iconPosition="start"
              label="Change Password"
              onClick={() => routeChange("/change-password")}
              {...a11yProps(4)}
            />
            <Tab
              icon={<LocalShippingOutlinedIcon />}
              iconPosition="start"
              label="Delivery Address"
              onClick={() => routeChange("/shipping-address")}
              {...a11yProps(5)}
            />
            <Tab
              icon={<ReceiptOutlinedIcon />}
              iconPosition="start"
              label="Billing Address"
              onClick={() => routeChange("/billing-address")}
              {...a11yProps(6)}
            />
            <Tab
              icon={<LogoutOutlinedIcon />}
              iconPosition="start"
              label="Logout"
              onClick={handleLogout}
              {...a11yProps(7)}
            />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <MyAccount />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          /* style={{ width: "100%" }} */
          hasFullWidth={true}
        >
          <MyOrders />
        </TabPanel>
        <TabPanel index={2} value={value} hasFullWidth={true}>
          <MyBookings />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          /* style={{ width: "100%" }} */
          hasFullWidth={true}
        >
          <MyWishlist />
        </TabPanel>
        <TabPanel value={value} index={4} hasFullWidth={true}>
          <MyCart />
        </TabPanel>
        <TabPanel value={value} index={5} hasFullWidth={true}>
          <ChangePassword />
        </TabPanel>
        <TabPanel value={value} index={6} hasFullWidth={true}>
          <ShippingAddress />
        </TabPanel>
        <TabPanel value={value} index={7} hasFullWidth={true}>
          <BillingAddress />
        </TabPanel>
        {/* <TabPanel value={value} index={7}>
          Item Eight
        </TabPanel> */}
      </Box>
    </>
  );
}
