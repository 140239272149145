import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import "./index.css";

interface Props {
  withText?: boolean;
  text?: string;
  containerWidth?: string;
  loading?: boolean;
  success?: boolean;
  failure?: boolean;
}

const Spinner = ({
  withText = true,
  text = "Loading...",
  containerWidth,
  loading = true,
  success = false,
  failure = false,
}: Props) => {
  return (
    <>
      {withText ? (
        <div
          className="loading-container"
          style={containerWidth ? { width: containerWidth } : {}}
        >
          {loading ? (
            <span className="loader"></span>
          ) : failure ? (
            <ReportIcon color="error" />
          ) : success ? (
            <CheckCircleIcon color="success" />
          ) : null}
          <p className="loading-text">{text}</p>
        </div>
      ) : (
        <span className="loader"></span>
      )}
    </>
  );
};

export default Spinner;
