import * as React from "react";
import Menu from "@mui/material/Menu";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import "../../styles/SideMenu.css";

// Icons
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeAuthToken } from "../../features/auth/authTokenSlice";

export default function SideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // This action will remove "authToken" from both global state and localstorage.
    dispatch(removeAuthToken());
    location.reload();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="side-menu-gear"
      >
        <ListOutlinedIcon className="" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/account");
            handleClose();
          }}
        >
          <ManageAccountsIcon />
          MY ACCOUNT
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/orders");
            handleClose();
          }}
        >
          <WorkOutlineIcon />
          MY ORDERS
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/my-bookings");
            handleClose();
          }}
        >
          <CalendarMonthOutlined />
          MY BOOKINGS
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/wishlist");
            handleClose();
          }}
        >
          <FavoriteBorderIcon />
          MY WISHLIST
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/cart");
            handleClose();
          }}
        >
          <ShoppingCartOutlinedIcon />
          MY CART
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/change-password");
            handleClose();
          }}
        >
          <LockResetOutlinedIcon />
          CHANGE PASSWORD
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/shipping-address");
            handleClose();
          }}
        >
          <LocalShippingOutlinedIcon />
          DELIVERY ADDRESS
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            navigate("/billing-address");
            handleClose();
          }}
        >
          <ReceiptOutlinedIcon />
          BILLING ADDRESS
        </MenuItem>
        <MenuItem
          className="menu-list-item"
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <LogoutOutlinedIcon />
          LOGOUT
        </MenuItem>
      </Menu>
    </>
  );
}
