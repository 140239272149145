import { useState, useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ButtonBlue, ButtonWhite } from "../Button";
import {
  useDeleteAllProductCartMutation,
  useDeleteFromPackageCartMutation,
  useDeleteFromProductCartMutation,
} from "../../../../services/cart";
import { GetProductCartResponse } from "../../../../models/productCart";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import { AddToPackageCartResponse } from "../../../../models/packageCart";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import { toast } from "react-toastify";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import "../../../../styles/CartDrop.css";
import { getPriceStatement } from "../../../../utils/priceCommas";
import Spinner from "../../../utils/Spinner";
import NoData from "../../../utils/NoData";
import PackageDetailModal from "../PackageDetailModal";
import useServiceDetail from "../../../../hooks/useServiceDetail";
import { getPackageModalType } from "../../../../utils/getServiceType";

interface Props {
  cartAnchorEl: null | HTMLElement;
  openCart: boolean;
  handleCartClose: () => void;
  productsCartData: GetProductCartResponse | undefined;
  packagesCartData: AddToPackageCartResponse[] | undefined;
}

type ClearType = "clear-all" | "clear-prod" | "clear-pkg";

const CartNav = ({
  cartAnchorEl,
  openCart,
  handleCartClose,
  productsCartData,
  packagesCartData,
}: Props) => {
  // Package Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // Product Total Amount.
  const [totalAmt, setTotalAmt] = useState<number>(0);
  // Packages Total Amount
  const [pkgTotalAmt, setPkgTotalAmt] = useState<number>(0);
  // Confirmation Modal
  const [clearType, setClearType] = useState<ClearType>("clear-all");
  const [openClearModal, setOpenClearModal] = useState(false);
  const handleOpenClearModal = () => setOpenClearModal(true);
  const handleCloseClearModal = () => setOpenClearModal(false);
  // Delete Individual Products and Packages
  const [delProdId, setDelProdId] = useState<number | undefined>(undefined);
  const [delPkgId, setDelPkgId] = useState<number | undefined>(undefined);

  // API
  const [
    clearCart,
    { isLoading: clearing, isSuccess: isClearSuccess, isError: isClearError },
  ] = useDeleteAllProductCartMutation();

  const handleClearCart = () => {
    setClearType("clear-all");
    handleOpenClearModal();
    // clearCart();
  };

  // Delete Product API
  const [
    deleteProd,
    { isLoading: prodDeleting, isSuccess: isProdDelSuccess },
  ] = useDeleteFromProductCartMutation();

  const handleDeleteProd = (product_id: number | undefined) => {
    setDelProdId(product_id);
    setClearType("clear-prod");
    handleOpenClearModal();
    // deleteProd({ product_id });
  };

  // Delete Package API
  const [
    deletePkg,
    { isLoading: pkgDeleting, isSuccess: isPkgDelSuccess },
  ] = useDeleteFromPackageCartMutation();

  const handleDeletePkg = (package_id: number | undefined) => {
    setDelPkgId(package_id);
    setClearType("clear-pkg");
    handleOpenClearModal();
    // deletePkg({ package_id });
  };

  useEffect(() => {
    if (isClearError) toast.error(errorMessages?.networkError);
    else if (isClearSuccess) {
      toast.success(successMessages?.clearCart);
      handleCloseClearModal();
    }
  }, [isClearError, isClearSuccess]);

  useEffect(() => {
    if (isProdDelSuccess) {
      handleCloseClearModal();
    } else if (isPkgDelSuccess) {
      handleCloseClearModal();
    }
  }, [isProdDelSuccess, isPkgDelSuccess]);

  useEffect(() => {
    if (productsCartData?.products?.length ?? packagesCartData?.length) {
      const prodTotal = productsCartData?.products?.reduce((total, obj) => {
        return total + (obj?.total_price ? obj?.total_price : 0);
      }, 0);
      const pkgTotal = packagesCartData?.reduce((total, obj) => {
        return total + (obj?.total_price ? obj?.total_price : 0);
      }, 0);
      setTotalAmt(prodTotal ?? 0);
      setPkgTotalAmt(pkgTotal ?? 0);
    } else {
      setTotalAmt(0);
      setPkgTotalAmt(0);
    }
  }, [productsCartData, packagesCartData]);

  return (
    <Menu
      id="basic-menu"
      anchorEl={cartAnchorEl}
      open={openCart}
      onClose={handleCartClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        onMouseLeave: handleCartClose,
      }}
      sx={{ cursor: "pointer" }}
    >
      <div className="cart-menu-container">
        {/* Products Section  */}
        <div className="cart-scroll">
          {!productsCartData?.products?.length && !packagesCartData?.length ? (
            <>
              <h4>Products</h4>
              <NoData containerHeight="80%" imgWidth="50%" />
            </>
          ) : (
            <>
              {productsCartData?.products?.length ? (
                <>
                  <h4>Products</h4>
                  {productsCartData?.products?.map((p, idx) => (
                    <MenuItem className="menu-item" key={idx}>
                      <div className="cart-container">
                        <div className="cart-img-section">
                          <Link
                            to={`/product-detail/${p?.product?.slug}`}
                            onClick={handleCartClose}
                          >
                            <img
                              src={
                                p?.product?.images?.length
                                  ? resolveImgPath(p?.product?.images[0]?.image)
                                  : "/assets/images/placeholders/no-image-placeholder.svg"
                              }
                              style={{
                                width: 60,
                                height: 60,
                                borderRadius: 10,
                              }}
                            />
                          </Link>
                        </div>
                        <div className="cart-text-section">
                          <div className="cart-text-title">
                            <Link
                              className="cart-title"
                              to={`/product-detail/${p?.product?.slug}`}
                              onClick={handleCartClose}
                            >
                              <p>{p?.product?.title}</p>
                            </Link>
                          </div>
                          <div className="cart-text-price">
                            <p className="cart-price">
                              <span>
                                {getPriceStatement(
                                  p?.product?.discounted_price ??
                                    p?.product?.price
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="cart-btn-section">
                          {prodDeleting && delProdId === p?.id ? (
                            <Spinner />
                          ) : (
                            <button
                              className="cart-btn"
                              onClick={() => handleDeleteProd(p?.id)}
                              // onClick={() => setClearType("clear-prod")}
                            >
                              <DeleteOutlineOutlinedIcon className="cart-delete" />
                            </button>
                          )}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                  <div className="cart-underline"></div>
                </>
              ) : null}

              {/* Services Section  */}
              {packagesCartData?.length ? (
                <>
                  <h4>Services</h4>
                  {packagesCartData?.map((pkg, idx) => (
                    <MenuItem className="menu-item" key={idx}>
                      <div className="cart-container">
                        <div
                          className="cart-img-section"
                          onClick={() =>
                            handleOpen(
                              pkg?.package
                                ? getPackageModalType(pkg?.package)
                                : "test-pkg",
                              pkg?.package
                                ? pkg?.package?.slug
                                : pkg?.test?.slug
                            )
                          }
                        >
                          <img
                            src={
                              pkg?.package?.icon
                                ? resolveImgPath(pkg?.package?.icon)
                                : pkg?.test?.icon
                                ? resolveImgPath(pkg?.test?.icon)
                                : "assets/images/Vector (1).svg"
                            }
                            style={{
                              width: 60,
                              height: 60,
                              borderRadius: 10,
                            }}
                          />
                        </div>
                        <div className="cart-text-section">
                          <div
                            className="cart-text-title"
                            onClick={() =>
                              handleOpen(
                                pkg?.package
                                  ? getPackageModalType(pkg?.package)
                                  : "test-pkg",
                                pkg?.package
                                  ? pkg?.package?.slug
                                  : pkg?.test?.slug
                              )
                            }
                          >
                            <p className="cart-title">
                              {pkg?.package?.title ?? pkg?.test?.title}
                            </p>
                          </div>
                          <div className="cart-text-price">
                            <p className="cart-price">
                              <span>
                                {pkg?.package
                                  ? pkg?.package?.discounted_price
                                    ? getPriceStatement(
                                        pkg?.package?.discounted_price
                                      )
                                    : getPriceStatement(
                                        pkg?.package?.market_price
                                      )
                                  : pkg?.test?.discounted_price
                                  ? getPriceStatement(
                                      pkg?.test?.discounted_price
                                    )
                                  : getPriceStatement(pkg?.test?.market_price)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="cart-btn-section">
                          {pkgDeleting && delPkgId === pkg?.id ? (
                            <Spinner />
                          ) : (
                            <button
                              className="cart-btn"
                              onClick={() => handleDeletePkg(pkg?.id)}
                            >
                              <DeleteOutlineOutlinedIcon className="cart-delete" />
                            </button>
                          )}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>

        <div className="cart-underline"></div>
        {productsCartData?.products?.length ?? packagesCartData?.length ? (
          <div className="cart-total-price">
            <div className="cart-total-title">
              <h4>Total Cart Amount</h4>
              <p>{getPriceStatement(totalAmt + pkgTotalAmt)}</p>
            </div>
          </div>
        ) : null}

        <div className="cart-btn-collection">
          <Link to={"/cart"}>
            <ButtonBlue
              name="Go To Cart"
              className="cart-go-btn"
              onClick={() => {
                // navigate("/cart");
                handleCartClose();
              }}
            />
          </Link>
          {!productsCartData?.products?.length &&
          !packagesCartData?.length ? null : (
            <ButtonWhite
              name="Remove All"
              className="cart-remove-btn"
              // onClick={handleOpenClearModal}
              onClick={handleClearCart}
            />
          )}
        </div>
      </div>
      {/* Modal for Bulk Delete */}
      <ConfirmationModal
        open={openClearModal}
        setOpen={setOpenClearModal}
        loading={clearing || prodDeleting || pkgDeleting}
        yesAction={
          clearType === "clear-all"
            ? () => clearCart()
            : clearType === "clear-prod"
            ? () => deleteProd({ product_id: delProdId })
            : () => deletePkg({ package_id: delPkgId })
        }
      />
      {/* Detail */}
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        slug={slug}
        type={type}
        hasAddToCart={false}
        hasBookNow={false}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
      />
    </Menu>
  );
};

export default CartNav;
