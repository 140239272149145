import HorizontalTab from "../../uiComponents/HorizontalTab";
import {
  useGetOrderedItemsControlledQuery,
  // useGetOrderedItemsQuery,
} from "../../../../services/order-item";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import { getDiscountPercent } from "../../../../utils/discountPercentCalc";
import { deliveryStatus } from "../../../../utils/deliveryStatus";
import {
  DeliveryStatusType,
  OrderItemsResponse,
} from "../../../../models/orderItem";
import "./MyOrders.css";
import { errorMessages } from "../../../../utils/alertMessages";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../utils/routeNames";
import { useState } from "react";
import { serializeArrOfString } from "../../../../utils/serializeQueryString";
import Select from "../../uiComponents/Select";
import { capitalizeAllWords } from "../../../../utils/capitalizeString";
import { getPriceStatement } from "../../../../utils/priceCommas";
import NoData from "../../../utils/NoData";
import Spinner from "../../../utils/Spinner";
import useServiceDetail from "../../../../hooks/useServiceDetail";
import PackageDetailModal from "../../uiComponents/PackageDetailModal";
import { getPackageModalType } from "../../../../utils/getServiceType";

export default function MyOrders() {
  const navigate = useNavigate();
  // Package Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // Sort State
  const [selectedStatus, setSelectedStatus] = useState<
    DeliveryStatusType | "all"
  >("all");

  // API: Get my orders.
  const { data: myOrders, isLoading } = useGetOrderedItemsControlledQuery(
    {
      queryString:
        selectedStatus === "all"
          ? undefined
          : serializeArrOfString([selectedStatus], "status"),
    },
    {
      skip: ![selectedStatus]?.length,
      // skip: selectedStatus === "all",
      refetchOnMountOrArgChange: true,
    }
  );

  const renderAll = (myOrders: OrderItemsResponse | undefined) => {
    return (
      <>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {myOrders?.results?.length ? (
              <>
                {myOrders?.results?.map((or, idx) => (
                  <div className="product" key={idx}>
                    <img
                      src={
                        or?.product?.images?.length
                          ? resolveImgPath(or?.product?.images[0]?.image)
                          : or?.package?.icon ?? or?.test?.icon
                          ? resolveImgPath(or?.package?.icon ?? or?.test?.icon)
                          : "/assets/images/placeholders/no-image-placeholder.svg"
                      }
                      alt={
                        or?.product?.title ??
                        or?.package?.title ??
                        or?.test?.title
                      }
                      onClick={() => {
                        if (or?.product) {
                          navigate(
                            `/${ROUTES?.productDetail}/${or?.product?.slug}`
                          );
                        } else {
                          handleOpen(
                            or?.package
                              ? getPackageModalType(or?.package)
                              : "test-pkg",
                            or?.package ? or?.package?.slug : or?.test?.slug
                          );
                        }
                      }}
                    />
                    <div className="name-genre">
                      <h4
                        className="name"
                        onClick={() => {
                          if (or?.product) {
                            navigate(
                              `/${ROUTES?.productDetail}/${or?.product?.slug}`
                            );
                          } else {
                            handleOpen(
                              or?.package
                                ? getPackageModalType(or?.package)
                                : "test-pkg",
                              or?.package ? or?.package?.slug : or?.test?.slug
                            );
                          }
                        }}
                      >
                        {or?.product?.title ??
                          or?.package?.title ??
                          or?.test?.title}
                      </h4>
                      {/* {or?.product?.category?.title && (
                <p className="genre">{or?.product?.category?.title}</p>
              )} */}
                      <p className="genre">
                        {or?.product
                          ? or?.product?.category?.title
                          : or?.package
                          ? "Package"
                          : or?.test
                          ? "Test"
                          : null}
                      </p>
                      <p className="quantity">Qty: {or?.quantity}</p>
                    </div>

                    <div className="price-discount">
                      <h4 className="price">
                        {getPriceStatement(or?.total_amount)}
                      </h4>
                      {or?.product?.discounted_price ??
                      or?.package?.discounted_price ??
                      or?.test?.discounted_price ? (
                        <p className="discount">
                          <span>
                            {or?.product?.price
                              ? getPriceStatement(or?.product?.price)
                              : or?.package?.market_price
                              ? getPriceStatement(or?.package?.market_price)
                              : or?.test?.market_price
                              ? getPriceStatement(or?.test?.market_price)
                              : null}
                          </span>{" "}
                          {getDiscountPercent(
                            or?.product?.price ??
                              or?.package?.market_price ??
                              or?.test?.market_price,
                            or?.product?.discounted_price ??
                              or?.package?.discounted_price ??
                              or?.test?.discounted_price
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>

                    <div className="order-report">
                      <p className="outcome">
                        {" "}
                        <i
                          className={`fa-solid fa-circle ${
                            or?.delivery_status === deliveryStatus?.shipped
                              ? "shipped"
                              : or?.delivery_status ===
                                deliveryStatus?.delivered
                              ? "delivered"
                              : or?.delivery_status ===
                                deliveryStatus?.cancelled
                              ? "cancelled"
                              : "pending"
                          }`}
                        ></i>{" "}
                        {or?.delivery_status === deliveryStatus?.shipped
                          ? "Order Shipped."
                          : or?.delivery_status === deliveryStatus?.delivered
                          ? "Delivered."
                          : or?.delivery_status === deliveryStatus?.cancelled
                          ? "Cancelled."
                          : "Order Pending"}
                      </p>
                      <p className="remarks">
                        {" "}
                        {or?.delivery_status === deliveryStatus?.shipped
                          ? "Your order has been shipped."
                          : or?.delivery_status === deliveryStatus?.delivered
                          ? "Your order has been delivered."
                          : or?.delivery_status === deliveryStatus?.cancelled
                          ? "Your order has been cancelled."
                          : "Your order is pending."}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoData
                containerHeight="50%"
                imgWidth="25%"
                msg={errorMessages?.noItemsInMyOrders}
              />
            )}
          </>
        )}
      </>
    );
  };

  const renderProducts = (myOrders: OrderItemsResponse | undefined) => {
    return (
      <>
        {myOrders?.results?.filter((d) => d?.product)?.length ? (
          <>
            {myOrders?.results
              ?.filter((d) => d?.product)
              ?.map((or, idx) => (
                <div className="product" key={idx}>
                  <img
                    // src="https://5.imimg.com/data5/YH/RH/MY-43324479/natural-honey-500x500.jpg"
                    src={
                      or?.product?.images?.length
                        ? resolveImgPath(or?.product?.images[0]?.image)
                        : "/assets/images/placeholders/no-image-placeholder.svg"
                    }
                    alt={or?.product?.title}
                    onClick={() =>
                      navigate(`/${ROUTES?.productDetail}/${or?.product?.slug}`)
                    }
                  />
                  <div className="name-genre">
                    <h4
                      className="name"
                      onClick={() =>
                        navigate(
                          `/${ROUTES?.productDetail}/${or?.product?.slug}`
                        )
                      }
                    >
                      {or?.product?.title}
                    </h4>
                    <p className="genre">{or?.product?.category?.title}</p>
                    <p className="quantity">Qty: {or?.quantity}</p>
                  </div>

                  <div className="price-discount">
                    <h4 className="price">
                      {getPriceStatement(or?.total_amount)}
                    </h4>
                    {or?.product?.discounted_price ? (
                      <p className="discount">
                        <span>{getPriceStatement(or?.product?.price)}</span>{" "}
                        {getDiscountPercent(
                          or?.product?.price,
                          or?.product?.discounted_price
                        )}
                        % OFF
                      </p>
                    ) : null}
                  </div>

                  <div className="order-report">
                    <p className="outcome">
                      {" "}
                      <i
                        className={`fa-solid fa-circle ${
                          or?.delivery_status === deliveryStatus?.shipped
                            ? "shipped"
                            : or?.delivery_status === deliveryStatus?.delivered
                            ? "delivered"
                            : or?.delivery_status === deliveryStatus?.cancelled
                            ? "cancelled"
                            : "pending"
                        }`}
                      ></i>{" "}
                      {or?.delivery_status === deliveryStatus?.shipped
                        ? "Order Shipped."
                        : or?.delivery_status === deliveryStatus?.delivered
                        ? "Delivered."
                        : or?.delivery_status === deliveryStatus?.cancelled
                        ? "Cancelled."
                        : "Order Pending"}
                    </p>
                    <p className="remarks">
                      {" "}
                      {or?.delivery_status === deliveryStatus?.shipped
                        ? "Your order has been shipped."
                        : or?.delivery_status === deliveryStatus?.delivered
                        ? "Your order has been delivered."
                        : or?.delivery_status === deliveryStatus?.cancelled
                        ? "Your order has been cancelled."
                        : "Your order is pending."}
                    </p>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <NoData
            containerHeight="50%"
            imgWidth="25%"
            msg={errorMessages?.noItemsInMyOrders}
          />
        )}
      </>
    );
  };

  const renderServices = (myOrders: OrderItemsResponse | undefined) => {
    return (
      <>
        {myOrders?.results?.filter((d) => d?.package ?? d?.test)?.length ? (
          <>
            {myOrders?.results
              ?.filter((d) => d?.package ?? d?.test)
              ?.map((or, idx) => (
                <div className="product" key={idx}>
                  <img
                    src={
                      or?.package?.icon ?? or?.test?.icon
                        ? resolveImgPath(or?.package?.icon ?? or?.test?.icon)
                        : "/assets/images/placeholders/no-image-placeholder.svg"
                    }
                    alt={or?.package?.title ?? or?.test?.title}
                    onClick={() => {
                      handleOpen(
                        or?.package
                          ? getPackageModalType(or?.package)
                          : "test-pkg",
                        or?.package ? or?.package?.slug : or?.test?.slug
                      );
                    }}
                  />
                  <div className="name-genre">
                    <h4
                      className="name"
                      onClick={() => {
                        handleOpen(
                          or?.package
                            ? getPackageModalType(or?.package)
                            : "test-pkg",
                          or?.package ? or?.package?.slug : or?.test?.slug
                        );
                      }}
                    >
                      {or?.package?.title ?? or?.test?.title}
                    </h4>
                    <p className="genre">{or?.package ? "Package" : "Test"}</p>
                    <p className="quantity">Qty: {or?.quantity}</p>
                  </div>

                  <div className="price-discount">
                    <h4 className="price">
                      {getPriceStatement(or?.total_amount)}
                    </h4>
                    {or?.package?.discounted_price ??
                    or?.test?.discounted_price ? (
                      <p className="discount">
                        <span>
                          {getPriceStatement(
                            or?.package?.market_price ?? or?.test?.market_price
                          )}
                        </span>{" "}
                        {getDiscountPercent(
                          or?.package?.market_price ?? or?.test?.market_price,
                          or?.package?.discounted_price ??
                            or?.test?.discounted_price
                        )}
                        % OFF
                      </p>
                    ) : null}
                  </div>

                  <div className="order-report">
                    <p className="outcome">
                      {" "}
                      <i
                        className={`fa-solid fa-circle ${
                          or?.delivery_status === deliveryStatus?.shipped
                            ? "shipped"
                            : or?.delivery_status === deliveryStatus?.delivered
                            ? "delivered"
                            : or?.delivery_status === deliveryStatus?.cancelled
                            ? "cancelled"
                            : "pending"
                        }`}
                      ></i>{" "}
                      {or?.delivery_status === deliveryStatus?.shipped
                        ? "Order Shipped."
                        : or?.delivery_status === deliveryStatus?.delivered
                        ? "Delivered."
                        : or?.delivery_status === deliveryStatus?.cancelled
                        ? "Cancelled."
                        : "Order Pending"}
                    </p>
                    <p className="remarks">
                      {" "}
                      {or?.delivery_status === deliveryStatus?.shipped
                        ? "Your order has been shipped."
                        : or?.delivery_status === deliveryStatus?.delivered
                        ? "Your order has been delivered."
                        : or?.delivery_status === deliveryStatus?.cancelled
                        ? "Your order has been cancelled."
                        : "Your order is pending."}
                    </p>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <NoData
            containerHeight="50%"
            imgWidth="25%"
            msg={errorMessages?.noItemsInMyOrders}
          />
        )}
      </>
    );
  };

  const handleSort = (e: any) => {
    const { value } = e?.target;
    setSelectedStatus(value);
  };

  return (
    <div className="ordersOutlet">
      <h1 className="accHeader">My Orders</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Select
          label="Filter By: "
          handleChange={handleSort}
          children={Object?.entries(deliveryStatus)
            ?.filter(
              (st) =>
                st[1] !== deliveryStatus?.acknowledged &&
                st[1] !== deliveryStatus?.ready
            )
            ?.map((status, idx) => (
              <option
                value={status[1]}
                style={{ textTransform: "capitalize" }}
                key={idx}
              >
                {capitalizeAllWords(status[0])}
              </option>
            ))}
          disabled={isLoading}
        />
      </div>
      <div className="tabs">
        <div className="products">
          <HorizontalTab
            label1="All"
            label2="Products"
            label3="Services"
            label1data={renderAll(myOrders)}
            label2data={renderProducts(myOrders)}
            label3data={renderServices(myOrders)}
          />
        </div>
      </div>

      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        slug={slug}
        type={type}
        hasAddToCart={false}
        hasBookNow={false}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
      />
    </div>
  );
}
