import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { SpecialityDetail, SpecialityPaginated } from "../../models/speciality";

export const specialityApi = createApi({
  reducerPath: "specialityApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getSpecialities: builder.query<SpecialityPaginated, void>({
      query: () => {
        return {
          url: `/speciality/`,
          method: "GET",
        };
      },
    }),

    // Get a specific speciality
    getSpeciality: builder.query<
      SpecialityDetail,
      { slug: string | undefined }
    >({
      query: ({ slug }) => {
        return {
          url: `/speciality/${slug}/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetSpecialitiesQuery, useGetSpecialityQuery } = specialityApi;
