import { useState, useLayoutEffect } from "react";

function useWindowSize() {
  const [winWidth, setWinWidth] = useState<number>(0);

  const handleSize = () => {
    setWinWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setWinWidth(window.innerWidth);
      window.addEventListener("resize", handleSize);
      return () => window.removeEventListener("resize", handleSize);
    }
  }, []);

  return winWidth;
}

export default useWindowSize;
