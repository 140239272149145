import CheckoutAccordion from "../../components/UI/uiComponents/CheckoutAccordion";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";

export default function Checkout() {
  const crumbs = [
    // Must be in order after home
    {
      name: "Checkout",
      link: "",
    },
  ];
  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <CheckoutAccordion />
    </>
  );
}
