import { AddToPackageCartResponse } from "../../../../../models/packageCart";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../../VerticalAppBarComponents/MyCart/MyCart.css";
import { resolveImgPath } from "../../../../../utils/imagePathResolver";
import { getPriceStatement } from "../../../../../utils/priceCommas";
import { getDiscountPercent } from "../../../../../utils/discountPercentCalc";
import { PackageModalType } from "../../PackageDetailModal";
import { getPackageModalType } from "../../../../../utils/getServiceType";

interface Props {
  pkg: AddToPackageCartResponse;
  handleDelete: (pkgId?: number) => void;
  handleOpen?: (type: PackageModalType, slug?: string | undefined) => void;
  type: "package" | "test";
}

const CartPackages = ({ pkg, handleDelete, handleOpen, type }: Props) => {
  return (
    <div className="package">
      <div className="vendible">
        <div className="vendible__action">
          <CloseOutlinedIcon
            onClick={() => handleDelete(pkg?.id)}
            className="delete-icon"
          />
        </div>
        <div>
          <img
            className="vendible__img"
            src={
              type === "package"
                ? pkg?.package?.icon
                  ? resolveImgPath(pkg?.package?.icon)
                  : "assets/images/Vector (1).svg"
                : pkg?.test?.icon
                ? resolveImgPath(pkg?.test?.icon)
                : "assets/images/Vector (1).svg"
            }
            alt={type === "package" ? pkg?.package?.title : pkg?.test?.title}
            onClick={() => {
              if (handleOpen) {
                if (type === "package") {
                  handleOpen(
                    getPackageModalType(pkg?.package),
                    pkg?.package?.slug
                  );
                } else {
                  handleOpen("test-pkg", pkg?.test?.slug);
                }
              }
            }}
          />
        </div>
        <div className="chkvendible__content">
          <ul>
            <li>
              <h2
                className="title"
                onClick={() => {
                  if (handleOpen) {
                    if (type === "package") {
                      handleOpen(
                        getPackageModalType(pkg?.package),
                        pkg?.package?.slug
                      );
                    } else {
                      handleOpen("test-pkg", pkg?.test?.slug);
                    }
                  }
                }}
              >
                {type === "package" ? pkg?.package?.title : pkg?.test?.title}
              </h2>
            </li>
            <ul className="grayed-data-list">
              {type === "package" ? (
                <>
                  {pkg?.package?.test?.slice(0, 2)?.map((d, idx) => (
                    <li key={idx}>{d?.title}</li>
                  ))}
                </>
              ) : (
                <>
                  {pkg?.test?.included_tests?.slice(0, 2)?.map((d, idx) => (
                    <li key={idx}>{d}</li>
                  ))}
                </>
              )}
            </ul>
          </ul>
        </div>
        {/* Qty goes here if needed */}
        <div className="flex__start-express">
          <div className="d-flex flex-column chkvendible__pricing">
            <div className="chkvendible__pricing__dis">
              {getPriceStatement(pkg?.total_price)}
            </div>
            {type === "package" ? (
              getDiscountPercent(
                pkg?.package?.market_price,
                pkg?.package?.discounted_price
              ) ? (
                <span className="chkvendible__pricing__off">
                  {getDiscountPercent(
                    pkg?.package?.market_price,
                    pkg?.package?.discounted_price
                  )}
                  % OFF
                </span>
              ) : null
            ) : getDiscountPercent(
                pkg?.test?.market_price,
                pkg?.test?.discounted_price
              ) ? (
              <span className="chkvendible__pricing__off">
                {getDiscountPercent(
                  pkg?.test?.market_price,
                  pkg?.test?.discounted_price
                )}
                % OFF
              </span>
            ) : null}
            {/* <span className="chkvendible__pricing__off">20% Off</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPackages;
