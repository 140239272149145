import style from "../../styles/VideoPlayer.module.css";
export default function VideoPlayer() {
  return (
    <div className={style.container}>
      <div className="capsule">
        <h3 className={style.headerTitle}>Videos to guide you at home</h3>
        <div className={style.videos}>
          <div className={style.video1}>
            <iframe
              width="870"
              height="470"
              src="https://www.youtube.com/embed/Q0FpuBn_kUk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <p className={style.video1Name}>
              How to measure blood oxygen from oximeter?
            </p> */}
          </div>

          <div className={style.sideVideos}>
            <div className={style.video2}>
              <iframe
                width="362"
                height="200"
                src="https://www.youtube.com/embed/BejoUkqtTaA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* <p className={style.sideVideoName}>
                How to measure blood oxygen from oximeter?
              </p> */}
            </div>

            <div className={style.video3}>
              <iframe
                width="362"
                height="200"
                src="https://www.youtube.com/embed/ZXtZgxJv2jo"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* <p className={style.sideVideoName}>
                How to measure blood oxygen from oximeter?
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
