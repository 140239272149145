import React from "react";
import style from "../../styles/ImpactDoc.module.css";
import CountUp from "react-countup";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import CampaignIcon from "@mui/icons-material/Campaign";
import VisibilitySensor from "react-visibility-sensor";

export default function ImpactDoc() {
  const [focus, setFocus] = React.useState(false);
  return (
    <div className={style.container}>
      <div className="capsule">
        <h3 className={style.headerTitle}>Impacts Created by DOC Nepal</h3>
      </div>
      <div className={style.dataDiv}>
        <img src="assets/images/Rectangle 4844.png" />
        <div className={style.colorBg}></div>

        <div className={style.information}>
          <div className={style.info}>
            <div className={style.icon}>
              <BusinessCenterIcon />
            </div>
            <CountUp start={focus ? 0 : undefined} end={3} redraw={true}>
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(start: any) => {
                    if (start) {
                      setFocus(true);
                    }
                  }}
                  delayedCall
                >
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p>Years of Experience</p>
          </div>
          <div className={style.info}>
            <div className={style.icon}>
              <PersonIcon />
            </div>
            <CountUp
              start={focus ? 0 : undefined}
              end={60}
              redraw={true}
              suffix="+"
            >
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(start: any) => {
                    if (start) {
                      setFocus(true);
                    }
                  }}
                  delayedCall
                >
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p>Team Members</p>
          </div>
          <div className={style.info}>
            <div className={style.icon}>
              <EmojiEmotionsIcon />
            </div>
            <CountUp start={focus ? 0 : undefined} end={10} suffix="k+">
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(start: any) => {
                    if (start) {
                      setFocus(true);
                    }
                  }}
                  delayedCall
                >
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p>Smiling Clients</p>
          </div>

          <div className={style.info}>
            <div className={style.icon}>
              <CampaignIcon />
            </div>
            <CountUp start={focus ? 0 : undefined} end={100} suffix="+">
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(start: any) => {
                    if (start) {
                      setFocus(true);
                    }
                  }}
                  delayedCall
                >
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p>Acknowledgements</p>
          </div>
        </div>
      </div>
    </div>
  );
}
