import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import {
  BookAppointmentListModel,
  BookAppointmentResponseModel,
} from "../../models/appointment";
import { getLocalAuthToken } from "../../storage/localStorage";

export const appointmentApi = createApi({
  reducerPath: "appointmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAppointements: builder.query<
      BookAppointmentListModel[],
      { queryString: string | undefined }
    >({
      query: ({ queryString }) => {
        return {
          url: `/appointment/?${queryString}`,
          method: "GET",
        };
      },
    }),
    bookAppointment: builder.mutation<
      BookAppointmentResponseModel,
      {
        first_name: string;
        last_name: string;
        speciality_id: number;
        guardian_name: string;
        dob: string;
        email: string;
        address: string;
        gender: string;
        phone_number: string;
        remarks: string;
        appointment_date: string;
      }
    >({
      query: ({
        first_name,
        last_name,
        speciality_id,
        guardian_name,
        dob,
        email,
        address,
        gender,
        phone_number,
        remarks,
        appointment_date,
      }) => {
        return {
          url: `/appointment/`,
          method: "POST",
          body: {
            first_name,
            last_name,
            speciality_id,
            guardian_name,
            dob,
            email,
            address,
            gender,
            phone_number,
            remarks,
            appointment_date,
          },
        };
      },
    }),
  }),
});

export const {
  useBookAppointmentMutation,
  useGetAppointementsQuery,
} = appointmentApi;
