import { useState } from "react";
import { Box, Modal } from "@mui/material";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPw";
import ResetPassword from "./ResetPw";
import LoginPhone from "./LoginPhone";
import Otp from "./Otp";
import { Feature } from "../../../../models/feature";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type?:
    | "Login"
    | "SignUp"
    | "LoginPhone"
    | "Otp"
    | "ForgotPassword"
    | "ResetPassword";
  feature?: Feature;
}

export interface ResetPwStateType {
  email: String | undefined;
  key: String | undefined;
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "600px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
};

const AuthModal = ({ open, setOpen, type = "Login", feature }: Props) => {
  //   const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  // Modal to mount
  const [modalType, setModalType] = useState<
    | "Login"
    | "SignUp"
    | "LoginPhone"
    | "Otp"
    | "ForgotPassword"
    | "ResetPassword"
  >(type);

  const handleModalType = (
    type:
      | "Login"
      | "SignUp"
      | "LoginPhone"
      | "Otp"
      | "ForgotPassword"
      | "ResetPassword"
  ) => {
    setOpen(true);
    setModalType(type);
  };

  // State For Reset Password
  const [resetPwState, setResetPwState] = useState<ResetPwStateType>({
    email: undefined,
    key: undefined,
  });

  const handleResetPwState = (state: ResetPwStateType) => {
    setResetPwState(state);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle} className="loginModal">
        {modalType === "Login" ? (
          <Login
            onClose={handleClose}
            switchModalType={handleModalType}
            feature={feature}
          />
        ) : modalType === "SignUp" ? (
          <SignUp onClose={handleClose} switchModalType={handleModalType} />
        ) : modalType === "ForgotPassword" ? (
          <ForgotPassword
            onClose={handleClose}
            switchModalType={handleModalType}
            handleResetPwState={handleResetPwState}
          />
        ) : modalType === "ResetPassword" ? (
          <ResetPassword
            onClose={handleClose}
            switchModalType={handleModalType}
            handleResetPwState={handleResetPwState}
            resetPwState={resetPwState}
          />
        ) : modalType === "LoginPhone" ? (
          <LoginPhone onClose={handleClose} />
        ) : modalType === "Otp" ? (
          <Otp onClose={handleClose} />
        ) : null}
      </Box>
    </Modal>
  );
};

export default AuthModal;
