// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { FC } from "react";

import "../../styles/Hero.css";
// import { height } from "@mui/system";
import { Banner } from "../../models/home";
import { resolveImgPath } from "../../utils/imagePathResolver";
import LoadingShimmer from "../UI/uiComponents/LoadingShimmer";
import useWindowSize from "../../hooks/useWindowSize";
import { LazyLoadImage } from "react-lazy-load-image-component";

// install Swiper modules

interface HeroProps {
  hasContainer?: Boolean;
  data: Banner[] | undefined;
  // height?: boolean;
  isLoading?: boolean;
}

const Hero: FC<HeroProps> = ({
  hasContainer = false,
  data,
  // height = true,
  isLoading = false,
}: HeroProps) => {
  const winWidth = useWindowSize();
  return (
    <>
      <div
        className={`${hasContainer ? "capsule" : ""} detailSlider`}
        // style={height ? { height: "360px" } : {}}
        style={isLoading ? { height: "360px" } : {}}
      >
        {isLoading ? (
          <LoadingShimmer />
        ) : (
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 3000,
            }}
            // autoplay={{
            //   delay: 5500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {data?.length ? (
              data?.map((e, idx) => (
                <SwiperSlide
                  className="sliderBox"
                  key={idx}
                  style={{ height: "auto" }}
                >
                  <a href={e?.launch_url ?? "#"}>
                    <img
                      src={
                        winWidth <= 768
                          ? resolveImgPath(e?.mobile_image)
                          : winWidth <= 992
                            ? resolveImgPath(e?.medium_image)
                            : resolveImgPath(e?.web_image)
                      }
                      alt={e?.title}
                    />
                    {/* <LazyLoadImage
                      src={
                        winWidth <= 768
                          ? resolveImgPath(e?.compressed_mobile_image)
                          : winWidth <= 992
                          ? resolveImgPath(e?.compressed_medium_image)
                          : resolveImgPath(e?.compressed_web_image)
                      }
                      alt={e?.title}
                    /> */}
                  </a>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide className="sliderBox">
                <img src="/assets/images/placeholders/banner-placeholder.png" />
              </SwiperSlide>
            )}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default Hero;
