import style from "../../styles/AppointmentForm.module.css";
import ContactUsForm from "../../components/utils/Career";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
export default function CareerPage() {
  const crumbs = [
    // Must be in order after home
    {
      name: "Careers",
      link: "",
    },
  ];
  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <div className="capsule">
        <section>
          <header>
            <h1 className={style.mainHeader}>
              Let's create something extraordinary together
            </h1>
            <p className={style.mainP}>
              Collaborate with us as you technical partners and let's transform
              your idea into feasible reality with the talented human resource
              of Doctors on call (DOC) Nepal.
            </p>
          </header>

          <div className="capsule" style={{ height: "250px" }}>
            Please click{" "}
            <a href="https://forms.gle/Dh5y1AmdAQzBVq3A8" target="_blank">
              here.
            </a>
          </div>
        </section>
      </div>
    </>
  );
}
