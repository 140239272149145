import TuneIcon from "@mui/icons-material/Tune";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useSearchParams } from "react-router-dom";
import { appendNewParam } from "../../../utils/serializeQueryString";
import { useGetConfigQuery } from "../../../services/config";
import { addPriceCommas } from "../../../utils/priceCommas";
import { useDebounce } from "../../../hooks/useDebounce";

function valuetext(value: number, index: number) {
  return `${value}°C`;
}

interface Props {
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPage: React.Dispatch<React.SetStateAction<boolean>>;
  minPrice?: number | null;
  maxPrice?: number | null;
}

export default function FilterViewMore({
  setReset,
  setResetPage,
  minPrice,
  maxPrice,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  // Get search params
  const min_price = searchParams.get("min_price");
  const max_price = searchParams.get("max_price");
  // Controlled Slider
  // To change slider when user directly goes into the view all page by using a url (Especially in a banner of special offers).
  const [value, setValue] = useState([
    min_price ? parseInt(min_price) / 1000 : 0,
    max_price ? parseInt(max_price) / 1000 : 10,
  ]);
  const debouncedValue = useDebounce(value, 1000);
  const [marks, setMarks] = useState<any[]>([]);
  const [resultType, setResultType] = useState(
    searchParams.get("type") ?? "product"
  );
  const [category, setCategory] = useState(searchParams.get("category") ?? "");

  const { data: configData } = useGetConfigQuery();

  const productCategories = [
    <FormControlLabel
      value={"is_featured"}
      control={<Radio />}
      label="Featured"
    />,
    <FormControlLabel
      value={"is_top_selling"}
      control={<Radio />}
      label="Top Selling"
    />,
    <FormControlLabel
      value={"is_most_viewed"}
      control={<Radio />}
      label="Most Viewed"
    />,
    <FormControlLabel
      value={"is_just_for_you"}
      control={<Radio />}
      label="Just For you"
    />,
  ];

  const testCategories = [
    <FormControlLabel
      value={"is_featured"}
      control={<Radio />}
      label="Featured"
    />,
    <FormControlLabel
      value={"is_discount"}
      control={<Radio />}
      label="Discount"
    />,
    <FormControlLabel
      value={"is_popular"}
      control={<Radio />}
      label="Popular"
    />,
  ];

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    setResetPage(true);
  };

  const handleClearAll = () => {
    setSearchParams({ type: "product" });
    setResultType("product");
    setCategory("");
    setValue([0, 10]);
    setReset(true);
  };

  const handleFilterByCategory = (e: any, filter: "category" | "type") => {
    if (filter === "type") {
      setResultType(e.target.value);
    } else {
      setCategory(e?.target?.value);
    }
    setSearchParams(
      appendNewParam(searchParams, {
        [filter]: e.target.value,
      })
    );
    setResetPage(true);
  };

  useEffect(() => {
    if (!searchParams.get("type")) setResultType("product");
  }, [searchParams]);

  useEffect(() => {
    if (minPrice && maxPrice) {
      setMarks(
        new Array(
          {
            value: minPrice / 1000,
            label: `Rs. ${addPriceCommas(minPrice)}`,
          },
          {
            value: maxPrice / 1000,
            label: `Rs. ${addPriceCommas(maxPrice)}`,
          }
        )
      );
    } else if (configData) {
      if (configData) {
        setMarks(
          new Array(
            {
              value: configData?.min_price / 1000,
              label: `Rs. ${addPriceCommas(configData?.min_price)}`,
            },
            {
              value: configData?.max_price / 1000,
              label: `Rs. ${addPriceCommas(configData?.max_price)}`,
            }
          )
        );
      }
    }
  }, [configData, minPrice, maxPrice]);

  useEffect(() => {
    if (debouncedValue) {
      setSearchParams(
        appendNewParam(searchParams, {
          min_price: value[0] * 1000,
          max_price: value[1] * 1000,
        })
      );
    }
  }, [debouncedValue]);

  // WARN: To Reset Price when resultType or Category changes but this makes the page load data two times.
  // useEffect(() => {
  //   setValue([0, 10]);
  // }, [resultType, category]);

  return (
    <>
      <div className="left">
        {/* Filter Section for mobile  */}
        <div className="leftMobile">
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <img
                    className="filter-icon"
                    src="https://cdn.asparksys.com/medias/1661429275277.svg"
                    alt=""
                  />
                  Filters
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <small onClick={handleClearAll}>Clear all</small>
              </AccordionItemPanel>
              <AccordionItemPanel>
                <div className="rangeSlider">
                  <h3>Set Price Range</h3>
                  <Box sx={{ width: "70vw", padding: "0px 5px" }}>
                    <Slider
                      // getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      getAriaValueText={valuetext}
                      marks={marks}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) =>
                        `${addPriceCommas(value * 1000)}`
                      }
                      min={
                        minPrice
                          ? minPrice / 1000
                          : configData?.min_price
                          ? configData?.min_price / 1000
                          : 0
                      }
                      max={
                        maxPrice
                          ? maxPrice / 1000
                          : configData?.max_price
                          ? configData?.max_price / 1000
                          : 100
                      }
                      sx={{
                        '& .MuiSlider-markLabel[data-index="0"]': {
                          left: "6% !important",
                        },
                      }}
                    />
                  </Box>
                </div>
                <hr />
                <div className="category">
                  <h3>Type</h3>
                  <FormControl>
                    <RadioGroup
                      onChange={(e) => handleFilterByCategory(e, "type")}
                      name="radio-buttons-group"
                      className="radioCategory"
                      value={resultType}
                    >
                      <FormControlLabel
                        value="product"
                        control={<Radio />}
                        label="Products"
                      />
                      <FormControlLabel
                        value="package"
                        control={<Radio />}
                        label="Packages"
                      />
                      <FormControlLabel
                        value="test"
                        control={<Radio />}
                        label="Tests"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <hr />
                {resultType === "package" ? null : (
                  <div className="category">
                    <h3>Categories</h3>
                    {resultType === "product" ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="category-radio-buttons-group"
                          className="radioCategory"
                          onChange={(e) =>
                            handleFilterByCategory(e, "category")
                          }
                          value={category}
                        >
                          {productCategories}
                        </RadioGroup>
                      </FormControl>
                    ) : resultType === "test" ? (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="category-radio-buttons-group"
                          className="radioCategory"
                          onChange={(e) =>
                            handleFilterByCategory(e, "category")
                          }
                          value={category}
                        >
                          {testCategories}
                        </RadioGroup>
                      </FormControl>
                    ) : null}
                  </div>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="leftDesktop">
          <div className="top">
            <div className="head" id="close-filter">
              <TuneIcon />
              <p>Filters</p>
            </div>
            <small onClick={handleClearAll}>Clear all</small>
          </div>
          <hr />
          <div className="rangeSlider">
            <h3>Set Price Range</h3>
            <Box sx={{ width: "18vw", padding: "0px 5px" }}>
              <Slider
                // getAriaLabel={() => "Temperature range"}
                value={value}
                onChange={handleChange}
                getAriaValueText={valuetext}
                marks={marks}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${addPriceCommas(value * 1000)}`}
                // min={configData?.min_price ? configData?.min_price / 1000 : 0}
                // max={configData?.max_price ? configData?.max_price / 1000 : 100}
                min={
                  minPrice
                    ? minPrice / 1000
                    : configData?.min_price
                    ? configData?.min_price / 1000
                    : 0
                }
                max={
                  maxPrice
                    ? maxPrice / 1000
                    : configData?.max_price
                    ? configData?.max_price / 1000
                    : 100
                }
                sx={{
                  '& .MuiSlider-markLabel[data-index="0"]': {
                    left: "6% !important",
                  },
                }}
              />
            </Box>
          </div>
          <hr />
          <div className="category">
            <h3>Type</h3>
            <FormControl>
              <RadioGroup
                onChange={(e) => handleFilterByCategory(e, "type")}
                name="radio-buttons-group"
                className="radioCategory"
                value={resultType}
              >
                <FormControlLabel
                  value="product"
                  control={<Radio />}
                  label="Products"
                />
                <FormControlLabel
                  value="package"
                  control={<Radio />}
                  label="Packages"
                />
                <FormControlLabel
                  value="test"
                  control={<Radio />}
                  label="Lab Tests"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <hr />
          {resultType === "package" ? null : (
            <div className="category">
              <h3>Categories</h3>
              {resultType === "product" ? (
                <FormControl>
                  <RadioGroup
                    onChange={(e) => handleFilterByCategory(e, "category")}
                    name="radio-buttons-group"
                    className="radioCategory"
                    value={category}
                  >
                    {productCategories}
                  </RadioGroup>
                </FormControl>
              ) : resultType === "test" ? (
                <FormControl>
                  <RadioGroup
                    onChange={(e) => handleFilterByCategory(e, "category")}
                    name="radio-buttons-group"
                    className="radioCategory"
                    value={category}
                  >
                    {testCategories}
                  </RadioGroup>
                </FormControl>
              ) : null}
              {/* <FormControl>
              <RadioGroup
                onChange={(e) => handleFilterByCategory(e, "category")}
                name="radio-buttons-group"
                className="radioCategory"
              >
                {filterCategoriesList}
              </RadioGroup>
            </FormControl> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
