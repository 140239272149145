import { useState, useEffect, useRef } from "react";
import PasswordInput from "../../uiComponents/Formik/PasswordInput";
import * as Yup from "yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Formik, Form, FormikProps } from "formik";
import { FormButtonBlue } from "../../uiComponents/Button";
import {
  passwordValidation,
  confirmPasswordValidation,
} from "../../../../validations";
import "./ChangePassword.css";
import { useChangePasswordMutation } from "../../../../services/user";
import { Alert } from "@mui/material";
import {
  successMessages,
  errorMessages,
} from "../../../../utils/alertMessages";

interface InitialValuesType {
  old_password: string;
  new_password: string;
  confim_new_password: string;
}

const validaitonSchema = Yup.object({
  old_password: passwordValidation("Please enter your Old Password"),
  new_password: passwordValidation("Please enter your New Password"),
  confim_new_password: confirmPasswordValidation("new_password"),
});

const initialValues = {
  old_password: "",
  new_password: "",
  confim_new_password: "",
};

export default function ChangePassword() {
  const [customErr, setCustomErr] = useState<String>("");
  const formikRef = useRef<FormikProps<any>>(null);

  const [
    changePassword,
    { isLoading, error, isSuccess },
  ] = useChangePasswordMutation();

  const handleChangePassword = (values: InitialValuesType) => {
    changePassword({
      old_password: values?.old_password,
      new_password: values?.new_password,
    });
  };

  useEffect(() => {
    if (error) {
      if ("data" in error) {
        const errMsg: any = "error" in error ? error.error : error.data;
        setCustomErr(
          errMsg?.old_password || errMsg?.Error || errorMessages?.networkError
        );
      } else {
        setCustomErr(errorMessages?.networkError);
      }
    } else if (isSuccess) {
      formikRef?.current?.resetForm();
    }
  }, [isSuccess, error]);

  return (
    <>
      <div className="accountOutlet">
        <h1 className="accHeader">Change Password</h1>
        <p className="welcomeMsg">
          Please use at least 8 characters, including at least an uppercase
          alphabet, a symbol and a numerial while changing your password.
        </p>
        <div className="flexDiv">
          <Formik
            initialValues={initialValues}
            onSubmit={handleChangePassword}
            validationSchema={validaitonSchema}
            innerRef={formikRef}
          >
            {() => (
              <Form>
                <div className="inputs">
                  <PasswordInput
                    type="password"
                    name="old_password"
                    label="Old Password"
                    placeholder="Enter Old Password"
                    labelLogo={<LockOutlinedIcon />}
                  />
                  <PasswordInput
                    type="password"
                    name="new_password"
                    label="New Password"
                    placeholder="Enter New Password"
                    labelLogo={<LockOutlinedIcon />}
                  />
                  <PasswordInput
                    type="password"
                    name="confim_new_password"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    labelLogo={<LockOutlinedIcon />}
                  />
                  <div style={{ width: "100%" }}>
                    {error && <Alert severity="error">{customErr}</Alert>}
                    {isSuccess && (
                      <Alert severity="success">
                        {successMessages?.changePw}
                      </Alert>
                    )}
                    <FormButtonBlue
                      name={isLoading ? "Updating..." : "Change Password"}
                      type="submit"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <img src="assets/images/Rectangle 4860.svg" />
        </div>
      </div>
    </>
  );
}
