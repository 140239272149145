import { useState } from "react";

function useCheckItems() {
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<string | undefined>>(
    []
  );

  // Check All
  const handleCheckAll = (data: Array<string | undefined>) => {
    setIsCheckAll(!isCheckAll);
    if (data?.length) {
      setCheckedItems(data?.map((d) => d));
    }
    if (isCheckAll) {
      setCheckedItems([]);
    }
  };

  // Check All when data is loaded/updated from the API without Toggling "isCheckAll" and keeping it "true".
  const handleAutoCheckAll = (data: Array<string | undefined>) => {
    if (data?.length) {
      setCheckedItems(data?.map((d) => d));
      setIsCheckAll(true);
    }
  };

  // Check specific items
  const handleCheck = (value: string, checked: boolean | undefined) => {
    setCheckedItems([...checkedItems, value]);
    if (!checked) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  return {
    isCheckAll,
    setIsCheckAll,
    checkedItems,
    setCheckedItems,
    handleCheckAll,
    handleAutoCheckAll,
    handleCheck,
  };
}

export default useCheckItems;
