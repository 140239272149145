import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import AboutUsHero from "../../components/utils/AboutUsHero";
import VideoPlayer from "../../components/utils/VideoPlayer";
import ImpactDoc from "../../components/utils/ImpactsDoc";
import AboutUsAim from "../../components/utils/AboutUsAim";
import AboutUsMissions from "../../components/utils/AboutUsMissions";
// import Testimonial from "../../components/utils/Testimonial";
// import TeamMembers from "../../components/utils/TeamMembers";
// import HealthArticles from "../../components/utils/HealthArticles";
// import DoctorAdvice from "../../components/utils/DoctorAdvice";
// import SocialResponsibility from "../../components/utils/SocialResponsibility";
// import Feature from "../../components/utils/NewsFeature";

export default function AboutUsPage() {
  const crumbs = [
    // Must be in order after home
    {
      name: "About Us",
      link: "",
    },
  ];
  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <AboutUsHero />
      <AboutUsAim />
      <AboutUsMissions />
      {/* <Testimonial /> */}
      {/* <TeamMembers /> */}
      {/* <HealthArticles /> */}
      {/* <DoctorAdvice /> */}
      <VideoPlayer />
      {/* <SocialResponsibility /> */}
      <ImpactDoc />
      {/* <Feature /> */}
    </>
  );
}
