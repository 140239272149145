import Hero from "../../components/utils/Hero";
import WhatWeDo from "../../components/utils/WhatWeDo";
import TopSelling from "../../components/layouts/TopSelling";
import JustForYou from "../../components/layouts/JustForYou";
import HealthPackages from "../../components/utils/HealthPackages";
import { SwiperSlide } from "swiper/react";
import Cards from "../../components/UI/cards/HealthPackagesCards";
import AdImageCard from "../../components/UI/cards/AdImageCard";
// API
import { useGetHomepageQuery } from "../../services/home";

import "../../styles/App.css";
// import "../../styles/AdImageCard.css";

// import "swiper/css/bundle";
import { resolveImgPath } from "../../utils/imagePathResolver";

function homePage() {
  const { data: homePageData, isError, isLoading } = useGetHomepageQuery();
  console.log(homePageData)
  return (
    <>
      <Hero data={homePageData?.banner} isLoading={isLoading || isError} />
      <WhatWeDo data={homePageData?.services} />
      {/* Featured Product */}
      <TopSelling
        data={homePageData?.featured_products}
        title="Featured Products"
        isLoading={isLoading || isError}
        category="is_featured"
      />
      {/* Top Selling */}
      <TopSelling
        data={homePageData?.top_selling}
        title="Top Selling"
        isLoading={isLoading || isError}
        category="is_top_selling"
      />
      {/* Deals */}
      <div className="display-flex capsule">
        {homePageData?.ads_banner?.map((e, idx) => (
          <AdImageCard
            key={idx}
            url={e?.launch_url ?? ""}
            image={e?.web_image ?? ""}
            index={idx}
            title={e?.title}
            subtitle={e?.subtitle}
            color={e?.color}
            button_title={e?.button_title}
          ></AdImageCard>
        ))}
      </div>
      {/* Health Packages */}
      <HealthPackages
        header="Our Health Packages Gets You Covered"
        type="health-packages"
        view="View More"
        isLoading={isLoading || isError}
        data={homePageData?.packages}
      >
        {homePageData?.packages?.map((pkg, idx) => {
          return (
            <SwiperSlide key={idx}>
              <Cards
                id={pkg?.id}
                slug={pkg?.slug}
                image={resolveImgPath(pkg?.icon)}
                header={pkg?.title}
                tests={pkg?.test}
                actualPrice={pkg?.discounted_price}
                crossedPrice={pkg?.market_price}
                key={idx}
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages>
      {/* Most Viewed */}
      <TopSelling
        data={homePageData?.most_viewed_products}
        title="Most Viewed"
        isLoading={isLoading || isError}
        category="is_most_viewed"
      />
      {/* Just For You */}
      <JustForYou
        data={homePageData?.just_for_you?.slice(0, 20)}
        title="Just For You"
        isLoading={isLoading || isError}
      />
    </>
  );
}

export default homePage;
