import AddressList from "../AddressList";
import "./BillingAddress.css";

export default function BillingAddress() {
  return (
    <>
      <div className="accountOutlet">
        <h1 className="accHeader">Billing Address</h1>
        <p className="welcomeMsg">Your Billing Addresses.</p>
        {/* Address List */}
        <AddressList type="billing" />
      </div>
    </>
  );
}
