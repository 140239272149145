import AddressList from "../AddressList";
import "./ShippingAddress.css";

export default function ShippingAddress() {
  return (
    <>
      <div className="accountOutlet">
        <h1 className="accHeader">Delivery Address</h1>
        <p className="welcomeMsg">Your Delivery Addresses.</p>

        {/* Address List */}
        <AddressList type="shipping" />
      </div>
    </>
  );
}
