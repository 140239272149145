import { useState, useEffect } from "react";
import "../../styles/DetailPage.css";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
import ProductDetailSlider from "../../components/utils/ProductDetailSlider";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useAddToPackageCartMutation } from "../../services/cart";
import { getPriceStatement } from "../../utils/priceCommas";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import LoadingShimmer from "../../components/UI/uiComponents/LoadingShimmer";
import AuthModal from "../../components/UI/uiComponents/AuthModals";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
// Toast.
import { toast } from "react-toastify";
import { errorMessages, successMessages } from "../../utils/alertMessages";
import WishProduct from "../../components/UI/uiComponents/WishProduct";
import BuyNowBtn from "../../components/UI/uiComponents/Buttons/BuyNow";
import { Feature } from "../../models/feature";
import { PackageType } from "../../models/package";
import { useGetShortProcedurePkgQuery } from "../../services/consult-doctor";
import { ProductMetaData } from "../../models/metaData";

interface CrumbsModel {
  name: string | undefined;
  link: string | undefined;
}

export default function PackageDetailPage() {
  const { slug, pkgType } = useParams();
  const { pathname, state } = useLocation();
  const {
    stName,
    stGenre,
    stActualPrice,
    stCrossedPrice,
  } = state as ProductMetaData;

  const checkPkgType = (type: PackageType): boolean => {
    // const query = search?.split("?pkgType=")[1];
    const query = pkgType;
    if (query === type) return true;
    else return false;
  };

  // To check if logged in or not.
  const authToken = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );
  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );
  // Quantity
  const [count, setCount] = useState<number>(1);
  // For Auth Modals
  const [open, setOpen] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);

  // API: Package Details
  const {
    data: shProData,
    isError: isShProErr,
    isLoading: isShProLoading,
  } = useGetShortProcedurePkgQuery(
    { slug },
    { skip: !checkPkgType("shortProcedure") }
  );

  // API: Cart
  const [
    addToCart,
    { error: addError, isLoading: adding, isSuccess: isAddSuccess },
  ] = useAddToPackageCartMutation();

  // Breadcrumbs
  const [crumbs, setCrumbs] = useState<CrumbsModel[]>([]);

  useEffect(() => {
    if (shProData) {
      setCrumbs([
        {
          name: shProData?.title,
          link: "",
        },
      ]);
    }
  }, [shProData]);

  useEffect(() => {
    if (isAddSuccess) {
      toast.success(successMessages?.addToCart);
    } else if (addError) {
      if ("status" in addError) {
        // For some reason, TS doesn't take addError.data.Error but, takes addError.data.error like in login api.
        const errMsg: any =
          addError?.status === 400 || addError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isAddSuccess, addError]);

  // Reset Quantity Count after route changes
  useEffect(() => {
    if (pathname) setCount(1);
  }, [pathname]);

  // Add to cart
  const handleAddToCart = () => {
    if (!authToken) {
      setFeature("cart");
      setOpen(true);
    } else {
      if (count) {
        addToCart({
          package_id: shProData?.id,
          user_id: userProfileId,
        });
      }
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <div className="main-section capsule">
        <div className="picture">
          {isShProLoading || isShProErr ? (
            <LoadingShimmer />
          ) : (
            <ProductDetailSlider productImages={shProData?.images} />
          )}
        </div>
        <div className="details">
          <p className="category">
            {shProData?.package_category?.title?.toUpperCase() ??
              stGenre?.toUpperCase()}
          </p>
          <h2 className="name">{shProData?.title ?? stName}</h2>
          <h2 className="price">
            {shProData?.discounted_price ?? shProData?.market_price
              ? getPriceStatement(
                  shProData?.discounted_price ?? shProData?.market_price
                )
              : getPriceStatement(stActualPrice ?? stCrossedPrice)}
            {/* {getPriceStatement(
              shProData?.discounted_price ?? shProData?.market_price
            )} */}{" "}
            /-
          </h2>
          {shProData?.discounted_price ? (
            <h3 className="offer">
              {shProData?.market_price !== shProData?.discounted_price && (
                <span>{getPriceStatement(shProData?.market_price)}</span>
              )}
              {getDiscountPercent(
                shProData?.market_price,
                shProData?.discounted_price
              )
                ? `${getDiscountPercent(
                    shProData?.market_price,
                    shProData?.discounted_price
                  )}% OFF`
                : null}
            </h3>
          ) : stActualPrice ? (
            <h3 className="offer">
              {stCrossedPrice !== stActualPrice && (
                <span>{getPriceStatement(stCrossedPrice)}</span>
              )}
              {getDiscountPercent(stCrossedPrice, stActualPrice)
                ? `${getDiscountPercent(stCrossedPrice, stActualPrice)}% OFF`
                : null}
            </h3>
          ) : null}
          {/* <h4 className="availability">
            Availability:<span>In stock</span>
          </h4> */}
          {/* {data?.product?.brand?.title && (
            <h4 className="manufacture">
              Manufactured By: <span>{data?.product?.brand?.title}</span>
            </h4>
          )} */}
          {/* <div className="quantity">
            <h4>Quantity:</h4>
            <div className="wrapper">
              <span
                onClick={() => count > 1 && setCount(count - 1)}
                className="minus"
              >
                -
              </span>
              <input
                type="number"
                name="quantity"
                value={count}
                onChange={(e: any) => setCount(parseInt(e?.target?.value))}
                className={style.productDetailInput}
                style={{ margin: "0px 6px" }}
                max="99"
              />
              <span
                onClick={() => count < 99 && setCount(count + 1)}
                className="plus"
              >
                +
              </span>
            </div>
            {(!count || count < 0 || count > 99) && (
              <Alert severity="error">
                {count > 99
                  ? errorMessages?.quantityCartMax
                  : errorMessages?.quantityCart}
              </Alert>
            )}
            <p>| Only 5 items left</p>
          </div> */}

          <WishProduct
            setOpen={setOpen}
            product_id={shProData?.id}
            setFeature={setFeature}
            type="package"
          />

          <div className="buttons">
            <BuyNowBtn
              setOpen={setOpen}
              id={shProData?.id}
              type="package"
              name="Buy Now"
              quantity={count}
              setFeature={setFeature}
            />
            <button
              className="add-to-cart"
              onClick={handleAddToCart}
              disabled={adding || count < 0 || count > 99 || !count}
            >
              {adding ? "Adding..." : "Add To Cart"}
            </button>
          </div>
        </div>
      </div>

      <div style={{ paddingBottom: "24px" }}>
        <HorizontalTab
          label1="Description"
          label2="Speciality"
          label3="Tests"
          label1data={
            <div
              dangerouslySetInnerHTML={{
                __html: shProData?.description ?? "",
              }}
            />
          }
          label2data={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  shProData?.speciality?.reduce(
                    (total, d) => `${total}${d?.title}, `,
                    ""
                  ) ?? "",
              }}
            />
          }
          label3data={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  shProData?.test?.reduce(
                    (total, d) => `${total}${d?.title}, `,
                    ""
                  ) ?? "",
              }}
            />
          }
        />
      </div>
      <AuthModal open={open} setOpen={setOpen} feature={feature} />
    </>
  );
}
