import LoadingShimmer from ".";
import useWindowSize from "../../../../hooks/useWindowSize";
import "../../../../styles/LoadingShimmer/index.css";

interface Props {
  width?: string | undefined;
  height?: string | undefined;
  maxHeight?: string | undefined;
  itemsToShow?: number | undefined;
  itemsToShowOnMobile?: number | undefined;
  showAllOnMobile?: boolean;
}

const ShimmerCards = ({
  width = "200px",
  height = "280px",
  maxHeight = "280px",
  itemsToShow = 6,
  itemsToShowOnMobile = 4,
  showAllOnMobile = false,
}: Props) => {
  const winWidth = useWindowSize();
  const isMobile = () => {
    return winWidth <= 576 ? true : false;
  };

  // Create Shimmer Items according to itemsToShow.
  const getContent = (num: number | undefined) => {
    const content = [];
    if (num) {
      for (let i = 0; i < num; i++) {
        content.push(
          <div className="shimmer-item" style={{ width, height }} key={i}>
            <LoadingShimmer />
          </div>
        );
      }
      return content;
    } else return undefined;
  };

  return (
    <div
      className="shimmer-items-container"
      style={showAllOnMobile && isMobile() ? {} : { maxHeight }}
    >
      <>{getContent(isMobile() ? itemsToShowOnMobile : itemsToShow)}</>
    </div>
  );
};

export default ShimmerCards;
