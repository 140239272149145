import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Image } from "../../models/images";
import { resolveImgPath } from "../../utils/imagePathResolver";

interface Props {
  productImages: Image[] | undefined;
}

export default function ProductDetailSlider({ productImages }: Props) {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>();

  return (
    <>
      <Swiper
        // loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumbnail"
      >
        {productImages?.length ? (
          <>
            {productImages?.map((img, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={resolveImgPath(img?.image)} alt={img?.alt_text} />
                </SwiperSlide>
              );
            })}
          </>
        ) : (
          <SwiperSlide>
            <img src={"/assets/images/placeholders/no-image-placeholder.svg"} />
          </SwiperSlide>
        )}
      </Swiper>

      {productImages?.length ? (
        <>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="thumb"
          >
            {productImages?.map((img, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img src={resolveImgPath(img?.image)} alt={img?.alt_text} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      ) : null}
    </>
  );
}
