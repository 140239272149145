import { useField, FieldHookConfig } from "formik";
import style from "../../../../../styles/input.module.css";

interface OtherProps {
  required?: Boolean;
  disabled?: Boolean;
  errMessage?: String;
  label: String;
  labelFor: string;
  checked?: boolean;
}

const CheckBox = ({
  required = true,
  disabled = false,
  errMessage = "",
  label = "",
  labelFor = "",
  //   checked = false,
  ...props
}: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  return (
    <div className="checkmark">
      <input
        {...field}
        id={props?.id}
        type="checkbox"
        required={required}
        disabled={disabled}
        // checked={checked}
      />
      <label htmlFor={labelFor}>
        <p>{label}</p>
      </label>
      {meta.touched && meta.error ? (
        <p className={style.formError}>
          {errMessage?.length ? errMessage : meta.error}
        </p>
      ) : null}
    </div>
  );
};

export default CheckBox;
