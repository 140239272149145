import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Links from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import "../../../styles/Breadcrumb.css";
import { Link } from "react-router-dom";

function handleClick(event: any) {
  event.preventDefault();
  // console.info("You clicked a breadcrumb.");
}

export default function Breadcrumb(props: any) {
  function isLast(index: any) {
    return index === props.crumbs.lenth - 1;
  }
  return (
    <div className="breadCrumbs capsule">
      <Stack>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
          sx={{
            paddingTop: "1.4rem",
            paddingBottom: "1.7rem",
            fontFamily: "Poppins",
          }}
        >
          <Links
            underline="none"
            key="0"
            color="inherit"
            href="/"
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#000000",
              fontWeight: 500,
              fontSize: "0.96rem",
            }}
          >
            <Link to="/" style={{ display: "flex" }}>
              <HomeOutlinedIcon
                sx={{ mr: 0.5, fontSize: "1.3rem", mb: 0.3, color: "#808080" }}
                fontSize="inherit"
              />
              Home
            </Link>
          </Links>
          ,
          {props.crumbs.map((crumb: any, ci: any) => {
            return (
              <>
                <Links
                  underline="none"
                  key={ci}
                  color="inherit"
                  onClick={handleClick}
                  sx={
                    isLast(ci)
                      ? {
                          color: "#144580",
                          fontWeight: 600,
                          fontSize: "0.96rem",
                        }
                      : {
                          color: "#000000",
                          fontWeight: 500,
                          fontSize: "0.96rem",
                        }
                  }
                >
                  <Link to={crumb.link}>{crumb.name}</Link>
                </Links>
              </>
            );
          })}
        </Breadcrumbs>
      </Stack>
    </div>
  );
}
