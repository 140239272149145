import TopSellingCards from "../../components/UI/cards/ProductCard";
import "../../styles/CategoryPage.css";
import { useState } from "react";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useSearchParams } from "react-router-dom";
import { useGetAllProductsQuery } from "../../services/product";
import { resolveImgPath } from "../../utils/imagePathResolver";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import CardSmall from "../../components/UI/uiComponents/TestCardSmall";
import Cards from "../../components/UI/cards/HealthPackagesCards";
import { Package } from "../../models/package";
import ShimmerCards from "../../components/UI/uiComponents/LoadingShimmer/ShimmerCards";
import { useGetAllTestsQuery } from "../../services/tests";
// import usePage from "../../hooks/usePage";
import Paginate from "../../components/UI/uiComponents/Pagination";
import { useGetPackagesQuery } from "../../services/packages";
import FilterViewMore from "../../components/utils/Filters/FiltersViewMore";
import SortBy from "../../components/utils/Filters/SortBy";

export default function ViewAllPage() {
  const [searchParams] = useSearchParams();
  const resultType = searchParams.get("type");
  const category = searchParams.get("category");
  const order = searchParams.get("order");
  const min_price = searchParams.get("min_price");
  const max_price = searchParams.get("max_price");
  const page = searchParams.get("page");
  // Pagination
  // const { offset, setOffset } = usePage();
  const [resetPage, setResetPage] = useState<boolean>(false);
  // Reset
  const [reset, setReset] = useState<boolean>(false);

  // Breadcrumbs
  const crumbs = [
    // Must be in order after home
    {
      name: "Products List",
      link: "",
    },
  ];

  const getOffset = () => {
    if (page) return (parseInt(page) - 1) * 20;
    return undefined;
  };

  // API: Products
  const {
    data: products,
    isFetching: loadingProducts,
  } = useGetAllProductsQuery(
    {
      offset: getOffset(),
      order: order?.length ? order : undefined,
      min_price: min_price ?? undefined,
      max_price: max_price ?? undefined,
      is_featured: category === "is_featured" ? true : undefined,
      is_top_selling: category === "is_top_selling" ? true : undefined,
      is_most_viewed: category === "is_most_viewed" ? true : undefined,
      is_just_for_you: category === "is_just_for_you" ? true : undefined,
    },
    { skip: resultType !== "product" }
  );

  // API: Packages
  const { data: packages, isFetching: loadingPackages } = useGetPackagesQuery(
    {
      offset: getOffset(),
      order: order?.length ? order : undefined,
      min_price: min_price ?? undefined,
      max_price: max_price ?? undefined,
    },
    { skip: resultType !== "package" }
  );

  // API: Tests
  const { data: tests, isFetching: loadingTests } = useGetAllTestsQuery(
    {
      offset: getOffset(),
      order: order?.length ? order : undefined,
      min_price: min_price ?? undefined,
      max_price: max_price ?? undefined,
      is_featured: category === "is_featured" ? true : undefined,
      is_discount: category === "is_discount" ? true : undefined,
      is_popular: category === "is_popular" ? true : undefined,
    },
    { skip: resultType !== "test" }
  );

  const packageModalType = (pck: Package) => {
    switch (pck.package_category.slug) {
      case "short-procedure":
        return "shortprocedure-pkg";
      case "health-package":
        return "health-pkg";
      case "nursing-services":
        return "nursing-pkg";
      case "physiotherapy":
        return "physiotherapy-pkg";
      case "doctor-consultation":
        return "consult-doctor-pkg";
    }
  };

  // No Data UI
  let noData = false;
  if (
    !products?.results?.length &&
    !packages?.results?.length &&
    !tests?.results?.length
  ) {
    noData = true;
  }
  const renderNoData = (
    <div>
      <div
        style={{
          textAlign: "center",
          fontSize: 18,
          fontWeight: "normal",
          margin: "4rem 0",
          color: "gray",
        }}
      >
        <img
          width="150px"
          src="/assets/images/illustrations/not-found.svg"
          alt="not found"
        />
        <p>Sorry, No results found!</p>
      </div>
    </div>
  );

  const getMinPrice = () => {
    const min =
      resultType === "product"
        ? products?.min
        : resultType === "package"
        ? packages?.min
        : resultType === "test"
        ? tests?.min
        : undefined;
    return min;
  };

  const getMaxPrice = () => {
    const max =
      resultType === "product"
        ? products?.max
        : resultType === "package"
        ? packages?.max
        : resultType === "test"
        ? tests?.max
        : undefined;
    return max;
  };

  // useEffect(() => {
  //   if (resultType ?? category ?? min_price ?? max_price ?? order) {
  //     // setResetPage(true);
  //     // Sometimes due to caching, offset might not be set to 0 in the Paginate Component. So explicitly doing it.
  //     // setOffset(0);
  //     window.scrollTo(0, 0);
  //   }
  // }, [resultType, category, min_price, max_price, order]);

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <section className="filters-section capsule">
        <FilterViewMore
          setReset={setReset}
          setResetPage={setResetPage}
          minPrice={getMinPrice()}
          maxPrice={getMaxPrice()}
        />
        <div className="right">
          <div className="information">
            <SortBy
              reset={reset}
              setReset={setReset}
              setResetPage={setResetPage}
              type="viewAll"
            />
          </div>
          {!loadingProducts &&
            !loadingPackages &&
            !loadingPackages &&
            noData &&
            renderNoData}

          {/* Products */}
          {loadingProducts ? (
            <ShimmerCards maxHeight="auto" itemsToShow={8} showAllOnMobile />
          ) : (
            <>
              {/* Products */}
              {resultType === "product" && Boolean(products?.results?.length) && (
                <>
                  <div className="headertitle">
                    <span>Products</span>
                  </div>
                  <div className="products">
                    {resultType === "product" && (
                      <>
                        {products?.results?.map((product) => (
                          <TopSellingCards
                            slug={product.slug}
                            image={
                              product.images && product.images.length > 0
                                ? resolveImgPath(product.images[0].image)
                                : undefined
                            }
                            genre={product?.category?.title}
                            discount={getDiscountPercent(
                              product?.price,
                              product?.discounted_price
                            )}
                            name={product?.title}
                            actualPrice={product?.discounted_price}
                            crossedPrice={product?.price}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {/* Packages */}
          {loadingPackages ? (
            <ShimmerCards maxHeight="auto" itemsToShow={8} showAllOnMobile />
          ) : (
            <>
              {resultType === "package" && Boolean(packages?.results?.length) && (
                <>
                  <div className="headertitle">
                    <span>Packages</span>
                  </div>
                  <div className="products">
                    {resultType === "package" &&
                      packages?.results?.map((detail, idx) => {
                        // const detail = (product as unknown) as Package;
                        return (
                          <div className="search-products" key={idx}>
                            <Cards
                              id={detail?.id}
                              slug={detail?.slug}
                              image={detail?.icon}
                              header={detail?.title}
                              actualPrice={detail?.discounted_price}
                              crossedPrice={detail?.market_price}
                              tests={detail?.test}
                              type={packageModalType(detail)}
                              showFeatures={false}
                              isSearchResult={true}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}

          {/* Tests */}
          {loadingTests ? (
            <ShimmerCards maxHeight="auto" itemsToShow={8} showAllOnMobile />
          ) : (
            <>
              {/* Tests */}
              {resultType === "test" && Boolean(tests?.results?.length) && (
                <>
                  <div className="headertitle">
                    <span>Tests</span>
                  </div>
                  <div className="products">
                    {resultType === "test" &&
                      tests?.results?.map((test, idx) => {
                        return (
                          <div className="search-test-products" key={idx}>
                            <CardSmall
                              id={test?.id}
                              slug={test?.slug}
                              title={test?.title}
                              price={test?.market_price}
                              discounted_price={test?.discounted_price}
                              description={test?.description}
                              image={test?.icon}
                              key={idx}
                              isSearchResult={true}
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}

          {/* All need to be rendered separately */}
          {/* Product Pagination */}
          {resultType === "product" ? (
            <div className="width-full">
              <Paginate
                count={products?.count}
                // setOffset={setOffset}
                resetPage={resetPage}
                setResetPage={setResetPage}
              />
            </div>
          ) : null}
          {/* Package Pagination */}
          {resultType === "package" ? (
            <div className="width-full">
              <Paginate
                count={packages?.count}
                // setOffset={setOffset}
                resetPage={resetPage}
                setResetPage={setResetPage}
              />
            </div>
          ) : null}
          {/* Test Pagination */}
          {resultType === "test" ? (
            <div className="width-full">
              <Paginate
                count={tests?.count}
                // setOffset={setOffset}
                resetPage={resetPage}
                setResetPage={setResetPage}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
}
