import { Package } from "../../models/package";

export const getPackageModalType = (pck?: Package) => {
  switch (pck?.package_category?.slug) {
    case "short-procedure":
      return "shortprocedure-pkg";
    case "health-package":
      return "health-pkg";
    case "nursing-services":
      return "nursing-pkg";
    case "physiotherapy":
      return "physiotherapy-pkg";
    case "doctor-consultation":
      return "consult-doctor-pkg";
    default:
      return "health-pkg";
  }
};
