import { useState } from "react";
import "../../../styles/HealthPackages.css";
import { resolveImgPath } from "../../../utils/imagePathResolver";
import { PackageTest } from "../../../models/package";
import { shortenArray } from "../../../utils/shortenArray";
import PackageDetailModal from "../uiComponents/PackageDetailModal";
import AuthModal from "../uiComponents/AuthModals";
import BuyNowBtn from "../uiComponents/Buttons/BuyNow";
import { Feature } from "../../../models/feature";
import { getPriceStatement } from "../../../utils/priceCommas";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  id?: number | undefined;
  slug?: string | undefined;
  image: string | undefined;
  header: string | undefined;
  actualPrice: number | undefined;
  crossedPrice: number | undefined;
  tests?: PackageTest[] | undefined;
  type?:
    | "health-pkg"
    | "consult-doctor-pkg"
    | "nursing-pkg"
    | "physiotherapy-pkg"
    | "shortprocedure-pkg";
  showFeatures?: boolean;
  showFeaturesInDetail?: boolean;
  isSearchResult?: boolean;
}

export default function HealthPackagesCards({
  id = undefined,
  slug = undefined,
  image,
  header,
  actualPrice,
  crossedPrice,
  tests,
  type = "health-pkg",
  showFeatures = true,
  showFeaturesInDetail = true,
  isSearchResult = false,
}: Props) {
  // Modal States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // Auth Modal
  const [openAuthModal, setOpenAuthModal] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);
  return (
    <>
      <div
        style={{
          cursor: "pointer",
          height: `${showFeatures ? "auto" : "380px"}`,
          overflow: `${showFeatures ? "auto" : "hidden"}`,
        }}
        // className="search-result"
      >
        <div className={`table${isSearchResult ? " search-result" : ""}`}>
          <div onClick={handleOpen}>
            <div className="image-section">
              {/* <img
                src={
                  resolveImgPath(image) ??
                  "/assets/images/placeholders/doctor-placeholder.png"
                }
                alt="Packages"
                style={{ objectFit: "contain" }}
                loading="lazy"
              /> */}
              <LazyLoadImage
                src={
                  resolveImgPath(image) ??
                  "/assets/images/placeholders/doctor-placeholder.png"
                }
                alt="Packages"
                style={{ objectFit: "contain" }}
              />
            </div>
            <h2 className="package-name">{header}</h2>
            {showFeatures && (
              <ul className="features">
                {shortenArray(tests ? tests?.map((d) => d?.title) : [])?.map(
                  (t, idx) => (
                    <li
                      key={idx}
                      className={idx === 3 ? "more" : ""}
                      onClick={() => idx === 3 && handleOpen()}
                    >
                      <span className="icon check">
                        <i className="fas fa-calendar-check"></i>
                      </span>
                      <span className="list-name">{t}</span>
                    </li>
                  )
                )}
              </ul>
            )}
            <div className="price">
              {actualPrice ? (
                <h1>
                  {getPriceStatement(actualPrice)}{" "}
                  <span>{getPriceStatement(crossedPrice)}</span>
                </h1>
              ) : (
                <h1>{getPriceStatement(crossedPrice)}</h1>
              )}
            </div>
          </div>

          <div className="btn">
            <BuyNowBtn
              setOpen={setOpenAuthModal}
              id={id}
              type="package"
              name="Buy Now"
              setFeature={setFeature}
            />
            {/* <Link to="#" style={{ width: "80%" }}>
          <button>Buy Now</button>
        </Link> */}
          </div>
          <p className="view-more" onClick={handleOpen}>
            View more details
          </p>
        </div>
      </div>
      <AuthModal
        open={openAuthModal}
        setOpen={setOpenAuthModal}
        feature={feature}
      />
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        setOpenAuthModal={setOpenAuthModal}
        slug={slug}
        // type="health-pkg"
        type={type}
        hasAddToCart={false}
        hasIncludedTests={showFeaturesInDetail}
        hasSpeciality={showFeaturesInDetail}
        setFeature={setFeature}
      />
    </>
  );
}
