import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import style from "../../styles/AboutUsHero.module.css";
import { ButtonBlue } from "../UI/uiComponents/Button";

export default function AboutUsHero() {
  return (
    <div className="capsule">
      <div className={style.heroContainer}>
        <h2 className={style.heroHeader}>About Us</h2>
        <div className={style.hero}>
          <div className={style.left}>
            {/* <img className={style.image1} src="assets/images/image 257.png" /> */}
            {/* <img className={style.image2} src="assets/images/Rectangle 3.png" /> */}
            <LazyLoadImage
              className={style.image1}
              src="assets/images/mission.jpg"
            />
            <LazyLoadImage
              className={style.image2}
              src="assets/images/about.png"
            />
          </div>
          <div className={style.right}>
            <h4>Welcome to Doctors on call</h4>
            {/* <h3>Best Care for Your Good Health</h3> */}
            {/* <div className={style.ulDiv}>
              <ul>
                <li>A Passion for Healing</li>
                <li>All our best</li>
                <li>Always Caring</li>
              </ul>
              <ul>
                <li>5-Star Care</li>
                <li>Believe in Us</li>
                <li>A Legacy of Excellence</li>
              </ul>
            </div> */}
            <p>
              Doctors on Call Nepal is constantly working in making health care
              accessible, affordable & equitably available via means of
              providing home based care services and digital health care
              services: strengthening health care delivery eco system.
            </p>
            <p>
              Doctors on call (DOC) Nepal is one of the leading home healthcare
              service providers commenced with an entirely new concept in health
              sector to deliver hassle free, convenient, quality home healthcare
              facilities by experienced and qualified medical professionals
              Doctors, Physiotherapist, Dietician , Nurses, Health assistants
              etc.
            </p>
            <p>
              This is the Modern approach of care changing the healthcare
              environment. DOC is the easy way to health to your doorstep; at
              your comfort zone for you and your loved ones with just a phone
              call or intimation of message.
            </p>
            <Link to="/contact-us">
              <ButtonBlue name="CONTACT US" className={style.ContactUsButton} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
