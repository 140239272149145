import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { NurseServicesResponse } from "../../models/nurseService";
import { Package } from "../../models/package";

export const nurseServiceApi = createApi({
  reducerPath: "nurseServiceApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getNurseServices: builder.query<NurseServicesResponse, void>({
      query: () => {
        return {
          url: `/nursing-services/`,
          method: "GET",
        };
      },
    }),

    getNurseServicePkg: builder.query<Package, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/nursing-services/${slug}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetNurseServicesQuery,
  useGetNurseServicePkgQuery,
} = nurseServiceApi;
