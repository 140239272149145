interface Props {
  containerWidth?: string;
  containerHeight?: string;
  imgWidth?: string;
  msg?: string;
}

const NoData = ({
  containerWidth = "100%",
  containerHeight = "100%",
  imgWidth = "50%",
  msg = "No Data!",
}: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: containerWidth,
        height: containerHeight,
      }}
    >
      <img src="/assets/images/illustrations/no-data.svg" width={imgWidth} />
      <p style={{ color: "gray", paddingTop: 8 }}>{msg}</p>
    </div>
  );
};

export default NoData;
