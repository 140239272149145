import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import {
  BookedPackages,
  BookPackageResponse,
  Package,
  Packages,
} from "../../models/package";
import { getLocalAuthToken } from "../../storage/localStorage";

export const packagesApi = createApi({
  reducerPath: "packagesApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // Get all packages
    getPackages: builder.query<
      Packages,
      {
        offset?: number;
        order?: string;
        min_price?: string;
        max_price?: string;
      }
    >({
      query: ({ offset, order, min_price, max_price }) => {
        return {
          url: `/package/`,
          method: "GET",
          params: {
            offset,
            order,
            min_price,
            max_price,
          },
        };
      },
    }),

    // Get selected package
    getSelectedPackage: builder.query<Package, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/package/${slug}/`,
          method: "GET",
        };
      },
    }),

    // Get Booked Packages
    getBookedPackages: builder.query<BookedPackages, void | null>({
      query: () => {
        return {
          url: `/book-package/`,
          method: "GET",
        };
      },
    }),

    // Book Packages
    bookPackage: builder.mutation<
      BookPackageResponse,
      {
        user: number | undefined | null;
        package_id: number | undefined;
      }
    >({
      query: ({ package_id, user }) => {
        return {
          url: `/book-package/`,
          method: "POST",
          body: {
            user,
            package_id,
            service_date: "2022-05-04T12:12",
          },
        };
      },
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useGetSelectedPackageQuery,
  useGetBookedPackagesQuery,
  useBookPackageMutation,
} = packagesApi;
