import "../../styles/TopSelling.css";
import Cards from "../UI/cards/ProductCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { FC } from "react";
import { Product } from "../../models/product";
import { resolveImgPath } from "../../utils/imagePathResolver";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import ShimmerCards from "../UI/uiComponents/LoadingShimmer/ShimmerCards";
import { Link } from "react-router-dom";
import { Package, PackageType } from "../../models/package";
import { gotoViewMore } from "../../utils/routeNames";

interface Props {
  data?: Product[] | undefined;
  pkgData?: Package[] | undefined;
  title: String;
  isLoading?: boolean;
  type?: "product" | "package";
  pkgType?: PackageType;
  category?:
  | "is_featured"
  | "is_top_selling"
  | "is_most_viewed"
  | "is_just_for_you";
}

const TopSelling: FC<Props> = ({
  data,
  pkgData,
  title,
  isLoading = false,
  type = "product",
  pkgType,
  category,
}) => {
  return (
    <section className="top-selling">
      <div className="capsule">
        <div className="header">
          <h2>{title}</h2>
          <Link to={gotoViewMore("product", category)}>View More</Link>
        </div>
        {isLoading ? (
          <ShimmerCards />
        ) : (
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            slidesPerGroup={1}
            // loop={true}
            loopFillGroupWithBlank={true}
            navigation={true}
            cssMode={true}
            breakpoints={{
              1400: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              // 576: {
              //   slidesPerView: 3,
              //   spaceBetween: 0,
              // },
              320: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              // 200: {
              //   slidesPerView: 1,
              //   spaceBetween: 20,
              // },
            }}
            modules={[Navigation]}
            className="topSelling"
          >
            {type === "product" ? (
              <>
                {data?.map((prod, idx) => (
                  <SwiperSlide key={idx}>
                    <Cards
                      image={resolveImgPath(
                        prod?.images
                          ? prod?.images[0]?.image
                          : undefined
                      )}
                      genre={prod?.category?.title}
                      discount={getDiscountPercent(
                        prod?.price,
                        prod?.discounted_price
                      )}
                      name={prod?.title}
                      actualPrice={prod?.discounted_price}
                      crossedPrice={prod?.price}
                      slug={prod?.slug}
                    />
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {pkgData?.map((pkg, idx) => (
                  <SwiperSlide key={idx}>
                    <Cards
                      image={resolveImgPath(pkg?.icon ?? undefined)}
                      genre={pkg?.package_category?.title}
                      discount={getDiscountPercent(
                        pkg?.market_price,
                        pkg?.discounted_price
                      )}
                      name={pkg?.title}
                      actualPrice={pkg?.discounted_price}
                      crossedPrice={pkg?.market_price}
                      slug={pkg?.slug}
                      type="package"
                      pkgType={pkgType}
                    />
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default TopSelling;
