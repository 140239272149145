import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  errorMessages,
  successMessages,
} from "../../../../../utils/alertMessages";
import { RootState } from "../../../../../store";
import {
  useAddToPackageCartMutation,
  useAddToProductCartMutation,
  useAddToTestPackageCartMutation,
} from "../../../../../services/cart";
import "./index.css";
import { Feature } from "../../../../../models/feature";

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  id: number | undefined;
  type?: "product" | "package" | "test";
  name?: string;
  size?: "normal" | "small";
  setFeature?: React.Dispatch<React.SetStateAction<Feature | undefined>>;
}

const AddToCartBtn = ({
  setOpen,
  id,
  type = "product",
  name = "Add To Cart",
  size = "normal",
  setFeature,
}: Props) => {
  // To check if logged in or not.
  const authToken = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );

  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );

  // API: Add product to Cart
  const [
    addProductToCart,
    { error: prodError, isLoading: isProdLoading, isSuccess: isProdSuccess },
  ] = useAddToProductCartMutation();

  // API: Add package to Cart
  const [
    addPkgToCart,
    { error: pkgError, isLoading: isPkgLoading, isSuccess: isPkgSuccess },
  ] = useAddToPackageCartMutation();

  // API: Add test to Cart
  const [
    addTestToCart,
    { error: testError, isLoading: isTestLoading, isSuccess: isTestSuccess },
  ] = useAddToTestPackageCartMutation();

  const handleAddToCart = () => {
    if (!authToken) {
      if (setFeature) {
        setFeature("cart");
      }
      if (setOpen) setOpen(true);
    } else {
      if (type === "product") {
        addProductToCart({
          product_id: id,
          user_id: userProfileId,
        });
      } else if (type === "package") {
        addPkgToCart({
          package_id: id,
          user_id: userProfileId,
        });
      } else if (type === "test") {
        addTestToCart({
          test_id: id,
          user_id: userProfileId,
        });
      }
    }
  };

  // Side effects: Product Cart
  useEffect(() => {
    if (isProdSuccess) {
      toast.success(successMessages?.addToCart);
    } else if (prodError) {
      if ("status" in prodError) {
        const errMsg: any =
          prodError?.status === 400 || prodError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isProdSuccess, prodError]);

  // Side effects: Package Cart
  useEffect(() => {
    if (isPkgSuccess) {
      toast.success(successMessages?.addToCart);
    } else if (pkgError) {
      if ("status" in pkgError) {
        const errMsg: any =
          pkgError?.status === 400 || pkgError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isPkgSuccess, pkgError]);

  // Side effects: Test Cart
  useEffect(() => {
    if (isTestSuccess) {
      toast.success(successMessages?.addToCart);
    } else if (testError) {
      if ("status" in testError) {
        const errMsg: any =
          testError?.status === 400 || testError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isTestSuccess, testError]);

  return (
    // <ButtonWhite
    //   name={
    //     isProdLoading || isPkgLoading || isTestLoading
    //       ? "Adding..."
    //       : "Add to cart"
    //   }
    //   onClick={handleAddToCart}
    //   disabled={isProdLoading || isPkgLoading || isTestLoading}
    // />
    <button
      className={size === "small" ? "add-to-cart-small" : "add-to-cart"}
      onClick={handleAddToCart}
      disabled={isProdLoading || isPkgLoading || isTestLoading}
    >
      {isProdLoading || isPkgLoading || isTestLoading ? "Processing..." : name}
    </button>
    // <div className="buttons">
    //   <button
    //     className="add-to-cart"
    //     onClick={handleAddToCart}
    //     disabled={isProdLoading || isPkgLoading || isTestLoading}
    //   >
    //     {isProdLoading || isPkgLoading || isTestLoading
    //       ? "Adding..."
    //       : "ADD TO CART"}
    //   </button>
    // </div>
  );
};

export default AddToCartBtn;
