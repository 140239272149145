import { useState, useEffect } from "react";
import style from "../../../../../styles/SignUp.module.css";
// import Input from "./Input";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Alert from "@mui/material/Alert";
import Input from "../../Formik/Input";
import PasswordInput from "../../Formik/PasswordInput";
import { FormButtonBlue } from "../../Button";
// Icons
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockIcon from "@mui/icons-material/Lock";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
// Modals
import {
  emailValidation,
  nameValidation,
  passwordValidation,
  phoneNumberValidation,
} from "../../../../../validations";
// API services
import { useRegisterMutation } from "../../../../../services/auth";
import {
  errorMessages,
  successMessages,
} from "../../../../../utils/alertMessages";

interface Props {
  onClose: Function;
  switchModalType: Function;
}

interface Values {
  email: string;
  password: string;
  full_name: string;
  phone_number: string;
}

const validationSchema = Yup.object({
  email: emailValidation(),
  password: passwordValidation(),
  full_name: nameValidation(),
  phone_number: phoneNumberValidation(),
});

const initialValues = {
  email: "",
  password: "",
  full_name: "",
  phone_number: "",
};

export default function SignUp({ onClose, switchModalType }: Props) {
  const [alertMsg, setAlertMsg] = useState<String>("");
  const [
    register,
    {
      isLoading: registering,
      error: registerError,
      isSuccess: isRegisterSuccess,
    },
  ] = useRegisterMutation();

  const handleSignUp = async (values: Values) => {
    register({
      email: values?.email,
      password: values?.password,
      full_name: values?.full_name,
      phone_number: values?.phone_number,
    });
  };

  useEffect(() => {
    if (isRegisterSuccess) {
      setAlertMsg(successMessages?.register);
      setTimeout(() => switchModalType("Login"), 1500);
    } else if (registerError) {
      if ("data" in registerError) {
        const errMsg: any =
          "error" in registerError ? registerError.error : registerError.data;
        setAlertMsg(
          errMsg?.email || errMsg?.phone_number || errorMessages?.networkError
        );
      } else {
        setAlertMsg(errorMessages?.networkError);
      }
    }
  }, [isRegisterSuccess, registerError]);

  return (
    <>
      <div className={style.box}>
        <i
          onClick={() => onClose()}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
            zIndex: "99",
          }}
        ></i>
        <div className={style.img}>
          {/* <img src="assets/images/Rectangle 880.svg" alt="nurse" /> */}
          <button
            style={{ cursor: "pointer" }}
            onClick={() => {
              switchModalType("Login");
            }}
          >
            Login
          </button>
        </div>
        <div className={style.loginPanel}>
          <h3>Sign Up</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSignUp}
            validationSchema={validationSchema}
          >
            <Form>
              <Input
                type="text"
                name="full_name"
                label="Full Name"
                placeholder="Enter Full Name"
                labelLogo={<PersonOutlineOutlinedIcon />}
              />
              <Input
                type="text"
                name="email"
                label="Email Address"
                placeholder="Enter Email Address"
                labelLogo={<EmailOutlinedIcon />}
              />
              <Input
                type="text"
                name="phone_number"
                label="Phone Number"
                placeholder="Enter 10 digit mobile number"
                labelLogo={<LocalPhoneOutlinedIcon />}
              />
              <PasswordInput
                type="password"
                name="password"
                label="Password"
                placeholder="Enter Password"
                labelLogo={<LockIcon />}
              />
              {registerError ? (
                <Alert severity="error">{alertMsg}</Alert>
              ) : isRegisterSuccess ? (
                <Alert severity="success">{alertMsg}</Alert>
              ) : null}
              <FormButtonBlue
                name={registering ? "Signing Up..." : "Sign Up"}
                type="submit"
                disabled={registering}
              />
            </Form>
          </Formik>
          <p className={style.signUp}>
            Already have an acount? <span className={style.stick}>|</span>{" "}
            <span
              className={style.red}
              style={{ cursor: "pointer" }}
              onClick={() => {
                switchModalType("Login");
              }}
            >
              Login
            </span>
          </p>

          {/* <p className={style.or}>or</p>
          <button className={style.connects}>
            <i className="fa-brands fa-google" id="fa-google"></i>Continue with
            Google
          </button>
          <button className={style.connects}>
            {" "}
            <i className="fa-brands fa-facebook"></i> Continue with Facebook
          </button> */}
        </div>
      </div>
    </>
  );
}
