import { Organization } from "../../models/config";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { mailTo, tel } from "../../utils/mailTel";

interface Props {
  data?: Organization;
}

const TopNav = ({ data }: Props) => {
  return (
    <div className="info-header">
      <div
        className="capsule"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div className="content">
          {data?.primary_contact_number && (
            <div className="info">
              <i>
                <PhoneIcon sx={{ fontSize: "16px" }} />
              </i>
              <p>
                <a href={tel(data?.secondary_contact_number)}>
                  {data?.secondary_contact_number &&
                    `${data?.secondary_contact_number} | `}
                </a>
                Hotline : -{" "}
                <a href={tel(data?.primary_contact_number)}>
                  {data?.primary_contact_number}
                </a>
              </p>
            </div>
          )}
          {data?.primary_comany_email && (
            <div className="info top-email">
              <i>
                <EmailIcon sx={{ fontSize: "16px" }} />
              </i>
              <p>
                {data?.primary_comany_email && (
                  <a href={mailTo(data?.primary_comany_email)}>
                    {data?.primary_comany_email}
                  </a>
                )}
                {data?.secondary_company_email && (
                  <a href={mailTo(data?.secondary_company_email)}>
                    {` / ${data?.secondary_company_email}`}
                  </a>
                )}
              </p>
            </div>
          )}
        </div>
        <p className="top-description">
          Nepal's 1st ISO-Certified Home Health Care Company
        </p>
      </div>
    </div>
  );
};

export default TopNav;
