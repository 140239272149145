export const getDiscountPercent = (
  actual: number | undefined,
  discount: number | undefined
) => {
  if (actual && discount) {
    const discPercent = Math.ceil(((actual - discount) / actual) * 100);
    if (typeof discPercent === "number" && Math.sign(discPercent) === 1) {
      return discPercent;
    }
  }
  return undefined;
};
