import "../../../../styles/LoadingShimmer/index.css";

const LoadingShimmer = () => {
  return (
    <div className="shimmer-container">
      <div className="box shine"></div>
    </div>
  );
};

export default LoadingShimmer;
