import { useState, useEffect } from "react";
import { Formik, Form /* useFormik */ } from "formik";
import * as Yup from "yup";
// import style from "../../../../../styles/Login.module.css";
import style from "../../../../../styles/Otp.module.css";
import Alert from "@mui/material/Alert";
// import Input from "./Input";
import Input from "../../Formik/Input";
// Icons
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { /* ButtonBlue, */ FormButtonBlue } from "../../Button";
// Models
import { emailValidation } from "../../../../../validations";
// API services
import { useForgotPasswordMutation } from "../../../../../services/auth";
import { errorMessages } from "../../../../../utils/alertMessages";

interface Props {
  onClose: Function;
  switchModalType: Function;
  handleResetPwState: Function;
}

interface Values {
  email: string;
}

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: emailValidation(),
});

export default function ForgotPassword({
  onClose,
  switchModalType,
  handleResetPwState,
}: Props) {
  const [customErr, setCustomErr] = useState<String>("");
  const [
    forgotPassword,
    { data: forgotPwResponse, isLoading, error, isSuccess },
  ] = useForgotPasswordMutation();

  const handleForgotPw = async (values: Values) => {
    forgotPassword({ email: values?.email });
  };

  useEffect(() => {
    if (error) {
      if ("data" in error) {
        const errMsg: any = "error" in error ? error.error : error.data;
        setCustomErr(
          errMsg?.email || errMsg?.phone_number || errorMessages?.networkError
        );
      } else {
        setCustomErr(errorMessages?.networkError);
      }
    }
    if (isSuccess) {
      handleResetPwState({
        email: forgotPwResponse?.email,
        key: forgotPwResponse?.key,
      });
      switchModalType("ResetPassword");
    }
  }, [error, isSuccess]);

  return (
    <>
      <div className={style.box}>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
            zIndex: 99,
          }}
        ></i>
        <div className={style.img} />
        <div className={style.loginPanel}>
          <h3>Forgot Password</h3>
          <Alert severity="info">
            Enter your email so that we can send you an OTP Token.
          </Alert>
          <Formik
            initialValues={initialValues}
            onSubmit={handleForgotPw}
            validationSchema={validationSchema}
          >
            <Form style={{ marginTop: 14 }}>
              <Input
                type="text"
                name="email"
                label="Email Address"
                placeholder="Enter Email"
                labelLogo={<EmailOutlinedIcon />}
              />
              {error && <Alert severity="error">{customErr}</Alert>}
              <FormButtonBlue
                name={isLoading ? "Sending OTP..." : "Send OTP"}
                type="submit"
                disabled={isLoading}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
