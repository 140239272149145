import style from "../../../styles/ProductCard.module.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routeNames";
import LoadingShimmer from "../uiComponents/LoadingShimmer";
import { PackageType } from "../../../models/package";
import { getPriceStatement } from "../../../utils/priceCommas";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  image: string | undefined;
  genre: string | undefined;
  discount: number | undefined;
  name: string | undefined;
  actualPrice: number | undefined;
  crossedPrice: number | undefined;
  hasStartsFrom?: boolean;
  slug?: string | undefined;
  isLoading?: boolean;
  type?: "product" | "package";
  pkgType?: PackageType;
}

export default function TopSellingCards({
  image,
  genre,
  discount,
  name,
  actualPrice,
  crossedPrice,
  hasStartsFrom = false,
  slug = "#",
  // Loading for future use.
  isLoading = false,
  type = "product",
  pkgType,
}: Props) {
  // const [listView, setListView] = useState("list-view");
  const navigate = useNavigate();

  // THIS IS FOR LIST VIEW
  // const id = view;

  const noDiscount = actualPrice === crossedPrice;

  const getRoutes = (): string => {
    if (type === "product") {
      return `/${ROUTES?.productDetail}/${slug}`;
    } else {
      switch (pkgType) {
        case "shortProcedure":
          // return `/${ROUTES?.packageDetail}/${slug}?pkgType=shortProcedure`;
          return `/${ROUTES?.packageDetail}/${slug}/shortProcedure`;
        default:
          return `/${ROUTES?.packageDetail}/${slug}`;
      }
    }
  };

  const getRouteMetaData = () => {
    return {
      state: {
        stName: name,
        stGenre: genre,
        stActualPrice: actualPrice,
        stCrossedPrice: crossedPrice,
      },
    };
  };

  return (
    <div
      className={style.cards}
      // FOR LIST VIEW
      // id={style[id]}
      // onClick={() => navigate(`/${ROUTES?.productDetail}/${slug}`)}
      onClick={() => navigate(getRoutes(), getRouteMetaData())}
      style={isLoading ? { border: "none" } : {}}
    >
      {isLoading ? (
        <LoadingShimmer />
      ) : (
        <>
          <div className={style.imgDiv}>
            {/* <img
              src={
                image ?? "/assets/images/placeholders/no-image-placeholder.svg"
              }
              alt={name}
              loading="lazy"
            /> */}
            <LazyLoadImage
              src={
                image ?? "/assets/images/placeholders/no-image-placeholder.svg"
              }
              alt={name}
            />
          </div>
          <div className={style.details}>
            <div className={style.genreDiscount}>
              <p className={style.genre}>{genre}</p>
              {discount && <p className={style.discount}>{discount}% Off</p>}
            </div>
            <h3 className={style.title}>{name}</h3>
            {noDiscount ? (
              <h2>
                <span> {hasStartsFrom ? `Starts from ` : ""} </span>
                {getPriceStatement(crossedPrice)}
              </h2>
            ) : actualPrice ? (
              <h2>
                <span> {hasStartsFrom ? `Starts from ` : ""} </span>
                {getPriceStatement(actualPrice)}{" "}
                <span className={style.crossed}>
                  {getPriceStatement(crossedPrice)}
                </span>
              </h2>
            ) : (
              <h2>
                <span> {hasStartsFrom ? `Starts from ` : ""} </span>
                {getPriceStatement(crossedPrice)}
              </h2>
            )}
            {!noDiscount && discount && (
              <p className={style.listViewPricing}>
                <span>Rs. {getPriceStatement(actualPrice)}</span> {discount}%
                Off
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}
