import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Feature } from "../../../../../models/feature";
import {
  useAddToPackageCartMutation,
  useAddToProductCartMutation,
  useAddToTestPackageCartMutation,
  useLazyGetPackageCartQuery,
  useLazyGetProductCartQuery,
} from "../../../../../services/cart";
import { RootState } from "../../../../../store";
import { errorMessages } from "../../../../../utils/alertMessages";
import "./index.css";

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  id: number | undefined;
  type?: "product" | "package" | "test";
  name?: string;
  size?: "normal" | "small";
  quantity?: number;
  setFeature?: React.Dispatch<React.SetStateAction<Feature | undefined>>;
  disabled?: boolean;
}

const BuyNowBtn = ({
  setOpen,
  id,
  type = "product",
  name = "Buy Now",
  size = "normal",
  quantity = 1,
  setFeature,
  disabled = false,
}: Props) => {
  const navigate = useNavigate();
  // To check if logged in or not.
  const authToken = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );
  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );

  // Get Product Cart by using a Trigger Function i.e. getProductCart
  const [
    getProductCart,
    { data: prodInCart, isError: isProdInCartErr },
  ] = useLazyGetProductCartQuery();

  // API: Product Buy Now
  const [
    addProductToCart,
    {
      data: productCart,
      error: prodError,
      isLoading: isProdLoading,
      isSuccess: isProdSuccess,
    },
  ] = useAddToProductCartMutation();

  // Get Package Cart by using a Trigger Function i.e. getPackageCart
  const [
    getPackageCart,
    { data: packInCart, isError: isPackInCartErr },
  ] = useLazyGetPackageCartQuery();

  // API: Add package to Cart
  const [
    addPkgToCart,
    {
      data: packageCart,
      error: pkgError,
      isLoading: isPkgLoading,
      isSuccess: isPkgSuccess,
    },
  ] = useAddToPackageCartMutation();

  // API: Add test to Cart
  const [
    addTestToCart,
    {
      data: testCart,
      error: testError,
      isLoading: isTestLoading,
      isSuccess: isTestSuccess,
    },
  ] = useAddToTestPackageCartMutation();

  // Buy Now
  const handleBuyNow = () => {
    if (!authToken) {
      if (setFeature) {
        setFeature("buyNow");
      }
      if (setOpen) setOpen(true);
    } else {
      if (type === "product") {
        addProductToCart({
          product_id: id,
          user_id: userProfileId,
          quantity,
        });
      } else if (type === "package") {
        addPkgToCart({
          package_id: id,
          user_id: userProfileId,
        });
      } else if (type === "test") {
        addTestToCart({
          test_id: id,
          user_id: userProfileId,
        });
      }
    }
  };

  // Side effects: Product Cart
  useEffect(() => {
    if (isProdSuccess) {
      navigate("/checkout", {
        state: {
          products: [productCart?.id],
          packages: [],
        },
      });
    } else if (prodError) {
      if ("status" in prodError) {
        // const errMsg: any =
        //   prodError?.status === 400 || prodError?.status === 403
        //     ? errorMessages?.alreadyInCart
        //     : errorMessages?.networkError;

        // Navigate to "/checkout" if product is already in cart i.e. 400 or 403
        const errMsg: any =
          prodError?.status === 400 || prodError?.status === 403
            ? ""
            : errorMessages?.networkError;
        if (errMsg) toast.error(errMsg);
        else {
          getProductCart();
        }
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isProdSuccess, prodError]);

  // For Redirecting to "/checkout" even if product exists in cart.
  useEffect(() => {
    if (prodInCart) {
      const buyNowProductInCart = prodInCart?.products?.find(
        (d) => d?.product?.id === id
      );
      if (buyNowProductInCart) {
        navigate("/checkout", {
          state: {
            products: [buyNowProductInCart?.id],
            packages: [],
          },
        });
      }
    } else if (isProdInCartErr) {
      toast.error(errorMessages?.networkError);
    }
  }, [prodInCart, isProdInCartErr]);

  // Side effects: Package Cart
  useEffect(() => {
    if (isPkgSuccess) {
      navigate("/checkout", {
        state: {
          products: [],
          packages: [packageCart?.id],
        },
      });
    } else if (pkgError) {
      if ("status" in pkgError) {
        // const errMsg: any =
        //   pkgError?.status === 400 || pkgError?.status === 403
        //     ? errorMessages?.alreadyInCart
        //     : errorMessages?.networkError;
        // toast.error(errMsg);

        // Navigate to "/checkout" if package is already in cart i.e. 400 or 403
        const errMsg: any =
          pkgError?.status === 400 || pkgError?.status === 403
            ? ""
            : errorMessages?.networkError;
        if (errMsg) toast.error(errMsg);
        else {
          getPackageCart();
        }
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isPkgSuccess, pkgError]);

  // Side effects: Test Cart
  useEffect(() => {
    if (isTestSuccess) {
      navigate("/checkout", {
        state: {
          products: [],
          packages: [testCart?.id],
        },
      });
    } else if (testError) {
      if ("status" in testError) {
        // const errMsg: any =
        //   testError?.status === 400 || testError?.status === 403
        //     ? errorMessages?.alreadyInCart
        //     : errorMessages?.networkError;
        // toast.error(errMsg);

        // Navigate to "/checkout" if test is already in cart i.e. 400 or 403
        const errMsg: any =
          testError?.status === 400 || testError?.status === 403
            ? ""
            : errorMessages?.networkError;
        if (errMsg) toast.error(errMsg);
        else {
          getPackageCart();
        }
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isTestSuccess, testError]);

  // For Redirecting to "/checkout" even if package/test exists in cart.
  useEffect(() => {
    if (packInCart) {
      const buyNowPackageInCart = packInCart?.find(
        (d) => d?.package?.id === id || d?.test?.id === id
      );
      if (buyNowPackageInCart) {
        navigate("/checkout", {
          state: {
            products: [],
            packages: [buyNowPackageInCart?.id],
          },
        });
      }
    } else if (isPackInCartErr) {
      toast.error(errorMessages?.networkError);
    }
  }, [packInCart, isPackInCartErr]);

  return (
    <button
      className={size === "small" ? "buy-now-small" : "buy-now"}
      onClick={handleBuyNow}
      disabled={
        disabled ||
        !quantity ||
        quantity < 0 ||
        quantity > 99 ||
        isProdLoading ||
        isPkgLoading ||
        isTestLoading
      }
    >
      {isProdLoading || isPkgLoading || isTestLoading ? "Processing..." : name}
    </button>
  );
};

export default BuyNowBtn;
