import { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import "../../../../styles/Pagination/pagination.css";
import { useSearchParams } from "react-router-dom";
import { appendNewParam } from "../../../../utils/serializeQueryString";

interface Props {
  count?: number;
  dataPerPage?: number;
  // setOffset?: React.Dispatch<React.SetStateAction<number | undefined>>;
  resetPage?: boolean;
  setResetPage?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Paginate = ({
  count,
  dataPerPage = 20,
  // NOTE: Now, pagination hits api through client url params not through state.
  // setOffset,
  resetPage,
  setResetPage,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Get search params
  const pageUrl = searchParams.get("page");
  // Pagination
  // To change pagination dropdown when user directly goes into the view all page by using a url (Especially in a banner of special offers).
  const [page, setPage] = useState<number>(pageUrl ? parseInt(pageUrl) : 1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // if (setOffset) setOffset((value - 1) * dataPerPage);
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (resetPage) {
      setPage(1);
      // if (setOffset) setOffset(0);
      if (setResetPage) setResetPage(false);
    }
  }, [resetPage]);

  useEffect(() => {
    setSearchParams(appendNewParam(searchParams, { page }));
  }, [page]);

  return (
    <div className="capsule paginationContainer">
      <Pagination
        count={count ? Math.ceil(count / dataPerPage) : undefined}
        shape="rounded"
        onChange={handleChange}
        page={page}
      />
    </div>
  );
};

export default Paginate;
