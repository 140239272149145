import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import {
  AddDeliveryAddressResponse,
  GetDeliveryAddressResponse,
} from "../../models/deliveryAddress";
import {
  UserChangePasswordModel,
  UserProfileResponseModel,
} from "../../models/user";
import { getLocalAuthToken } from "../../storage/localStorage";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    // prepareHeaders: (headers, { getState }) => {
    //   const token = (getState() as RootState).authTokenState.authToken;
    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }
    //   return headers;
    // },
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["DeliveryAddress"],

  endpoints: (builder) => ({
    // Get User Profile
    getUserProfile: builder.query<UserProfileResponseModel, void>({
      query: () => {
        return {
          url: `/user/profile/`,
          method: "GET",
        };
      },
    }),

    // Edit User Profile
    editUserProfile: builder.mutation<
      UserProfileResponseModel,
      {
        full_name: string | undefined;
        email: string | undefined;
        phone_number: string | undefined;
        gender: string;
        dob: string;
      }
    >({
      query: ({ full_name, email, phone_number, gender, dob }) => {
        return {
          url: `/user/profile/`,
          method: "PUT",
          body: { full_name, email, phone_number, gender, dob },
        };
      },
    }),

    // Change Password
    changePassword: builder.mutation<
      UserChangePasswordModel,
      {
        old_password: string;
        new_password: string;
      }
    >({
      query: ({ old_password, new_password }) => {
        return {
          url: `/user/change_password/`,
          method: "PATCH",
          body: { old_password, new_password },
        };
      },
    }),

    // Add Delivery Address
    addDeliveryAddress: builder.mutation<
      AddDeliveryAddressResponse,
      {
        receiver_name: string;
        receiver_contact_number: string;
        address: string;
        state_id: number | string;
        city_id: number | string;
        is_only_shipping_address: boolean;
        is_only_billing_address: boolean;
        is_default?: boolean;
      }
    >({
      query: ({
        receiver_name,
        receiver_contact_number,
        address,
        state_id,
        city_id,
        is_only_shipping_address,
        is_only_billing_address,
        is_default = false,
      }) => {
        return {
          url: `/user/delivery_address/`,
          method: "POST",
          body: {
            receiver_name,
            receiver_contact_number,
            address,
            state_id,
            city_id,
            is_only_shipping_address,
            is_only_billing_address,
            is_default,
          },
        };
      },
      invalidatesTags: ["DeliveryAddress"],
    }),

    // Get All Delivery Addresses
    getDeliveryAddress: builder.query<GetDeliveryAddressResponse, void | null>({
      query: () => {
        return {
          url: `/user/delivery_address/`,
          method: "GET",
        };
      },
      providesTags: ["DeliveryAddress"],
    }),

    // Get selected Delivery Address
    getSelectedDeliveryAddress: builder.query<
      AddDeliveryAddressResponse,
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `/user/delivery_address/${id}/`,
          method: "GET",
        };
      },
      providesTags: ["DeliveryAddress"],
    }),

    // Edit Delivery Address
    editDeliveryAddress: builder.mutation<
      AddDeliveryAddressResponse,
      {
        id: string;
        receiver_name: string;
        receiver_contact_number: string;
        address: string;
        state_id: number | string;
        city_id: number | string;
        is_only_shipping_address: boolean;
        is_only_billing_address: boolean;
        // is_default: boolean;
      }
    >({
      query: ({
        id,
        receiver_name,
        receiver_contact_number,
        address,
        state_id,
        city_id,
        is_only_shipping_address,
        is_only_billing_address,
        // is_default = false,
      }) => {
        return {
          url: `/user/delivery_address/${id}/`,
          method: "PATCH",
          body: {
            receiver_name,
            receiver_contact_number,
            address,
            state_id,
            city_id,
            is_only_shipping_address,
            is_only_billing_address,
            // is_default,
          },
        };
      },
      invalidatesTags: ["DeliveryAddress"],
    }),

    // Change Default Delivery Address
    changeDefDeliveryAddress: builder.mutation<
      AddDeliveryAddressResponse,
      {
        id: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `/user/delivery_address/${id}/`,
          method: "PATCH",
          body: {
            is_default: true,
          },
        };
      },
      invalidatesTags: ["DeliveryAddress"],
    }),

    // Delete selected Delivery Address
    deleteDeliveryAddress: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/user/delivery_address/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeliveryAddress"],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  // For triggering get user profile using the provided trigger function.
  useLazyGetUserProfileQuery,
  useEditUserProfileMutation,
  useChangePasswordMutation,
  // Delivery Address
  useAddDeliveryAddressMutation,
  useGetDeliveryAddressQuery,
  useGetSelectedDeliveryAddressQuery,
  useEditDeliveryAddressMutation,
  useChangeDefDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
} = userApi;
