import Cards from "../UI/cards/ProductCard";
import { FC } from "react";
import "../../styles/TopSelling.css";
import jfuStyles from "../../styles/JustForYou.module.css";
import { Product } from "../../models/product";
import { resolveImgPath } from "../../utils/imagePathResolver";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import ShimmerCards from "../UI/uiComponents/LoadingShimmer/ShimmerCards";
import { Link } from "react-router-dom";

// interface DataProp {
//   image: string;
//   genre: string;
//   discount: number;
//   name: string;
//   actualPrice: number;
//   crossedPrice: number;
//   startPrice: string;
// }

interface Props {
  // data: DataProp[];
  data: Product[] | undefined;
  title: String;
  isLoading?: boolean;
}

const JustForYou: FC<Props> = ({ data, title, isLoading }) => {
  return (
    <section className="top-selling">
      <div className="capsule">
        <div className="header">
          <h2>{title}</h2>
          <Link to={`/all?type=product&category=is_just_for_you`}>
            View More
          </Link>
        </div>
        {isLoading ? (
          <ShimmerCards showAllOnMobile />
        ) : (
          <div className="flexContainer no-slider">
            {data?.map((prod, idx) => (
              <div key={idx} className={`${jfuStyles.cardContainer}`}>
                <Cards
                  image={resolveImgPath(
                    prod?.images ? prod?.images[0]?.image : undefined
                  )}
                  genre={prod?.category?.title}
                  discount={getDiscountPercent(
                    prod?.price,
                    prod?.discounted_price
                  )}
                  name={prod?.title}
                  actualPrice={prod?.discounted_price}
                  crossedPrice={prod?.price}
                  slug={prod?.slug}
                  key={idx}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default JustForYou;
