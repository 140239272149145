import { useState } from "react";
import { useField, FieldHookConfig } from "formik";
import style from "../../../../../styles/input.module.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface OtherProps {
  required?: Boolean;
  placeholder?: String;
  errMessage?: String;
  label: String;
  labelLogo?: any;
}

// FieldHookConfig already contains: name, validate, type, multiple and value.
const PasswordInput = ({
  required = true,
  placeholder = "",
  errMessage = "",
  label = "",
  labelLogo,
  ...props
}: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={style.inputLabel}>
      <div className={style.eyeIcon} onClick={togglePasswordVisiblity}>
        {passwordShown ? (
          <VisibilityOffOutlinedIcon />
        ) : (
          <VisibilityOutlinedIcon />
        )}
      </div>
      <label className={style.label} htmlFor={props.id}>
        {/* {labelLogo && labelLogo} {props.name} <span className={style.star}>*</span>{" "} */}
        {labelLogo && labelLogo} {label}
      </label>
      <input
        {...field}
        // type={props.type}
        type={passwordShown ? "text" : "password"}
        required={required}
        placeholder={placeholder}
        className={style.input}
      />
      {meta.touched && meta.error ? (
        <p className={style.formError}>
          {errMessage?.length ? errMessage : meta.error}
        </p>
      ) : null}
    </div>
  );
};

export default PasswordInput;
