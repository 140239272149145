import { useState } from "react";
import Typography from "@mui/material/Typography";
import { ButtonBlue } from "../../Button";
// import {
//   useKhaltiV2Mutation,
//   useLazyCheckoutQuery,
// } from "../../../../../services/checkout";
// import { toast } from "react-toastify";
// import {
//   errorMessages,
//   successMessages,
// } from "../../../../../utils/alertMessages";
// import { serializeArrOfString } from "../../../../../utils/serializeQueryString";
import CheckoutModal from "../../CheckoutModal";
import { AddressType, ConfirmedOrder } from "../../CheckoutAccordion";
// import { handlePayment } from "../../../../../utils/paymentIntegration";

interface Props {
  selectedShipAddr: number | undefined;
  selectedBillAddr: number | undefined;
  selectedCartItems: Array<string | undefined>;
  selectedPkgCartItems: Array<string | undefined>;
  totalAmount: number | undefined;
  confirmedOrder?: ConfirmedOrder[];
  confirmedDelAddr?: AddressType;
  confirmedBillAddr?: AddressType;
  confirmedPayOption?: "khalti" | "cod";
  setConfirmedPayOption?: React.Dispatch<
    React.SetStateAction<"khalti" | "cod">
  >;
}

const PaymentOptions = ({
  selectedShipAddr,
  selectedBillAddr,
  selectedCartItems,
  selectedPkgCartItems,
  totalAmount,
  confirmedOrder,
  confirmedDelAddr,
  confirmedBillAddr,
  confirmedPayOption,
  setConfirmedPayOption,
}: Props) => {
  // Checkout Confirmation Modal
  const [open, setOpen] = useState<boolean>(false);
  // Selected Option
  const [selectedOption, setSelectedOption] = useState<"khalti" | "cod">(
    "khalti"
  );

  // Cash on Delivery
  // const [trigger, { isSuccess, isLoading, isError }] = useLazyCheckoutQuery();
  // Khalti V2
  // const [
  //   checkoutKhaltiV2,
  //   {
  //     data: khaltiResponse,
  //     isLoading: loadingKhaltiV2,
  //     isSuccess: successKhaltiV2,
  //     isError: errorKhaltiV2,
  //   },
  // ] = useKhaltiV2Mutation();

  const handleSelectAddress = (e: any) => {
    setSelectedOption(e?.target?.value);
    if (setConfirmedPayOption) setConfirmedPayOption(e?.target?.value);
  };

  // const handleCheckout = (e: any) => {
  //   e?.preventDefault();
  //   if (
  //     selectedShipAddr &&
  //     selectedBillAddr &&
  //     (selectedCartItems?.length || selectedPkgCartItems?.length)
  //   ) {
  //     const queryString = `${serializeArrOfString(
  //       selectedCartItems,
  //       "cart_id"
  //     )}&${serializeArrOfString(selectedPkgCartItems, "scart_id")}`;
  //     const amtQs = `&total_amount=${totalAmount}&`;
  //     trigger({
  //       shipping_address_id: selectedShipAddr,
  //       billing_address_id: selectedBillAddr,
  //       cartIdsQueryString: queryString,
  //       totalAmountQueryString: amtQs,
  //       paymentMethod: "cod",
  //     });
  //   }
  // };

  // Khalti V1
  // const handleKhalti = () => {
  //   if (
  //     selectedShipAddr &&
  //     selectedBillAddr &&
  //     (selectedCartItems?.length || selectedPkgCartItems?.length)
  //   ) {
  //     const queryString = `${serializeArrOfString(
  //       selectedCartItems,
  //       "cart_id"
  //     )}&${serializeArrOfString(selectedPkgCartItems, "scart_id")}`;
  //     const amtQs = `&total_amount=${totalAmount}&`;

  //     handlePayment({
  //       productName: "Product_Name",
  //       productIdentity: "123",
  //       productUrl: "https://doc.asparksys.com/",
  //       triggerCheckout: trigger,
  //       shipping_address_id: selectedShipAddr,
  //       billing_address_id: selectedBillAddr,
  //       cartIdsQueryString: queryString,
  //       totalAmountQueryString: amtQs,
  //       totalAmount,
  //     });
  //   }
  // };

  // const handleKhaltiV2 = () => {
  //   if (
  //     selectedShipAddr &&
  //     selectedBillAddr &&
  //     (selectedCartItems?.length || selectedPkgCartItems?.length)
  //   ) {
  //     checkoutKhaltiV2({
  //       cart_id: selectedCartItems?.length ? selectedCartItems : undefined,
  //       scart_id: selectedPkgCartItems?.length
  //         ? selectedPkgCartItems
  //         : undefined,
  //       total_amount: totalAmount,
  //       payment_method: "khalti",
  //       shipping_address_id: selectedShipAddr,
  //       billing_address_id: selectedBillAddr,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success(successMessages?.checkout);
  //     setTimeout(() => window.location.replace("/orders"), 1500);
  //   } else if (isError) {
  //     toast.error(errorMessages?.networkError);
  //   }
  // }, [isSuccess, isError]);

  // useEffect(() => {
  //   if (errorKhaltiV2) {
  //     toast.error(errorMessages?.txnFailed);
  //   } else if (successKhaltiV2) {
  //     if (khaltiResponse?.payment_url) {
  //       window?.location?.replace(khaltiResponse?.payment_url);
  //     } else {
  //       toast.error(errorMessages?.txnFailed);
  //     }
  //   }
  // }, [errorKhaltiV2, successKhaltiV2, khaltiResponse]);

  return (
    <Typography>
      <div className="payment">
        <form style={{ minWidth: "265px" }}>
          <div className="accordionInputLabel paymentAccordion">
            <input
              type="radio"
              id="khalti"
              name="payment"
              value="khalti"
              checked={selectedOption === `khalti`}
              onChange={handleSelectAddress}
            />
            <label
              style={{ display: "flex", alignItems: "center" }}
              htmlFor="khalti"
              className="khalti"
            >
              <img
                src="/assets/images/Khalti_Logo.png"
                alt="khalti"
                style={{ marginRight: 5, width: 28 }}
              />
              <p className="labelP">Khalti</p>
            </label>
          </div>

          <div className="accordionInputLabel paymentAccordion">
            <input
              type="radio"
              id="cod"
              name="payment"
              value="cod"
              checked={selectedOption === `cod`}
              onChange={handleSelectAddress}
            />
            <label
              style={{ display: "flex", alignItems: "center" }}
              htmlFor="cod"
              className="cod"
            >
              <img
                src="/assets/images/illustrations/cash-on-delivery.webp"
                alt="cash on delivery"
                style={{ marginRight: 5, width: 28 }}
              />
              <p className="labelP">Cash on Delivery</p>
            </label>
          </div>
          {/* {selectedOption === "cod" ? (
            <ButtonBlue
              name={isLoading ? "Processing" : "Checkout"}
              onClick={handleCheckout}
              disabled={isLoading}
            />
          ) : (
            <ButtonBlue
              name={loadingKhaltiV2 ? "Processing" : "Proceed To Payment"}
              // onClick={handleKhalti}
              onClick={handleKhaltiV2}
              disabled={loadingKhaltiV2}
            />
          )} */}
          <ButtonBlue name="Checkout" onClick={() => setOpen(true)} />
        </form>
        <CheckoutModal
          open={open}
          setOpen={setOpen}
          confirmedOrder={confirmedOrder}
          confirmedDelAddr={confirmedDelAddr}
          confirmedBillAddr={confirmedBillAddr}
          confirmedPayOption={confirmedPayOption}
          totalAmount={totalAmount}
          // For API
          selectedShipAddr={selectedShipAddr}
          selectedBillAddr={selectedBillAddr}
          selectedCartItems={selectedCartItems}
          selectedPkgCartItems={selectedPkgCartItems}
        />
      </div>
    </Typography>
  );
};

export default PaymentOptions;
