import Cards from "../UI/cards/WhatWeDoCards";
import "../../styles/WhatWeDo.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Service } from "../../models/service";
import { resolveImgPath } from "../../utils/imagePathResolver";

interface Props {
  data: Service[] | undefined;
}

export default function WhatWeDo({ data }: Props) {
  return (
    <section className="what-we-do capsule">
      {data?.map((e, idx) => {
        return (
          <div key={idx} className="what-we-do-cards-collection">
            <a href={e?.launch_url ?? "#"}>
              <Cards
                img={resolveImgPath(e?.image)}
                title={e?.title}
                alt={e?.title}
                key={idx}
              />
            </a>
          </div>
        );
      })}
    </section>
  );
}
