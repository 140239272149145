import style from "../../../styles/TestCardSmall.module.css";
import { useState } from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
import "../../../styles/LabTestModal.css";
// import { ButtonBlue, ButtonWhite } from "./Button";
import { resolveImgPath } from "../../../utils/imagePathResolver";
import PackageDetailModal from "./PackageDetailModal";
import AuthModal from "./AuthModals";
import { Feature } from "../../../models/feature";
import { getPriceStatement } from "../../../utils/priceCommas";

interface Props {
  id: number | undefined;
  slug: string | undefined;
  title: string | undefined;
  price: number | undefined;
  discounted_price: number | undefined;
  description: string | undefined;
  image: string | undefined;
  type?: "health-pkg" | "test-pkg";
  isSearchResult?: boolean;
}

export default function TestCardSmall({
  id,
  slug,
  title,
  price,
  discounted_price,
  description,
  image,
  type = "test-pkg",
  isSearchResult = false,
}: Props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // Auth Modal
  const [openAuthModal, setOpenAuthModal] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);
  return (
    <>
      <div
        className={`${style.testCard} ${
          isSearchResult ? style.searchTestCard : ""
        }`}
        id="testCard"
      >
        <div className={style.paddingCommon} onClick={handleOpen}>
          <>
            {image ? (
              <img
                src={resolveImgPath(image)}
                width={25}
                height={25}
                style={{ objectFit: "contain" }}
              />
            ) : (
              <i className="fa-solid fa-flask" id={style.flask}></i>
            )}
          </>
          <h3 className={style.cardH3} title={title}>
            {title}
          </h3>
          {/* <p className={style.cardP}>E-Reports on next day</p> */}
          {/* <p
            className={style.cardP}
            dangerouslySetInnerHTML={{ __html: description ?? "" }}
          /> */}
          <br />
          {discounted_price ? (
            <h2 className={style.price}>
              {" "}
              {getPriceStatement(discounted_price)}{" "}
              <span>{getPriceStatement(price)}</span>{" "}
            </h2>
          ) : (
            <h2 className={style.price}> {getPriceStatement(price)}</h2>
          )}
          <button className={style.button}>Book Test</button>
          <AuthModal
            open={openAuthModal}
            setOpen={setOpenAuthModal}
            feature={feature}
          />
        </div>
        <PackageDetailModal
          open={open}
          setOpen={setOpen}
          setOpenAuthModal={setOpenAuthModal}
          slug={slug}
          type={type}
          hasSpeciality={false}
          hasIncludedTests={false}
          setFeature={setFeature}
        />
      </div>
    </>
  );
}
