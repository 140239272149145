import style from "../../../styles/DoctorCardNoButton.module.css";
import { resolveImgPath } from "../../../utils/imagePathResolver";
export default function DoctorCardNoButton(props: any) {
  return (
    <div className={style.doctorsDiv}>
      <div className={style.doctorImg}>
        <img
          alt="s"
          src={
            resolveImgPath(props.image) ??
            "/assets/images/placeholders/health-proff-placeholder.jpg"
          }
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className={style.information}>
        <h3>{props.name}</h3>
        <p>{props.profession}</p>
        <p>{props.experience} Years Experience</p>
        <p>{props.consults} Consults Done</p>
      </div>
    </div>
  );
}
