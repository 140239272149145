export const serializeArrOfNum = (
  arr: Array<number | undefined>,
  keyText: string
) => {
  if (arr?.length) {
    // Returns Eg: "keyText=arr[0]&keyText=arr[1]&keyText=arr[2]&", etc.
    return arr?.map((d: number | undefined) => `${keyText}=${d}`)?.join("&");
  } else return "";
};

export const serializeArrOfString = (
  arr: Array<string | undefined>,
  keyText: string
) => {
  if (arr?.length) {
    // Returns Eg: "keyText=arr[0]&keyText=arr[1]&keyText=arr[2]&", etc.
    return arr?.map((d: string | undefined) => `${keyText}=${d}`)?.join("&");
  } else return "";
};

export const appendNewParam = (
  searchParams: URLSearchParams,
  keyValues: any
) => {
  const newParam: any = {};
  searchParams.forEach((key, value) => {
    newParam[value] = key;
  });
  return { ...newParam, ...keyValues };
};
