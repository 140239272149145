export const errorMessages = {
  networkError: "Something went wrong.",
  pleaseLogin: "Please login to use this feature",
  pleaseLoginForCart: "Please login first to Add to Cart",
  pleaseLoginForWishlist: "Please login first to Add to Wishlist",
  pleaseLoginForBuyNow: "Please login first to Buy This Product",
  alreadyInCart: "Already in cart.",
  alreadyInWishlist: "Already in wishlist.",
  deleteFromWishlist: "Failed to delete from wishlist.",
  quantityCart: "Quantity must be greater than 0.",
  quantityCartMax: "Max Quantity reached.",
  noProductsInCart: "No products in cart.",
  noPackagesInCart: "No packages in cart.",
  noProductsInWishlist: "No products in wishlist.",
  noPackagesInWishlist: "No packages in wishlist.",
  noItemsInMyOrders: "No Orders Yet.",
  noBookings: "No Bookings Yet.",
  locations: "Something went wrong when loading provinces and districts.",
  deliveryAddress: "Something went wrong when loading delivery address.",
  noDeliveryAddress: "No delivery addresses. Please add one.",
  noBillingAddress: "No billing addresses. Please add one.",
  txnFailed: "Transaction Failed.",
  paymentVerifyFailure: "Payment Verification Failed.",
};

export const successMessages = {
  register: "Successfully registered. Redirecting to login...",
  resetPw: "Successfully reset password. Redirecting to login in 3 seconds...",
  updateProfile: "Successfully Updated Profile. Refreshing page...",
  changePw: "Successfully Changed Password",
  bookAppointment:
    "Successfully Booked Appointment. You might receive a call later. Redirecting...",
  addToCart: "Added to cart.",
  deleteFromCart: "Deleted from cart.",
  clearCart: "Cart cleared.",
  moveAllToCart: "Added all to cart.",
  addToWishlist: "Added to wishlist.",
  deleteFromWishlist: "Deleted from wishlist.",
  clearWishlist: "Wishlist cleared.",
  addDeliveryAddress: "Delivery Address Added.",
  deleteDeliveryAddress: "Delivery Address Deleted.",
  editDeliveryAddress: "Delivery Address Updated",
  checkout: "Successfully Ordered. Redirecting...",
  bookPackage: "Package Booked",
  bookTest: "Test Booked",
  paymentVerified: "Payment Verified.",
};

export const infoMessages = {
  resetPasswordOtpEnter:
    "Enter the OTP Token that you received in your email along with your new password.",
  selectCartItems: "Please select the items you want to check out with.",
  loadingPayment: "Processing Payment. Please Wait...",
};
