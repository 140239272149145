import { useField, FieldHookConfig } from "formik";
import { useRef } from "react";
import style from "../../../../../styles/input.module.css";

interface OtherProps {
  required?: Boolean;
  disabled?: Boolean;
  placeholder?: String;
  errMessage?: String;
  label: String;
  labelLogo?: any;
}

// FieldHookConfig already contains: name, validate, type, multiple and value.
const Input = ({
  required = true,
  disabled = false,
  placeholder = "",
  errMessage = "",
  label = "",
  labelLogo,
  ...props
}: OtherProps & FieldHookConfig<string>) => {
  // The props argument must be of type: FieldHookConfig<string>.
  // "props" will contain: name, validate, type, multiple and value.
  const [field, meta] = useField(props);

  // For Date Inputs
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={style.inputLabel}>
      <label className={style.label} htmlFor={props.id}>
        {" "}
        {/* {labelLogo && labelLogo} {props.name} <span className={style.star}>*</span>{" "} */}
        {labelLogo && labelLogo} {label}
      </label>
      <input
        {...field}
        type={props.type}
        required={required}
        placeholder={placeholder}
        className={
          disabled ? `${style.input} ${style.disabled}` : `${style.input}`
        }
        disabled={disabled}
        ref={inputRef}
        onClick={() => {
          if (inputRef?.current?.type === "date") {
            inputRef?.current?.showPicker();
          }
        }}
      />
      {meta.touched && meta.error ? (
        <p className={style.formError}>
          {errMessage?.length ? errMessage : meta.error}
        </p>
      ) : null}
    </div>
  );
};

export default Input;
