import TestPackagesCard from "../../components/UI/cards/TestPackagesCard";
import { OffersCards2 } from "../../components/UI/cards/OfferCards";
import BookAppointments from "../../components/utils/BookAppointmentsSlider";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useGetPackagesQuery } from "../../services/packages";
import HealthPackages from "../../components/utils/HealthPackages";
import { SwiperSlide } from "swiper/react";
import { useGetMarketingBannersQuery } from "../../services/home";
import Hero from "../../components/utils/Hero";

export default function HealthPackagesPage() {
  // API
  const { data, isLoading, isError } = useGetPackagesQuery({});

  // const crumbs = [
  //   // Must be in order after home
  //   {
  //     name: " Health Packages",
  //     link: "",
  //   },
  // ];
  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();

  return (
    <>
      {/* <Breadcrumb crumbs={crumbs} /> */}
      {/* <SliderHero /> */}
      <Hero
        hasContainer={true}
        data={bannerData?.health_packages_banner}
        isLoading={isBannerLoading}
      />
      {/* DATA */}
      {/* <HealthPackages
        h1="TOP BOOKED HEALTH PACKAGES"
        type="test-packages"
        view="View More"
      >
        {packages.details.map((detail) => {
          return (
            <SwiperSlide>
              {" "}
              <TestPackagesCard
                image={detail.image}
                header={detail.header}
                info1={detail.info1}
                info2={detail.info2}
                info3={detail.info3}
                info4={detail.info4}
                actualPrice={detail.actualPrice}
                crossedPrice={detail.crossedPrice}
              />{" "}
            </SwiperSlide>
          );
        })}
      </HealthPackages> */}
      <HealthPackages
        header="TOP BOOKED HEALTH PACKAGES"
        type="test-packages"
        view="View More"
        isLoading={isLoading || isError}
        data={data?.results}
      >
        {data?.results?.map((d, idx) => {
          return (
            <SwiperSlide key={idx}>
              <TestPackagesCard
                id={d?.id}
                slug={d?.slug}
                header={d?.title}
                image={d?.icon}
                // tests={d?.test}
                healthPackageTests={d?.test}
                actualPrice={d?.discounted_price}
                crossedPrice={d?.market_price}
                type="health-pkg"
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages>

      <BookAppointments>
        {bannerData?.health_packages_offer_banner.map((e, idx) => (
          <OffersCards2
            launchUrl={e?.launch_url}
            key={idx}
            heading={e?.title}
            image={e?.web_image}
          />
        ))}
      </BookAppointments>

      {/* DATA */}
      {/* <HealthPackages
        h1="TOP OFFICE PACKAGES"
        type="test-packages"
        view="View More"
      >
        {packages.details.map((detail) => {
          return (
            <SwiperSlide>
              {" "}
              <TestPackagesCard
                image={detail.image}
                header={detail.header}
                info1={detail.info1}
                info2={detail.info2}
                info3={detail.info3}
                info4={detail.info4}
                actualPrice={detail.actualPrice}
                crossedPrice={detail.crossedPrice}
              />{" "}
            </SwiperSlide>
          );
        })}
      </HealthPackages> */}
    </>
  );
}
