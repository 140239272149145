import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ButtonBlue, ButtonWhite } from "../../uiComponents/Button";
import "../../../../styles/LabTestModal.css";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  yesAction: Function;
}

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 710,
  bgcolor: "#ffffff",
  border: "none",
  boxShadow: 0,
  p: 4,
  outline: "none",
  borderRadius: "7px",
  padding: "0px",
};

const ConfirmationModal = ({ open, setOpen, loading, yesAction }: Props) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle} className="boxModal">
        <div className="modal no-overflow">
          <div className="modalHeader">
            {/* <i className="fa-regular fa-circle-question"></i> */}
            <HelpOutlineOutlinedIcon />
            <h4>Are you sure?</h4>
          </div>
          <div className="footData footData-small">
            <div className="buttonGroup">
              <ButtonWhite name="Cancel" onClick={() => handleClose()} />
              <ButtonBlue
                name={loading ? "Processing" : "Yes"}
                // onClick={() => handleDeleteFromCart(p?.id)}
                onClick={yesAction}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
