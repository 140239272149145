import { SwiperSlide } from "swiper/react";
import HealthPackages from "../../components/utils/HealthPackages";
import TitlePicture from "../../components/utils/TitlePicture";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
// import DoctorCardNoButton from "../../components/UI/uiComponents/DoctorCardNoButton";
// import BookAppointmentsSlider from "../../components/utils/BookAppointmentsSlider";
import Cards from "../../components/UI/cards/HealthPackagesCards";
// import style from "../../styles/HealthArticles.module.css";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import Hero from "../../components/utils/Hero";
import { useGetNurseServicesQuery } from "../../services/nurse-service";
import { useGetMarketingBannersQuery } from "../../services/home";
import DetailsAccordion from "../../components/base/DetailsAccordion";
// import Hero from "../../components/utils/Hero";

export default function NurseServicePage() {
  // API
  const {
    data: nurseServicesData,
    isLoading: isServicesLoading,
    isError: isServicesError,
  } = useGetNurseServicesQuery();
  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();

  return (
    <>
      <TitlePicture header="Nurse Service" />

      <Hero
        hasContainer={true}
        data={bannerData?.nursing_services_banner}
        isLoading={isBannerLoading}
      />

      <DetailsAccordion>
        <HorizontalTab
          label1="Description"
          label2="Included"
          label3="Excluded"
          label1data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.nursing_services[0]?.description ?? "",
              }}
            />
          }
          label2data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.nursing_services[0]?.included ?? "",
              }}
            />
          }
          label3data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.nursing_services[0]?.excluded ?? "",
              }}
            />
          }
        />
      </DetailsAccordion>

      {/* Certified Nurses Removed  */}
      {/* <BookAppointmentsSlider h1="Certified Nurses on DOC Nepal">
        {doctors.details.map((detail) => {
          return (
            <SwiperSlide className="bookSlide">
              {" "}
              <DoctorCardNoButton
                image={detail.image}
                name={detail.name}
                profession={detail.profession}
                experience={detail.experience}
                consults={detail.consults}
              />{" "}
            </SwiperSlide>
          );
        })}
      </BookAppointmentsSlider> */}
      <HealthPackages
        header="Nursing Care Services"
        type="health-packages"
        // view="View More"
        isLoading={isServicesLoading || isServicesError}
        data={nurseServicesData?.results}
      >
        {nurseServicesData?.results?.map((detail, idx) => {
          return (
            <SwiperSlide key={idx}>
              <Cards
                id={detail?.id}
                slug={detail?.slug}
                image={detail?.icon}
                header={detail?.title}
                actualPrice={detail?.discounted_price}
                crossedPrice={detail?.market_price}
                tests={detail?.test}
                type="nursing-pkg"
                showFeatures={false}
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages>
    </>
  );
}
