import { useNavigate } from "react-router-dom";
import useUiAmt from "../../../../../../hooks/useUiAmt";
import { ProductAddToCartResponse } from "../../../../../../models/product";
import { getDiscountPercent } from "../../../../../../utils/discountPercentCalc";
import { resolveImgPath } from "../../../../../../utils/imagePathResolver";
import { getPriceStatement } from "../../../../../../utils/priceCommas";
import CartQty from "../../../CartQty";

interface Props {
  p?: ProductAddToCartResponse;
  setDeleteId: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleOpen: () => void;
  setQtyErr: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductsInCheckout = ({
  p,
  setDeleteId,
  handleOpen,
  setQtyErr,
}: Props) => {
  const navigate = useNavigate();
  // For showing instantaneous update in price
  const { uiAmt, setUiAmt } = useUiAmt({
    value: p?.product?.discounted_price ?? p?.product?.price,
  });

  // Route
  const gotoDetail = (slug: string | undefined) => {
    if (slug) navigate(`/product-detail/${slug}`);
    else navigate("#");
  };
  return (
    <div className="productRow">
      <div className="orderedProductCross">
        <i
          className="fa-solid fa-xmark"
          onClick={() => {
            setDeleteId(p?.id);
            handleOpen();
          }}
        ></i>
      </div>
      <div className="orderedProductDetails">
        <img
          alt={p?.product?.title}
          src={
            p?.product?.images?.length
              ? resolveImgPath(p?.product?.images[0]?.image)
              : "/assets/images/placeholders/no-image-placeholder.svg"
          }
          onClick={() => gotoDetail(p?.product?.slug)}
        />
        <div className="nameQuantity">
          <p
            className="productName"
            onClick={() => gotoDetail(p?.product?.slug)}
          >
            {p?.product?.title}
          </p>

          <div className="quantityWrapper">
            <CartQty
              product_id={p?.id}
              qty={p?.quantity}
              setQtyErr={setQtyErr}
              basePrice={p?.product?.discounted_price ?? p?.product?.price}
              setUiAmt={setUiAmt}
            />
          </div>
        </div>
      </div>

      <div className="priceAndDiscount">
        {p?.product?.discounted_price ? (
          <h3>
            {getPriceStatement(p?.product?.discounted_price)}
            {p?.product?.discounted_price !== p?.product?.price ? (
              <span style={{ display: "block" }}>
                {getPriceStatement(p?.product?.price)}
              </span>
            ) : null}
          </h3>
        ) : (
          <h3>{getPriceStatement(p?.product?.price)}</h3>
        )}
        {getDiscountPercent(p?.product?.price, p?.product?.discounted_price) ? (
          <p>
            {getDiscountPercent(
              p?.product?.price,
              p?.product?.discounted_price
            )}
            % OFF
          </p>
        ) : null}
      </div>

      <div className="totalPrice max-width-total-price">
        <div className="totalPrice-wrap">
          <div className="totalPrice-wrap-total">Total:</div>
          <div>
            {/* Asynchronous Price */}
            {/* <h3>{getPriceStatement(p?.total_price)}</h3> */}
            {/* Instantaneous Price */}
            <h3>{getPriceStatement(uiAmt)}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsInCheckout;
