import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { ButtonBlue } from "../../Button";
import { toast } from "react-toastify";
import {
  useDeleteFromPackageCartMutation,
  useDeleteFromProductCartMutation,
  useGetSpecificPackagesInCartQuery,
  useGetSpecificProductsInCartQuery,
} from "../../../../../services/cart";
import { resolveImgPath } from "../../../../../utils/imagePathResolver";
import { getDiscountPercent } from "../../../../../utils/discountPercentCalc";
import { useLocation } from "react-router-dom";
import {
  errorMessages,
  successMessages,
} from "../../../../../utils/alertMessages";
import ConfirmationModal from "../../ConfirmationModal";
import { serializeArrOfString } from "../../../../../utils/serializeQueryString";
import { getPriceStatement } from "../../../../../utils/priceCommas";
import NoData from "../../../../utils/NoData";
import ProductsInCheckout from "./Products";
import Spinner from "../../../../utils/Spinner";
import useServiceDetail from "../../../../../hooks/useServiceDetail";
import PackageDetailModal from "../../PackageDetailModal";
import { getPackageModalType } from "../../../../../utils/getServiceType";
import { ConfirmedOrder } from "../../CheckoutAccordion";

interface Props {
  setExpanded: React.Dispatch<
    React.SetStateAction<"step1" | "step2" | "step3" | "step4">
  >;
  // setStep4: React.Dispatch<React.SetStateAction<boolean>>;
  setNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCartItems: React.Dispatch<
    React.SetStateAction<Array<string | undefined>>
  >;
  setSelectedPkgCartItems: React.Dispatch<
    React.SetStateAction<Array<string | undefined>>
  >;
  // For Final Checkout Confirmation Modal
  setConfirmedOrder?: React.Dispatch<React.SetStateAction<ConfirmedOrder[]>>;
}

interface LocationProps {
  pathname: string;
  // state: Array<string | undefined>;
  state: {
    products: Array<string | undefined>;
    packages: Array<string | undefined>;
  };
}

const OrderOverview = ({
  setExpanded,
  // setStep4,
  setNextStep,
  setTotalItems,
  setTotalPrice,
  setSelectedCartItems,
  setSelectedPkgCartItems,
  setConfirmedOrder,
}: Props) => {
  const location = useLocation();
  const { state } = location as LocationProps;
  // Package Detail Modal
  const {
    open: openPkgDetail,
    setOpen: setOpenPkgDetail,
    type,
    slug,
    handleOpen: handleOpenPkgDetail,
  } = useServiceDetail();
  // For Products
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  // For Packages
  const [openPkgModal, setOpenPkgModal] = useState(false);
  const handleOpenPkgModal = () => setOpenPkgModal(true);
  const handleClosePkgModal = () => setOpenPkgModal(false);
  const [pkgDeleteId, setPkgDeleteId] = useState<number | undefined>(undefined);
  // Quantity Control
  const [qtyErr, setQtyErr] = useState<boolean>(false);

  // Products API
  const {
    data: productsData,
    isLoading: loadingProducts,
  } = useGetSpecificProductsInCartQuery(
    { cartIdsQueryString: serializeArrOfString(state?.products, "cart_id") },
    {
      skip: !state?.products?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  // Packages API
  const {
    data: packagesData,
    isLoading: loadingPackages,
  } = useGetSpecificPackagesInCartQuery(
    { cartIdsQueryString: serializeArrOfString(state?.packages, "scart_id") },
    {
      skip: !state?.packages?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  // Product Delete API
  const [
    deleteFromCart,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteFromProductCartMutation();

  const handleDeleteFromCart = (product_id: number | undefined) => {
    deleteFromCart({ product_id });
  };

  // Package Delete API
  const [
    pkgDeleteFromCart,
    {
      isLoading: pkgDeleting,
      isSuccess: isPkgDeleteSuccess,
      isError: isPkgDeleteError,
    },
  ] = useDeleteFromPackageCartMutation();

  const handlePkgDeleteFromCart = (package_id: number | undefined) => {
    pkgDeleteFromCart({ package_id });
  };

  // Product Cart Delete
  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteFromCart);
      handleClose();
    }
  }, [isDeleteError, isDeleteSuccess]);

  // Package Cart Delete
  useEffect(() => {
    if (isPkgDeleteError) toast.error(errorMessages?.networkError);
    else if (isPkgDeleteSuccess) {
      toast.success(successMessages?.deleteFromCart);
      handleClosePkgModal();
    }
  }, [isPkgDeleteError, isPkgDeleteSuccess]);

  useEffect(() => {
    let totalProducts = 0;
    let totalPackages = 0;
    let totalProductsPrice = 0;
    let totalPackagesPrice = 0;
    if (productsData) {
      // Total Quantity
      totalProducts = productsData?.products?.length ?? 0;
      // Total Price of Products
      totalProductsPrice =
        productsData?.products?.reduce((total, obj) => {
          return total + (obj?.total_price ? obj?.total_price : 0);
        }, 0) ?? 0;
      // For Checkout Confirmation Order
      if (setConfirmedOrder && productsData?.products?.length) {
        setConfirmedOrder(
          productsData?.products?.map((d) => ({
            name: d?.product?.title,
            img: d?.product?.images?.length ? d?.product?.images[0]?.image : "",
            qty: d?.quantity,
            price: d?.product?.discounted_price ?? d?.product?.price,
            total: d?.total_price,
            orderType: "product",
          }))
        );
      }
    }
    if (packagesData) {
      totalPackages = packagesData?.length ?? 0;
      // Total Price of Packages
      totalPackagesPrice =
        packagesData?.reduce((total, obj) => {
          return total + (obj?.total_price ? obj?.total_price : 0);
        }, 0) ?? 0;
      // For Checkout Confirmation Order
      if (setConfirmedOrder && packagesData?.length) {
        setConfirmedOrder((prevState) => [
          ...prevState,
          ...packagesData?.map((d) => ({
            name: d?.package?.title ?? d?.test?.title,
            img: d?.package?.icon ?? d?.test?.icon ?? "",
            qty: d?.quantity,
            price: d?.package
              ? d?.package?.discounted_price || d?.package?.market_price // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              : d?.test
              ? d?.test?.discounted_price || d?.test?.market_price // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
              : 0,
            total: d?.total_price,
            // orderType: "service",
          })),
        ]);
      }
    }
    setTotalItems(totalProducts + totalPackages);
    setTotalPrice(totalProductsPrice + totalPackagesPrice);
  }, [productsData, packagesData]);

  useEffect(() => {
    if (state?.products?.length) setSelectedCartItems(state?.products);
    if (state?.packages?.length) setSelectedPkgCartItems(state?.packages);
  }, [state]);

  return (
    <Typography>
      <div className="productsServices">
        {loadingProducts || loadingPackages ? (
          <Spinner />
        ) : (
          <>
            {!productsData?.products?.length && !packagesData?.length ? (
              <NoData
                containerHeight="50%"
                imgWidth="25%"
                msg={errorMessages?.noProductsInCart}
              />
            ) : (
              <>
                {productsData?.products?.length ? (
                  <>
                    <div className="titleDiv">
                      <p className="type">Products</p>
                      <p className="total">Total</p>
                    </div>
                    <>
                      {productsData?.products?.map((p, idx) => (
                        <ProductsInCheckout
                          p={p}
                          setDeleteId={setDeleteId}
                          handleOpen={handleOpen}
                          setQtyErr={setQtyErr}
                          key={idx}
                        />
                      ))}
                    </>
                  </>
                ) : null}

                {/* Services Section */}
                {packagesData?.length ? (
                  <>
                    <h3 className="services"> Services</h3>
                    <div className="">
                      {/* Static Data  */}
                      <>
                        {packagesData?.map((pkg, idx) => (
                          <div className="productRow" key={idx}>
                            <div className="orderedProductCross">
                              <i
                                className="fa-solid fa-xmark"
                                onClick={() => {
                                  // setDeleteId(p?.id);
                                  // handleOpen();
                                  setPkgDeleteId(pkg?.id);
                                  handleOpenPkgModal();
                                }}
                              ></i>
                            </div>
                            <div className="orderedProductDetails">
                              <img
                                src={
                                  pkg?.package?.icon
                                    ? resolveImgPath(pkg?.package?.icon)
                                    : pkg?.test?.icon
                                    ? resolveImgPath(pkg?.test?.icon)
                                    : "assets/images/Vector (1).svg"
                                }
                                alt={pkg?.package?.title}
                                onClick={() => {
                                  if (handleOpenPkgDetail) {
                                    if (pkg?.package) {
                                      handleOpenPkgDetail(
                                        getPackageModalType(pkg?.package),
                                        pkg?.package?.slug
                                      );
                                    } else {
                                      handleOpenPkgDetail(
                                        "test-pkg",
                                        pkg?.test?.slug
                                      );
                                    }
                                  }
                                }}
                              />
                              <div className="nameQuantity">
                                <p
                                  className="productName"
                                  onClick={() => {
                                    if (handleOpenPkgDetail) {
                                      if (pkg?.package) {
                                        handleOpenPkgDetail(
                                          getPackageModalType(pkg?.package),
                                          pkg?.package?.slug
                                        );
                                      } else {
                                        handleOpenPkgDetail(
                                          "test-pkg",
                                          pkg?.test?.slug
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {pkg?.package?.title ?? pkg?.test?.title}
                                </p>

                                {/* <div className="quantityWrapper">
                                  <CartQty
                                    product_id={p?.id}
                                    qty={p?.quantity}
                                    setQtyErr={setQtyErr}
                                  />
                                </div> */}
                              </div>
                            </div>

                            <div className="priceAndDiscount">
                              {pkg?.package ? (
                                pkg?.package?.discounted_price ? (
                                  <h3>
                                    {getPriceStatement(
                                      pkg?.package?.discounted_price
                                    )}
                                    {pkg?.package?.discounted_price !==
                                    pkg?.package?.market_price ? (
                                      <span style={{ display: "block" }}>
                                        {getPriceStatement(
                                          pkg?.package?.market_price
                                        )}
                                      </span>
                                    ) : null}
                                  </h3>
                                ) : (
                                  <h3>
                                    {getPriceStatement(
                                      pkg?.package?.market_price
                                    )}
                                  </h3>
                                )
                              ) : pkg?.test ? (
                                pkg?.test?.discounted_price ? (
                                  <h3>
                                    {getPriceStatement(
                                      pkg?.test?.discounted_price
                                    )}
                                    {pkg?.test?.discounted_price !==
                                    pkg?.test?.market_price ? (
                                      <span style={{ display: "block" }}>
                                        {getPriceStatement(
                                          pkg?.test?.market_price
                                        )}
                                      </span>
                                    ) : null}
                                  </h3>
                                ) : (
                                  <h3>
                                    {getPriceStatement(pkg?.test?.market_price)}
                                  </h3>
                                )
                              ) : null}

                              {pkg?.package ? (
                                getDiscountPercent(
                                  pkg?.package?.market_price,
                                  pkg?.package?.discounted_price
                                ) ? (
                                  <p>
                                    {getDiscountPercent(
                                      pkg?.package?.market_price,
                                      pkg?.package?.discounted_price
                                    )}
                                    % OFF
                                  </p>
                                ) : null
                              ) : pkg?.test ? (
                                getDiscountPercent(
                                  pkg?.test?.market_price,
                                  pkg?.test?.discounted_price
                                ) ? (
                                  <p>
                                    {getDiscountPercent(
                                      pkg?.test?.market_price,
                                      pkg?.test?.discounted_price
                                    )}
                                    % OFF
                                  </p>
                                ) : null
                              ) : null}
                            </div>

                            <div className="totalPrice max-width-total-price">
                              <div className="totalPrice-wrap">
                                <div className="totalPrice-wrap-total">
                                  Total:
                                </div>
                                <div>
                                  <h3>{getPriceStatement(pkg?.total_price)}</h3>
                                </div>
                              </div>
                              {/* <h3>{getPriceStatement(p?.total_price)}</h3> */}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        )}

        {productsData?.products?.length ?? packagesData?.length ? (
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <ButtonBlue
              name="Continue"
              // onClick={() => setStep4(true)}
              onClick={() => {
                // setExpanded("step4");
                setExpanded("step2");
                // setStep4(true);
                setNextStep(true);
              }}
              disabled={
                qtyErr ||
                (!productsData?.products?.length && !packagesData?.length)
              }
            />
          </div>
        ) : null}

        {/* Modal for product singular delete */}
        <ConfirmationModal
          open={open}
          setOpen={setOpen}
          loading={deleting}
          yesAction={() => handleDeleteFromCart(deleteId)}
        />

        {/* Modal for package singular delete */}
        <ConfirmationModal
          open={openPkgModal}
          setOpen={setOpenPkgModal}
          loading={pkgDeleting}
          yesAction={() => handlePkgDeleteFromCart(pkgDeleteId)}
        />

        <PackageDetailModal
          open={openPkgDetail}
          setOpen={setOpenPkgDetail}
          slug={slug}
          type={type}
          hasAddToCart={false}
          hasBookNow={false}
          hasSpeciality={type === "health-pkg"}
          hasIncludedTests={type === "health-pkg"}
        />
      </div>
    </Typography>
  );
};

export default OrderOverview;
