import CardSmall from "../../components/UI/uiComponents/TestCardSmall";
import TestCardSlider from "../../components/utils/TestCardSlider";
import { SwiperSlide } from "swiper/react";
// import HealthPackages from "../../components/utils/HealthPackages";
// import TestPackagesCard from "../../components/UI/cards/TestPackagesCard";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useGetTestsQuery } from "../../services/tests";
import { useGetMarketingBannersQuery } from "../../services/home";
import style from "../../styles/TestCardSmall.module.css";
import Hero from "../../components/utils/Hero";
import { Link } from "react-router-dom";
import { gotoViewMore } from "../../utils/routeNames";
import ShimmerCards from "../../components/UI/uiComponents/LoadingShimmer/ShimmerCards";

export default function LabTest() {
  const { data, isError, isLoading } = useGetTestsQuery();
  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();

  return (
    <>
      {/* <Breadcrumb crumbs={crumbs} /> */}
      {/* <SliderHero bannerData={bannerData} /> */}
      <Hero
        hasContainer={true}
        data={bannerData?.lab_test_banner}
        isLoading={isBannerLoading}
      />
      <br />
      <TestCardSlider
        type="is_featured"
        header="Featured Tests"
        isLoading={isLoading || isError}
        data={data?.featured_test}
      >
        {data?.featured_test?.map((test, idx) => {
          return (
            <SwiperSlide>
              <CardSmall
                id={test?.id}
                slug={test?.slug}
                title={test?.title}
                price={test?.market_price}
                discounted_price={test?.discounted_price}
                description={test?.description}
                image={test?.icon}
                key={idx}
              />
            </SwiperSlide>
          );
        })}
      </TestCardSlider>
      {/* <HealthPackages
        header="Health Screening Package"
        type="test-packages"
        view="View More"
        isLoading={isLoading || isError}
        data={data?.package_test}
      >
        {data?.package_test?.map((d, idx) => {
          return (
            <SwiperSlide key={idx}>
              <TestPackagesCard
                id={d?.id}
                slug={d?.slug}
                header={d?.title}
                image={d?.icon}
                tests={d?.included_tests}
                actualPrice={d?.discounted_price}
                crossedPrice={d?.market_price}
                type="test-pkg"
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages> */}

      <TestCardSlider
        type="is_discount"
        header="Discount On Lab Test"
        isLoading={isLoading || isError}
        data={data?.discount_test}
      >
        {data?.discount_test?.map((test, idx) => {
          return (
            <SwiperSlide>
              {" "}
              <CardSmall
                id={test?.id}
                slug={test?.slug}
                title={test?.title}
                price={test?.market_price}
                discounted_price={test?.discounted_price}
                description={test?.description}
                image={test?.icon}
                key={idx}
              />{" "}
            </SwiperSlide>
          );
        })}
      </TestCardSlider>
      {/* 
      <TestCardSlider
        header="Health Test At Home"
        isLoading={isLoading || isError}
        data={data?.popular_test}
      >
        {data?.popular_test?.map((test, idx) => {
          return (
            <SwiperSlide>
              <CardSmall
                id={test?.id}
                slug={test?.slug}
                title={test?.title}
                price={test?.market_price}
                discounted_price={test?.discounted_price}
                description={test?.description}
                image={test?.icon}
                key={idx}
              />
            </SwiperSlide>
          );
        })}
      </TestCardSlider> */}
      <br />
      <div className="capsule">
        <div className={style.healthTest}>
          <div className={`${style.header}`}>
            <h1>Health Tests at Home</h1>
            <Link to={gotoViewMore("test", "is_popular")} className="view-all">
              View More
            </Link>
          </div>
          {isLoading ? (
            <ShimmerCards itemsToShowOnMobile={4} showAllOnMobile />
          ) : (
            <div className={`${style.healthTestContainer} capsule`}>
              {data?.popular_test?.map((test, idx) => {
                return (
                  <div>
                    <CardSmall
                      id={test?.id}
                      slug={test?.slug}
                      title={test?.title}
                      price={test?.market_price}
                      discounted_price={test?.discounted_price}
                      description={test?.description}
                      image={test?.icon}
                      key={idx}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <br />
    </>
  );
}
