import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// Global States
import authTokenReducer from "../features/auth/authTokenSlice";
import userProfileReducer from "../features/user/userProfileSlice";
import cartReducer from "../features/cart/cartSlice";
// Auth and User API
import { authApi } from "../services/auth";
import { userApi } from "../services/user";
// Other APIs
import { homePageApi } from "../services/home";
import { productApi } from "../services/product";
import { specialityApi } from "../services/speciality";
import { testsApi } from "../services/tests";
import { appointmentApi } from "../services/appointment";
import { packagesApi } from "../services/packages";
import { cartApi } from "../services/cart";
import { wishlistApi } from "../services/wishlist";
import { configApi } from "../services/config";
import { checkoutApi } from "../services/checkout";
import { orderItemApi } from "../services/order-item";
import { consultDoctorApi } from "../services/consult-doctor";
import { nurseServiceApi } from "../services/nurse-service";
import { categoryApi } from "../services/category";
import { faqApi } from "../services/faq";
import { contactUsApi } from "../services/contact-us";

export const store = configureStore({
  reducer: {
    // Global States
    authTokenState: authTokenReducer,
    userProfileState: userProfileReducer,
    cartState: cartReducer,
    // API Services
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [homePageApi.reducerPath]: homePageApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [specialityApi.reducerPath]: specialityApi.reducer,
    [testsApi.reducerPath]: testsApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [packagesApi.reducerPath]: packagesApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [orderItemApi.reducerPath]: orderItemApi.reducer,
    [consultDoctorApi.reducerPath]: consultDoctorApi.reducer,
    [nurseServiceApi.reducerPath]: nurseServiceApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [contactUsApi.reducerPath]: contactUsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      homePageApi.middleware,
      productApi.middleware,
      specialityApi.middleware,
      testsApi.middleware,
      appointmentApi.middleware,
      packagesApi.middleware,
      cartApi.middleware,
      wishlistApi.middleware,
      configApi.middleware,
      checkoutApi.middleware,
      categoryApi.middleware,
      orderItemApi.middleware,
      consultDoctorApi.middleware,
      nurseServiceApi.middleware,
      faqApi.middleware,
      contactUsApi.middleware,
    ]),
});

setupListeners(store.dispatch);

// Boilerplate for RTK for Typescript.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
