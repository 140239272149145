import { useState, useEffect } from "react";
import TopSelling from "../../components/layouts/TopSelling";
import "../../styles/DetailPage.css";
import style from "../../styles/input.module.css";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
import ProductDetailSlider from "../../components/utils/ProductDetailSlider";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import { useGetProductDetailsQuery } from "../../services/wishlist";
import { useAddToProductCartMutation } from "../../services/cart";
import { getPriceStatement } from "../../utils/priceCommas";
import { getDiscountPercent } from "../../utils/discountPercentCalc";
import LoadingShimmer from "../../components/UI/uiComponents/LoadingShimmer";
import AuthModal from "../../components/UI/uiComponents/AuthModals";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setProductCart } from "../../features/cart/cartSlice";
// Toast.
import { toast } from "react-toastify";
import { errorMessages, successMessages } from "../../utils/alertMessages";
import { Alert } from "@mui/material";
import WishProduct from "../../components/UI/uiComponents/WishProduct";
import BuyNowBtn from "../../components/UI/uiComponents/Buttons/BuyNow";
import { Feature } from "../../models/feature";
import { ProductMetaData } from "../../models/metaData";

interface CrumbsModel {
  name: string | undefined;
  link: string | undefined;
}

export default function DetailPage() {
  const { slug } = useParams();
  const { pathname, state } = useLocation();
  const { stName, stGenre, stActualPrice, stCrossedPrice } =
    (state as ProductMetaData) || {};

  // To check if logged in or not.
  const authToken = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );
  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );
  // Dispatch add to cart actions.
  const dispatch = useDispatch();
  // Quantity
  const [count, setCount] = useState<number>(1);
  // For Auth Modals
  const [open, setOpen] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);

  // API: Product Details
  const { data, isError, isLoading } = useGetProductDetailsQuery(
    { slug },
    { refetchOnMountOrArgChange: true }
  );

  // API: Cart
  const [
    addToCart,
    { error: addError, isLoading: adding, isSuccess: isAddSuccess },
  ] = useAddToProductCartMutation();

  // Breadcrumbs
  const [crumbs, setCrumbs] = useState<CrumbsModel[]>([]);

  useEffect(() => {
    if (data) {
      setCrumbs([
        {
          name: data?.product?.title,
          link: "",
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (isAddSuccess) {
      toast.success(successMessages?.addToCart);
      // Update Global State
      dispatch(
        setProductCart({
          id: data?.product?.id,
          title: data?.product?.title,
          price: data?.product?.discounted_price ?? data?.product?.price,
          quantity: count,
          image: data?.product?.images
            ? data?.product?.images[0]?.image
            : undefined,
        })
      );
    } else if (addError) {
      if ("status" in addError) {
        // For some reason, TS doesn't take addError.data.Error but, takes addError.data.error like in login api.
        const errMsg: any =
          addError?.status === 400
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isAddSuccess, addError]);

  // Reset Quantity Count after route changes
  useEffect(() => {
    if (pathname) setCount(1);
  }, [pathname]);

  // Add to cart
  const handleAddToCart = () => {
    if (!authToken) {
      setFeature("cart");
      setOpen(true);
    } else {
      if (count) {
        addToCart({
          product_id: data?.product?.id,
          user_id: userProfileId,
          quantity: count,
        });
      }
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <div className="main-section capsule">
        <div className="picture">
          {isLoading || isError ? (
            <LoadingShimmer />
          ) : (
            <ProductDetailSlider productImages={data?.product?.images} />
          )}
        </div>
        <div className="details">
          <p className="category">
            {data?.product?.category?.title?.toUpperCase() ??
              stGenre?.toUpperCase()}
          </p>
          <h2 className="name">{data?.product?.title ?? stName}</h2>
          <h2 className="price">
            {data?.product?.discounted_price ?? data?.product?.price
              ? getPriceStatement(
                  data?.product?.discounted_price ?? data?.product?.price
                )
              : getPriceStatement(stActualPrice ?? stCrossedPrice)}{" "}
            /-
          </h2>
          {data?.product?.discounted_price ? (
            <h3 className="offer">
              {data?.product?.price !== data?.product?.discounted_price && (
                <span>{getPriceStatement(data?.product?.price)}</span>
              )}
              {getDiscountPercent(
                data?.product?.price,
                data?.product?.discounted_price
              )
                ? `${getDiscountPercent(
                    data?.product?.price,
                    data?.product?.discounted_price
                  )}% OFF`
                : null}
            </h3>
          ) : stActualPrice ? (
            <h3 className="offer">
              {stCrossedPrice !== stActualPrice && (
                <span>{getPriceStatement(stCrossedPrice)}</span>
              )}
              {getDiscountPercent(stCrossedPrice, stActualPrice)
                ? `${getDiscountPercent(stCrossedPrice, stActualPrice)}% OFF`
                : null}
            </h3>
          ) : null}
          {/* <h4 className="availability">
            Availability:<span>In stock</span>
          </h4> */}
          {data?.product?.brand?.title && (
            <h4 className="manufacture">
              Manufactured By: <span>{data?.product?.brand?.title}</span>
            </h4>
          )}
          <div className="quantity">
            <h4>Quantity:</h4>
            <div className="wrapper">
              <span
                onClick={() => count > 1 && setCount(count - 1)}
                className="minus"
              >
                -
              </span>
              <input
                type="number"
                name="quantity"
                value={count}
                onChange={(e: any) => setCount(parseInt(e?.target?.value))}
                className={style.productDetailInput}
                style={{ margin: "0px 6px" }}
                max="99"
              />
              <span
                onClick={() => count < 99 && setCount(count + 1)}
                className="plus"
              >
                +
              </span>
            </div>
            {(!count || count < 0 || count > 99) && (
              <Alert severity="error">
                {count > 99
                  ? errorMessages?.quantityCartMax
                  : errorMessages?.quantityCart}
              </Alert>
            )}
            {/* <p>| Only 5 items left</p> */}
          </div>

          <WishProduct
            setOpen={setOpen}
            product_id={data?.product?.id}
            setFeature={setFeature}
            in_wishlist={data?.product?.in_wishlist}
            wishlist_id={data?.product?.wishlist_id}
          />

          <div className="buttons">
            <BuyNowBtn
              setOpen={setOpen}
              id={data?.product?.id}
              type="product"
              name="Buy Now"
              quantity={count}
              setFeature={setFeature}
              disabled={isLoading}
            />
            <button
              className="add-to-cart"
              onClick={handleAddToCart}
              disabled={
                isLoading || adding || count < 0 || count > 99 || !count
              }
            >
              {adding ? "Adding..." : "Add To Cart"}
            </button>
          </div>
        </div>
      </div>

      <div style={{ paddingBottom: "24px" }}>
        <HorizontalTab
          label1="Description"
          label2="Additional Information"
          label3="Return Policy"
          label1data={
            <div
              dangerouslySetInnerHTML={{
                __html: data?.product?.description ?? "",
              }}
            />
          }
          label2data={
            <div
              dangerouslySetInnerHTML={{
                __html: data?.product?.additional_information ?? "",
              }}
            />
          }
          label3data={
            <div
              dangerouslySetInnerHTML={{
                __html: data?.product?.return_policy ?? "",
              }}
            />
          }
        />
      </div>
      {data?.similar_products ? (
        <TopSelling
          data={data?.similar_products}
          title="Similar Products"
          isLoading={isLoading || isError}
        />
      ) : null}
      {data?.trending_products?.length ? (
        <TopSelling
          data={data?.trending_products}
          title="Trending Products"
          isLoading={isLoading || isError}
        />
      ) : null}
      <AuthModal open={open} setOpen={setOpen} feature={feature} />
    </>
  );
}
