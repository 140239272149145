import "../../../../../styles/CheckOutAccordion.css";
import { getPriceStatement } from "../../../../../utils/priceCommas";

interface Props {
  totalItems: number;
  totalPrice: number;
  deliveryCharge: number;
}

const BillingDetails = ({ totalItems, totalPrice, deliveryCharge }: Props) => {
  return (
    <div className="detailsDiv">
      {/* <div className="promoCode">
          <h4>Apply Promo Code</h4>
          <div className="promoCodeInput">
            <input
              type="text"
              id="name"
              placeholder="Enter Promo Code"
              name="name"
              required
            />
            <ButtonBlue name="APPLY" />
          </div>
          <p>No Promo Code Available!</p>
        </div> */}
      {/* <div className='priceDetailsHeader'>
                    <h4>Price Details</h4>
                </div> */}
      <div className="paymentInfo">
        <h4>Bill Summary</h4>
        <div className="details">
          <p>Price ({totalItems} items)</p>
          <p>{getPriceStatement(totalPrice)}</p>
        </div>
        <div className="details">
          <p>Delivery Charges</p>
          <p>{getPriceStatement(deliveryCharge)}</p>
        </div>
        {/* <div className="details">
            <p>
              Promo Code Discount <span>Remove</span>
            </p>
            <p>-NPR 987</p>
          </div> */}
        <div className="dashedBorder"></div>
        <div className="totalAmount">
          <h3>Amount Payable</h3>
          <h2>{getPriceStatement(totalPrice + deliveryCharge)}</h2>
        </div>
        {/* <p className="remarks">Your total saving on this order NPR 4900</p> */}
      </div>
      {/* <div className="finalText">
          <i className="fa-solid fa-shield-halved"></i>
          <p>We offer safe and secure payments.</p>
        </div> */}
    </div>
  );
};

export default BillingDetails;
