export const deliveryStatus = {
  all: "all",
  // But, in TS types, only the below status are defined.
  pending: "p",
  acknowledged: "a",
  ready: "r",
  shipped: "s",
  delivered: "d",
  cancelled: "c",
};
