import style from "../../../../../styles/Login.module.css";
import Input from "../../Input";
import * as Yup from "yup";
import { useFormik } from "formik";

// Icons
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import LockIcon from "@mui/icons-material/Lock";
import { ButtonBlue } from "../../Button";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import {
  // emailValidation,
  // nameValidation,
  phoneNumberValidation,
} from "../../../../../validations";

const validator = Yup.object({
  phoneNumber: phoneNumberValidation(),
});
const initialValues = {
  phoneNumber: "",
};

export default function LoginPhone(props: any) {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validator,
      onSubmit: (values, action) => {
        action.resetForm();
      },
    });
  return (
    <>
      <div className={style.box}>
        <i
          onClick={props.onClose}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
          }}
        ></i>
        <div className={style.img}>
          <button>Signup</button>
        </div>
        <div className={style.loginPanel}>
          <h3>Login</h3>
          <form className={style.form} onSubmit={handleSubmit}>
            <Input
              id="phoneNumber"
              type="text"
              name="Phone Number"
              placeholder="Enter 10 digit mobile number"
              logo={<LocalPhoneOutlinedIcon />}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touch={touched.phoneNumber}
            />

          <p className={style.grey}>
            By continuing, I agree to the{" "}
            <span className={style.red}>Terms of Use</span> &{" "}
            <span className={style.red}>Privacy Policy</span>{" "}
          </p>
          <ButtonBlue name="Log In" />
          </form>
          <p className={style.signUp}>
            Do not have an acount? <span className={style.stick}>|</span>{" "}
            <span className={style.red}>Sign Up</span>
          </p>

          <p className={style.or}>or</p>

          <button className={style.connects}>
            <i className="fa-brands fa-google"></i>Continue with Google
          </button>
          <button className={style.connects}>
            {" "}
            <i className="fa-brands fa-facebook"></i> Continue with Facebook
          </button>
        </div>
      </div>
    </>
  );
}
