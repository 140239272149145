import "../../styles/BookingPage.css";

// Import Swiper React components
// import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import ShimmerCards from "../UI/uiComponents/LoadingShimmer/ShimmerCards";

// import required modules
// import { FreeMode, Navigation } from "swiper";

interface Props {
  isLoading?: boolean;
  h1NoPad?: boolean;
  h1?: string | undefined;
  children: React.ReactNode;
}

export default function BookAppointmentsSlider({
  h1NoPad = false,
  h1,
  isLoading = false,
  children,
}: Props) {
  return (
    <>
      <div className="container capsule">
        <h1 className={h1NoPad ? "h1-nopt" : ""}>{h1}</h1>
        {isLoading ? (
          <ShimmerCards width="220px" itemsToShow={5} />
        ) : (
          <div className="flex-div">{children}</div>
        )}
      </div>
    </>
  );
}
