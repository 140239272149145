import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { Category } from "../../models/Category";
import { getLocalAuthToken } from "../../storage/localStorage";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getCategoryList: builder.query<{ results: Category[] }, void>({
      query: () => {
        return {
          url: `/product/category/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetCategoryListQuery } = categoryApi;
