import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useDeleteFromPackageCartMutation,
  useGetPackageCartQuery,
} from "../../../../../services/cart";
import Alert from "@mui/material/Alert";
import {
  errorMessages,
  successMessages,
} from "../../../../../utils/alertMessages";
import ConfirmationModal from "../../../uiComponents/ConfirmationModal";
import useCheckItems from "../../../../../hooks/useCheckItems";
import { AddToPackageCartResponse } from "../../../../../models/packageCart";
import CartPackages from "../../../uiComponents/CartUi/CartPackages";
import useServiceDetail from "../../../../../hooks/useServiceDetail";
import PackageDetailModal from "../../../uiComponents/PackageDetailModal";

interface Props {
  totalAmt: number;
  setTotalAmt: React.Dispatch<React.SetStateAction<number>>;
  setCheckedPackages: React.Dispatch<
    React.SetStateAction<Array<string | undefined>>
  >;
  setIsNoPackages: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingPkg: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackageCart = ({
  totalAmt,
  setTotalAmt,
  setCheckedPackages,
  setIsNoPackages,
  setLoadingPkg,
}: Props) => {
  // Package Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // For selecting items to check out.
  const {
    checkedItems,
    isCheckAll,
    handleCheck,
    handleCheckAll,
    handleAutoCheckAll,
  } = useCheckItems();

  // For single delete.
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  // Get API
  const {
    data: packagesData,
    isSuccess: isPackageDataSuccess,
    isLoading: loadingPackages,
  } = useGetPackageCartQuery(null, { refetchOnMountOrArgChange: true });

  // Delete API
  const [
    deleteFromCart,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteFromPackageCartMutation();

  const handleDeleteFromCart = (package_id: number | undefined) => {
    deleteFromCart({ package_id });
  };

  const handleDelete = (pkgId?: number) => {
    setDeleteId(pkgId);
    handleOpenDelete();
  };

  // Check All
  const handleSelectAll = () => {
    if (packagesData?.length) {
      handleCheckAll(packagesData?.map((d: any) => d?.id?.toString()));
    }
  };

  // Check specific items
  const handleSelect = (e: any) => {
    const { value, checked } = e.target;
    handleCheck(value, checked);
  };

  // Set Selected Package Items for the outer main cart
  useEffect(() => {
    setCheckedPackages(checkedItems);
  }, [checkedItems]);

  // Delete Side Effects.
  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteFromCart);
      handleCloseDelete();
    }
  }, [isDeleteError, isDeleteSuccess]);

  useEffect(() => {
    setLoadingPkg(loadingPackages);
    if (isPackageDataSuccess) {
      if (packagesData?.length) {
        setIsNoPackages(false);
        setTotalAmt(
          packagesData?.reduce((total, obj) => {
            return total + (obj?.total_price ? obj?.total_price : 0);
          }, 0)
        );
        // By Default all items will be checked
        handleAutoCheckAll(packagesData?.map((d) => d?.id?.toString()));
      } else {
        setTotalAmt(0);
        setIsNoPackages(true);
      }
    }
  }, [isPackageDataSuccess, packagesData, loadingPackages]);

  return (
    <>
      {packagesData?.length ? (
        <div className="services">
          <p className="greyText">
            Services in my cart ({packagesData?.length ?? 0})
          </p>

          <div className="subject">
            {packagesData?.length ? (
              <>
                {packagesData?.map(
                  (pkg: AddToPackageCartResponse, idx: number) => (
                    <>
                      <CartPackages
                        pkg={pkg}
                        handleDelete={handleDelete}
                        handleOpen={handleOpen}
                        type={pkg?.package ? "package" : "test"}
                      />
                      {/* Modal for singular delete */}
                      <ConfirmationModal
                        open={openDelete}
                        setOpen={setOpenDelete}
                        loading={deleting}
                        yesAction={() => handleDeleteFromCart(deleteId)}
                      />
                    </>
                  )
                )}
              </>
            ) : (
              <Alert severity="error">{errorMessages?.noPackagesInCart}</Alert>
            )}
          </div>
        </div>
      ) : null}
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        slug={slug}
        type={type}
        hasAddToCart={false}
        hasBookNow={false}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
      />
    </>
  );
};

export default PackageCart;
