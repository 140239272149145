import HealthPackages from "../../components/utils/HealthPackages";
import TitlePicture from "../../components/utils/TitlePicture";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
import DoctorCardNoButton from "../../components/UI/uiComponents/DoctorCardNoButton";
import BookAppointmentsSlider from "../../components/utils/BookAppointmentsSlider";
import { SwiperSlide } from "swiper/react";
import Cards from "../../components/UI/cards/HealthPackagesCards";
import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import Hero from "../../components/utils/Hero";
import {
  useGetConsultDoctorsQuery,
  useGetHealthProfessionalsQuery,
} from "../../services/consult-doctor";
import { useGetMarketingBannersQuery } from "../../services/home";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DetailsAccordion from "../../components/base/DetailsAccordion";

export default function ConsultDoctorPage() {
  const { data: doctorsData } = useGetConsultDoctorsQuery();

  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();
  const { data: healthProffData } = useGetHealthProfessionalsQuery();
  const crumbs = [
    {
      name: "Consult Doctors",
      link: "",
    },
  ];

  return (
    <>
      {/* <Breadcrumb crumbs={crumbs} /> */}
      <TitlePicture
        header={
          bannerData?.doctor_consultation[0]?.title ??
          "Doctor Packages - Book Now for Consultation"
        }
      />
      <Hero
        hasContainer={true}
        data={bannerData?.consult_doctors_banner}
        isLoading={isBannerLoading}
      />
      <DetailsAccordion>
        <HorizontalTab
          label1="Description"
          label2="Included"
          label3="Excluded"
          label1data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.doctor_consultation[0]?.description ?? "",
              }}
            />
          }
          label2data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.doctor_consultation[0]?.included ?? "",
              }}
            />
          }
          label3data={
            <div
              dangerouslySetInnerHTML={{
                __html: bannerData?.doctor_consultation[0]?.excluded ?? "",
              }}
            />
          }
        />
      </DetailsAccordion>
      <HealthPackages
        header="Book Doctor Consultation"
        type="health-packages"
        // view="View More"
        data={doctorsData?.results}
      >
        {doctorsData?.results?.map((detail) => {
          return (
            <SwiperSlide>
              <Cards
                id={detail?.id}
                slug={detail?.slug}
                image={detail?.icon}
                header={detail?.title}
                actualPrice={detail?.discounted_price}
                crossedPrice={detail?.market_price}
                tests={detail?.test}
                type="consult-doctor-pkg"
                showFeatures={false}
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages>
      <BookAppointmentsSlider h1="Specialized Doctors for Consultation ">
        {healthProffData?.results?.map((detail, idx) => {
          return (
            <SwiperSlide key={idx} className="bookSlide">
              <DoctorCardNoButton
                image={detail?.image}
                name={detail?.profile?.user?.full_name}
                profession={detail?.specialities
                  ?.map((s) => s?.title)
                  ?.join(",")}
                experience={detail?.year_of_experience}
                consults={detail?.consultation_count}
              />
            </SwiperSlide>
          );
        })}
      </BookAppointmentsSlider>
    </>
  );
}
