import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { ProductDetail } from "../../models/product";
import {
  GetProductWishlist,
  PackageAddToWishlist,
  ProductAddToWishlist,
} from "../../models/wishlist";
import { getLocalAuthToken } from "../../storage/localStorage";

export const wishlistApi = createApi({
  reducerPath: "wishlistApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ProductDetails", "ProductWishlist", "PackageWishlist"],

  endpoints: (builder) => ({
    // Product Details to invalidate wishlist in details page
    getProductDetails: builder.query<
      ProductDetail,
      { slug: string | undefined }
    >({
      query: ({ slug }) => {
        return {
          url: `/products/${slug}/`,
          method: "GET",
        };
      },
      providesTags: ["ProductDetails"],
    }),

    /** -- PRODUCT WISHLIST -- **/
    // Add to Wishlist product.
    addToProductWishlist: builder.mutation<
      ProductAddToWishlist,
      {
        product_id: number | undefined;
        user_id: number | undefined | null;
      }
    >({
      query: ({ product_id, user_id }) => {
        return {
          url: `/product/wishlist/`,
          method: "POST",
          body: {
            product_id,
            user_id,
          },
        };
      },
      invalidatesTags: ["ProductDetails"],
    }),

    // Get Product Wishlist
    getProductWishlist: builder.query<GetProductWishlist, void | null>({
      query: () => {
        return {
          url: `/product/wishlist/`,
          method: "GET",
        };
      },
      providesTags: ["ProductWishlist"],
    }),

    // Delete product from wishlist.
    deleteFromProductWishlist: builder.mutation<
      void,
      {
        product_id: number | undefined;
      }
    >({
      query: ({ product_id }) => {
        return {
          url: `/product/wishlist/${product_id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ProductWishlist", "ProductDetails"],
    }),

    // Update product from wishlist.
    updateProductWishlist: builder.mutation<
      ProductAddToWishlist,
      {
        product_id: number | undefined;
        quantity?: string | number | undefined;
      }
    >({
      query: ({ product_id, quantity }) => {
        return {
          url: `/product/wishlist/${product_id}/`,
          method: "PATCH",
          body: {
            quantity,
          },
        };
      },
      invalidatesTags: ["ProductWishlist"],
    }),

    // Move all product wishlist to cart
    bulkAddProductToCart: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/product/cart/bulk_add/`,
          method: "POST",
          body: "",
        };
      },
    }),

    // DELETE ALL PRODUCTS (Currently, there is one api for products and another for packages for clearing wishlist)
    clearProductsWishlist: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/product/wishlist/bulk_remove/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ProductWishlist"],
    }),

    /** -- PACKAGE WISHLIST -- **/

    // Add health package to wishlist
    addToPackageWishlist: builder.mutation<
      PackageAddToWishlist,
      {
        package_id: number | undefined;
        user_id: number | undefined | null;
      }
    >({
      query: ({ package_id, user_id }) => {
        return {
          url: `/package-wishlist/`,
          method: "POST",
          body: {
            package_id,
            user_id,
          },
        };
      },
    }),

    // Add lab test to wishlist
    addToTestWishlist: builder.mutation<
      PackageAddToWishlist,
      {
        tests_id: number | undefined;
        user_id: number | undefined | null;
      }
    >({
      query: ({ tests_id, user_id }) => {
        return {
          url: `/package-wishlist/`,
          method: "POST",
          body: {
            tests_id,
            user_id,
          },
        };
      },
    }),

    // Get Packages Wishlist
    getPackageWishlist: builder.query<PackageAddToWishlist[], void | null>({
      query: () => {
        return {
          url: `/package-wishlist/`,
          method: "GET",
        };
      },
      providesTags: ["PackageWishlist"],
    }),

    // Delete packages from wishlist.
    deleteFromPackageWishlist: builder.mutation<
      void,
      {
        package_id: number | undefined;
      }
    >({
      query: ({ package_id }) => {
        return {
          url: `/package-wishlist/${package_id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["PackageWishlist"],
    }),

    // Move all package wishlist to cart
    bulkAddPackageToCart: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/packagecart/package_bulk_add/`,
          method: "POST",
          body: "",
        };
      },
    }),

    // DELETE ALL PACKAGES (Currently, there is one api for products and another for packages for clearing wishlist)
    clearPackagesWishlist: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/package-wishlist/bulk_remove/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["PackageWishlist"],
    }),
  }),
});

export const {
  useGetProductDetailsQuery,
  // For Products
  useAddToProductWishlistMutation,
  useGetProductWishlistQuery,
  useDeleteFromProductWishlistMutation,
  useUpdateProductWishlistMutation,
  useBulkAddProductToCartMutation,
  // For Products and Packages
  useClearProductsWishlistMutation,
  useClearPackagesWishlistMutation,
  // For Packages and Tests
  useAddToPackageWishlistMutation,
  useAddToTestWishlistMutation,
  useGetPackageWishlistQuery,
  useDeleteFromPackageWishlistMutation,
  useBulkAddPackageToCartMutation,
} = wishlistApi;
