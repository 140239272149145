import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { SearchResults } from "../../../../models/search";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import Spinner from "../../../utils/Spinner";
import PackageDetailModal from "../PackageDetailModal";
import { Feature } from "../../../../models/feature";
import AuthModal from "../AuthModals";
import useServiceDetail from "../../../../hooks/useServiceDetail";
import { getPackageModalType } from "../../../../utils/getServiceType";

interface Props {
  searchAnchorEl: HTMLElement | null;
  openSearch: boolean;
  handleCloseSearch: () => void;
  data?: SearchResults;
  isLoading?: boolean;
}

const SearchDropdown = ({
  searchAnchorEl,
  openSearch,
  handleCloseSearch,
  data,
  isLoading = false,
}: Props) => {
  // Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // Auth Modal
  const [openAuthModal, setOpenAuthModal] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);

  return (
    <>
      {!data?.product?.length &&
      !data?.package?.length &&
      !data?.test?.length ? null : (
        <Menu
          id="basic-menu"
          anchorEl={searchAnchorEl}
          open={openSearch}
          // sx={windowWidth < 1200 ? { display: "none" } : undefined}
          className="display-mobile" // It works issue in padding
          onClose={handleCloseSearch}
          MenuListProps={{
            "aria-labelledby": "basic-button-search",
          }}
          disableAutoFocus
        >
          <div className="search-drop-container">
            {isLoading ? (
              <div className="search-products-section">
                <Spinner containerWidth="37.5rem" />
              </div>
            ) : data ? (
              <>
                {/* Products */}
                {data?.product?.length ? (
                  <div className="search-products-section">
                    <h4 className="search-products-title">Products</h4>
                    {data?.product?.slice(0, 3)?.map((p, idx) => (
                      <MenuItem
                        className="search-drop"
                        onClick={handleCloseSearch}
                        key={idx}
                      >
                        <Link
                          to={`/product-detail/${p?.slug}`}
                          className="search-items"
                        >
                          <img
                            src={resolveImgPath(
                              p?.images ? p?.images[0]?.image : undefined
                            )}
                            className="search-img"
                          />
                          <span>{p?.title}</span>
                        </Link>
                      </MenuItem>
                    ))}
                  </div>
                ) : null}
                {/* Packages */}
                {data?.package?.length ? (
                  <div className="search-products-section">
                    <h4 className="search-products-title">Packages</h4>
                    {data?.package?.slice(0, 3)?.map((p, idx) => (
                      <MenuItem
                        className="search-drop"
                        onClick={() =>
                          handleOpen(getPackageModalType(p), p?.slug)
                        }
                        key={idx}
                      >
                        <span className="search-items">
                          <img
                            src={resolveImgPath(p?.icon)}
                            className="search-img"
                          />
                          <span>{p?.title}</span>
                        </span>
                      </MenuItem>
                    ))}
                  </div>
                ) : null}
                {/* Tests */}
                {data?.test?.length ? (
                  <div className="search-products-section">
                    <h4 className="search-products-title">Lab Tests</h4>
                    {data?.test?.slice(0, 3)?.map((p, idx) => (
                      <MenuItem
                        className="search-drop"
                        // onClick={handleCloseSearch}
                        onClick={() => handleOpen("test-pkg", p?.slug)}
                        key={idx}
                      >
                        <span className="search-items">
                          <img
                            src={resolveImgPath(p?.icon)}
                            className="search-img"
                          />
                          <span>{p?.title}</span>
                        </span>
                      </MenuItem>
                    ))}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </Menu>
      )}
      <AuthModal
        open={openAuthModal}
        setOpen={setOpenAuthModal}
        feature={feature}
      />
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        setOpenAuthModal={setOpenAuthModal}
        slug={slug}
        type={type}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
        setFeature={setFeature}
      />
    </>
  );
};

export default SearchDropdown;
