import "../../../styles/WhatWeDo.css";

export default function WhatWeDoCards(props: any) {
  return (
    <div className="content">
      <div className="box">
        <img src={props.img} alt="" />
        <div className="semi-circle"></div>
        {/* <div className="lowerText">
        <p>{props.title}</p>
      </div> */}
        {/* <div>
        <p>{props.title}</p>
      </div> */}
      </div>
      <div className="text">
        <p>{props.title}</p>
      </div>
    </div>
  );
}
