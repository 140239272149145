import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function DetailsAccordion({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="capsule" style={{ margin: "10px auto" }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3 className="m-0">Details</h3>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
