import HorizontalTab from "../../uiComponents/HorizontalTab";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import { DeliveryStatusType } from "../../../../models/orderItem";
import "./MyBookings.css";
import { errorMessages } from "../../../../utils/alertMessages";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { serializeArrOfString } from "../../../../utils/serializeQueryString";
import { useGetAppointementsQuery } from "../../../../services/appointment";
import { BookAppointmentListModel } from "../../../../models/appointment";
import { TestBookings } from "../../../../models/tests";
import { useGetBookedTestsQuery } from "../../../../services/tests";
import { useGetBookedPackagesQuery } from "../../../../services/packages";
import { BookedPackages } from "../../../../models/package";
import { getDiscountPercent } from "../../../../utils/discountPercentCalc";
import { getPriceStatement } from "../../../../utils/priceCommas";
import NoData from "../../../utils/NoData";
import Spinner from "../../../utils/Spinner";
import useServiceDetail from "../../../../hooks/useServiceDetail";
import PackageDetailModal from "../../uiComponents/PackageDetailModal";
import { getPackageModalType } from "../../../../utils/getServiceType";

export default function MyBookings() {
  const { search } = useLocation();
  // Package Detail Modal
  const { open, setOpen, type, slug, handleOpen } = useServiceDetail();
  // Sort State
  const [selectedStatus, setSelectedStatus] = useState<DeliveryStatusType>("p");

  // API: Get my bookings.
  const {
    data: myOrders,
    isLoading: loadingAppointments,
  } = useGetAppointementsQuery(
    { queryString: serializeArrOfString([selectedStatus], "status") },
    {
      skip: ![selectedStatus]?.length,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: myBookedTests,
    isLoading: loadingTests,
  } = useGetBookedTestsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: myBookedPackages,
    isLoading: loadingPackages,
  } = useGetBookedPackagesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const renderAppointments = (
    myOrders: BookAppointmentListModel[] | undefined
  ) => {
    return (
      <>
        {loadingAppointments ? (
          <Spinner />
        ) : (
          <>
            {myOrders?.length ? (
              <>
                {myOrders?.map((or, idx) => (
                  <div className="product" key={idx}>
                    <img
                      src={
                        or?.speciality?.image
                          ? resolveImgPath(or?.speciality?.image)
                          : "/assets/images/placeholders/no-image-placeholder.svg"
                      }
                      alt={or?.speciality?.title}
                    />
                    <div className="name-genre">
                      <h4 className="name">{or?.speciality?.title}</h4>
                      {/* {or?.product?.category?.title && (
                <p className="genre">{or?.product?.category?.title}</p>
              )} */}
                      <p className="quantity">
                        Scheduled date: {or?.appointment_date}
                      </p>
                    </div>

                    {/* <div className="price-discount">
              <h4 className="price">NPR {or?.total_amount}</h4>
              {or?.product?.discounted_price ??
              or?.package?.discounted_price ??
              or?.test?.discounted_price ? (
                <p className="discount">
                  <span>
                    NPR{" "}
                    {or?.product?.price ??
                      or?.package?.market_price ??
                      or?.test?.market_price}
                  </span>{" "}
                  {getDiscountPercent(
                    or?.product?.price ??
                      or?.package?.market_price ??
                      or?.test?.market_price,
                    or?.product?.discounted_price ??
                      or?.package?.discounted_price ??
                      or?.test?.discounted_price
                  )}
                  % OFF
                </p>
              ) : null}
            </div> */}

                    {/* <div className="order-report">
              <p className="outcome">
                {" "}
                <i
                  className={`fa-solid fa-circle ${
                    or?.delivery_status === deliveryStatus?.shipped
                      ? "shipped"
                      : or?.delivery_status === deliveryStatus?.delivered
                      ? "delivered"
                      : or?.delivery_status === deliveryStatus?.cancelled
                      ? "cancelled"
                      : "pending"
                  }`}
                ></i>{" "}
                {or?.delivery_status === deliveryStatus?.shipped
                  ? "Order Shipped."
                  : or?.delivery_status === deliveryStatus?.delivered
                  ? "Delivered."
                  : or?.delivery_status === deliveryStatus?.cancelled
                  ? "Cancelled."
                  : "Order Pending"}
              </p>
              <p className="remarks">
                {" "}
                {or?.delivery_status === deliveryStatus?.shipped
                  ? "Your order has been shipped."
                  : or?.delivery_status === deliveryStatus?.delivered
                  ? "Your order has been delivered."
                  : or?.delivery_status === deliveryStatus?.cancelled
                  ? "Your order has been cancelled."
                  : "Your order is pending."}
              </p>
            </div> */}
                  </div>
                ))}
              </>
            ) : (
              <NoData
                containerHeight="50%"
                imgWidth="25%"
                msg={errorMessages?.noBookings}
              />
            )}
          </>
        )}
      </>
    );
  };

  const renderTests = (myBookedTests: TestBookings | undefined) => {
    return (
      <>
        {loadingTests ? (
          <Spinner />
        ) : (
          <>
            {myBookedTests?.results?.length ? (
              <>
                {myBookedTests?.results?.map((or, idx) => (
                  <div className="product test-service" key={idx}>
                    <div className="img-name">
                      <img
                        src={
                          or?.test?.icon
                            ? resolveImgPath(or?.test?.icon)
                            : "/assets/images/placeholders/no-image-placeholder.svg"
                        }
                        alt={or?.test.title}
                        onClick={() => {
                          handleOpen("test-pkg", or?.test?.slug);
                        }}
                      />
                      <div className="name-genre">
                        <h4
                          className="name"
                          onClick={() => {
                            handleOpen("test-pkg", or?.test?.slug);
                          }}
                        >
                          {or?.test?.title}
                        </h4>
                        {/* {or?.product?.category?.title && (
                <p className="genre">{or?.product?.category?.title}</p>
              )} */}
                        {or?.test?.included_tests?.length ? (
                          <p className="quantity">
                            {/* Scheduled date:{" "}
                {new Date(or?.service_date).toDateString() ?? "N/A"} */}
                            Includes {or?.test?.included_tests?.length} Tests
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="price-discount">
                      <h4 className="price">
                        {or?.test?.discounted_price
                          ? getPriceStatement(or?.test?.discounted_price)
                          : getPriceStatement(or?.test?.market_price)}
                      </h4>
                      {or?.test?.discounted_price ? (
                        <p className="discount">
                          <span>
                            {getPriceStatement(or?.test?.market_price)}
                          </span>{" "}
                          {getDiscountPercent(
                            or?.test?.market_price,
                            or?.test?.discounted_price
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoData
                containerHeight="50%"
                imgWidth="25%"
                msg={errorMessages?.noBookings}
              />
            )}
          </>
        )}
      </>
    );
  };

  const renderServices = (myBookedPackages: BookedPackages | undefined) => {
    return (
      <>
        {loadingPackages ? (
          <Spinner />
        ) : (
          <>
            {myBookedPackages?.results ? (
              <>
                {myBookedPackages?.results?.map((or, idx) => (
                  <div className="product test-service" key={idx}>
                    <div className="img-name">
                      <img
                        src={
                          or?.package?.icon
                            ? resolveImgPath(or?.package?.icon)
                            : "/assets/images/placeholders/no-image-placeholder.svg"
                        }
                        alt={or?.package?.title}
                        onClick={() => {
                          handleOpen(
                            getPackageModalType(or?.package),
                            or?.package?.slug
                          );
                        }}
                      />
                      <div className="name-genre">
                        <h4
                          className="name"
                          onClick={() => {
                            handleOpen(
                              getPackageModalType(or?.package),
                              or?.package?.slug
                            );
                          }}
                        >
                          {or?.package?.title}
                        </h4>
                        {or?.package?.test?.length ? (
                          <p className="genre">
                            Includes {or?.package?.test?.length} Tests
                          </p>
                        ) : null}
                        {/* <p className="quantity">Qty: {or?.total_amount}</p> */}
                      </div>
                    </div>

                    <div className="price-discount">
                      <h4 className="price">
                        {or?.package?.discounted_price
                          ? getPriceStatement(or?.package?.discounted_price)
                          : getPriceStatement(or?.package?.market_price)}
                      </h4>
                      {or?.package?.discounted_price ? (
                        <p className="discount">
                          <span>
                            {getPriceStatement(or?.package?.market_price)}
                          </span>{" "}
                          {getDiscountPercent(
                            or?.package?.market_price,
                            or?.package?.discounted_price
                          )}
                          % OFF
                        </p>
                      ) : null}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <NoData
                containerHeight="50%"
                imgWidth="25%"
                msg={errorMessages?.noBookings}
              />
            )}
          </>
        )}
      </>
    );
  };

  const handleSort = (e: any) => {
    const { value } = e?.target;
    setSelectedStatus(value);
  };

  return (
    <div className="ordersOutlet">
      <h1 className="accHeader">My Bookings</h1>
      <div className="tabs">
        <div className="products">
          <HorizontalTab
            label1="Appointments"
            label2="Tests"
            label3="Package"
            label1data={renderAppointments(myOrders)}
            label2data={renderTests(myBookedTests)}
            label3data={renderServices(myBookedPackages)}
            selectedTab={
              search?.split("?type=")[1] === "package"
                ? 2
                : search?.split("?type=")[1] === "test"
                ? 1
                : 0
            }
          />
        </div>
      </div>
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        slug={slug}
        type={type}
        hasAddToCart={false}
        hasBookNow={false}
        hasSpeciality={type === "health-pkg"}
        hasIncludedTests={type === "health-pkg"}
      />
    </div>
  );
}
