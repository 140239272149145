import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// Routes
// import BookingPage from "./pages/Booking";
import HomePage from "./pages/Homepage";
import CategoryPage from "./pages/Category";
import DetailPage from "./pages/Detail";
import Faq from "./pages/Faq";
import ConsultDoctorPage from "./pages/ConsultDoctor";
// import BookAppointmentPage from "./pages/BookAppointment";
import LabTest from "./pages/LabTest";
import HealthPackages from "./pages/HealthPackages";
import NurseServicePage from "./pages/NurseService";
import AboutUsPage from "./pages/AboutUs";
import Checkout from "./pages/Checkout";
import ContactUs from "./pages/ContactUs";
import MyAccount from "./pages/MyAccount";
import Career from "./pages/Career";
// Styles
import "swiper/css/bundle";
import "./styles/App.css";
// Wrappers
import ClientLayout from "./layouts/clientLayout";
import ProtectedRoutes from "./layouts/ProtectedRoutes";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { ROUTES } from "./utils/routeNames";
// import BookAppointmentDetailPage from "./pages/BookAppointment/Detail";
import PhysiotherapyPage from "./pages/Physiotherapy";
import ShortProcedurePage from "./pages/ShortProcedure";
import PackageDetailPage from "./pages/Detail/PackageDetail";
import NotFoundPage from "./pages/NotFound";
import ViewAllPage from "./pages/All";
import PaymentSuccessPage from "./pages/PaymentSuccess";

const App: React.FC = () => {
  const token = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );
  return (
    <>
      <Routes>
        <Route path="/" element={<ClientLayout />}>
          <Route index element={<HomePage />} />
          {/* Uncomment Book Appointments Routes for later */}
          {/* <Route
            path={`${ROUTES?.bookAppointments}`}
            element={<BookingPage />}
          />
          <Route
            path={`${ROUTES?.bookAppointments}/:slug`}
            element={<BookAppointmentDetailPage />}
          />
          <Route
            path={`${ROUTES?.bookAppointments}/:slug/detail`}
            element={<BookAppointmentPage />}
          /> */}

          {/* <Route path={`/${ROUTES?.category}`} element={<CategoryPage />} /> */}
          <Route path={`/${ROUTES?.productList}`} element={<CategoryPage />} />
          <Route path={`/${ROUTES?.viewAll}`} element={<ViewAllPage />} />
          <Route
            path={`/${ROUTES?.productDetail}/:slug`}
            element={<DetailPage />}
          />
          <Route
            path={`/${ROUTES?.packageDetail}/:slug/:pkgType`}
            element={<PackageDetailPage />}
          />
          <Route path="/faq" element={<Faq />} />
          <Route
            path={`/${ROUTES?.doctorConsultation}`}
            element={<ConsultDoctorPage />}
          />
          <Route
            path={`/${ROUTES?.physiotherapy}`}
            element={<PhysiotherapyPage />}
          />
          <Route
            path={`/${ROUTES?.shortProcedure}`}
            element={<ShortProcedurePage />}
          />
          <Route path={`/${ROUTES?.labTest}`} element={<LabTest />} />
          <Route
            path={`/${ROUTES?.healthPackages}`}
            element={<HealthPackages />}
          />
          <Route
            path={`/${ROUTES?.nursePackages}`}
            element={<NurseServicePage />}
          />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<Career />} />
          <Route element={<ProtectedRoutes token={token} />}>
            {[
              "/account",
              "/orders",
              "/my-bookings",
              "/wishlist",
              "/cart",
              "/change-password",
              "/shipping-address",
              "/billing-address",
            ].map((path, idx) => (
              <Route path={path} element={<MyAccount />} key={idx} />
            ))}
            <Route path="/checkout" element={<Checkout />} />
            <Route
              path={`/${ROUTES?.paymentSuccess}`}
              element={<PaymentSuccessPage />}
            />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <ToastContainer autoClose={3000} position="top-center" />
    </>
  );
};

export default App;
