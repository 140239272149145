import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import {
  ConsultDoctorsResponse,
  HealthProfessionalsResponse,
} from "../../models/consultDoctor";
import { Package } from "../../models/package";
import { getLocalAuthToken } from "../../storage/localStorage";

export const consultDoctorApi = createApi({
  reducerPath: "consultDoctorApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getConsultDoctors: builder.query<ConsultDoctorsResponse, void>({
      query: () => {
        return {
          url: `/consult-doctor/`,
          method: "GET",
        };
      },
    }),

    getConsultDoctorPkg: builder.query<Package, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/consult-doctor/${slug}`,
          method: "GET",
        };
      },
    }),

    getHealthProfessionals: builder.query<HealthProfessionalsResponse, void>({
      query: () => {
        return {
          url: `/health-professional/`,
          method: "GET",
        };
      },
    }),

    // Physiotherapy
    getPhysiotherapy: builder.query<ConsultDoctorsResponse, void>({
      query: () => {
        return {
          url: `/physiotherapy/`,
          method: "GET",
        };
      },
    }),

    getPhysiotherapyPkg: builder.query<Package, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/physiotherapy/${slug}`,
          method: "GET",
        };
      },
    }),

    // Short Procedure
    getShortProcedures: builder.query<ConsultDoctorsResponse, void>({
      query: () => {
        return {
          url: `/short-procedure/`,
          method: "GET",
        };
      },
    }),

    getShortProcedurePkg: builder.query<Package, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/short-procedure/${slug}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetConsultDoctorsQuery,
  useGetConsultDoctorPkgQuery,
  useGetHealthProfessionalsQuery,
  // Physiotherapy
  useGetPhysiotherapyQuery,
  useGetPhysiotherapyPkgQuery,
  // Short Procedure
  useGetShortProceduresQuery,
  useGetShortProcedurePkgQuery,
} = consultDoctorApi;
