import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { OrderItemsResponse } from "../../models/orderItem";
import { getLocalAuthToken } from "../../storage/localStorage";

export const orderItemApi = createApi({
  reducerPath: "orderItemApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // Get all order items
    getOrderedItems: builder.query<OrderItemsResponse, void | null>({
      query: () => {
        return {
          url: `/order-item/`,
          method: "GET",
        };
      },
    }),

    // Get sorted & paginated order items
    getOrderedItemsControlled: builder.query<
      OrderItemsResponse,
      { queryString?: string }
    >({
      query: ({ queryString }) => {
        return {
          url: `/order-item/${queryString ? `?${queryString}` : ""}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetOrderedItemsQuery,
  useGetOrderedItemsControlledQuery,
} = orderItemApi;
