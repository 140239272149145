const AUTH_TOKEN = "DocToken";
// const AUTH_TOKEN_EXPIRY_TIME_IN_HOUR = 23;
const AUTH_TOKEN_EXPIRY_TIME_IN_DAYS = 7;

// For Client
export const setLocalAuthToken = (token: String) => {
  const now = new Date();

  const item = {
    value: token,
    // expiry: now.getTime() + AUTH_TOKEN_EXPIRY_TIME_IN_HOUR * 60 * 60 * 1000,
    expiry:
      now.getTime() + AUTH_TOKEN_EXPIRY_TIME_IN_DAYS * 24 * 60 * 60 * 1000,
  };

  localStorage.setItem(AUTH_TOKEN, JSON.stringify(item));
};

export const getLocalAuthToken = () => {
  const itemStr = localStorage.getItem(AUTH_TOKEN);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(AUTH_TOKEN);
    return null;
  }
  return item.value;
};

export const removeLocalAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
