import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CSS from "csstype";
import SearchDropdown from "../SearchDropdown";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useLazySearchAllQuery } from "../../../../services/product";
import "../../../../styles/SearchDrop.css";
// import Spinner from "../../../utils/Spinner";
import useWindowSize from "../../../../hooks/useWindowSize";

interface Props {
  NavOn: boolean;
  open: boolean;
  closeNav: () => void;
}

const searchStyles: CSS.Properties = {
  position: "absolute",
  marginLeft: "2.5rem",
  marginRight: "auto",
  left: "0",
  right: "0",
  top: "0",
  textAlign: "left",
};

const NavSearchInput = ({ NavOn, open, closeNav }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const winWidth = useWindowSize();
  const searchInputEl = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Debounce for calling search api
  const debouncedQuery = useDebounce(searchQuery, 500);
  const [trigger, { data, isFetching }] = useLazySearchAllQuery();

  useEffect(() => {
    if (typeof debouncedQuery === "string") {
      if (winWidth > 1200) {
        if (debouncedQuery?.length) {
          trigger({ q: `?search=${debouncedQuery}` });
        }
      }
    }
  }, [debouncedQuery]);

  // Search Dropdown
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const handleOpenSearch = () => {
    setOpenSearch(true);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  // Go to Product Search Page
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchQuery?.length) navigate(`/product-list?search=${searchQuery}`);
    // else navigate(`/product-list`);
    closeNav();
  };

  useEffect(() => {
    if (data ?? isFetching) {
      handleOpenSearch();
    }
    return handleOpenSearch;
  }, [data, isFetching]);

  useEffect(() => {
    // Make Input Lose Focus once Route Changes
    searchInputEl?.current?.blur();
    // To Close Menu when route changes
    setForceClose(true);
  }, [location]);

  const [forceClose, setForceClose] = useState<boolean>(false);

  return (
    <>
      <form
        onSubmit={handleSearch}
        action=""
        className="search-form"
        style={NavOn ? searchStyles : undefined}
      >
        <input
          name="q"
          type="search"
          id="search-box"
          placeholder="Search for services and wellness product."
          defaultValue={searchParams.get("search") ?? ""}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onChange={(e) => {
            setSearchQuery(e?.target?.value);
            setForceClose(false);
          }}
          // Uncomment: Search for later
          onClick={(e) => setSearchAnchorEl(e?.currentTarget)}
          ref={searchInputEl}
          autoComplete="off"
        />
        <button type="submit" className="search-button">
          {/* {isFetching ? (
          <Spinner />
        ) : (
          <label htmlFor="search-box" className="fas fa-search"></label>
        )} */}
          <label htmlFor="search-box" className="fas fa-search"></label>
        </button>
      </form>
      <>
        {!forceClose ? (
          <SearchDropdown
            searchAnchorEl={searchAnchorEl}
            openSearch={openSearch}
            handleCloseSearch={handleCloseSearch}
            data={data}
            isLoading={isFetching}
          />
        ) : null}
      </>
    </>
  );
};

export default NavSearchInput;
