import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import InfoFooter from "../../components/layouts/Info-footer";
import Nav from "../../components/layouts/Nav";
import {
  removeAuthToken,
  setAuthToken,
} from "../../features/auth/authTokenSlice";
import {
  removeProfile,
  setProfile,
} from "../../features/user/userProfileSlice";
import { useLazyGetUserProfileQuery } from "../../services/user";
import { getLocalAuthToken } from "../../storage/localStorage";
import MessengerChat from "./chat";

const ClientLayout = () => {
  const dispatch = useDispatch();

  // Set User Profile State
  const [
    trigger,
    { data: userProfile, isSuccess: isProfileSuccess, isError: isProfileError },
  ] = useLazyGetUserProfileQuery();

  /* 
  Next time the user opens the application (or page reloads after login success), check:
  a) If authToken exists in Local Storage, set global "authToken" state. 
  b) Else, dispatch "removeAuthToken" action which will remove "authToken" from both global state and localStorage. 
  */
  useEffect(() => {
    const token = getLocalAuthToken();
    if (!token) {
      dispatch(removeAuthToken());
      // // Reset the profile state.
      dispatch(removeProfile());
    } else {
      dispatch(setAuthToken());
      // Hit the "userProfile" api.
      trigger();
    }
  }, []);

  // Set Profile State
  useEffect(() => {
    if (isProfileSuccess) {
      dispatch(
        setProfile({
          id: userProfile?.profile?.user?.id,
          email: userProfile?.email,
          full_name: userProfile?.full_name,
          phone_number: userProfile?.phone_number,
          gender: userProfile?.profile?.gender,
          dob: userProfile?.profile?.dob,
          address: userProfile?.profile?.address,
        })
      );
    }
  }, [isProfileError, isProfileSuccess]);

  return (
    <>
      <Nav />
      <Outlet />
      <InfoFooter />
      <MessengerChat />
    </>
  );
};

export default ClientLayout;
