import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../styles/AdImageCard.css";
import { adColors } from "../../../utils/adColors";

interface Props {
  url: string;
  image: string;
  index: number;
  title?: string;
  subtitle?: string;
  color?: string;
  button_title?: string | null;
}

export default function AdImageCard({
  url,
  image,
  index,
  title,
  subtitle,
  color,
  button_title,
}: Props) {
  return (
    <>
      <div className="ad-card">
        <div className="image-width photo-container">
          <a href={url ?? "#"}>
            {/* <img className="image" src={image} /> */}
            <LazyLoadImage src={image} className="image" />
          </a>
        </div>
        <div
          className="offer-box"
          style={{
            backgroundColor: `${color?.length ? color : adColors[index]}`,
          }}
        >
          <div className="main-offer">
            <h3 className="tag">Upto</h3>
            <div className="offer-content-box">
              <div className="offer-content">
                <span className="number">{subtitle ?? 0}</span>
                <div className="discount">
                  <span className="percent">%</span>
                  <span className="off">Off</span>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-details">
            <p className="heading">{title}</p>
            <a href={url ?? "#"}>
              <button>{button_title ?? "See the deal"}</button>
            </a>
          </div>
        </div>
      </div>
      {/* Mobile Ad Card */}
      <div className="m-ad-card">
        <div className="m-photo-container">
          <a href={url ?? "#"}>
            {/* <img className="image" src={image} alt="" /> */}
            <LazyLoadImage src={image} className="image" />
          </a>
        </div>
        <div
          className="m-offer-box"
          style={{
            backgroundColor: `${color?.length ? color : adColors[index]}`,
          }}
        >
          <div className="m-main-offer">
            <h3 className="tag">Upto</h3>
            <div className="offer-content-box">
              <div className="offer-content">
                <span className="number">{subtitle ?? 0}</span>
                <div className="discount">
                  <span className="percent">%</span>
                  <span className="off">Off</span>
                </div>
              </div>
            </div>
          </div>
          <div className="m-offer-details">
            <p className="heading">{title}</p>
            <a href={url ?? "#"}>
              <button>See the deal</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
