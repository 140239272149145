import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Typography from "@mui/material/Typography";
import ShippingAddress from "./CheckoutAccordionComponents/ShippingAddress";
import BillingAddress from "./CheckoutAccordionComponents/BillingAddress";
import OrderOverview from "./CheckoutAccordionComponents/OrderOverview";
import PaymentOptions from "./CheckoutAccordionComponents/PaymentOptions";
import BillingDetails from "./CheckoutAccordionComponents/BillingDetails";
import "../../../styles/CheckOutAccordion.css";

export interface ConfirmedOrder {
  name?: string;
  img?: string;
  qty?: number;
  price?: number;
  total?: number;
  orderType?: "product" | "service";
}

export interface AddressType {
  name?: string | null;
  phone?: string | null;
  province?: string | null;
  district?: string | null;
  address?: string | null;
}

export default function CheckoutAccordion() {
  // Step Management
  const [expanded, setExpanded] = useState<
    "step1" | "step2" | "step3" | "step4"
  >("step1");
  const [step2, setStep2] = useState<boolean>(false);
  const [step3, setStep3] = useState<boolean>(false);
  const [step4, setStep4] = useState<boolean>(false);
  // Totals
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  // Selected Addresses
  const [selectedShipAddr, setSelectedShipAddr] = useState<number | undefined>(
    undefined
  );
  const [selectedBillAddr, setSelectedBillAddr] = useState<number | undefined>(
    undefined
  );
  // Selected Product Cart Items
  const [selectedCartItems, setSelectedCartItems] = useState<
    Array<string | undefined>
  >([]);

  // Selected Package Cart Items
  const [selectedPkgCartItems, setSelectedPkgCartItems] = useState<
    Array<string | undefined>
  >([]);

  // Data for Confirmation Modal
  const [confirmedOrder, setConfirmedOrder] = useState<ConfirmedOrder[]>([]);
  const [confirmedDelAddr, setConfirmedDelAddr] = useState<AddressType>({});
  const [confirmedBillAddr, setConfirmedBillAddr] = useState<AddressType>({});
  const [confirmedPayOption, setConfirmedPayOption] = useState<
    "khalti" | "cod"
  >("khalti");

  const handleChange = (panel: "step1" | "step2" | "step3" | "step4") => {
    setExpanded(panel);
  };

  return (
    <div className="mainDiv capsule">
      <div className="accordionDiv">
        {/* Accordion 1 */}
        <Accordion
          // disabled={!step3}
          // expanded={expanded === "step3"}
          // onChange={() => handleChange("step3")}
          expanded={expanded === "step1"}
          onChange={() => handleChange("step1")}
          sx={{
            "& .MuiButtonBase-root": {
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              height: "55px",
              boxShadow: "0px 4px 14px 0px #00000026",
              border: "none",
              fontFamily: "poppins",
            },

            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#757575",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography gutterBottom>
              <div className="accordionHeader">
                {/* <Looks3Icon /> <h4> Order Summary </h4> */}
                <LooksOneIcon /> <h4> Order Summary </h4>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrderOverview
              setExpanded={setExpanded}
              // setStep4={setStep4}
              setNextStep={setStep2}
              setTotalItems={setTotalItems}
              setTotalPrice={setTotalPrice}
              setSelectedCartItems={setSelectedCartItems}
              setSelectedPkgCartItems={setSelectedPkgCartItems}
              setConfirmedOrder={setConfirmedOrder}
            />
          </AccordionDetails>
        </Accordion>

        {/* Accordion 2 */}
        <Accordion
          // expanded={expanded === "step1"}
          // onChange={() => handleChange("step1")}
          disabled={!step2}
          expanded={expanded === "step2"}
          onChange={() => handleChange("step2")}
          sx={{
            "& .MuiButtonBase-root": {
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              height: "55px",
              boxShadow: "0px 4px 14px 0px #00000026",
              border: "none",
              fontFamily: "poppins",
            },

            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#757575",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography gutterBottom>
              <div className="accordionHeader">
                {/* <LooksOneIcon /> <h4> Delivery Address </h4> */}
                <LooksTwoIcon /> <h4> Delivery Address </h4>{" "}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShippingAddress
              setExpanded={setExpanded}
              // setStep2={setStep2}
              setNextStep={setStep3}
              setSelectedShipAddr={setSelectedShipAddr}
              setConfirmedDelAddr={setConfirmedDelAddr}
            />
          </AccordionDetails>
        </Accordion>

        {/* Accordion 3 */}
        <Accordion
          // disabled={!step2}
          // expanded={expanded === "step2"}
          // onChange={() => handleChange("step2")}
          disabled={!step3}
          expanded={expanded === "step3"}
          onChange={() => handleChange("step3")}
          sx={{
            "& .MuiButtonBase-root": {
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              height: "55px",
              boxShadow: "0px 4px 14px 0px #00000026",
              border: "none",
              fontFamily: "poppins",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#757575",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography gutterBottom>
              <div className="accordionHeader">
                {/* <LooksTwoIcon /> <h4> Billing Address </h4> */}
                <Looks3Icon /> <h4> Billing Address </h4>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BillingAddress
              setExpanded={setExpanded}
              // setStep3={setStep3}
              setNextStep={setStep4}
              setSelectedBillAddr={setSelectedBillAddr}
              selectedShipAddr={selectedShipAddr}
              setConfirmedBillAddr={setConfirmedBillAddr}
            />
          </AccordionDetails>
        </Accordion>

        {/* Accordion 4 */}
        <Accordion
          disabled={!step4}
          expanded={expanded === "step4"}
          onChange={() => handleChange("step4")}
          sx={{
            "& .MuiButtonBase-root": {
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              height: "55px",
              boxShadow: "0px 4px 14px 0px #00000026",
              border: "none",
              fontFamily: "poppins",
            },

            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#757575",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography gutterBottom>
              <div className="accordionHeader">
                <Looks4Icon /> <h4> Payment Options </h4>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentOptions
              selectedShipAddr={selectedShipAddr}
              selectedBillAddr={selectedBillAddr}
              selectedCartItems={selectedCartItems}
              selectedPkgCartItems={selectedPkgCartItems}
              totalAmount={totalPrice}
              confirmedOrder={confirmedOrder}
              confirmedDelAddr={confirmedDelAddr}
              confirmedBillAddr={confirmedBillAddr}
              confirmedPayOption={confirmedPayOption}
              setConfirmedPayOption={setConfirmedPayOption}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <BillingDetails
        totalItems={totalItems}
        totalPrice={totalPrice}
        deliveryCharge={0}
      />
    </div>
  );
}
