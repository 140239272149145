import { useNavigate } from "react-router-dom";
import style from "../../styles/TitlePicture.module.css";
import { ButtonBlue } from "../UI/uiComponents/Button";

interface Props {
  header: string | undefined;
  img?: string | undefined;
  btn?: { name: string; link: string } | undefined;
}

export default function TitlePicture({
  header,
  img = undefined,
  btn = undefined,
}: Props) {
  const navigate = useNavigate();
  return (
    <div className="capsule" style={{ marginTop: "10px" }}>
      <div className={style.box}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 className={style.header}>{header}</h1>
          {btn && (
            <ButtonBlue name={btn?.name} onClick={() => navigate(btn?.link)} />
          )}
        </div>
        {img && (
          <div className={style.imgDiv}>
            <img className={style.img} src={img} />
          </div>
        )}
      </div>
    </div>
  );
}
