import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { HomepageModel } from "../../models/home";
import { MarketingBanners } from "../../models/marketing";

export const homePageApi = createApi({
  reducerPath: "homePageApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getHomepage: builder.query<HomepageModel, void>({
      query: () => {
        return {
          url: `/homepage/`,
          method: "GET",
        };
      },
    }),
    getMarketingBanners: builder.query<MarketingBanners, void>({
      query: () => {
        return {
          url: `/marketing/banners/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetHomepageQuery, useGetMarketingBannersQuery } = homePageApi;
