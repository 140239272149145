import CSS from "csstype";
import { MouseEventHandler } from "react";
import "../../../styles/Button/index.css";

interface BlueBtnProps {
  type?: "button" | "submit" | "reset" | undefined;
  name: String;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: Boolean;
}

function FormButtonBlue({
  type = "button",
  name,
  onClick = undefined,
  disabled = false,
}: BlueBtnProps) {
  return (
    <>
      {/* <button style={blue} type={type} onClick={onClick}> */}
      <button
        className={`custom-btn blue-btn ${disabled && "blue-btn-disabled"}`}
        type={type}
        onClick={onClick}
      >
        {name}
      </button>
    </>
  );
}

function ButtonBlue(props: any) {
  const blue: CSS.Properties = {
    marginTop: "6px",
    padding: "0.5rem 1.2rem",
    border: "none",
    backgroundColor: "#144580",
    color: "#ffffff",
    borderRadius: "5px",
    fontSize: "1rem",
    transition: "all 0.2s",
    cursor: "pointer",
    whiteSpace: `${props?.wrapSpace ? "normal" : "nowrap"}`,
    marginRight: "1rem",
  };
  return (
    <>
      <button
        style={blue}
        type={props.type ?? "button"}
        onClick={props.onClick}
        disabled={props.disabled}
        {...props}
      >
        {props.name}{" "}
      </button>
    </>
  );
}

function ButtonWhite(props: any) {
  const white = {
    marginTop: "6px",
    padding: "0.4rem 1.4rem",
    border: "2px solid #144580",
    backgroundColor: "#ffffff",
    color: "#144580",
    borderRadius: "5px",
    fontSize: "1rem",
    fontWeight: "500",
    transition: "all 0.2s",
    cursor: "pointer",
    marginRight: "1rem",
  };
  return (
    <>
      <button
        style={white}
        type={props.type}
        onClick={props.onClick}
        {...props}
      >
        {props.name}
      </button>
    </>
  );
}

export { ButtonBlue, ButtonWhite, FormButtonBlue };
