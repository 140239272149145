import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useUpdateProductCartMutation } from "../../../../services/cart";
import { errorMessages } from "../../../../utils/alertMessages";
import style from "../../../../styles/input.module.css";
// import { Alert } from "@mui/material";

interface Props {
  product_id: number | undefined;
  qty: number | undefined;
  setQtyErr?: React.Dispatch<React.SetStateAction<boolean>>;
  basePrice?: number | undefined;
  setUiAmt?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CartQty = ({
  product_id,
  qty = 1,
  setQtyErr,
  basePrice,
  setUiAmt,
}: Props) => {
  const [count, setCount] = useState(1);
  const [flag, setFlag] = useState<boolean>(false);
  // Debounce for calling search api
  const debouncedQty = useDebounce(count, 500);

  // API
  const [
    updateProductCart,
    { isError: isUpdateProdError },
  ] = useUpdateProductCartMutation();

  useEffect(() => {
    if (qty) setCount(qty);
  }, [qty]);

  useEffect(() => {
    if (setQtyErr) {
      if (count < 1 || count > 99) {
        setQtyErr(true);
      } else {
        setQtyErr(false);
        // Instantaneous Price Update
        if (setUiAmt && basePrice) {
          setUiAmt(basePrice * count);
        }
      }
    }
  }, [count]);

  useEffect(() => {
    if (debouncedQty > 0 && debouncedQty < 100) {
      if (flag) updateProductCart({ product_id, quantity: debouncedQty });
    }
  }, [debouncedQty]);

  useEffect(() => {
    if (isUpdateProdError) toast.error(errorMessages?.networkError);
  }, [isUpdateProdError]);

  const handleQtyChange = (e: any) => {
    setFlag(true);
    setCount(parseInt(e?.target?.value));
  };

  const handlePlus = () => {
    setFlag(true);
    if (count < 99) setCount(count + 1);
  };

  const handleMinus = () => {
    setFlag(true);
    if (count > 1) setCount(count - 1);
  };

  return (
    // Parent must be the <label> tag having ".product" as parent and ".products" as super parent.
    <div className="wrapper">
      <div className="cart-qty">
        <span
          // onClick={() => (count > 1 ? setCount(count - 1) : setCount(1))}
          onClick={handleMinus}
          className="minus"
        >
          -
        </span>
        <input
          type="number"
          name="quantity"
          value={count}
          onChange={handleQtyChange}
          className={style.input}
          style={{ margin: "0px 6px" }}
        />
        {/* <span className="num">{count}</span> */}
        <span
          // onClick={() => setCount(count + 1)}
          onClick={handlePlus}
          className="plus"
        >
          +
        </span>
      </div>
      {/* {(!count || count < 0 || count > 99) && (
        <div style={{ width: "60%" }}>
          <Alert severity="error">
            {count > 99
              ? errorMessages?.quantityCartMax
              : errorMessages?.quantityCart}
          </Alert>
        </div>
      )} */}
    </div>
  );
};

export default CartQty;
