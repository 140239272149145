import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { AddToPackageCartResponse } from "../../models/packageCart";
import { ProductAddToCartResponse } from "../../models/product";
import { GetProductCartResponse } from "../../models/productCart";
import { getLocalAuthToken } from "../../storage/localStorage";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ProductCart", "PackageCart"],

  endpoints: (builder) => ({
    /** KEEPING BOTH CART TYPES IN ONE BECAUSE WE ALSO HAVE TO INVALIDATE "GET PACKAGE CART" WHEN USING BULK DELETE.**/

    /** -- PRODUCT CART -- **/
    // Add to Cart product.
    addToProductCart: builder.mutation<
      ProductAddToCartResponse,
      {
        product_id: number | undefined;
        user_id: number | undefined | null;
        quantity?: number | undefined;
      }
    >({
      query: ({ product_id, user_id, quantity = 1 }) => {
        return {
          url: `/product/cart/`,
          method: "POST",
          body: {
            product_id,
            user_id,
            quantity,
          },
        };
      },
      invalidatesTags: ["ProductCart"],
    }),

    // Get Product Cart
    getProductCart: builder.query<GetProductCartResponse, void | null>({
      query: () => {
        return {
          url: `/product/cart/`,
          method: "GET",
        };
      },
      providesTags: ["ProductCart"],
    }),

    // Get Specific Products in Product Cart
    getSpecificProductsInCart: builder.query<
      GetProductCartResponse,
      { cartIdsQueryString: string }
    >({
      query: ({ cartIdsQueryString }) => {
        return {
          url: `/product/cart/?${cartIdsQueryString}`,
          method: "GET",
        };
      },
      providesTags: ["ProductCart"],
    }),

    // Delete product from cart.
    deleteFromProductCart: builder.mutation<
      void,
      {
        product_id: number | undefined;
      }
    >({
      query: ({ product_id }) => {
        return {
          url: `/product/cart/${product_id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ProductCart"],
    }),

    // Update product from cart.
    updateProductCart: builder.mutation<
      ProductAddToCartResponse,
      {
        product_id: number | undefined;
        quantity?: string | number | undefined;
      }
    >({
      query: ({ product_id, quantity }) => {
        return {
          url: `/product/cart/${product_id}/`,
          method: "PATCH",
          body: {
            quantity,
          },
        };
      },
      invalidatesTags: ["ProductCart"],
    }),

    // DELETE ALL PRODUCTS AND PACKAGES
    deleteAllProductCart: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/product/cart/bulk_remove/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ProductCart", "PackageCart"],
    }),

    /** -- PACKAGE CART -- **/

    // Add health package to cart
    addToPackageCart: builder.mutation<
      AddToPackageCartResponse,
      {
        package_id: number | undefined;
        user_id: number | undefined | null;
      }
    >({
      query: ({ package_id, user_id }) => {
        return {
          url: `/packagecart/`,
          method: "POST",
          body: {
            package_id,
            user_id,
          },
        };
      },
      invalidatesTags: ["PackageCart"],
    }),

    // Add lab-test package to cart
    addToTestPackageCart: builder.mutation<
      AddToPackageCartResponse,
      {
        test_id: number | undefined;
        user_id: number | undefined | null;
      }
    >({
      query: ({ test_id, user_id }) => {
        return {
          url: `/packagecart/`,
          method: "POST",
          body: {
            test_id,
            user_id,
          },
        };
      },
      invalidatesTags: ["PackageCart"],
    }),

    // Get Package Cart
    getPackageCart: builder.query<
      AddToPackageCartResponse[] | undefined,
      void | null
    >({
      query: () => {
        return {
          url: `/packagecart/`,
          method: "GET",
        };
      },
      providesTags: ["PackageCart"],
    }),

    // Get Specific Packages in Product Cart
    getSpecificPackagesInCart: builder.query<
      AddToPackageCartResponse[] | undefined,
      { cartIdsQueryString: string }
    >({
      query: ({ cartIdsQueryString }) => {
        return {
          url: `/packagecart/?${cartIdsQueryString}`,
          method: "GET",
        };
      },
      providesTags: ["PackageCart"],
    }),

    // Delete package from cart.
    deleteFromPackageCart: builder.mutation<
      void,
      {
        package_id: number | undefined;
      }
    >({
      query: ({ package_id }) => {
        return {
          url: `/packagecart/${package_id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["PackageCart"],
    }),
  }),
});

export const {
  // For Products
  useAddToProductCartMutation,
  useGetProductCartQuery,
  useLazyGetProductCartQuery, // For getting product cart by using a trigger function.
  useGetSpecificProductsInCartQuery,
  useDeleteFromProductCartMutation,
  useUpdateProductCartMutation,
  // For Products and Packages
  useDeleteAllProductCartMutation,
  // For Packages
  useAddToPackageCartMutation, // For Health Package
  useAddToTestPackageCartMutation, // For Lab Test Package
  useGetPackageCartQuery,
  useLazyGetPackageCartQuery, // For getting package cart by using a trigger function.
  useGetSpecificPackagesInCartQuery,
  useDeleteFromPackageCartMutation,
} = cartApi;
