import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import { ButtonBlue } from "../../uiComponents/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  useBulkAddPackageToCartMutation,
  useBulkAddProductToCartMutation,
  useClearPackagesWishlistMutation,
  useClearProductsWishlistMutation,
  useDeleteFromProductWishlistMutation,
  useGetProductWishlistQuery,
} from "../../../../services/wishlist";
import "./MyWishlist.css";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import { resolveImgPath } from "../../../../utils/imagePathResolver";
import ConfirmationModal from "../../uiComponents/ConfirmationModal";
import { useAddToProductCartMutation } from "../../../../services/cart";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import PackageWishlist from "./PackagesInWishlist";
import { getPriceStatement } from "../../../../utils/priceCommas";
import NoData from "../../../utils/NoData";
import Spinner from "../../../utils/Spinner";

export default function MyWishlist() {
  const navigate = useNavigate();

  // Get User Id
  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );

  // For single delete.
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  // For clear wishlist.
  const [openClearModal, setOpenClearModal] = useState(false);
  const handleOpenClearModal = () => setOpenClearModal(true);
  const handleCloseClearModal = () => setOpenClearModal(false);

  // No Data
  const [isNoProducts, setIsNoProducts] = useState<boolean>(false);
  const [isNoPackages, setIsNoPackages] = useState<boolean>(false);
  const [loadingPkg, setLoadingPkg] = useState<boolean>(false);

  // Move to cart product
  const [movedProductId, setMovedProductId] = useState<string | number>("");

  // API: Get Wishlist
  const {
    data: wishlistData,
    isLoading: loadingProducts,
  } = useGetProductWishlistQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // API: Delete from Wishlist
  const [
    deleteFromWishlist,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteFromProductWishlistMutation();

  // API: Clear Products Wishlist API
  const [
    clearWishlist,
    { isLoading: clearing, isSuccess: isClearSuccess, isError: isClearError },
  ] = useClearProductsWishlistMutation();

  // API: Clear Products Wishlist API
  const [
    clearPkgWishlist,
    {
      isLoading: clearingPkg,
      isSuccess: isClearPkgSuccess,
      isError: isClearPkgError,
    },
  ] = useClearPackagesWishlistMutation();

  // API: Move to Cart
  const [
    moveToCart,
    { error: moveError, isLoading: moving, isSuccess: isMoveSuccess },
  ] = useAddToProductCartMutation();

  // API: Move all products to Cart
  const [
    moveAllToCart,
    { error: moveAllError, isLoading: movingAll, isSuccess: isMoveAllSuccess },
  ] = useBulkAddProductToCartMutation();

  // API: Move all packages to Cart
  const [
    moveAllPkgToCart,
    {
      error: moveAllPkgError,
      isLoading: movingAllPkg,
      isSuccess: isMoveAllPkgSuccess,
    },
  ] = useBulkAddPackageToCartMutation();

  const handleDeleteFromWishlist = (product_id: number | undefined) => {
    deleteFromWishlist({ product_id });
  };

  const handleClearWishlist = () => {
    clearWishlist();
    clearPkgWishlist();
  };

  const handleMoveToCart = (product_id: number | undefined) => {
    moveToCart({
      product_id,
      user_id: userProfileId,
      quantity: 1,
    });
  };

  const handleMoveAllToCart = () => {
    moveAllToCart();
    moveAllPkgToCart();
  };

  const gotoDetail = (slug: string | undefined) => {
    if (slug) navigate(`/product-detail/${slug}`);
    else navigate("#");
  };

  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteFromWishlist);
      handleClose();
    }
  }, [isDeleteError, isDeleteSuccess]);

  useEffect(() => {
    if (isClearError && isClearPkgError) {
      toast.error(errorMessages?.networkError);
    } else if (isClearSuccess && isClearPkgSuccess) {
      toast.success(successMessages?.clearWishlist);
      handleCloseClearModal();
    }
  }, [isClearError, isClearPkgError, isClearSuccess, isClearPkgSuccess]);

  useEffect(() => {
    if (isMoveSuccess) {
      toast.success(successMessages?.addToCart);
      setMovedProductId("");
      navigate("/cart");
    } else if (moveError) {
      if ("status" in moveError) {
        const errMsg: any =
          moveError?.status === 400 || moveError?.status === 403
            ? errorMessages?.alreadyInCart
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
      setMovedProductId("");
    }
  }, [isMoveSuccess, moveError]);

  useEffect(() => {
    if (isMoveAllSuccess && isMoveAllPkgSuccess) {
      toast.success(successMessages?.moveAllToCart);
      navigate("/cart");
    } else if (moveAllError && moveAllPkgError) {
      toast.error(errorMessages?.networkError);
    }
  }, [isMoveAllSuccess, isMoveAllPkgSuccess, moveAllError, moveAllPkgError]);

  useEffect(() => {
    if (wishlistData?.products?.length) setIsNoProducts(false);
    else setIsNoProducts(true);
  }, [wishlistData]);

  return (
    <>
      <div className="outlet">
        <h3>My Wishlist</h3>
        <>
          {loadingProducts && loadingPkg ? (
            <Spinner />
          ) : (
            <>
              <div
                className="row"
                style={{
                  justifyContent: wishlistData?.products?.length
                    ? "space-between"
                    : "flex-end",
                }}
              >
                {wishlistData?.products?.length ? (
                  <h5>
                    Products in My Wishlist (
                    {wishlistData?.products?.length ?? 0})
                  </h5>
                ) : null}
                {isNoProducts && isNoPackages ? null : (
                  <div>
                    <p className="clear-cart" onClick={handleOpenClearModal}>
                      Clear my wishlist
                    </p>

                    {/* <button className="moveToCart">Move all to cart</button> */}
                    <ButtonBlue
                      name={
                        movingAll || movingAllPkg ? "Adding" : "Add all to Cart"
                      }
                      onClick={() => {
                        handleMoveAllToCart();
                      }}
                      disabled={movingAll || movingAllPkg}
                    />
                  </div>
                )}
              </div>
              {wishlistData?.products?.length ? (
                <>
                  <div className="subject">
                    {/* <input type="checkbox" id="product1" name="product1" value="honey" /> */}
                    <label htmlFor="product1"> Items </label>
                  </div>
                  <div className="products">
                    {wishlistData?.products?.length ? (
                      <>
                        {wishlistData?.products?.map((p, idx) => (
                          <div className="product" key={idx}>
                            {/* <input
                    type="checkbox"
                    id="product1"
                    name="product1"
                    value="honey"
                  /> */}
                            <label htmlFor="product1" className="column">
                              <div className="imgPrice">
                                <img
                                  src={
                                    p?.product?.images?.length
                                      ? resolveImgPath(
                                          p?.product?.images[0]?.image
                                        )
                                      : "/assets/images/placeholders/no-image-placeholder.svg"
                                  }
                                  onClick={() => gotoDetail(p?.product?.slug)}
                                  alt={p?.product?.title}
                                />
                                <div className="namePrice">
                                  <p
                                    className="name"
                                    onClick={() => gotoDetail(p?.product?.slug)}
                                  >
                                    {p?.product?.title}
                                  </p>
                                  <p className="price">
                                    {getPriceStatement(
                                      p?.product?.discounted_price ??
                                        p?.product?.price
                                    )}
                                  </p>
                                </div>
                              </div>
                              {/* <p className="green stock">In Stock</p> */}
                              <ButtonBlue
                                name={
                                  moving && movedProductId === p?.product?.id
                                    ? "Adding"
                                    : "Add to Cart"
                                }
                                onClick={() => {
                                  setMovedProductId(p?.product?.id ?? "");
                                  handleMoveToCart(p?.product?.id);
                                }}
                                disabled={
                                  moving && movedProductId === p?.product?.id
                                }
                              />
                              <CloseOutlinedIcon
                                onClick={() => {
                                  setDeleteId(p?.id);
                                  handleOpen();
                                }}
                                className="delete-icon"
                              />
                            </label>
                            {/* Modal for singular delete */}
                            <ConfirmationModal
                              open={open}
                              setOpen={setOpen}
                              loading={deleting}
                              yesAction={() =>
                                handleDeleteFromWishlist(deleteId)
                              }
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <Alert severity="error">
                        {errorMessages?.noProductsInWishlist}
                      </Alert>
                    )}
                  </div>
                </>
              ) : null}

              {/* Packages in Wishlist */}
              <PackageWishlist
                setIsNoPackages={setIsNoPackages}
                setLoadingPkg={setLoadingPkg}
              />
              {/* Modal for Bulk Delete */}
              <ConfirmationModal
                open={openClearModal}
                setOpen={setOpenClearModal}
                loading={clearing || clearingPkg}
                yesAction={handleClearWishlist}
              />
            </>
          )}
          {!loadingProducts && !loadingPkg && isNoPackages && isNoProducts && (
            <NoData imgWidth="20%" msg="No Products in Wishlist" />
          )}
        </>
      </div>
    </>
  );
}
