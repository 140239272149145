import style from "../../styles/AppointmentForm.module.css";
import Input from "../UI/uiComponents/Input";
import * as Yup from "yup";
import { BsPersonFill, BsTelephone } from "react-icons/bs";
import { MdMailOutline, MdOutlineAddLocationAlt } from "react-icons/md";
import { ButtonBlue } from "../UI/uiComponents/Button";
import { useFormik } from "formik";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  emailValidation,
  nameValidation,
  phoneNumberValidation,
} from "../../validations";
import { useContactUsMutation } from "../../services/contact-us";
import { toast } from "react-toastify";
import { errorMessages } from "../../utils/alertMessages";
import { useEffect } from "react";
import { useGetConfigQuery } from "../../services/config";
import { mailTo, tel } from "../../utils/mailTel";
import { LazyLoadImage } from "react-lazy-load-image-component";

const validator = Yup.object({
  full_name: nameValidation(),
  email: emailValidation(),
  phone_number: phoneNumberValidation(),
  address: nameValidation(),
  message: nameValidation(),
});
const initialValues = {
  full_name: "",
  phone_number: "",
  email: "",
  address: "",
  message: "",
};

interface Values {
  full_name?: String;
  phone_number?: String;
  email?: String;
  address?: String;
  message?: String;
}

export default function ContactUsForm() {
  // Contact Data
  const { data: configData } = useGetConfigQuery();
  // API
  const [contactUs, { isSuccess, isLoading, isError }] = useContactUsMutation();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validator,
    onSubmit: async ({
      full_name,
      phone_number,
      email,
      address,
      message,
    }: Values) => {
      contactUs({
        full_name,
        phone_number,
        email,
        address,
        message,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Thank you for contacting us. We will get back to you.");
      resetForm();
    } else if (isError) {
      toast.error(errorMessages?.networkError);
    }
  }, [isSuccess, isError]);

  return (
    <div className="capsule">
      <section className={style.main}>
        <header>
          <h1 className={style.mainHeader}>Get a Callback</h1>
          <p className={style.mainP}>Let our team call you back for service</p>
        </header>

        <div className={style.appointment}>
          <form className={style.form} onSubmit={handleSubmit}>
            <Input
              id="full_name"
              type="text"
              name="Full Name"
              placeholder="Enter First Name"
              logo={<BsPersonFill />}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.full_name}
              error={errors.full_name}
              touch={touched.full_name}
            />

            <Input
              id="phone_number"
              type="text"
              name="Phone Number"
              placeholder="Enter 10 digit mobile number"
              logo={<BsTelephone />}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              error={errors.phone_number}
              touch={touched.phone_number}
            />

            <Input
              id="email"
              type="email"
              name="Email Address"
              placeholder="Enter email address"
              logo={<MdMailOutline />}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              touch={touched.email}
            />

            <Input
              id="address"
              type="text"
              name="Address"
              placeholder="Enter your address"
              logo={<MdOutlineAddLocationAlt />}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={errors.address}
              touch={touched.address}
            />

            <div className={style.messageBox}>
              <label className={style.label} htmlFor="message">
                {" "}
                <EmailIcon /> Message <span className={style.star}>*</span>{" "}
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                cols={50}
                placeholder="Type Your Message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              ></textarea>

              {errors.message && touched.message ? (
                <p className={style.formError}>{errors.message}</p>
              ) : null}
            </div>

            <div className={style.buttons}>
              <ButtonBlue
                type="submit"
                name={isLoading ? "Submitting" : "Submit"}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </form>

          <div className={style.rightSide}>
            {/* <img src="assets/images/Rectangle 4861.png" alt="Side" /> */}
            <LazyLoadImage src={"assets/images/Rectangle 4861.png"} />
            <div className={style.companyInfo}>
              {configData?.organization?.company_address && (
                <p>
                  {" "}
                  <div>
                    <LocationOnRoundedIcon />{" "}
                  </div>
                  {configData?.organization?.company_address}
                </p>
              )}
              {configData?.organization?.primary_support_contact_number && (
                <p>
                  {" "}
                  <div>
                    <PhoneRoundedIcon />{" "}
                  </div>
                  <a
                    className={style.contactLink}
                    href={tel(
                      configData?.organization?.primary_support_contact_number
                    )}
                  >
                    {configData?.organization?.primary_support_contact_number}
                  </a>
                  {configData?.organization
                    ?.secondary_support_contact_number && (
                    <a
                      className={style.contactLink}
                      href={tel(
                        configData?.organization
                          ?.secondary_support_contact_number
                      )}
                    >
                      ,{" "}
                      {
                        configData?.organization
                          ?.secondary_support_contact_number
                      }
                    </a>
                  )}
                </p>
              )}
              {configData?.organization?.primary_comany_email && (
                <p>
                  {" "}
                  <div>
                    <EmailIcon />
                  </div>
                  <a
                    className={style.contactLink}
                    href={mailTo(
                      configData?.organization?.primary_comany_email
                    )}
                  >
                    {configData?.organization?.primary_comany_email}
                  </a>
                  {configData?.organization?.secondary_company_email && (
                    <a
                      className={style.contactLink}
                      href={mailTo(
                        configData?.organization?.secondary_company_email
                      )}
                    >
                      / {configData?.organization?.secondary_company_email}
                    </a>
                  )}
                </p>
              )}
            </div>
            <div className={style.socialMediaIcons}>
              {configData?.organization?.facebook_url && (
                <a
                  href={configData?.organization?.facebook_url}
                  target="_blank"
                >
                  <FacebookRoundedIcon />
                </a>
              )}
              {configData?.organization?.instagram_url && (
                <a
                  href={configData?.organization?.instagram_url}
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
              )}
              {configData?.organization?.twitter_url && (
                <a href={configData?.organization?.twitter_url} target="_blank">
                  <TwitterIcon />
                </a>
              )}
              {configData?.organization?.linkedin_url && (
                <a
                  href={configData?.organization?.linkedin_url}
                  target="_blank"
                >
                  <LinkedInIcon />
                </a>
              )}
              {configData?.organization?.youtube_url && (
                <a href={configData?.organization?.youtube_url} target="_blank">
                  <YouTubeIcon />
                </a>
              )}
              {/* <a href="#">
                {" "}
                <InstagramIcon />{" "}
              </a>
              <a href="#">
                {" "}
                <TwitterIcon />{" "}
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
