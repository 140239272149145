import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import {
  Test,
  Tests,
  TestBookings,
  BookTestResponse,
  AllTests,
} from "../../models/tests";
import { getLocalAuthToken } from "../../storage/localStorage";

export const testsApi = createApi({
  reducerPath: "testsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // Global state is slow when refreshing page, so using localstorage instead.
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    // Get all tests.
    getTests: builder.query<Tests, void>({
      query: () => {
        return {
          url: `/test/`,
          method: "GET",
        };
      },
    }),

    getSelectedTest: builder.query<Test, { slug: string | undefined }>({
      query: ({ slug }) => {
        return {
          url: `/test/${slug}/`,
          method: "GET",
        };
      },
    }),

    getAllTests: builder.query<
      AllTests,
      {
        offset?: number;
        order?: string;
        min_price?: string;
        max_price?: string;
        is_featured?: boolean;
        is_discount?: boolean;
        is_popular?: boolean;
        include_test?: boolean;
      }
    >({
      query: ({
        offset,
        order,
        min_price,
        max_price,
        is_featured,
        is_discount,
        is_popular,
        include_test,
      }) => {
        return {
          url: `/alltest/`,
          method: "GET",
          params: {
            offset,
            order,
            min_price,
            max_price,
            is_featured,
            is_discount,
            is_popular,
            include_test,
          },
        };
      },
    }),

    // Get Booked Tests
    getBookedTests: builder.query<TestBookings, void | null>({
      query: () => {
        return {
          url: `/book-test/`,
          method: "GET",
        };
      },
    }),

    // Book Tests
    bookTest: builder.mutation<
      BookTestResponse,
      {
        user: number | undefined | null;
        test_id: number | undefined;
      }
    >({
      query: ({ test_id, user }) => {
        return {
          url: `/book-test/`,
          method: "POST",
          body: {
            user,
            test_id,
            service_date: "2022-05-04T12:12",
          },
        };
      },
    }),
  }),
});

export const {
  useGetTestsQuery,
  useGetAllTestsQuery,
  useGetSelectedTestQuery,
  useGetBookedTestsQuery,
  useBookTestMutation,
} = testsApi;
