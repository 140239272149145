import style from "../../../../../styles/Otp.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import Input from "../../Input";

// Icons
import { ButtonBlue } from "../../Button";

import {
  // emailValidation,
  // nameValidation,
  otpValidation,
  // phoneNumberValidation,
} from "../../../../../validations";

const validator = Yup.object({
  otp1: otpValidation(),
  otp2: otpValidation(),
  otp3: otpValidation(),
  otp4: otpValidation(),
  otp5: otpValidation(),
  otp6: otpValidation(),
});
const initialValues = {
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  otp5: "",
  otp6: "",
};

export default function Otp(props: any) {
  const { values, touched, handleBlur, handleChange, handleSubmit } = useFormik(
    {
      initialValues,
      validationSchema: validator,
      onSubmit: (values, action) => {
        action.resetForm();
      },
    }
  );

  return (
    <>
      <div className={style.box}>
        <i
          onClick={props.onClose}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
          }}
        ></i>
        <div className={style.img}></div>
        <div className={style.loginPanel}>
          <h3>Verify With OTP</h3>
          <p className={style.description}>A 6-digit code sent to 9475837583</p>
          <form className={style.form} onSubmit={handleSubmit}>
            <div className={style.inputs}>
              <Input
                id="otp1"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp1}
                touch={touched.otp1}
              />
              <Input
                id="otp2"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp2}
                touch={touched.otp2}
              />
              <Input
                id="otp3"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp3}
                touch={touched.otp3}
              />
              <Input
                id="otp4"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp4}
                touch={touched.otp4}
              />
              <Input
                id="otp5"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp5}
                touch={touched.otp5}
              />
              <Input
                id="otp6"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.otp6}
                touch={touched.otp6}
              />
            </div>
            <ButtonBlue name="Log In" />
          </form>

          <p className={style.signUp}>
            Didn’t get the code? <span className={style.stick}>|</span>{" "}
            <span className={style.red}>Resend OTP</span>
          </p>

          <p className={style.bottom}>
            Log in using{" "}
            <span className={style.red}>
              Password <i className="fa-solid fa-arrow-right"></i>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
