import { useState, useEffect } from "react";
import {
  useChangeDefDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useGetDeliveryAddressQuery,
} from "../../../../services/user";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { ButtonBlue } from "../../uiComponents/Button";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import DeliveryAddressModal from "../../uiComponents/DeliveryAddressModal";
import DeliveryAddressEditModal from "../../uiComponents/DeliveryAddressModal/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
import ConfirmationModal from "../../uiComponents/ConfirmationModal";
import NoData from "../../../utils/NoData";
import Spinner from "../../../utils/Spinner";

interface Props {
  type: "shipping" | "billing";
}

const AddressList = ({ type }: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // For Edit
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const [editAddressId, setEditAddressId] = useState<string>("");

  // For Delete
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const [deleteAddressId, setDeleteAddressId] = useState<string>("");

  // Address Radio
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  // API: Get Added Delivery Addresses
  const { data: delAddrData, isLoading } = useGetDeliveryAddressQuery();

  // API: Delete Delivery Address
  const [
    deleteAddress,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteDeliveryAddressMutation();

  // API: Change Default Delivery Address
  const [
    changeDefault,
    { isLoading: changing, isSuccess: isChangeSuccess, isError: isChangeError },
  ] = useChangeDefDeliveryAddressMutation();

  const handleSelectAddress = (e: any) => {
    setSelectedAddress(e?.target?.value);
  };

  const handleDeleteAddress = (id: string) => {
    deleteAddress({ id });
  };

  const handleChangeDefault = (id: string) => {
    changeDefault({ id });
  };

  // Get Side Effects
  useEffect(() => {
    if (delAddrData) {
      if (delAddrData?.results) {
        // Find the default shipping address.
        const defaultAddress = delAddrData?.results?.find((d) => {
          if (type === "shipping") {
            return d?.is_default && d?.is_only_shipping_address;
          } else if (type === "billing") {
            return d?.is_default && d?.is_only_billing_address;
          } else return false;
        });
        setSelectedAddress(
          defaultAddress?.id ? `address-${defaultAddress?.id}` : ""
        );
      }
    }
  }, [delAddrData]);

  // Delete Side Effects
  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteDeliveryAddress);
      setOpenDelete(false);
    }
  }, [isDeleteError, isDeleteSuccess]);

  // Change Default Side Effects
  useEffect(() => {
    if (isChangeError) toast.error(errorMessages?.networkError);
    else if (isChangeSuccess) {
      toast.success(successMessages?.editDeliveryAddress);
    }
  }, [isChangeError, isChangeSuccess]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {delAddrData?.results?.filter((d: any) => {
            if (type === "shipping") {
              return d?.is_only_shipping_address;
            } else if (type === "billing") {
              return d?.is_only_billing_address;
            } else return false;
          })?.length ? (
            delAddrData?.results
              ?.filter((d: any) => {
                if (type === "shipping") {
                  return d?.is_only_shipping_address;
                } else if (type === "billing") {
                  return d?.is_only_billing_address;
                } else return false;
              })
              ?.map((d: any, idx: number) => (
                <div
                  className="accordionInputLabel"
                  style={{
                    border: `2px solid ${
                      d?.is_default ? "#144580" : "rgba(218, 220, 224, 1)"
                    }`,
                    borderRadius: "7px",
                    padding: "0.9rem",
                  }}
                  key={idx}
                >
                  <input
                    type="radio"
                    id={`address-${d?.id}`}
                    name="shipping-addresses"
                    value={`address-${d?.id}`}
                    checked={selectedAddress === `address-${d?.id}`}
                    onChange={handleSelectAddress}
                  />
                  <label htmlFor={`address-${d?.id}`} className="addressLabel">
                    <div className="addressDiv">
                      <div className="personalInfo">
                        <p className="name">
                          {d?.receiver_name},
                          <span className="number">
                            {d?.receiver_contact_number}
                          </span>
                        </p>
                        <div className="addressEditDiv">
                          <p
                            className="edit"
                            onClick={(e) => {
                              e?.preventDefault();
                              setEditAddressId(d?.id?.toString());
                              handleOpenEdit();
                            }}
                          >
                            Edit
                          </p>
                          <div
                            className="delete"
                            onClick={(e) => {
                              e?.preventDefault();
                              setDeleteAddressId(d?.id?.toString());
                              handleOpenDelete();
                            }}
                            title="Delete"
                          >
                            <DeleteOutlineOutlinedIcon
                              sx={{ color: "#757575", fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <p className="location">
                        {d?.address}, {d?.city}, {d?.state}, {d?.country}
                        {/* ABC, DEF */}
                      </p>
                      {d?.is_default && (
                        <p className="default">Default Address</p>
                      )}
                      <p></p>
                      {!d?.is_default ? (
                        <>
                          {selectedAddress === `address-${d?.id}` && (
                            <ButtonBlue
                              name={changing ? "Processing" : "Set As Default"}
                              onClick={() => handleChangeDefault(d?.id)}
                              disabled={changing}
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </label>
                </div>
              ))
          ) : (
            <NoData
              containerHeight="50%"
              imgWidth="25%"
              msg={
                type === "shipping"
                  ? errorMessages?.noDeliveryAddress
                  : errorMessages?.noBillingAddress
              }
            />
          )}

          <div className="add-new">
            <p onClick={handleOpen}>
              <AddCircleOutlineSharpIcon /> Add a new address
            </p>
          </div>

          {/* Add New Address */}
          <DeliveryAddressModal open={open} setOpen={setOpen} type={type} />

          {/* Edit Address */}
          <DeliveryAddressEditModal
            open={openEdit}
            setOpen={setOpenEdit}
            id={editAddressId}
            type={type}
          />

          {/* Delete Address */}
          <ConfirmationModal
            open={openDelete}
            setOpen={setOpenDelete}
            loading={deleting}
            yesAction={() => handleDeleteAddress(deleteAddressId)}
          />
        </>
      )}
    </>
  );
};

export default AddressList;
