import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PayloadType {
  id: number | null | undefined;
  email: string | null | undefined;
  full_name: string | null | undefined;
  phone_number: string | null | undefined;
  gender: string | null | undefined;
  dob: string | null | undefined;
  address: string | null | undefined;
}

interface StateType {
  profile: PayloadType;
}

const initialState: StateType = {
  profile: {
    id: null,
    email: null,
    full_name: null,
    phone_number: null,
    gender: null,
    dob: null,
    address: null,
  },
};

export const userProfileSlice = createSlice({
  name: "userProfileState",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<PayloadType>) => {
      state.profile = action.payload;
    },
    removeProfile: (state) => {
      state.profile = initialState.profile;
    },
  },
});

export const { setProfile, removeProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
