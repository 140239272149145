import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/utils/Spinner";
import { useLazyKhaltiV2SuccessQuery } from "../../services/checkout";
import "../../styles/NotFound.css";
import {
  errorMessages,
  infoMessages,
  successMessages,
} from "../../utils/alertMessages";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const payment_id = searchParams?.get("purchase_order_id");
  // API
  const [
    trigger,
    { isSuccess, isLoading, isError },
  ] = useLazyKhaltiV2SuccessQuery();

  useEffect(() => {
    if (payment_id) {
      trigger({ payment_id });
    }
  }, [payment_id]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/orders", { replace: true });
      }, 1000);
    }
  }, [isSuccess]);

  return (
    <div className="not-found">
      <div className="img">
        <img src="/assets/images/illustrations/payment.svg" />
      </div>
      <Spinner
        text={
          isLoading
            ? infoMessages?.loadingPayment
            : isError
            ? errorMessages?.paymentVerifyFailure
            : isSuccess
            ? successMessages?.paymentVerified
            : "No Pending Purchases."
        }
        loading={isLoading}
        failure={isError}
        success={isSuccess}
      />
    </div>
  );
};

export default PaymentSuccessPage;
