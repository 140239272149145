// import HealthPackages from "../../components/utils/HealthPackages";
import TitlePicture from "../../components/utils/TitlePicture";
import HorizontalTab from "../../components/UI/uiComponents/HorizontalTab";
// import { SwiperSlide } from "swiper/react";
// import Cards from "../../components/UI/cards/HealthPackagesCards";
// import Breadcrumb from "../../components/UI/uiComponents/Breadcrumb";
import Hero from "../../components/utils/Hero";
import { useGetShortProceduresQuery } from "../../services/consult-doctor";
import { useGetMarketingBannersQuery } from "../../services/home";
import TopSelling from "../../components/layouts/TopSelling";

export default function ShortProcedurePage() {
  const {
    data: bannerData,
    isLoading: isBannerLoading,
  } = useGetMarketingBannersQuery();
  const {
    data: shortProData,
    isLoading: isLoadingPro,
    isError: isProErr,
  } = useGetShortProceduresQuery();

  // const crumbs = [
  //   // Must be in order after home
  //   {
  //     name: "Short Procedure",
  //     link: "",
  //   },
  // ];

  return (
    <>
      {/* <Breadcrumb crumbs={crumbs} /> */}
      <TitlePicture
        header={
          bannerData?.short_procedure_service[0]?.title ?? "Short Procedure"
        }
      />
      <Hero
        hasContainer={true}
        data={bannerData?.short_procedure_banner}
        isLoading={isBannerLoading}
      />
      <HorizontalTab
        label1="Description"
        label2="Included"
        label3="Excluded"
        label1data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.short_procedure_service[0]?.description ?? "",
            }}
          />
        }
        label2data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.short_procedure_service[0]?.included ?? "",
            }}
          />
        }
        label3data={
          <div
            dangerouslySetInnerHTML={{
              __html: bannerData?.short_procedure_service[0]?.excluded ?? "",
            }}
          />
        }
      />

      <TopSelling
        pkgData={shortProData?.results}
        title="Short Procedure Packages"
        isLoading={isLoadingPro || isProErr}
        type="package"
        pkgType="shortProcedure"
      />

      {/* <HealthPackages
        header="Short Procedure Packages"
        type="health-packages"
        view="View More"
        data={shortProData?.results}
      >
        {shortProData?.results?.map((detail) => {
          return (
            <SwiperSlide>
              <Cards
                id={detail?.id}
                slug={detail?.slug}
                image={detail?.icon}
                header={detail?.title}
                actualPrice={detail?.discounted_price}
                crossedPrice={detail?.market_price}
                tests={detail?.test}
                type="shortprocedure-pkg"
                showFeatures={false}
              />
            </SwiperSlide>
          );
        })}
      </HealthPackages> */}
    </>
  );
}
