import { useState } from "react";
import "../../styles/InfoFooter.css";
import { Link } from "react-router-dom";
import { useGetConfigQuery } from "../../services/config";
import { ROUTES } from "../../utils/routeNames";
// import { MdLock } from "react-icons/md";
// import { BsShieldFillCheck } from "react-icons/bs";
import AuthModal from "../UI/uiComponents/AuthModals";
import { mailTo, tel } from "../../utils/mailTel";

export default function InfoFooter() {
  const { data } = useGetConfigQuery();
  // For Auth Modals.
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* <section className="info-main">
        <div className="capsule">
          <h3>
            Stay Healthy with Doctor’s on Call Nepal: Making Healthcare
            accessible, affordable & equitably available
          </h3>
          <div className="info-data">
            <h5>We Bring Care to Health.</h5>
            <p>
              Doctor’s on Call Nepal is India's leading digital healthcare
              platform. From doctor consultations on chat to online pharmacy and
              lab tests at home: we have it all covered for you. Having
              delivered over 1 million orders in 10+ cities till date, we are on
              a mission to br/ing "care" to "health" to give you a flawless
              healthcare experience.
            </p>
          </div>

          <div className="info-data">
            <h5>Doctor’s on Call Nepal: Your Favourite Online Pharmacy!</h5>
            <p>
              Doctor’s on Call Nepal is Nepal's growing online chemist with over
              1 lakh medicines available at the best prices. We are your
              one-stop destination for other healthcare products as well, such
              as over the counter pharmaceuticals, healthcare devices and
              homeopathy and ayurveda medicines.
              <br /> <br />
              With Doctor’s on Call Nepal, you can buy medicines online and get
              them delivered at your doorstep anywhere in Nepal! But, is
              ordering medicines online a difficult process? Not at all! Simply
              search for the products you want to buy, add to cart and checkout.
              Now all you need to do is sit back as we get your order delivered
              to you. <br /> <br />
              In case you need assistance, just give us a call and we will help
              you complete your order. <br /> <br />
              Don't want to go thr/ough the hassle of adding each medicine
              separately? You can simply upload your prescription and we will
              place your order for you. And there is more! At Doctor’s on Call
              Nepal, you can buy health products and medicines online at best
              discounts. <br /> <br />
              Now, isn't that easy? Why go all the way to the medicine store and
              wait in line, when you have Doctor’s on Call Nepal Pharmacy at
              your service.
            </p>
          </div>

          <div className="info-data">
            <h5>The Feathers in Our Cap</h5>
            <p>
              Doctor’s on Call Nepal, our goal is to make healthcare
              understandable, accessible and affordable in India. We set out on
              our journey in 2015, and have come a long way since then. Along
              the way, we have been conferred with prestigious titles like
              Rastrapati Udyayam Samman Purashkar for 'Business Excellence
              thr/ough Learning and Development', Best Online Pharmacy in India
              Award and Top 50 venture in The Smart CEO-Startup50 India. We have
              been selected as the only company from across the globe for SD#3
              "Health & Well Being for all" by Unreasonable group, US State
              Department. In 2019 alone we received thr/ee awards including the
              BMW Simply Unstoppable Award.
            </p>
          </div>
          <div className="info-data">
            <h5>The Services We Offer</h5>
            <p>
              Doctor’s on Call Nepal is Nepal's leading digital healthcare
              platform, where you can buy medicines online with discount. Buy
              medicine online in Kathmandu, Bhaktapur, Lalitpur, Itahari, Dharan
              and soon to around 20 more cities. Besides delivering your online
              medicine order at your doorstep, we provide accurate,
              authoritative & trustworthy information on medicines and help
              people use their medicines effectively and safely. <br /> <br />
              We also facilitate lab tests at home. You can avail over 2,000
              tests and get tested by 120+ top and verified labs at the best
              prices. Need to consult a doctor? On our platform, you can talk to
              over 20 kinds of specialists in just a few clicks. <br /> <br />
              Customer centricity is the core of our values. Our team of highly
              trained and experienced doctors, phlebotomists and pharmacists
              looks into each order to give you a fulfilling experience. <br />{" "}
              <br />
              We’ve made healthcare accessible to millions by giving them
              quality care at affordable prices. Customer centricity is the core
              of our values. Our team of highly trained and experienced doctors,
              phlebotomists and pharmacists looks into each order to give you a
              fulfilling experience. <br /> <br />
              Visit our online medical store now, and avail online medicine
              purchase at a discount. Stay Healthy!
            </p>
          </div>
          <hr />
          <div className="info-data">
            <h5>Popular Searches</h5>
            <p>
              Syringe | Hair Care | Men’s Care | Diappers | Sunscreen Products |
              Glucose | Energy Drinks | Body Washes | Facewash | Herbal Juices |
              Antifungal Creams | Talcum Powders | Moov | Horlicks | Omnigel |
              Paracetamol | Nims Tablet | Sinex Medicine | Strepsils | Men’s
              Care | Diappers | Sunscreen Products | Glucose | Energy Drinks |
              Shelcal 500 Tablet | Vicks | Nims | Sunscreen Products | Glucose |
              Energy Drinks | Body Washes | Facewash | Herbal Juices |
              Antifungal Creams | Talcum Powders | Moov | Sunscreen Products |
              Glucose | Energy Drinks | Body Washes | Facewash | Herbal Juices |
              Antifungal Creams | Talcum Powders | Moov | Sunscreen Products |
              Glucose | Energy Drinks | Body Washes | Facewash | Moov
            </p>
          </div>
          <hr />

          <div className="info-data last">
            <div className="left">
              <h5>Registered Office Address</h5>
              <p>
                Rudramati Marg, Anamnagar,
                <br />
                Kathmandu 44600
                <br />
                <br />
                We Operate 7 days: <br />
                7am-7pm
              </p>
            </div>

            <div className="right">
              <h5>For any inquery</h5>
              <p>
                Email us: <br />
                <span>info@doctorsoncall.com.np</span>
                <br />
                <br />
                Call us at: <br />
                <span>014102547, 9801345345</span>
              </p>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section className="semi-footer capsule">
        <div className="box">
          <i>
            <MdLock />
          </i>
          <div>
            <h4>Secure</h4>
            <p>
              Doctor’s on Call Nepal uses Secure Sockets Layer (SSL) 128-bit
              encryption and is Payment Card In00dustry Data Security Standard
              (PCI DSS) compliant
            </p>
          </div>
        </div>
        <div className="box">
          <i>
            <BsShieldFillCheck />
          </i>
          <div>
            <h4>Reliable</h4>
            <p>
              All products displayed on Doctor’s on Call Nepal are procured from
              verified and licensed pharmacies. All labs listed on the platform
              are accredited
            </p>
          </div>
        </div>
        <div className="box">
          <i className="fa-solid fa-wallet"></i>
          <div>
            <h4>Affordable</h4>
            <p>
              Find affordable medicine substitutes, save up to 50% on health
              products, up to 80% off on lab tests and free doctor
              consultations.
            </p>
          </div>
        </div>
      </section> */}

      <footer className="footer">
        <div className="capsule">
          <div className="row">
            <div className="footer-col">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <Link to={`/${ROUTES?.doctorConsultation}`}>
                    Consult Doctors
                  </Link>
                </li>
                <li>
                  <Link to={`/${ROUTES?.nursePackages}`}>Nursing Service</Link>
                </li>
                <li>
                  <Link to={`/${ROUTES?.labTest}`}>Lab Test</Link>
                </li>
                <li>
                  <Link to={`/${ROUTES?.healthPackages}`}>Health Packages</Link>
                </li>
                {/* <li>
                  <Link to={`/${ROUTES?.bookAppointments}`}>
                    Book Appointments
                  </Link>
                </li> */}
                {/* <li>
                  <Link to={`/${ROUTES?.category}`}>Categories</Link>
                </li> */}
              </ul>
            </div>
            <div className="footer-col">
              <h4>Get to Know Us</h4>
              <ul>
                <li>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Let Us Help You</h4>
              <ul>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/account">Your Account</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Connect</h4>
              <div className="social-links">
                <div className="links">
                  <p>Social Links</p>
                  {data?.organization?.facebook_url && (
                    <a href={data?.organization?.facebook_url} target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  )}
                  {data?.organization?.instagram_url && (
                    <a href={data?.organization?.instagram_url} target="_blank">
                      <i className="fab fa-instagram"></i>
                    </a>
                  )}
                  {data?.organization?.twitter_url && (
                    <a href={data?.organization?.twitter_url} target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  )}
                  {data?.organization?.linkedin_url && (
                    <a href={data?.organization?.linkedin_url} target="_blank">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  )}
                  {data?.organization?.youtube_url && (
                    <a href={data?.organization?.youtube_url} target="_blank">
                      <i className="fab fa-youtube"></i>
                    </a>
                  )}
                </div>
                <div>
                  <p>Want daily dose of health?</p>
                  <button onClick={() => setOpen(true)}>
                    <Link to="#">SIGN UP</Link>
                  </button>
                </div>
              </div>
            </div>
            <div className="footer-col">
              <h4>Download App</h4>
              <small>Coming Soon</small>
              <ul className="app-links">
                <li>
                  <a
                    href={data?.organization?.play_store_url ?? "#"}
                    target={data?.organization?.play_store_url ? "_blank" : ""}
                  >
                    <img src="/assets/images/google-play-store.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href={data?.organization?.app_store_url ?? "#"}
                    target={data?.organization?.app_store_url ? "_blank" : ""}
                  >
                    <img src="assets/images/app-store.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-col-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.74185032368!2d85.32173686977539!3d27.696114799999993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb190bb44cf807%3A0x1ca7595ea48bf783!2sDoctors%20On%20Call%20Nepal!5e0!3m2!1sen!2snp!4v1676886560699!5m2!1sen!2snp"
                style={{ border: 0 }}
                // width={600}
                // height={300}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </footer>
      <div className="divider">
        <div className="capsule">
          <hr />
        </div>
      </div>
      <section className="info-main">
        <div className="capsule">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.organization?.footers ?? "",
            }}
          />
          <hr />
          <div className="info-data last">
            <div className="left">
              <h5>Registered Office Address</h5>
              {/* <p>
                      Rudramati Marg, Anamnagar,
                      <br />
                      Kathmandu 44600
                      <br />
                      <br />
                      We Operate 7 days: <br />
                      7am-7pm
                    </p> */}
              <p>{data?.organization?.company_address}</p>
            </div>

            <div className="right">
              <h5>For any inquery</h5>
              <p>
                {data?.organization?.primary_comany_email && (
                  <>
                    Email us: <br />
                    {/* <span>
                      {data?.organization?.primary_comany_email}
                      {data?.organization?.secondary_company_email &&
                        ` / ${data?.organization?.secondary_company_email}`}
                    </span> */}
                    <a href={mailTo(data?.organization?.primary_comany_email)}>
                      {data?.organization?.primary_comany_email}
                    </a>
                    {data?.organization?.secondary_company_email && (
                      <a
                        href={mailTo(
                          data?.organization?.secondary_company_email
                        )}
                      >
                        / {data?.organization?.secondary_company_email}
                      </a>
                    )}
                    <br />
                  </>
                )}
                {data?.organization?.primary_support_contact_number && (
                  <>
                    <br />
                    Call us at: <br />
                    {/* <span>
                      {data?.organization?.primary_support_contact_number}
                      {data?.organization?.secondary_support_contact_number &&
                        ` , ${data?.organization?.secondary_support_contact_number}`}
                    </span> */}
                    <a
                      href={tel(
                        data?.organization?.primary_support_contact_number
                      )}
                    >
                      {data?.organization?.primary_support_contact_number}
                    </a>
                    {data?.organization?.secondary_support_contact_number && (
                      <a
                        href={tel(
                          data?.organization?.secondary_support_contact_number
                        )}
                      >
                        , {data?.organization?.secondary_support_contact_number}
                      </a>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>
          <hr />
        </div>
      </section>
      <AuthModal open={open} setOpen={setOpen} type="SignUp" />
    </>
  );
}
