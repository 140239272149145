import { useState } from "react";
import { PackageModalType } from "../../components/UI/uiComponents/PackageDetailModal";

const useServiceDetail = () => {
  // Package Detail Modal
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<PackageModalType>("health-pkg");
  const [slug, setSlug] = useState<string | undefined>("");
  const handleOpen = (type: PackageModalType, slug?: string) => {
    setType(type);
    setSlug(slug);
    setOpen(true);
  };
  return { open, setOpen, type, slug, handleOpen };
};

export default useServiceDetail;
