import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";
import { ProductAddToCartResponse } from "../../../../../models/product";
import { getDiscountPercent } from "../../../../../utils/discountPercentCalc";
import { resolveImgPath } from "../../../../../utils/imagePathResolver";
import { getPriceStatement } from "../../../../../utils/priceCommas";
import CartQty from "../../CartQty";
import useUiAmt from "../../../../../hooks/useUiAmt";

interface Props {
  prod: ProductAddToCartResponse;
  handleDelete: (prodId?: number) => void;
  setQtyErr: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartProducts = ({ prod, handleDelete, setQtyErr }: Props) => {
  // For showing instantaneous update in price
  const { uiAmt, setUiAmt } = useUiAmt({
    value: prod?.product?.discounted_price ?? prod?.product?.price,
  });

  return (
    <div className="vendible">
      <div className="vendible__action">
        <CloseOutlinedIcon
          // onClick={() => {
          //   setDeleteId(p?.id);
          //   handleOpen();
          // }}
          onClick={() => handleDelete(prod?.id)}
          className="delete-icon"
        />
      </div>
      <div>
        <Link to={`/product-detail/${prod?.product?.slug}`}>
          <img
            className="vendible__img"
            src={
              prod?.product?.images?.length
                ? resolveImgPath(prod?.product?.images[0]?.image)
                : "/assets/images/placeholders/no-image-placeholder.svg"
            }
            //   onClick={() => gotoDetail(prod?.product?.slug)}
            alt={prod?.product?.title}
          />
        </Link>
      </div>
      <div className="chkvendible__content">
        <ul>
          <li>
            <h2 className="title">
              <Link to={`/product-detail/${prod?.product?.slug}`}>
                {prod?.product?.title}
              </Link>
            </h2>
          </li>
          <li>
            {getPriceStatement(
              prod?.product?.discounted_price ?? prod?.product?.price
            )}{" "}
            per piece
          </li>
        </ul>
      </div>
      <div className="cart-qty-flex-grow">
        <CartQty
          product_id={prod?.id}
          qty={prod?.quantity}
          setQtyErr={setQtyErr}
          basePrice={prod?.product?.discounted_price ?? prod?.product?.price}
          setUiAmt={setUiAmt}
        />
      </div>
      <div className="flex__start-express">
        <div className="d-flex flex-column chkvendible__pricing">
          <div className="chkvendible__pricing__dis">
            {/* Asynchronous Price */}
            {/* {getPriceStatement(prod?.total_price)} */}
            {/* Instantaneous Price */}
            {getPriceStatement(uiAmt)}
          </div>
          {getDiscountPercent(
            prod?.product?.price,
            prod?.product?.discounted_price
          ) ? (
            <span className="chkvendible__pricing__off">
              {getDiscountPercent(
                prod?.product?.price,
                prod?.product?.discounted_price
              )}
              % OFF
            </span>
          ) : null}
        </div>
        {/* <div className="chkvendible__discount">
            {p?.product?.discounted_price !==
            p?.product?.price
              ? getPriceStatement(p?.product?.price)
              : null}
          </div> */}
      </div>
    </div>
  );
};

export default CartProducts;
