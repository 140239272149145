import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useAddToPackageWishlistMutation,
  useAddToProductWishlistMutation,
  useAddToTestWishlistMutation,
  useDeleteFromProductWishlistMutation,
} from "../../../../services/wishlist";
import {
  errorMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { RootState } from "../../../../store";
import { Feature } from "../../../../models/feature";

interface Props {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  product_id: number | undefined;
  type?: "product" | "package" | "test";
  setFeature?: React.Dispatch<React.SetStateAction<Feature | undefined>>;
  in_wishlist?: boolean;
  wishlist_id?: number;
}

const WishProduct = ({
  setOpen,
  product_id,
  type = "product",
  setFeature,
  in_wishlist = false,
  wishlist_id,
}: Props) => {
  // For Icon Toggle
  const [wished, setWished] = useState<boolean | undefined>(false);

  // To check if logged in or not.
  const authToken = useSelector(
    (state: RootState) => state.authTokenState.authToken
  );

  const { id: userProfileId } = useSelector(
    (state: RootState) => state.userProfileState.profile
  );

  // API: Add product to Wishlist
  const [
    addToWishlist,
    { error: wishError, isSuccess: isWishSuccess },
  ] = useAddToProductWishlistMutation();

  // API: Add package to Wishlist
  const [
    addPkgToWishlist,
    { error: pkgWishError, isSuccess: isPkgWishSuccess },
  ] = useAddToPackageWishlistMutation();

  // API: Add test to Wishlist
  const [
    addTestToWishlist,
    { error: testWishError, isSuccess: isTestWishSuccess },
  ] = useAddToTestWishlistMutation();

  // API: Remove from Wishlist
  const [
    unWish,
    { error: unWishError, isSuccess: unWishSuccess },
  ] = useDeleteFromProductWishlistMutation();

  // const handleWishlist = () => {
  //   if (!authToken) {
  //     if (setFeature) {
  //       setFeature("wishlist");
  //     }
  //     if (setOpen) setOpen(true);
  //   } else setWished(!wished);
  // };

  const wish = () => {
    if (type === "product") {
      addToWishlist({
        product_id,
        user_id: userProfileId,
      });
    } else if (type === "package") {
      addPkgToWishlist({
        package_id: product_id,
        user_id: userProfileId,
      });
    } else if (type === "test") {
      addTestToWishlist({
        tests_id: product_id,
        user_id: userProfileId,
      });
    }
  };

  const handleWishlist = () => {
    if (!authToken) {
      if (setFeature) {
        setFeature("wishlist");
      }
      if (setOpen) setOpen(true);
    } else {
      if (wished) {
        setWished(false);
        if (wishlist_id) unWish({ product_id: wishlist_id });
      } else {
        setWished(true);
        wish();
      }
    }
  };

  useEffect(() => {
    setWished(in_wishlist);
  }, [in_wishlist]);

  // useEffect(() => {
  //   if (wished) {
  //     if (type === "product") {
  //       addToWishlist({
  //         product_id,
  //         user_id: userProfileId,
  //       });
  //     } else if (type === "package") {
  //       addPkgToWishlist({
  //         package_id: product_id,
  //         user_id: userProfileId,
  //       });
  //     } else if (type === "test") {
  //       addTestToWishlist({
  //         tests_id: product_id,
  //         user_id: userProfileId,
  //       });
  //     }
  //   }
  //   // else if (wished === false) {
  //   //   if (!authToken) setOpen(true);
  //   //   else {
  //   //     unWish({
  //   //       product_id,
  //   //     });
  //   //   }
  //   // }
  // }, [wished]);

  // Side effects: Product Wishlist
  useEffect(() => {
    if (isWishSuccess) {
      toast.success(successMessages?.addToWishlist);
    } else if (wishError) {
      if ("status" in wishError) {
        // For some reason, TS doesn't take wishError.data.Error but, takes wishError.data.error like in login api.
        const errMsg: any =
          wishError?.status === 400
            ? errorMessages?.alreadyInWishlist
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isWishSuccess, wishError]);

  // Side effects: Package Wishlist
  useEffect(() => {
    if (isPkgWishSuccess) {
      toast.success(successMessages?.addToWishlist);
    } else if (pkgWishError) {
      if ("status" in pkgWishError) {
        // For some reason, TS doesn't take pkgWishError.data.Error but, takes pkgWishError.data.error like in login api.
        const errMsg: any =
          pkgWishError?.status === 400 || pkgWishError?.status === 403
            ? errorMessages?.alreadyInWishlist
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isPkgWishSuccess, pkgWishError]);

  // Side effects: Test Wishlist
  useEffect(() => {
    if (isTestWishSuccess) {
      toast.success(successMessages?.addToWishlist);
    } else if (testWishError) {
      if ("status" in testWishError) {
        // For some reason, TS doesn't take testWishError.data.Error but, takes testWishError.data.error like in login api.
        const errMsg: any =
          testWishError?.status === 400 || testWishError?.status === 403
            ? errorMessages?.alreadyInWishlist
            : errorMessages?.networkError;
        toast.error(errMsg);
      } else {
        toast.error(errorMessages?.networkError);
      }
    }
  }, [isTestWishSuccess, testWishError]);

  useEffect(() => {
    if (unWishSuccess) {
      toast.success(successMessages?.deleteFromWishlist);
    } else if (unWishError) {
      toast.error(errorMessages?.deleteFromWishlist);
    }
  }, [unWishSuccess, unWishError]);

  return (
    <div
      className="wishlist"
      onClick={handleWishlist}
      style={{ display: "flex" }}
    >
      {/* <i className="fa-regular fa-heart"></i> */}
      {wished ? (
        <FavoriteIcon sx={{ color: "#ef481e" }} />
      ) : (
        <FavoriteBorderIcon sx={{ color: "#ef481e" }} />
      )}
      <p style={{ marginLeft: "0.5rem" }}>ADD TO WISHLIST</p>
    </div>
  );
};

export default WishProduct;
