import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import "./MyCart.css";
import {
  useDeleteAllProductCartMutation,
  useDeleteFromProductCartMutation,
  useGetProductCartQuery,
} from "../../../../services/cart";
import {
  errorMessages,
  infoMessages,
  successMessages,
} from "../../../../utils/alertMessages";
import ConfirmationModal from "../../uiComponents/ConfirmationModal";
import { ButtonBlue } from "../../uiComponents/Button";
import PackageCart from "./PackagesInCart";
import { ProductAddToCartResponse } from "../../../../models/product";
import useCheckItems from "../../../../hooks/useCheckItems";
import { getPriceStatement } from "../../../../utils/priceCommas";
import NoData from "../../../utils/NoData";
import CartProducts from "../../uiComponents/CartUi/CartProducts";
import Spinner from "../../../utils/Spinner";

export default function MyCart() {
  const navigate = useNavigate();
  // For selecting items to check out.
  const {
    checkedItems,
    isCheckAll,
    handleCheck,
    handleCheckAll,
    handleAutoCheckAll,
  } = useCheckItems();

  // For selecting packages to check out.
  const [checkedPackages, setCheckedPackages] = useState<
    Array<string | undefined>
  >([]);

  // For single delete.
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  // For clear cart.
  const [openClearModal, setOpenClearModal] = useState(false);
  const handleOpenClearModal = () => setOpenClearModal(true);
  const handleCloseClearModal = () => setOpenClearModal(false);

  // Quantity Control
  const [qtyErr, setQtyErr] = useState<boolean>(false);
  // Product Total Amount.
  const [totalAmt, setTotalAmt] = useState<number>(0);
  // Packages Total Amount
  const [pkgTotalAmt, setPkgTotalAmt] = useState<number>(0);
  // No Data
  const [isNoProducts, setIsNoProducts] = useState<boolean>(false);
  const [isNoPackages, setIsNoPackages] = useState<boolean>(false);
  const [loadingPkg, setLoadingPkg] = useState<boolean>(false);

  // Get API
  const {
    data: productsData,
    isLoading: loadingProducts,
    isSuccess: isProductsDataSuccess,
  } = useGetProductCartQuery(null, { refetchOnMountOrArgChange: true });

  // Delete API
  const [
    deleteFromCart,
    { isLoading: deleting, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteFromProductCartMutation();

  // Clear Product Cart API
  const [
    clearCart,
    { isLoading: clearing, isSuccess: isClearSuccess, isError: isClearError },
  ] = useDeleteAllProductCartMutation();

  const handleDeleteFromCart = (product_id: number | undefined) => {
    deleteFromCart({ product_id });
  };

  const handleDelete = (prodId?: number) => {
    setDeleteId(prodId);
    handleOpen();
  };

  const handleClearCart = () => {
    clearCart();
  };

  // Go to Checkout Page and send the selected cart items data.
  const handleCheckout = () => {
    // navigate("/checkout", { state: checkedItems });
    navigate("/checkout", {
      state: {
        products: checkedItems,
        packages: checkedPackages,
      },
    });
  };

  // Check All
  const handleSelectAll = () => {
    if (productsData?.products?.length) {
      handleCheckAll(productsData?.products?.map((d) => d?.id?.toString()));
    }
  };

  // Check specific items
  const handleSelect = (e: any) => {
    const { value, checked } = e.target;
    handleCheck(value, checked);
  };

  useEffect(() => {
    if (isProductsDataSuccess) {
      if (productsData?.products?.length) {
        setIsNoProducts(false);
        setTotalAmt(
          productsData?.products?.reduce((total, obj) => {
            return total + (obj?.total_price ? obj?.total_price : 0);
          }, 0)
        );
        // By Default all items will be checked
        handleAutoCheckAll(
          productsData?.products?.map((d) => d?.id?.toString())
        );
      } else {
        setIsNoProducts(true);
        setTotalAmt(0);
      }
    }
  }, [isProductsDataSuccess, productsData]);

  useEffect(() => {
    if (isDeleteError) toast.error(errorMessages?.networkError);
    else if (isDeleteSuccess) {
      toast.success(successMessages?.deleteFromCart);
      handleClose();
    }
  }, [isDeleteError, isDeleteSuccess]);

  useEffect(() => {
    if (isClearError) toast.error(errorMessages?.networkError);
    else if (isClearSuccess) {
      toast.success(successMessages?.clearCart);
      handleCloseClearModal();
    }
  }, [isClearError, isClearSuccess]);

  return (
    <div className="outlet">
      <h3>My Cart </h3>
      {loadingProducts && loadingPkg ? (
        <Spinner />
      ) : (
        <>
          <div
            className="row"
            style={{
              justifyContent: productsData?.products?.length
                ? "space-between"
                : "flex-end",
              marginBottom: 10,
            }}
          >
            {productsData?.products?.length ? (
              <h5>
                Products in My Cart ({productsData?.products?.length ?? 0})
              </h5>
            ) : null}
            {isNoProducts && isNoPackages ? null : (
              <>
                <p className="clear-cart" onClick={handleOpenClearModal}>
                  Clear my cart
                </p>
              </>
            )}
          </div>

          {productsData?.products?.length ? (
            <>
              <div className="subject">
                {/* <input
                  type="checkbox"
                  id="selectAll"
                  name="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                /> */}
                <label htmlFor="selectAll"> Items </label>
              </div>

              <div className="products">
                {productsData?.products?.length ? (
                  <>
                    {productsData?.products?.map(
                      (p: ProductAddToCartResponse, idx: number) => (
                        <div className="product" key={idx}>
                          <CartProducts
                            prod={p}
                            handleDelete={handleDelete}
                            setQtyErr={setQtyErr}
                            // uiTotalAmt={uiTotalAmt}
                            // setUiTotalAmt={setUiTotalAmt}
                          />
                          {/* Modal for singular delete */}
                          <ConfirmationModal
                            open={open}
                            setOpen={setOpen}
                            loading={deleting}
                            yesAction={() => handleDeleteFromCart(deleteId)}
                          />
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <Alert severity="error">
                    {errorMessages?.noProductsInCart}
                  </Alert>
                )}
              </div>
            </>
          ) : null}

          {/* Packages in Cart */}
          <PackageCart
            totalAmt={pkgTotalAmt}
            setTotalAmt={setPkgTotalAmt}
            setCheckedPackages={setCheckedPackages}
            setIsNoPackages={setIsNoPackages}
            setLoadingPkg={setLoadingPkg}
          />

          {/* Modal for Bulk Delete */}
          <ConfirmationModal
            open={openClearModal}
            setOpen={setOpenClearModal}
            loading={clearing}
            yesAction={handleClearCart}
          />

          {/* Product Cart Total */}
          {isNoProducts && isNoPackages ? null : (
            <>
              <div className="total">
                <div>
                  <p className="total-label">
                    Total Cart Value:{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      {/* Asynchronous Total Price */}
                      {getPriceStatement(totalAmt + pkgTotalAmt)} /-
                    </span>
                  </p>
                </div>
                <div>
                  <ButtonBlue
                    name="Proceed To Checkout"
                    onClick={handleCheckout}
                    disabled={
                      qtyErr ||
                      !(totalAmt + pkgTotalAmt) ||
                      (!checkedItems?.length && !checkedPackages?.length)
                    }
                    wrapSpace={true}
                  />
                </div>
              </div>
              {!checkedItems?.length && !checkedPackages?.length ? (
                <div style={{ /* width: "60%", */ paddingRight: "6px" }}>
                  <Alert severity="info">{infoMessages?.selectCartItems}</Alert>
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {isNoProducts && isNoPackages && (
        <NoData imgWidth="20%" msg="No Products in Cart" />
      )}
    </div>
  );
}
