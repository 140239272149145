import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { useGetDeliveryAddressQuery } from "../../../../../services/user";
import { errorMessages } from "../../../../../utils/alertMessages";
import "../../../VerticalAppBarComponents/ShippingAddress/ShippingAddress.css";
import DeliveryAddressModal from "../../DeliveryAddressModal";
import { ButtonBlue } from "../../Button";
import DeliveryAddressEditModal from "../../DeliveryAddressModal/Edit";
import { AddressType } from "../../CheckoutAccordion";

interface Props {
  setExpanded: React.Dispatch<
    React.SetStateAction<"step1" | "step2" | "step3" | "step4">
  >;
  // setStep3: React.Dispatch<React.SetStateAction<boolean>>;
  setNextStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBillAddr: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedShipAddr: number | undefined;
  setConfirmedBillAddr?: React.Dispatch<React.SetStateAction<AddressType>>;
}

const BillingAddress = ({
  setExpanded,
  // setStep3,
  setNextStep,
  setSelectedBillAddr,
  selectedShipAddr,
  setConfirmedBillAddr,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // For Edit
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const [editAddressId, setEditAddressId] = useState<string>("");
  // Address Radio
  const [selectedAddress, setSelectedAddress] = useState<string>(
    "same-as-ship"
  );

  // Get Added Delivery Addresses
  const {
    data: delAddrData,
    isError: isDelAddrError,
  } = useGetDeliveryAddressQuery(null, { refetchOnMountOrArgChange: true });

  const handleSelectAddress = (e: any, id: number | undefined) => {
    setSelectedAddress(e?.target?.value);
    setSelectedBillAddr(id);
    // For Checkout Confirmation Modal
    const selected = delAddrData?.results?.find((d) => d?.id === id);
    if (selected && setConfirmedBillAddr) {
      setConfirmedBillAddr({
        name: selected?.receiver_name,
        phone: selected?.receiver_contact_number,
        province: selected?.state,
        district: selected?.city,
        address: selected?.address,
      });
    }
  };

  useEffect(() => {
    if (delAddrData) {
      if (delAddrData?.results) {
        // Find the default billing address.
        const defaultAddress = delAddrData?.results?.find(
          (d) => d?.is_default && d?.is_only_billing_address
        );
        setSelectedAddress(
          defaultAddress?.id ? `address-${defaultAddress?.id}` : "same-as-ship"
        );
        setSelectedBillAddr(defaultAddress?.id ?? selectedShipAddr);
        // For Checkout Confirmation Modal
        if (setConfirmedBillAddr) {
          setConfirmedBillAddr({
            name: defaultAddress?.receiver_name,
            phone: defaultAddress?.receiver_contact_number,
            province: defaultAddress?.state,
            district: defaultAddress?.city,
            address: defaultAddress?.address,
          });
        }
      }
    }
  }, [delAddrData]);

  // For when delivery address changes when in billing address "Same as Shipping" was selected.
  useEffect(() => {
    if (selectedShipAddr && selectedAddress === "same-as-ship") {
      setSelectedBillAddr(selectedShipAddr);
      // For Checkout Confirmation Modal
      const selected = delAddrData?.results?.find(
        (d) => d?.id === selectedShipAddr
      );
      if (selected && setConfirmedBillAddr) {
        setConfirmedBillAddr({
          name: selected?.receiver_name,
          phone: selected?.receiver_contact_number,
          province: selected?.state,
          district: selected?.city,
          address: selected?.address,
        });
      }
    }
  }, [selectedShipAddr]);

  return (
    <Typography>
      <div className="accordionInputLabel">
        <input
          type="radio"
          id="same-as-ship"
          name="billing-addresses"
          value="same-as-ship"
          checked={selectedAddress === "same-as-ship"}
          onChange={(e) => handleSelectAddress(e, selectedShipAddr)}
        />
        <label htmlFor="same-as-ship">
          <p className="labelP">Same as Shipping Address</p>
          {selectedAddress === "same-as-ship" && (
            <ButtonBlue
              name="Continue"
              onClick={() => {
                // setExpanded("step3");
                setExpanded("step4");
                // setStep3(true);
                setNextStep(true);
              }}
              disabled={!selectedAddress}
            />
          )}
        </label>
      </div>
      {delAddrData?.results?.length ? (
        delAddrData?.results
          ?.filter((d) => d?.is_only_billing_address)
          ?.map((d, idx) => (
            <div className="accordionInputLabel" key={idx}>
              <input
                type="radio"
                id={`address-${d?.id}`}
                name="billing-addresses"
                value={`address-${d?.id}`}
                checked={selectedAddress === `address-${d?.id}`}
                onChange={(e) => handleSelectAddress(e, d?.id)}
              />
              {/* <label htmlFor="address1"> */}
              <label htmlFor={`address-${d?.id}`} className="address-wrapper">
                <div className="addressDiv">
                  <div className="personalInfo">
                    <p className="name">
                      {/* Ramesh Kumar,<span className="number">9800000000</span> */}
                      {d?.receiver_name},
                      <span className="number">
                        {d?.receiver_contact_number}
                      </span>
                    </p>
                    <p
                      className="edit"
                      onClick={(e) => {
                        e?.preventDefault();
                        setEditAddressId(d?.id?.toString());
                        handleOpenEdit();
                      }}
                    >
                      Edit
                    </p>
                  </div>

                  <p className="location">
                    {d?.address}, {d?.city}, {d?.state}, {d?.country}
                  </p>
                  {selectedAddress === `address-${d?.id}` && (
                    <ButtonBlue
                      name="Continue"
                      onClick={() => {
                        // setExpanded("step3");
                        setExpanded("step4");
                        // setStep3(true);
                        setNextStep(true);
                      }}
                      disabled={!selectedAddress}
                    />
                  )}
                </div>
              </label>
            </div>
          ))
      ) : (
        <Alert severity="error">{errorMessages?.noDeliveryAddress}</Alert>
      )}

      <div className="add-new">
        <p onClick={handleOpen}>
          <AddCircleOutlineSharpIcon /> Add a new address/guest
        </p>
      </div>

      {/* Add New Address */}
      <DeliveryAddressModal open={open} setOpen={setOpen} type="billing" />
      {/* Edit Address */}
      <DeliveryAddressEditModal
        open={openEdit}
        setOpen={setOpenEdit}
        id={editAddressId}
        type="billing"
      />

      <div style={{ width: "100%" }}>
        {/* Get Delivery Address Error */}
        {isDelAddrError && (
          <Alert severity="error">{errorMessages?.deliveryAddress}</Alert>
        )}
      </div>
    </Typography>
  );
};

export default BillingAddress;
