import { useState, MouseEvent, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, MenuItem, Divider } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { RootState } from "../../store";
import { removeAuthToken } from "../../features/auth/authTokenSlice";
import { ROUTES } from "../../utils/routeNames";
import useWindowSize from "../../hooks/useWindowSize";
import AuthModal from "../UI/uiComponents/AuthModals";
import { useGetConfigQuery } from "../../services/config";
import {
  useGetPackageCartQuery,
  useGetProductCartQuery,
} from "../../services/cart";
import CartNav from "../UI/uiComponents/CartNav";
import NavSearchInput from "../UI/uiComponents/NavSearchInput";
import TopNav from "./TopNav";
import "../../styles/Nav.css";

export interface ResetPwStateType {
  email: String | undefined;
  key: String | undefined;
}

export default function Nav() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(
    (state: RootState) => state?.authTokenState?.authToken
  );
  // For Close on outside click on Mobile
  const navRef = useRef<HTMLDivElement>(null);
  // const clickOutsidehandler = () => {
  //   closeNav();
  // };
  // useOnClickOutside(navRef, clickOutsidehandler);

  // Total Cart Items
  const [totalItems, setTotalItems] = useState<number>(0);

  // API
  const { data: configData } = useGetConfigQuery();
  const { data: productsCartData } = useGetProductCartQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const { data: packagesCartData } = useGetPackageCartQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // Set Total Cart Items
  useEffect(() => {
    if (productsCartData?.products ?? packagesCartData) {
      setTotalItems(
        (productsCartData?.products?.length ?? 0) +
          (packagesCartData?.length ?? 0)
      );
    }
  }, [productsCartData, packagesCartData]);

  // Get Window Width
  const windowWidth = useWindowSize();
  const [pathChecker, setpathChecker] = useState("");

  useEffect(() => {
    if (pathname) {
      setpathChecker(pathname);
    }
  }, [pathname]);

  const [lowerNavStyle, setLowerNavStyle] = useState("");
  const [NavOn, setNavOn] = useState(false);

  // Profile Dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorEl);
  const handleProfileOpen = (event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  // Cart Dropdown
  const [cartAnchorEl, setCartAnchorEl] = useState<null | HTMLElement>(null);
  const openCart = Boolean(cartAnchorEl);
  const handleCartOpen = (event: MouseEvent<HTMLAnchorElement>) => {
    setCartAnchorEl(event.currentTarget);
  };
  const handleCartClose = () => {
    setCartAnchorEl(null);
  };

  const openNav = () => {
    setLowerNavStyle("-1%");
    setNavOn(true);
  };

  const closeNav = () => {
    if (windowWidth <= 1200) {
      setLowerNavStyle("-100%");
      setNavOn(false);
    }
  };

  // For Auth Modals.
  const [open, setOpen] = useState(false);

  // Logout
  const handleLogout = () => {
    // This action will remove "authToken" from both global state and localstorage.
    dispatch(removeAuthToken());
    location.reload();
  };

  return (
    <>
      <div id="nav" className="nav">
        {configData?.organization && <TopNav data={configData?.organization} />}
        <div className="upper-nav capsule">
          {/* <!-- Opening nav icon for mobile devicce --> */}
          <i onClick={openNav} className="fa-solid fa-bars open-nav-logo"></i>

          <div className="logo-div">
            <Link to="/">
              <img
                className="logo"
                src="https://cdn.asparksys.com/medias/1672827903102.png"
                alt=""
                height={35}
              />
            </Link>
          </div>

          <div className="right-side">
            <NavSearchInput NavOn={NavOn} open={open} closeNav={closeNav} />

            <div className="icons">
              {authToken ? (
                <>
                  <Link to="/wishlist" className="wishlist">
                    <i>
                      <FavoriteBorderOutlinedIcon />
                    </i>
                  </Link>

                  <Link
                    to="#"
                    className="cart"
                    id="basic-button"
                    aria-controls={openCart ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openCart ? "true" : undefined}
                    onMouseEnter={handleCartOpen}
                  >
                    <i>
                      <ShoppingCartOutlinedIcon />
                    </i>
                    <p
                      style={{
                        backgroundColor: "#ef481e",
                        color: "white",
                        position: "absolute",
                        fontSize: "12px",
                        fontWeight: "bold",
                        top: "-14px",
                        right: "-10px",
                        padding: "0px 6px",
                        borderRadius: "50%",
                      }}
                    >
                      {totalItems}
                    </p>
                    <CartNav
                      cartAnchorEl={cartAnchorEl}
                      openCart={openCart}
                      handleCartClose={handleCartClose}
                      productsCartData={productsCartData}
                      packagesCartData={packagesCartData}
                    />
                  </Link>
                  <Link
                    to="#"
                    className="profile"
                    id="basic-button"
                    aria-controls={openProfile ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openProfile ? "true" : undefined}
                    onClick={handleProfileOpen}
                  >
                    {/* <i className="fa-regular fa-user"></i> */}
                    <i>
                      <PersonOutlinedIcon />
                    </i>
                  </Link>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openProfile}
                    onClose={handleProfileClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/account");
                        handleProfileClose();
                      }}
                    >
                      <ListItemIcon>
                        <PersonOutlinedIcon
                          fontSize="small"
                          sx={{ minWidth: 24 }}
                        />
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutOutlinedIcon
                          fontSize="small"
                          sx={{ minWidth: 24 }}
                        />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <a
                  href="#"
                  className="login"
                  // onClick={() => handleModalType("Login")}
                  onClick={() => setOpen(true)}
                >
                  <i className="fa-solid fa-arrow-right-to-bracket"></i>
                </a>
              )}

              {/* Modallll */}
              <AuthModal open={open} setOpen={setOpen} />
              {/* Modal  ends */}
            </div>
          </div>
        </div>

        <div
          className="lower-nav capsule"
          style={windowWidth <= 1200 ? { left: lowerNavStyle } : {}}
          ref={navRef}
        >
          {/* <!-- Closing icon for mobile devices  --> */}
          <i
            onClick={closeNav}
            className="fa-solid fa-xmark close-nav-logo"
          ></i>
          <ul>
            <li
              className={pathChecker === "/" ? "active" : ""}
              onClick={closeNav}
            >
              <Link to="/"> HOME</Link>
            </li>
            <li
              className={
                pathChecker === `/${ROUTES?.doctorConsultation}` ? "active" : ""
              }
              onClick={closeNav}
            >
              <Link to={`/${ROUTES?.doctorConsultation}`}>CONSULT DOCTORS</Link>
            </li>
            <li
              className={
                pathChecker === `/${ROUTES?.nursePackages}` ? "active" : ""
              }
              onClick={closeNav}
            >
              <Link to={`/${ROUTES?.nursePackages}`}>NURSING SERVICE</Link>
            </li>
            <li
              className={pathChecker === `/${ROUTES?.labTest}` ? "active" : ""}
              onClick={closeNav}
            >
              <Link to={`/${ROUTES?.labTest}`}>LAB TEST</Link>
            </li>
            <li
              className={
                pathChecker === `/${ROUTES?.healthPackages}` ? "active" : ""
              }
              onClick={closeNav}
            >
              <Link to={`/${ROUTES?.healthPackages}`}>HEALTH PACKAGES</Link>
            </li>
            <li
              className={
                pathChecker === `/${ROUTES?.contactUs}` ? "active" : ""
              }
              onClick={closeNav}
            >
              <Link
                to={`/${ROUTES?.contactUs}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LocalPhoneIcon
                  fontSize="small"
                  style={{ marginRight: 4, width: "0.8em", height: "0.8em" }}
                />
                GET A CALLBACK
              </Link>
            </li>
            {/* <li
              className={pathChecker === `/${ROUTES?.career}` ? "active" : ""}
              onClick={closeNav}
            >
              <Link
                to={`/${ROUTES?.career}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                CAREERS
              </Link>
            </li> */}
          </ul>
        </div>

        {NavOn && windowWidth <= 1200 ? (
          <div className="nav-backdrop" onClick={closeNav}></div>
        ) : null}
      </div>
    </>
  );
}
