import VerticalAppBar from "../../components/UI/uiComponents/VerticalAppBar";
import SideMenu from "./SideMenu";
export default function MyAccount() {
  return (
    <>
      <div
        className="capsule"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 12,
          paddingRight: 26,
        }}
      >
        <SideMenu />
      </div>
      <VerticalAppBar />
    </>
  );
}
