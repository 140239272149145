import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import style from "../../../../../styles/Otp.module.css";
import Alert from "@mui/material/Alert";
import Input from "../../Formik/Input";
import PasswordInput from "../../Formik/PasswordInput";
// Icons
import LockIcon from "@mui/icons-material/Lock";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { /* ButtonBlue, */ FormButtonBlue } from "../../Button";
// Models
import {
  confirmPasswordValidation,
  passwordValidation,
  stringValidation,
} from "../../../../../validations";
import { ResetPwStateType } from "../../../../layouts/Nav";
// API services
import { useResetPasswordMutation } from "../../../../../services/auth";
import {
  errorMessages,
  infoMessages,
  successMessages,
} from "../../../../../utils/alertMessages";

interface Props {
  onClose: Function;
  switchModalType: Function;
  handleResetPwState: Function;
  resetPwState: ResetPwStateType;
}

interface Values {
  token: string;
  password: string;
  confirmPassword: string;
}

const initialValues = {
  token: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  token: stringValidation(),
  password: passwordValidation(),
  confirmPassword: confirmPasswordValidation("password"),
});

export default function ResetPassword({
  onClose,
  switchModalType,
  handleResetPwState,
  resetPwState,
}: Props) {
  const [alertMsg, setAlertMsg] = useState<String>("");
  const [
    resetPassword,
    { isLoading, error, isSuccess },
  ] = useResetPasswordMutation();

  const handleResetPw = async (values: Values) => {
    resetPassword({
      email: resetPwState?.email,
      password: values?.password,
      key: resetPwState?.key,
      token: values?.token,
    });
  };

  useEffect(() => {
    if (error) {
      if ("data" in error) {
        const errMsg: any = "error" in error ? error.error : error.data;
        setAlertMsg(errMsg?.detail || errorMessages?.networkError);
      } else {
        setAlertMsg(errorMessages?.networkError);
      }
    }
    if (isSuccess) {
      setAlertMsg(successMessages?.resetPw);
      setTimeout(() => switchModalType("Login"), 3000);
      handleResetPwState({
        email: undefined,
        key: undefined,
      });
    }
  }, [error, isSuccess]);

  return (
    <>
      <div className={style.box}>
        <i
          onClick={() => {
            onClose();
          }}
          className="fa-solid fa-xmark"
          style={{
            color: "#000",
            position: "absolute",
            right: "5%",
            top: "3%",
            fontSize: "1.2rem",
            cursor: "pointer",
            zIndex: 99,
          }}
        ></i>
        <div className={style.img} />
        <div className={style.loginPanel}>
          <h3>Reset Password</h3>
          <Alert severity="info">{infoMessages?.resetPasswordOtpEnter}</Alert>
          <Formik
            initialValues={initialValues}
            onSubmit={handleResetPw}
            validationSchema={validationSchema}
          >
            <Form style={{ marginTop: 14 }}>
              <Input
                type="text"
                name="token"
                label="OTP Token"
                placeholder="Enter OTP Token"
                labelLogo={<KeyOutlinedIcon />}
              />
              <PasswordInput
                type="password"
                name="password"
                label="New Password"
                placeholder="Enter Password"
                labelLogo={<LockIcon />}
              />
              <PasswordInput
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter Password"
                labelLogo={<LockIcon />}
              />
              {error ? (
                <Alert severity="error">{alertMsg}</Alert>
              ) : isSuccess ? (
                <Alert severity="success">{alertMsg}</Alert>
              ) : null}
              <FormButtonBlue
                name={isLoading ? "Resetting Password..." : "Reset Password"}
                type="submit"
                disabled={isLoading}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
