import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { appendNewParam } from "../../../utils/serializeQueryString";

interface Props {
  reset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPage?: React.Dispatch<React.SetStateAction<boolean>>;
  type: "search" | "viewAll";
}

const SortBy = ({ reset, setReset, setResetPage, type }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Get search params
  const order = searchParams.get("order");
  // Sort By
  // To change sort by dropdown when user directly goes into the view all page by using a url (Especially in a banner of special offers).
  const [sortBy, setSortBy] = useState<string>(order ? order : "");

  const handleSortBy = (e: any) => {
    setSortBy(e?.target?.value);
    if (setResetPage) setResetPage(true);
  };

  useEffect(() => {
    if (type === "search") {
      setSearchParams(appendNewParam(searchParams, { sort: sortBy }));
    } else {
      setSearchParams(appendNewParam(searchParams, { order: sortBy }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (reset) {
      setSortBy("");
      setReset(false);
    }
  }, [reset]);

  return (
    <div className="sort-view">
      <div className="sort-by">
        <label htmlFor="sort">Sort By:</label>
        <select
          onChange={handleSortBy}
          id="sort"
          name="sortlist"
          form="sortform"
          value={sortBy}
        >
          {type === "search" ? (
            <>
              <option value="">Relevance</option>
              <option value="ascend">Price: Low to High</option>
              <option value="descend">Price: High to Low</option>
              <option value="popularity">Popularity</option>
              <option value="latest">Newest</option>
            </>
          ) : (
            <>
              <option value="">Relevance</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export default SortBy;
