import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { FAQModel } from "../../models/faq";

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getFAQLists: builder.query<FAQModel, void>({
      query: () => {
        return {
          url: `/org/faq/`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetFAQListsQuery } = faqApi;
