import { useState } from "react";
import "../../../styles/HealthPackages.css";
import style from "../../../styles/TestPackagesCards.module.css";
import { resolveImgPath } from "../../../utils/imagePathResolver";
import { PackageTest } from "../../../models/package";
import AuthModal from "../uiComponents/AuthModals";
import PackageDetailModal from "../uiComponents/PackageDetailModal";
import { shortenArray } from "../../../utils/shortenArray";
import AddToCartBtn from "../uiComponents/Buttons/AddToCart";
import { Feature } from "../../../models/feature";
import { getPriceStatement } from "../../../utils/priceCommas";

interface Props {
  id?: number | undefined;
  slug?: string | undefined;
  header: string | undefined;
  image: string | undefined;
  tests?: string[] | undefined;
  healthPackageTests?: PackageTest[] | undefined;
  actualPrice: string | number | undefined;
  crossedPrice: string | number | undefined;
  type: "health-pkg" | "test-pkg";
}

export default function HealthPackagesCards({
  id = undefined,
  slug = undefined,
  header,
  image,
  tests = [],
  healthPackageTests = [],
  actualPrice,
  crossedPrice,
  type,
}: Props) {
  // Modal States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // Auth Modal
  const [openAuthModal, setOpenAuthModal] = useState(false);
  // For Clicked Feature
  const [feature, setFeature] = useState<Feature | undefined>(undefined);

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div className={style.table}>
          <div onClick={handleOpen}>
            <div className={style.imageSection}>
              <img
                src={image ? resolveImgPath(image) : "assets/images/Vector.png"}
                alt="Packages"
              />
            </div>
            <h2 className={style.packageName}>{header}</h2>
            <ul className={style.features}>
              {tests?.length
                ? shortenArray(tests)?.map((test, idx) => (
                    <li
                      key={idx}
                      className={idx === 3 ? style.more : ""}
                      onClick={() => idx === 3 && handleOpen()}
                    >
                      <span className={style.icon}>
                        <i className="fas fa-calendar-check"></i>
                      </span>
                      <span className={style.listName}>{test}</span>
                    </li>
                  ))
                : healthPackageTests?.length
                ? shortenArray(healthPackageTests?.map((d) => d?.title))?.map(
                    (test, idx) => (
                      <li
                        key={idx}
                        className={idx === 3 ? style.more : ""}
                        onClick={() => idx === 3 && handleOpen()}
                      >
                        <span className={style.icon}>
                          <i className="fas fa-calendar-check"></i>
                        </span>
                        <span className={style.listName}>{test}</span>
                      </li>
                    )
                  )
                : null}
            </ul>
            <div className={style.price}>
              {actualPrice ? (
                <h1>
                  {getPriceStatement(actualPrice)}{" "}
                  <span>{getPriceStatement(crossedPrice)}</span>
                </h1>
              ) : (
                <h1>{getPriceStatement(crossedPrice)}</h1>
              )}
            </div>
          </div>
          <div className={style.buttons}>
            <AddToCartBtn
              setOpen={setOpenAuthModal}
              id={id}
              type={type === "health-pkg" ? "package" : "test"}
              size="small"
              setFeature={setFeature}
            />
            <button className={style.blueButton} onClick={handleOpen}>
              View
            </button>
          </div>
        </div>
      </div>
      <AuthModal
        open={openAuthModal}
        setOpen={setOpenAuthModal}
        feature={feature}
      />
      <PackageDetailModal
        open={open}
        setOpen={setOpen}
        setOpenAuthModal={setOpenAuthModal}
        slug={slug}
        type={type}
        hasAddToCart={false}
        setFeature={setFeature}
      />
    </>
  );
}
