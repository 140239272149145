import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";
import { AllProducts, Product } from "../../models/product";
import { SearchResults } from "../../models/search";
import { getLocalAuthToken } from "../../storage/localStorage";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getLocalAuthToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllProducts: builder.query<
      AllProducts,
      {
        offset?: number;
        order?: string;
        min_price?: string;
        max_price?: string;
        is_featured?: boolean;
        is_top_selling?: boolean;
        is_most_viewed?: boolean;
        is_just_for_you?: boolean;
      }
    >({
      query: ({
        offset,
        order,
        min_price,
        max_price,
        is_featured,
        is_top_selling,
        is_most_viewed,
        is_just_for_you,
      }) => {
        return {
          url: `/products/`,
          method: "GET",
          params: {
            offset,
            order,
            min_price,
            max_price,
            is_featured,
            is_top_selling,
            is_most_viewed,
            is_just_for_you,
          },
        };
      },
    }),

    // Moved to wishlist for invalidation purpose.
    // getProductDetails: builder.query<
    //   ProductDetail,
    //   { slug: string | undefined }
    // >({
    //   query: ({ slug }) => {
    //     return {
    //       url: `/products/${slug}/`,
    //       method: "GET",
    //     };
    //   },
    // }),

    searchProducts: builder.query<
      {
        product_you_may_like: Product[];
        results: Product[];
      },
      { q: string | null }
    >({
      query: ({ q }) => {
        return {
          url: `/products/${q}`,
          method: "GET",
        };
      },
    }),

    searchAll: builder.query<SearchResults, { q: string | null }>({
      query: ({ q }) => {
        return {
          url: `/search/${q}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  // useGetProductDetailsQuery,
  useLazySearchProductsQuery,
  useLazySearchAllQuery,
} = productApi;
